import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  width: 100%;
  height: 100%;

  &.pulseAnimation {
    animation: pulseAnimationCss 2s infinite;
  }
  
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    
    > div {
      color: ${props => props.theme.colors.gray};
      
      > h2 {
        color: ${props => props.theme.colors.white};
        font-size: 1rem;
        margin-bottom: 0.25rem;
        
        > span {
          font-size: 14px;
          color: ${props => props.theme.colors.muted};
          font-weight: 500;
        }
      }
      
      > p {
        color: ${props => props.theme.colors.gray};
        font-size: 14px;
      }
      
      > button {
        background: none;
        cursor: pointer;
        
        &:hover {
          background: ${props => props.theme.gradients.primary};
        }
      }

      svg {
        color: ${props => props.theme.colors.muted}80;
      }
    }
    
  }
  
  > main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;
    
    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      
      > aside {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem;
        border-radius: ${props => props.theme.radius.primary}; 
        background: #ffffff0f;
        border: 1px solid ${props => props.theme.cards.border};
        border-radius: ${props => props.theme.radius.secondary};
        backdrop-filter: blur(5px) saturate(122%);
        
        > h5 {
          color: ${props => props.theme.colors.white};
          font-weight: 600;
          margin-bottom: 0.25rem;
          white-space: nowrap;
        }
      }
    }
    
    > div:nth-child(2) {

      > p {
        color: ${props => props.theme.colors.gray};
        font-size: 14px;
      }

    }
  }  

  > footer {
    padding-left: 0.5rem;

    > a {
      display: flex;
      align-items: center;
      color: ${props => props.theme.colors.white};
      text-decoration: none;
      font-size: 0.875rem;
      text-transform: none;
      vertical-align: unset;
      text-decoration: none;
      font-weight: 600;
      cursor: pointer;
      font-weight: 600;
      transition: all .2s ease-in-out;

      > svg {
        font-size: 18px;
        transition: all .2s ease-in-out;
      }

      &:hover {
        
        > svg {
          margin-left: 0.25rem;
        }
      }
    }
  }
`;

export const ProgressInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > h2 {
    font-size: 3.2rem;

    &:after {
      content: '%';
    }
  }

  > p {
    color: ${props => props.theme.colors.muted};
    font-size: 1rem;
  }
`;