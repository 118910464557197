import styled from 'styled-components';

export const Container = styled.div`
`;

export const CardTableContainer = styled.section`

`;

export const HeaderSection = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;

  > aside {
    display: flex;
    gap: 1rem;

    > button {
      padding: 16px;
      text-transform: capitalize;
      font-size: 13px;
      border-radius: 6px;

      > svg {
        margin-right: 0.35rem;
      }
    }

    > section {

      > span {
        font-size: 11px;
        margin-right: 1rem;
      }
      
      > select {
        color: ${({ theme }) => theme.colors.white};
        height: 45px;
        width: 180px;
        border: 1px solid ${({ theme }) => theme.cards.border};
        padding: 0 0.5rem;
        border-radius: 6px;
        background: ${({ theme }) => theme.cards.primary};

        > option {
          background: ${({ theme }) => theme.modal.background};
        }
      }
    }

  }
`;

export const StatusBadge = styled.span`
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
`;

export const ModalContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

export const Line1 = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  color: ${({ theme }) => theme.colors.white};
  
  > h4 {
    font-size: 12px;
    margin: 1rem 0 0.75rem 1rem;
    font-weight: 600;
  }

  > div {
    gap: 1rem;
    margin-bottom: 1rem;
    > button {
      width: 100%;
      margin-top: -1rem;
    }
  }
`;

export const Line2 = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr ;
  gap: 1rem;
`;

export const ButtonContext = styled.button`
  border-radius: 4px;
  margin-right: 16px;
`;

export const Poster = styled.a`
  padding: 1rem 0;

  > img {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

`;
