import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';

import { Container, ContentGrid2, AbsoluteHeader, VoteButton } from './styles';
import LoaderBIO from '../../../components/Loader';
import api from '../../../services/api';
import AvatarProgress from '../../../components/AvatarProgress';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { House } from 'phosphor-react';
import { API_S3 } from '../../../services/api_s3';

export interface IArticleCategoryProps {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  position: number;
  created_at: string;
  updated_at: string;
}

interface IArticleProps {
  id: string;
  section_id: string;
  title: string;
  body: string;
  slug: string;
  is_promoted: boolean;
  abstract: string;
  vote_count: number;
  vote_sum: number;
  created_at: string;
  updated_at: string;
  reading_time: string;
}

const SuporteTecnicoPost: React.FC = () => {
  const { slug, article_slug } = useParams<{ slug: string, article_slug: string }>();

  const [loading, setLoading] = useState(true);
  const [userVote, setUserVote] = useState<'upvote' | 'downvote'>();

  const [articleCategory, setArticleCategory] = useState<IArticleCategoryProps>({} as IArticleCategoryProps);
  const [article, setArticle] = useState<IArticleProps>({} as IArticleProps);
  const [releatedArticle, setReleatedArticle] = useState<IArticleProps[]>([]);

  // List ARTICLE CATEGORY FOR BREADCUBE
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`/sections/${slug}`);

        console.log('LIST CATEGORY  >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setArticleCategory(response.data);
      } catch (error) {
        console.log(error);
      } finally {

      }
    }

    loadData();
  }, [slug, article_slug]);

  // List SINGLE ARTICLE
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get(`/sections/${slug}/articles/${article_slug}`);

        console.log('LIST ARTICLE >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setArticle(response.data);

        if (response.data.userVote) {
          setUserVote(response.data.userVote.type);
        }

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [slug, article_slug]);

  // List RELEATED ARTICLES
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get(`/sections/${slug}/articles/related`,
          { params: { limit: 5 } }
        );

        console.log('LIST RELEATED ARTICLES  >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setReleatedArticle(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [slug, article_slug]);


  const handleUtilArticle = useCallback(async (type: 'upvote' | 'downvote') => {
    try {
      const response = await api.post(`/sections/${slug}/articles/${article_slug}/vote`, {
        "type": type
      });

      console.log(response.data);
      setUserVote(type);
    } catch (error) {
      console.log(error);
    } finally {
      //
    }
  }, [article_slug, slug]);

  return (
    <>
      <AbsoluteHeader />
      <Container>
        <ContentGrid2>
          <section>
            <LoaderBIO isVisible={loading} />

            <h2><span>{article.reading_time}</span></h2>

            <h1>{article.title}</h1>
            <Breadcrumbs
              icon={House}
              separator="/"
              padding="2rem 0 0 0"
              route={[
                { title: 'Central de ajuda', link: '/suporte' },
                { title: articleCategory.name, link: `/suporte/artigos/${articleCategory.slug}` },
                { title: article.title, link: `/suporte/artigos/${articleCategory.slug}/${article.slug}` },
              ]}
            />
            <main>
              <div>
                <AvatarProgress
                  percent={100}
                  size={60}
                  url={API_S3 + '/samuel/profile-pic.jpg'}
                />
              </div>
              <div>
                <ReactMarkdown>{article.body?.replace(/\\s/g, '\n')}</ReactMarkdown>
              </div>
            </main>
            <footer>
              <h4>Esse artigo foi útil?</h4>
              <div>
                <div>
                  <VoteButton onClick={() => handleUtilArticle('upvote')} isActive={userVote === 'upvote'} disabled={!!userVote}>Sim 👍</VoteButton>
                  <VoteButton onClick={() => handleUtilArticle('downvote')} isActive={userVote === 'downvote'} disabled={!!userVote}>Não 👎</VoteButton>
                </div>
                <div>
                  {userVote && <p>obrigado pelo seu feedback!</p>}
                </div>
              </div>
              <p>Tem mais dúvidas? <a href="mailto:suporte@biolandia.com.br" target="_blank" rel="noreferrer">Envie nos um e-mail!</a></p>
            </footer>
          </section>
          <section>
            <h2>Artigos nesta seção</h2>
            <section>
              {releatedArticle.map((article, index) => (
                <Link to={`/suporte/artigos/${slug}/${article.slug}/`} key={index} className={article.slug === article_slug ? 'active' : ''}>
                  <h2>{index + 1}.</h2>
                  <h1>{article.title}</h1>
                  <p>{article.abstract}</p>
                  {
                    article.slug === article_slug ?
                      <h6>você está lendo este artigo</h6>
                      :
                      <>
                        <Link to={`/suporte/artigos/${slug}/${article.slug}/`}> visualizar artigo</Link>
                      </>
                  }
                </Link>
              ))}
            </section>
          </section>
        </ContentGrid2>
      </Container>
    </>
  );
}

export default SuporteTecnicoPost;
