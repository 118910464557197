import styled from 'styled-components';

export const Container = styled.div`
`;

export const HeaderSection = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

export const Feed = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
`;

export const Notice = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  color: ${props => props.theme.colors.white};
  border-radius: 12px;
  overflow: hidden;
  
  > aside {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 747px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  > main {
    position: relative;
    padding: 2rem;

    > h1 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    > p {
      font-size: 0.9rem;
      font-weight: 400;
      margin-bottom: 2rem;
    }

    > a {
      position: relative;
      padding: 10px 16px;
      background: ${props => props.theme.colors.primary};
      border-radius: 6px;
      color: #fff;
      font-size: 0.9rem;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;
    }

  }
`;
