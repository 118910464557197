import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  > header {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 8px;
    
    > aside {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 1rem;

      > main {
        position: relative;
        display: flex;
        flex-direction: column;

        > h5 {
          font-size: 1rem;
          font-weight: 300;
          color: ${props => props.theme.colors.white};
          font-weight: bold;
        }

        > p {
          color: ${props => props.theme.colors.muted};
          font-size: 11px;
        }
      }  
    }

    > main {
      position: relative;

      > a {
        color: ${props => props.theme.colors.muted};
        font-size: 11px;
      }
    }
  }

  > section {
    position: relative;
    display: flex;
    justify-content: center;

    > main {
      position: relative;
      width: 100%;
      border-radius: 12px;

      > a {

        > div {
          
          > figure {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            transition: all 0.2s ease-in-out;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      
      > section {
        position: absolute;
        padding: 2rem;
        gap: 0.75rem;
        display: flex;
        flex-direction: column;
        backdrop-filter: blur(6px);
        background: ${props => props.theme.colors.gray}20;
        border: 1px solid ${props => props.theme.cards.border};
        border-radius: 0 0 12px 12px;
        height: 100%;
        width: 100%;

        > header {
          position: relative;
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          font-weight: bold;
          color: ${props => props.theme.colors.muted} !important;
          
          a, p, span {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            font-size: 11px;
            color: ${props => props.theme.colors.muted} !important;
            text-transform: uppercase;
            font-size: 10px;
          }
        }
        
        > a {
          color: ${props => props.theme.colors.white};
          font-size: 1.2rem;
          font-weight: bold;
          margin: 1rem 0 0 0;
          text-decoration: none;
        }

        > p {
          color: ${props => props.theme.colors.white};
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
`;
