import React, { useEffect } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

import { Container } from './styles';

export interface ITooltipAdapterProps {
  place: 'top' | 'right' | 'bottom' | 'left';
  cursor?: 'default' | 'pointer' | 'not-allowed' | 'help';
  alt: string;
  textColor?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
  padding?: string;
  html?: boolean;
  clickable?: boolean;
  onClick?(): void;
}

const TooltipAdapter: React.FC <ITooltipAdapterProps & TooltipProps> = ({
  children, 
  onClick, 
  place, 
  padding, 
  html = true, 
  alt, 
  backgroundColor, 
  textColor, 
  clickable = true, 
  cursor = 'default'
}) => {

  useEffect(() => {
    ReactTooltip.rebuild();
    ReactTooltip.hide();
  }, [children]);

  return (
    <Container
      textColor={textColor}
      cursor={cursor}
      data-place={place}
      data-tip={alt}
      data-background-color={backgroundColor} 
      data-text-color={textColor}
      data-padding={padding}
      data-html={html}
      data-clickable={clickable}
      className="custom-tooltip-adapter"
      onClick={onClick}
     >
      {children}
    </Container>
  );
}

export default TooltipAdapter;