import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: relative;
  justify-content: flex-start;
  flex-direction: row;

  > a {
    display: flex;
    text-decoration: none;
    color: ${props => props.theme.colors.muted};
    transition: all .2s ease-in-out;
    font-weight: 600;
    white-space: nowrap;

    > span {
      font-weight: 600;
    }

    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.white};
    }

    &:first-child {
      color: ${props => props.theme.colors.white};
    }
    
    &:last-child {
      color: ${props => props.theme.colors.white};
    }
  }

  span {
    padding: 0 0.5rem;
  }
`;
