import styled from 'styled-components';

export const Container = styled.div`
`;

export const CardTableContainer = styled.section`

`;

export const HeaderSection = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;

  > aside {
    display: flex;
    gap: 1rem;

    > a {
      padding: 16px;
      text-transform: capitalize;
      font-size: 13px;
      border-radius: 6px;
      background: ${({ theme }) => theme.colors.primary};
      color: #ffffff;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      font-weight: 600;
      text-transform: uppercase;

      > svg {
        margin-right: 0.35rem;
      }
    }

    > section {

      > span {
        font-size: 11px;
        margin-right: 1rem;
      }
      
      > select {
        color: ${({ theme }) => theme.colors.white};
        height: 45px;
        width: 180px;
        border: 1px solid ${({ theme }) => theme.cards.border};
        padding: 0 0.5rem;
        border-radius: 6px;
        background: ${({ theme }) => theme.cards.primary};

        > option {
          background: ${({ theme }) => theme.modal.background};
        }
      }
    }

  }
`;

export const ButtonContext = styled.button`
  border-radius: 4px;
  margin-right: 16px;
`;

export const LessonInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  gap: 1rem;

  > span {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    color: ${props => props.theme.colors.muted};
  }
`;