import React from 'react';

import { useTheme } from '../../../../hooks/theme';

import { Container } from './styles';

import logoWhiteVertical from '../../../../assets/logo/logo-vertical-white.svg';
import logoWhiteHorizontal from '../../../../assets/logo/logo-horizontal-white.svg';

import logoColorVertical from '../../../../assets/logo/logo-vertical-color.svg';
import logoColorHorizontal from '../../../../assets/logo/logo-horizontal-color.svg';

interface ILogoProps {
  menuCollapsed: boolean;
}

const Logo: React.FC<ILogoProps> = ({ menuCollapsed }) => {
  const { theme } = useTheme();
  
  return (
    <Container>
      {menuCollapsed && (
        theme === 'dark' ?
          <img className="vertical" src={menuCollapsed ? logoWhiteVertical : logoWhiteHorizontal} alt="Logo Biolândia" />
          :
          <img className="vertical" src={menuCollapsed ? logoColorVertical : logoColorHorizontal} alt="Logo Biolândia" />
      )}

      {!menuCollapsed && (
        theme === 'dark' ?
          <img className="horizontal" src={menuCollapsed ? logoColorVertical : logoWhiteHorizontal} alt="Logo Biolândia" />
          :
          <img className="horizontal" src={menuCollapsed ? logoColorVertical : logoColorHorizontal} alt="Logo Biolândia" />
      )}

    </Container>
  );
}

export default Logo;