import styled, { css } from 'styled-components';

import blockIcon from '../../assets/block-icon.svg';
import checkIcon from '../../assets/check-icon.svg';

interface IInputGroupProps {
  readOnly: boolean;
  correctAnswer: string | null;
  checkedAnswer: string;
  value: string;
}

interface ICardQuestionProps {
  current: boolean;
  onClick(): void;
}

export const ExerciseContainer = styled.section`
  position: relative;
  width: 100%;
  background: ${props => props.theme.specific.asideModule};
  border: 1px solid ${props => props.theme.header.border};
  padding: 2rem;
  border-radius: 8px;

  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-size: 12px;
    margin: 2rem 0 1rem 0;
    color: ${props => props.theme.colors.muted}95;

    > svg {
      margin-right: 0.5rem;
    }

    > span {
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
      margin-left: 0.25rem;
    }
  }
  
  > header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid ${props => props.theme.colors.muted}20;
    
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      padding: 1rem;
      border-radius: 4px;
      transition: all 0.2s ease-in-out 0s;
      cursor: pointer;
      text-decoration: none;
      background: rgb(248, 248, 251);
      font-weight: bold;
      animation: unset;
      color: rgb(173, 175, 202) !important;
    }
  }
  
  > main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    
    > div {
      width: 100%;

      > h5 {
        color: ${props => props.theme.colors.muted};
        margin-bottom: 1rem;
      }
    }
    
    > h2 {
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 2rem;
    }
    
    p {
      font-size: 14px;
      margin-bottom: 1rem;
      font-weight: 400;
      user-select: none;
    }
  }
  
  > footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }
  `;

export const InputGroup = styled.div<IInputGroupProps>`
    position: relative;
    display: flex;
    width: 100%;
    position: relative;
    color: ${({ theme }) => theme.colors.textColor};
    user-select: none;

    img {
      mix-blend-mode: multiply;
      max-height: 200px;
    }
    
    label {
      ${props => props.readOnly && css`
      ${props.correctAnswer === props.value ? 'background: #03bb85; color: #fff;' : (props.checkedAnswer === props.value && 'background: #e74c3c;')};
      `}
      padding: 12px 50px;
      width: 100%;
      display: block;
      text-align: left;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid #5562eb26;
      ${props => props.readOnly && css`
      cursor: not-allowed;
      `}
      ${props => props.readOnly && css`
      ${props.correctAnswer === props.value ? 'border: 1px solid #03bb85;' : (props.checkedAnswer === props.value && 'border: 1px solid ##e74c3c;')};
      `}
      
      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #5562eb;
        ${props => props.readOnly && css`
        ${props.correctAnswer === props.value ? 'background: #03bb85' : (props.checkedAnswer === props.value && 'background: #e74c3c')};
        `}
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }
      
      &:after {
        background: url(${checkIcon});
        width: 1rem;
        height: 1rem;
        content: '';
        border: 2px solid #e6e8fc;
        
        ${props => props.readOnly && css`
        ${props.correctAnswer === props.value ?
      `background: url(${checkIcon}) #189570;border: 2px solid #289777;`
      :
      null
    };
        `}
        ${props => props.readOnly && css`
        ${props.correctAnswer !== props.value ?
      `background: url(${blockIcon}) #fff`
      :
      null
    };
        `}
        background-repeat: no-repeat;
        background-position: 1px 1px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        ${props => props.readOnly && css`
        cursor: not-allowed;
        `}
        transition: all 200ms ease-in;
      }
    }
    
    input:checked ~ label {
      color: #fff;
      
      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
        width: 100%;
      }
      
      &:after {
        background-color: #54E0C7;
        border-color: #54E0C7;
        
        ${props => props.readOnly && css`
        ${props.correctAnswer === props.value ? 'background-color: #189570; border-color: #289777' : null};
        `}
        
        ${props => props.readOnly && css`
        ${props.correctAnswer !== props.value ? 'background-color: #c92413; border-color: #bf3d2f' : null};
        `}
      }
    }
    
    input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
`;

export const FooterButtons = styled.section`
  position: relative;
  width: 100%;
  background: ${props => props.theme.specific.asideModule};
  border: 1px solid ${props => props.theme.header.border};
  padding: 2rem;
  border-radius: 8px;
  margin-top: 1rem;
  display: flex;
  gap: 1rem;

  @media (max-width: 620px) {
    flex-direction: column;
  }

  > button {
    width: 100%;
    line-height: 1.1;
    padding: 1rem 1.5rem;
    height: 51px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;

    
    &.loading-class {
      background: #2cc292 !important;
      border: none !important;
      color: #ffffff !important;
    }
    &:disabled {
      background: transparent !important;
      border: 2px solid ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.white} !important;
    }


    > svg {
      margin: 0;
    }
  }
`;

export const CardQuestion = styled.main<ICardQuestionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 1rem;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.muted}20;
  cursor: pointer;
  animation: ${props => props.current ? 'pulseAnimationCss 2s infinite !important' : 'unset'};

  &.answered {
      border: 2px solid ${props => props.theme.colors.tertiary}80;
  }
  
  &.current {
    animation: pulseAnimationCss 2s infinite;
  }
  
  &.blank {
    //background: ${props => props.theme.cards.primary};
  }
  
  &.correct {
    background: #03bb85;
    color: #fff !important;
  }
  
  &.incorrect {
    background: #e74c3c;
    color: #fff !important;
  }
  
  @keyframes pulseAnimationCss {
    0% {
      -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.tertiary}60;
      box-shadow: 0 0 0 0 ${props => props.theme.colors.tertiary}60;
    }
    70% {
      -moz-box-shadow: 0 0 0 10px ${props => props.theme.colors.tertiary}10;
      box-shadow: 0 0 0 10px ${props => props.theme.colors.tertiary}10;
    }
    100% {
      -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.tertiary}10;
      box-shadow: 0 0 0 0 ${props => props.theme.colors.tertiary}10;
    }
  }
`;

export const ModalConfirmationContainer = styled.section`
  width: 100%;

  > h3 {
    font-size: 1.2rem;
    width: 100%;
    text-align: left;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  > p {
    font-size: 0.9rem;
    width: 100%;
    text-align: left;
    font-weight: 500;
    margin-bottom: 1rem;
  }
`;