import styled from 'styled-components';

interface ICardMainFeaturesProps {
  iconBackground?: string;
}

export const Container = styled.div<ICardMainFeaturesProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background: ${props => props.theme.cards.primary};
  backdrop-filter: ${props => props.theme.cards.backdropFilter};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  width: 100%;

  > aside {
    
    > h6 {
      margin: 0px;
      font-size: 0.75rem;
      text-decoration: none;
      color: ${props => props.theme.colors.muted};
      font-weight: 600;
      margin-bottom: 0.25rem;
    }

    > h4 {
      font-size: 1.25rem;
      color: ${props => props.theme.colors.white};
      font-weight: 700;
    }
  }

  > main {
    padding: 0.75rem;
    background: ${props => props.iconBackground ? props.iconBackground : props => props.theme.colors.primary};
    border-radius: ${props => props.theme.radius.primary};
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      font-size: 1.5rem;
    }
  }
`;
