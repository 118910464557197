import confetti from "canvas-confetti";

export const loadConfettiAllPage = () => {
  setTimeout(() => {
    confetti({
      particleCount: 100,
      startVelocity: 30,
      spread: 360,
      zIndex: 99999999999999,
      origin: {
        x: Math.random(),
        // since they fall down, start a bit higher than random
        y: Math.random() - 0.2
      }
    });
  }, 0);

  setTimeout(() => {
    confetti({
      particleCount: 100,
      startVelocity: 30,
      spread: 360,
      zIndex: 99999999999999,
      origin: {
        x: Math.random(),
        // since they fall down, start a bit higher than random
        y: Math.random() - 0.2
      }
    });
  }, 0);

  setTimeout(() => {
    confetti({
      particleCount: 100,
      startVelocity: 30,
      spread: 360,
      zIndex: 99999999999999,
      origin: {
        x: Math.random(),
        // since they fall down, start a bit higher than random
        y: Math.random() - 0.2
      }
    });
  }, 0);

  setTimeout(() => {
    confetti({
      particleCount: 100,
      startVelocity: 30,
      spread: 360,
      zIndex: 99999999999999,
      origin: {
        x: Math.random(),
        // since they fall down, start a bit higher than random
        y: Math.random() - 0.2
      }
    });
  }, 0);

  setTimeout(() => {
    confetti({
      particleCount: 100,
      startVelocity: 30,
      spread: 360,
      zIndex: 99999999999999,
      origin: {
        x: Math.random(),
        // since they fall down, start a bit higher than random
        y: Math.random() - 0.2
      }
    });
  }, 0);
};
