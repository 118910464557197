import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DataTableAdapter from '../../../components/DataTableAdapter';
import DropdownButton from '../../../components/DropdownButton';
import Button from '../../../components/Button';
import LoaderSpinner from '../../../components/LoaderSpinner';
import Input from '../../../components/Form/Input';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import WysiwygEditor from '../../../components/WysiwygEditor';
import InputDatePicker from '../../../components/Form/InputDatePicker';
import Modal from '../../../components/Modal';

import { TableColumn } from 'react-data-table-component';
import { FiHome, FiSettings } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import toast from 'react-hot-toast';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import { CardTableContainer, Container, HeaderSection, Line1, Line2, ModalContainer, Poster, StatusBadge } from './styles';

import api from '../../../services/api';

import { IAnnouncementsProps } from '../../../interfaces/Announcements';

const Avisos: React.FC = () => {

  // Validation
  const schema = Joi.object({
    title: Joi.string().required().messages({ '*': 'Informe um título válido.' }),
    description: Joi.string().required().messages({ '*': 'Informe uma descrição válida.' }),
    start_date: Joi.date().required().messages({ '*': 'Informe uma data de início válida.' }),
    end_date: Joi.date().required().messages({ '*': 'Informe uma data de término válida.' }),
  });

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));
      return joiResolver(schema)(data, context, options);
    },
  });

  // Request 
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [filterText, setFilterText] = useState('');

  // Data
  const [announcementsData, setAnnouncementsData] = useState<IAnnouncementsProps[]>([]);

  // Loaders
  const [loadingMaterials, setLoadingMaterials] = useState(false);

  // Modal
  const [modalCreateIsOpen, setModalCreateIsOpen] = useState(false);

  const columns: TableColumn<IAnnouncementsProps>[] = [
    {
      name: 'Título',
      selector: row => row.title,
      cell: (row: IAnnouncementsProps) => (
        <>
          <span>{row.title}</span>
        </>
      ),
    },
    {
      name: 'Descrição / foto',
      selector: row => row.description,
      cell: (row: IAnnouncementsProps) => {
        if (row.description.includes('![image]')) {
          const image = row.description.split('![image]')[1].replaceAll('(', '').replaceAll(')', '');

          return (
            <Poster href={image} target="_blank">
              <img src={image} alt={row.title} />
            </Poster>
          )
        }
        else {
          return (
            <span style={{ padding: '1rem 0', }}>{row.description}</span>
          )
        }
      },
    },
    {
      name: 'Data Cadastro',
      selector: row => row.created_at,
      cell: (row: IAnnouncementsProps) => (
        new Date(row.created_at).toLocaleString('pt-BR')
      ),
    },
    {
      name: 'Data Início',
      selector: row => row.start_date,
      cell: (row: IAnnouncementsProps) => (
        new Date(row.start_date).toLocaleString('pt-BR')
      ),
    },
    {
      name: 'Data Termino',
      selector: row => row.end_date,
      cell: (row: IAnnouncementsProps) => (
        new Date(row.end_date).toLocaleString('pt-BR')
      ),
    },
    {
      name: 'Status',
      cell: (row: IAnnouncementsProps) => {
        const currentDate = new Date();
        const startDate = new Date(row.start_date);
        const endDate = new Date(row.end_date);

        if (currentDate >= startDate && currentDate <= endDate) {
          return (
            <StatusBadge style={{ color: '#fff', background: '#00b894', padding: '6px 8px', borderRadius: '4px' }}>Ativo</StatusBadge>
          )
        }
        else {
          return (
            <StatusBadge style={{ color: '#fff', background: '#ff7675', padding: '4px 6px', borderRadius: '4px' }}>Inativo</StatusBadge>
          )
        }
      }
    },
    {
      name: (<FiSettings size={16} strokeWidth={2.5} />),
      sortable: false,
      button: true,
      right: true,
      cell: (row: IAnnouncementsProps, index: number) => (
        <DropdownButton key={index}>
          <ul>
            <li>
              <Button color="primary" type="button" onClick={() => handleDeleteAnnoucement(row.id)}>
                <MdKeyboardArrowRight size={18} />
                Excluir aviso
              </Button>
            </li>
          </ul>
        </DropdownButton>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoadingMaterials(true);

        const response = await api.get(`/announcements`, {
          params: {
            pageIndex: page,
            pageSize: perPage,
            search: filterText,
          }
        });

        setAnnouncementsData(response.data.records);
        setTotalRows(response.data.totalRecords);
        
        console.log(response.data);
      } catch (error) {
        console.log(error);
        setLoadingMaterials(false);
      }
      finally {
        setLoadingMaterials(false);
      }
    })();
  }, [page, perPage, filterText]);

  const handleDeleteAnnoucement = useCallback(async (id: string) => {
    try {

      const confirm = window.confirm('Deseja excluir este aviso?');

      if (confirm) {
        await api.delete(`/announcements/${id}`);

        setAnnouncementsData((oldAnnouncements) => oldAnnouncements.filter(announcement => announcement.id !== id));

        toast.error('Aviso excluído com sucesso!', {
          duration: 5000,
          position: 'bottom-right',
          className: 'toast-samuqinha',
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Ops! Algo deu errado tentar excluir material!', {
        duration: 5000,
        position: 'bottom-right',
        className: 'toast-samuqinha',
      });
    }
  }, []);

  const onSubmitCreate = handleSubmit(async (data) => {
    try {
      const response = await api.post('/announcements', data);
      console.log(response.data);

      
      if (response.status === 200) {
        setAnnouncementsData((oldAnnouncements) => [response.data, ...oldAnnouncements]);
        setModalCreateIsOpen(false);
        reset();

        toast.success('Aviso cadastrado com sucesso!', {
          duration: 5000,
          position: 'bottom-right',
          className: 'toast-samuqinha',
        });
      }
    }
    catch (error: any) {
      console.log(error);

      toast.error('Ops! Algo deu errado tentar cadastrar aviso!', {
        duration: 5000,
        position: 'bottom-right',
        className: 'toast-samuqinha',
      });
    }
  });


  return (
    <>
      <Container>

        {loadingMaterials && (<LoaderSpinner />)}

        <HeaderSection>
          <Breadcrumbs
            icon={FiHome}
            separator="/"
            route={[{ title: 'Avisos', link: '/avisos' }]}
          />
          <aside>
            <Button
              onClick={() => {
                setModalCreateIsOpen(oldValue => !oldValue);
                reset();
              }}
              color="primary"
              iconBefore
            >
              Cadastrar novo aviso geral
            </Button>
          </aside>
        </HeaderSection>

        <CardTableContainer>
          <DataTableAdapter
            title="Lista de Avisos"
            subTitle={''}
            columns={columns}
            data={announcementsData}
            paginationServer={true}
            perPage={perPage}
            totalRows={totalRows}
            setFilterText={setFilterText}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </CardTableContainer>
      </Container>

      {/* START MODAL CREATE */}
      <Modal
        isOpen={modalCreateIsOpen}
        setIsOpen={() => setModalCreateIsOpen(oldValue => !oldValue)}
        closeModal={() => setModalCreateIsOpen(false)}
        size="lg"
        title="Cadastrar novo aviso"
      >
        <ModalContainer>
          <form onSubmit={onSubmitCreate}>
            <Line1>
              <div>
                <Input
                  register={register}
                  name={`title`}
                  type="text"
                  label="Título do aviso"
                  autoComplete="off"
                />
                {errors.title && <MessageErrorValidator>{errors?.title?.message as string}</MessageErrorValidator>}
              </div>
            </Line1>
            <Line2>
              <div>
                <InputDatePicker
                  register={register}
                  name="start_date"
                  label="Iniciar exibição em: "
                  setResult={(values) => { setValue('start_date', values) }}
                />
                {errors.start_date && <MessageErrorValidator>{errors.start_date.message as string}</MessageErrorValidator>}
              </div>
              <div>
                <InputDatePicker
                  register={register}
                  name="end_date"
                  label="Parar de exibir em: "
                  setResult={(values) => { setValue('end_date', values) }}
                />
                {errors.end_date && <MessageErrorValidator>{errors.end_date.message as string}</MessageErrorValidator>}
              </div>
            </Line2>
            <Line1>
              <h4>Aviso (pode ser apenas uma imagem | tamanho ideal 550x550px)</h4>
              <div>
                <WysiwygEditor
                  name={`description`}
                  setResult={(values) => { setValue(`description`, values) }}
                  minEditorHeight={200}
                  uploadRoute={`/exercises/upload`}
                />
                <MessageErrorValidator>{errors?.description?.message as string}</MessageErrorValidator>
              </div>
            </Line1>
            <Line1>
              <div>
                <Button
                  type="submit"
                  color="primary"
                >
                  Cadastrar aviso
                </Button>
              </div>
            </Line1>
          </form>
        </ModalContainer>
      </Modal>
      {/* END MODAL CREATE */}
    </>
  );
}

export default Avisos;