import styled from 'styled-components';

export const Container = styled.div`
`;

export const SectionContainer1 = styled.section`
  position: relative;
  width: 100%;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  padding: 2rem;
`;

export const HeaderSection = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;

  > aside {
    display: flex;
    gap: 1rem;

    > button {
      padding: 12px;
      text-transform: capitalize;
      font-size: 13px;

      > svg {
        margin-right: 0.35rem;
      }
    }
  }
`;

export const StatusBadge = styled.span`
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
`;


export const ListFilm = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  padding: 0.85rem 1rem;
  border-radius: 8px;

  &:hover {
    background: ${props => props.theme.input.border};
  }

  > aside {
    display: flex;
    
    > img {
      width: 30px;
      border-radius: 4px;
    }
  }

  > main {

    > h2 {
      font-size: 16px;
    }
  }
`;

export const Separator = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
  gap: 1rem;
  width: 100%;

  > h1 {
    font-size: 30px;
    margin-bottom: 1rem;
  }

  > h2 {
    font-size: 14px;
    font-weight: 500;
  }

  > button {
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
  }

  > section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > main {
      position: relative;
      width: 100%;
      padding-left: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      > div {
        width: 200px;
      }
    }
  }

`;

export const LineButton = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-columns: 1fr;

  > section {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    gap: 1rem;
    width: 100%;
    background: ${props => props.theme.colors.muted}20;
    cursor: pointer;
    user-select: none;
    padding: 1.2rem;
    border: 3px dashed ${props => props.theme.colors.muted}40;
    margin-bottom: 1rem;
    border-radius: 6px;
    transition: all .2s ease-in-out;

    &:hover {
      background: ${props => props.theme.colors.muted}40;
    }
  }

  > button {
    margin-top: 2rem;
    height: 60px;
  }
`;

export const Line1 = styled.section`  
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 2fr 0.5fr;
`;

export const Line2 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 2fr;
`;

export const Line3 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr 1fr;
  
  > div {
    position: relative;
    width: 100%;
    width: 100%;
    max-height: 270px;
    
    > img, iframe {
      width: 100%;
      height: 270px;
      border-radius: 4px;
      min-height: 270px;
      max-height: 270px;
    }
    
    textarea {
      height: 270px;
    }
  }

`;

export const Line4 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr;
`;

export const Line5 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr;
`;

export const Line6 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr;

  > div {
    width: 100%;

    > h4 {
      font-size: 14px;
      font-weight: 500;
      margin: 1rem;
    }
  }
`;

export const Line7 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr;

  > div {
    width: 100%;

    > h4 {
      font-size: 14px;
      font-weight: 500;
      margin: 1rem;
    }
  }
`;

export const LineInfoQuestions = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0 0 0;
  grid-template-columns: 3fr 1fr 1fr;

  > div {
    width: 100%;
  }
`;

export const ExerciseContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  cursor: default;
`;

export const FakeDropZone = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  height: 100% !important;
`;

export const DetailsExerciseToggle = styled.details`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  background: ${props => props.theme.colors.muted}20;
  cursor: pointer;
  user-select: none;
  padding: 0 1rem;
  border: 1px solid ${props => props.theme.colors.muted}40;
  margin-bottom: 1rem;
  border-radius: 6px;
  
  > summary {
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    
    > aside {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }

    > main {

      > button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 600;
        background: red;
        border-radius: 4px;
        padding: 10px 12px;
        font-size: 14px;
        border: none;
        color: #fff;
        cursor: pointer;
      }
    }
  }
`;