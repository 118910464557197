import styled from 'styled-components';

interface IDifficultyProps {
  difficulty: string;
}

export const Container = styled.div`
`;

export const CardTableContainer = styled.section`
  position: relative;
  
  > header {
    margin: 1rem 0;
  }
`;

export const QuestionContentainer = styled.div`
  padding: 1rem 0;

  > a {
    overflow: hidden;
    width: fit-content;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const BadgeDifficulty = styled.span<IDifficultyProps>`
  ${props => {
    switch (props.difficulty) {
      case 'easy':
        return `
          background: #03bb85;
        `;
      case 'medium':
        return `
          background: #e88b0d;
        `;
      case 'hard':
        return `
          background: #d50000;
        `;
    }
  }}
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
`;

export const HeaderSection = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;

  > aside {
    display: flex;
    gap: 1rem;

    > a {
      padding: 12px;
      text-transform: capitalize;
      font-size: 13px;
      padding: 16px;
      text-transform: capitalize;
      font-size: 13px;
      border-radius: 6px;
      background: ${props => props.theme.colors.primary};
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;

      > svg {
        margin-right: 0.35rem;
      }
    }
  }
`;

export const StatusBadge = styled.span`
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
`;

export const Poster = styled.img`
  width: 40px;
  margin: 4px 0;
  border-radius: 4px;
`;

export const Line0 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  > form {

    > div {
      margin-bottom: 1rem;

      &:last-child {
        margin: 0;
      }
  
      > input {
        border: solid 1px ${props => props.theme.input.border};
        border-radius: 6px;
        background: ${props => props.theme.input.background};
        padding: 0.75rem 1rem;
        font-size: 14px;
        height: 50px;
        width: 100%;
        font-weight: 500;
        color: ${props => props.theme.colors.white};
        transition: all .1s cubic-bezier(0.4,0,0.2,1);
  
        &:focus {
          outline: none;
          border: 2px solid ${props => props.theme.input.borderActive};
        }
  
        &:focus ~ label, &:valid ~ label {
          transform: translate(-10px, -50%) scale(0.8);
          background: ${props => props.theme.input.background};
          background: linear-gradient(0deg, ${props => props.theme.input.background} 48%, rgba(0,0,0,0) 60%);
          padding: 0 3px;
          color: ${props => props.theme.input.labelColor};
          font-size: 14px;
          font-weight: bold;
        }    
      }
  
      > button {
        border: none;
        border-radius: 8px;
        font-weight: bold;
        align-items: center;
        font-size: 12px;
        transition: all 0.3s ease-in-out 0s;
        text-transform: uppercase;
        background: ${props => props.theme.colors.primary};
        color: rgb(255, 255, 255);
        cursor: pointer;
        height: 50px;
        width: 100%;
  
        &:disabled {
          cursor: not-allowed;
          background: #ccc;
        }
      }

      > p {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 1rem 0 0 0;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;

export const ListFilm = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  padding: 0.85rem 1rem;
  border-radius: 8px;

  &:hover {
    background: ${props => props.theme.input.border};
  }

  > aside {
    display: flex;
    
    > img {
      width: 30px;
      border-radius: 4px;
    }
  }

  > main {

    > h2 {
      font-size: 16px;
    }
  }
  
`;
