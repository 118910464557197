import React, { useEffect } from 'react';

import { AuthProvider } from './hooks/auth';

import ReactTooltip from 'react-tooltip';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';

import Routes from './routes';

import GlobalStyles from './theme/globalStyles';
import { DashboardProvider } from './hooks/dashboard';
import { SocketProvider } from './hooks/socket';

const App: React.FC = () => {
  const { toasts } = useToasterStore();
  // Enforce Limit
  useEffect(() => {
    toasts
      .filter(t => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= 1) // limit to 1
      .forEach(t => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
  }, [toasts]);

  return (
    <AuthProvider>
      <SocketProvider>
        <DashboardProvider>
          <ReactTooltip />
          <Toaster containerStyle={{ zIndex: 9999999999999, }} />
          <GlobalStyles />

          <Routes />
        </DashboardProvider>
      </SocketProvider>
    </AuthProvider>
  );
};

export default App;
