import { Swap } from 'phosphor-react';
import React from 'react';

import { Container } from './styles';

interface FloatWallButtonsProps {
  to: string;
  style?: React.CSSProperties;
  onClick?(): void;
}

const FloatWallButtons: React.FC<FloatWallButtonsProps> = ({ to, style, onClick }) => {
  return (
    <Container to={to} onClick={onClick} style={style}>
      <aside>
        <Swap size={32} weight="duotone" color="#ffffff" />
      </aside>
      <main>
        <p>Alterar plano de estudos</p>
      </main>
    </Container>
  );
}

export default FloatWallButtons;