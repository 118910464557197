import React, { PropsWithChildren } from 'react';

import Modal from '../Modal';

import Button from '../Button';

import { CloseBtn, CloseBtnContainer, Content } from './styles';
import { X } from 'phosphor-react';

interface ModalConfirmProps extends PropsWithChildren {
  text?: string;
  title?: string;
  confirmText: string;
  onConfirm: () => void;
  cancelText: string;
  showCancelButton?: boolean;
  hideConfirmButton?: boolean;
  isOpen: boolean;
  setIsOpen: () => void;
  isLoading: boolean;
  closeModal(): void;
  confirmButtonColor?: string;
  allowOutsideClick?: boolean;
  allowEscapeKey?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'full';
  height?: string;
  className?: string;
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({ size, allowEscapeKey, height, className, allowOutsideClick, title, text, confirmText, onConfirm, cancelText, hideConfirmButton, showCancelButton = true, isOpen, setIsOpen, closeModal, confirmButtonColor, children }) => {

  return (
    <Modal
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      closeModal={closeModal}
      title={title}
      allowOutsideClick={allowOutsideClick}
      allowEscapeKey={allowEscapeKey}
      size={size}
      height={height}
    >
      {!title && (
        <CloseBtnContainer>
          <CloseBtn onClick={setIsOpen}><X weight="bold" size={24} /></CloseBtn>
        </CloseBtnContainer>
      )}

      <Content confirmButtonColor={confirmButtonColor} className={className}>
        {text && <p>{text}</p>}
        {children}

        {!hideConfirmButton && (
          <footer>
            {!hideConfirmButton && (
              <Button type="button" color="error" onClick={onConfirm} style={!showCancelButton ? { width: '100%' } : {}}>
                {confirmText}
              </Button>
            )}

            {showCancelButton && (
              <Button type="button" color="none" onClick={setIsOpen}>
                {cancelText}
              </Button>
            )}
          </footer>
        )}
      </Content>
    </Modal>
  );
};

export default ModalConfirm;