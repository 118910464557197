import React from 'react';

import { Link, useLocation } from  'react-router-dom';

import { Container } from './styles';

import accordionMenuLinks from '../../pages/user/Perfil/accordionMenuLinks';
import { FiBell, FiLock, FiUser } from 'react-icons/fi';

const Accordion: React.FC = () => {
  const location = useLocation();
  const localtionSplited = location.pathname.split('/')[1];

  return (
  <Container>
    <details open={accordionMenuLinks.find(link => link.location === localtionSplited && link.section === 1) ? true : false}>
    <summary>
      <FiUser size={20} strokeWidth={2.5} />
      Meu perfil
      <p>Altere sua foto de perfil, foto de capa e muito mais!</p>
    </summary>
    <div>
      <ul>
        {accordionMenuLinks.map((link, index) => (
          (link.section === 1 &&
            ((link.target === "_blank") ?
            <li key={index}>
              <a href={`${link.location}`} target={link.target}>{link.linkName}</a>
            </li>
          :
            <li key={index}>
              <Link to={`/${link.location}`} target={link.target} className={link.location === localtionSplited ? 'active' : ''}>{link.linkName}</Link>
            </li>
          ))
        ))}
      </ul>
    </div>
    </details>
    <details open={accordionMenuLinks.find(link => link.location === localtionSplited && link.section === 2) ? true : false}>
      <summary> 
        <FiBell size={20} strokeWidth={2.5} />
        Notificações
        <p>Não perca nenhum aviso da Plataforma.</p>
      </summary>
      <div>
        <ul>
        {accordionMenuLinks.map((link, index) => (
          (link.section === 2 &&
            ((link.target === "_blank") ?
            <li key={index}>
              <a href={`${link.location}`} target={link.target}>{link.linkName}</a>
            </li>
          :
            <li key={index}>
              <Link to={`/${link.location}`} target={link.target} className={link.location === localtionSplited ? 'active' : ''}>{link.linkName}</Link>
            </li>
          ))
        ))}
        </ul>
      </div>
    </details>
    <details open={accordionMenuLinks.find(link => link.location === localtionSplited && link.section === 3) ? true : false}>
      <summary>
        <FiLock size={20} strokeWidth={2.5} />
        Segurança
        <p>Altere sua senha e outras preferências mais.</p>
      </summary>
      <div>
        <ul>
        {accordionMenuLinks.map((link, index) => (
          (link.section === 3 &&
            ((link.target === "_blank") ?
            <li key={index}>
              <a href={`${link.location}`} target={link.target}>{link.linkName}</a>
            </li>
          :
            <li key={index}>
              <Link to={`/${link.location}`} target={link.target} className={link.location === localtionSplited ? 'active' : ''}>{link.linkName}</Link>
            </li>
          ))
        ))}
        </ul>
      </div>
    </details>
    </Container>
  );
}

export default Accordion;
