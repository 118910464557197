import styled, { css } from 'styled-components';

import { IAvatarProps } from './interface';

const avatarSizeVariations = {
  sm: css`
    width: 20px;
    height: 20px;
  `,
  md: css`
    width: 44px;
    height: 44px;
    `,
  lg: css`
    width: 64px;
    height: 64px;
  `,
  elg: css`
    width: 124px;
    height: 124px;
  `,
};

const badgeSizeVariations = {
  sm: css`
    width: 20px;
    height: 20px;
  `,
  md: css`
    width: 18px;
    height: 18px;
    `,
  lg: css`
    width: 35px;
    height: 35px;
  `,
  elg: css`
    width: 40px;
    height: 40px;
    bottom: 2px;
    right: 4px;
  `,
};

export const AvatarContainer = styled.section`
  position: relative;
`;

export const Container = styled.div<IAvatarProps>`
  position: relative;
  border-radius: ${props => props.radius === 'square' ? props.theme.radius.secondary : '50%'};
  overflow: hidden;
  ${props => avatarSizeVariations[props.size]};

  > img {
    width: 100%;
    height: auto;
  }
`;

export const BadgeLevel = styled.span<IAvatarProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -7px;
  right: -5px;
  ${props => badgeSizeVariations[props.size]};
  border-radius: 50%;
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  z-index: 9999;
`;
