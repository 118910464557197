import React from 'react';

import { Notepad } from 'phosphor-react';

import { Container, } from './styles';

interface MaterialFloatingBadgeProps {
  onClick?: () => void;
}

const MaterialFloatingBadge: React.FC<MaterialFloatingBadgeProps> = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <span>
        <Notepad size={20} weight="duotone" />
        Material de apoio
      </span>
    </Container>
  );
}

export default MaterialFloatingBadge;