import React, { useEffect, useState } from 'react';

import { useTheme } from '../../hooks/theme';

import { AvatarContainer, BadgeLevel, ProgressInnerAvatar } from './styles';

import GradientProgress from '@delowar/react-circle-progressbar';

interface IAvatarProgressProps {
  url: string;
  size: number;
  badgeValue?: number;
  percent: number;
  currentEvolutionUrl?: string;
}

const AvatarProgress: React.FC<IAvatarProgressProps> = ({
  url,
  size,
  badgeValue,
  percent,
  currentEvolutionUrl,
}) => {
  const { theme } = useTheme();
  const [isReady, setIsReady] = useState(false);
  const avatarRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    // RACK TO FIX BUG IN PROGRESS BAR
    setIsReady(false);
    setInterval(() => {
      setIsReady(true);
    }, 7);
  }, [percent]);

  return (
    <AvatarContainer url={url} size={size}>
      {isReady && (
        <GradientProgress
          className="progress-graph"
          percent={percent}
          viewport={false}
          size={size}
          isGradient
          transition={3500}
          linecap="rect" // round or rect
          gradient={{
            angle: 130,
            startColor: theme === 'dark' ? '#79ffb8' : '#47dca5',
            stopColor: theme === 'dark' ? '#47dca5' : '#79ffb8',
          }}
          emptyColor={theme === 'dark' ? '#ffffff1f' : '#17c0a71f'}
        >
        </GradientProgress>
      )}
      <ProgressInnerAvatar
        ref={avatarRef}
        size={size}
        url={url}
        onMouseOver={() => {
          if (currentEvolutionUrl && avatarRef.current) {
            avatarRef.current.style.backgroundImage = `url(${currentEvolutionUrl})`;
            avatarRef.current.style.backgroundSize = 'contain';
            avatarRef.current.style.backgroundRepeat = 'no-repeat';
            avatarRef.current.style.backgroundPosition = '0% -50%';
            avatarRef.current.style.backgroundSize = '115%';
            avatarRef.current.style.backgroundColor = '#9080fa80';
          }
        }}
        onMouseOut={() => {
          if (currentEvolutionUrl && avatarRef.current) {
            avatarRef.current.style.backgroundImage = `url(${url})`;
            avatarRef.current.style.backgroundSize = 'contain';
            avatarRef.current.style.backgroundRepeat = 'no-repeat';
            avatarRef.current.style.backgroundPosition = 'center';
            avatarRef.current.style.backgroundSize = '100%';
          }
        }}
      />
      {Number(badgeValue) >= 0 && (
        <BadgeLevel size={size} url={url}>
          {badgeValue || 0}
        </BadgeLevel>
      )}
    </AvatarContainer>
  );
};

export default AvatarProgress;
