import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';
import { useLocation } from 'react-router-dom';

import { Book, CaretDown, House, MagnifyingGlass } from 'phosphor-react';

import LoaderSpinner from '../../../components/LoaderSpinner';
import Breadcrumbs from '../../../components/Breadcrumbs';
import InputDebounceAdapter from '../../../components/InputDebounceAdapter';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import NotFoundRegister from '../../../components/NotFoundRegister';
import CardMaterialTumbnail from '../../../components/CardMaterialTumbnail';
import Pagination from '../../../components/Pagination';

import { Container, HeaderSection, SectionContainer1, SearchContainer, PaginationContainer, CategotyContainer } from './styles';

import { IMaterialProps } from '../../../interfaces/Material';
import { IGenericOptions } from '../../../interfaces/IGenericOptions';
export interface IPaginationProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: (IMaterialProps)[];
}

const Materiais: React.FC = () => {
  const location = useLocation();
  const urlSearchParans = location.search.split('?')[1];
  
  const handleFilterModule = useCallback((value: string) => {
    console.log(value);
    setSearch(value);
    setCurrentPage(1);
  }, []);

  // Loading
  const [loading, setLoading] = useState(false);

  // Data
  const [materialsData, setMaterialsData] = useState<IPaginationProps>({} as IPaginationProps);
  const [categoriesData, setCategoriesData] = useState<IGenericOptions[]>([]);

  // Filters
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/materials/categories/options`);

        console.log(response.data);
        setCategoriesData(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/materials`, {
          params: {
            pageIndex: currentPage - 1,
            pageSize: 9,
            search: search,
            category_id: category,
          }
        });

        console.log(response.data);
        setMaterialsData(response.data);

        //pagination
        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [search, category, currentPage]);

  const handleChangeCategory = useCallback((category_id: string) => {
    console.log(category_id);
    setCategory(category_id);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    if (urlSearchParans) {
      const urlSearchParansArray = urlSearchParans.split('=')[1];
      setCategory(urlSearchParansArray);
    }
  }, [urlSearchParans]);

  return (
    <Container>
      <HeaderSectionContainer padding="1rem 2rem">
        <h1><Book weight="duotone" /> Materiais</h1>
        <p>Aqui você encontra todos os materiais PDFs da  plataforma.</p>
      </HeaderSectionContainer>

      <HeaderSection>
        <Breadcrumbs
          icon={House}
          separator="/"
          route={[{ title: 'Materiais', link: '/materiais' }]}
        />
        <aside>
          <CategotyContainer>
            <select onChange={(e) => handleChangeCategory(e.target.value)} value={category}>
              <option value="" selected>Categoria...</option>
              {categoriesData.map((category, index) => (
                <option
                key={index}
                value={category.value}
                >
                  {category.label}
                </option>
              ))}
            </select>
            <CaretDown weight="duotone" />
          </CategotyContainer>
          <SearchContainer>
            <InputDebounceAdapter
              type="text"
              debounceTimeout={300}
              minLength={3}
              onChange={(event) => handleFilterModule(event.target.value)}
              placeholder="Busque por título..."
            />
            <MagnifyingGlass weight="duotone" />
          </SearchContainer>
        </aside>
      </HeaderSection>

      <SectionContainer1 style={loading ? { height: '250px', overflow: 'hidden' } : {}}>
        {loading && (<LoaderSpinner blur={true} />)}

        {materialsData.records?.map((material) => (
          <CardMaterialTumbnail
            key={material.id}
            name={material.name}
            description={material.description}
            thumbnail_url={material?.thumbnail_url}
            category={material.category}
            setCategory={setCategory}
            material_url={material.material_url}
          />
        ))}
      </SectionContainer1>

      {materialsData.records?.length === 0 && (
        <NotFoundRegister />
      )}

      {!loading && (<PaginationContainer>
        <p>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} materiais didáticos`}</p>
        {totalRecords !== 0 && (
          <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )}
      </PaginationContainer>)}
    </Container>
  );
}

export default Materiais;