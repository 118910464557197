import styled from 'styled-components';
import Switch from 'react-switch';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleLabel = styled.span`
  color: ${props => props.theme.colors.white};
  text-align: center;
  font-size: .7rem;
  text-transform: uppercase;
`;

export const ToggleSelector = styled(Switch)`
  margin: 0 7px;
`;
