import React from 'react';
import { BackgroundImage } from 'react-image-and-background-image-fade';

import { Badge, Container, Description } from './styles';

import { API_S3 } from '../../services/api_s3';
import { useTheme } from '../../hooks/theme';

interface ICardAchiviementProps {
  badge_icon: string;
  status: 'pending' | 'concluded';
  title: string;
  description: string;
  exp_total_value: number | string;
  is_hidden: boolean;
}

const CardAchiviement: React.FC<ICardAchiviementProps> = ({ badge_icon, status, title, description, exp_total_value }) => {
  const { theme } = useTheme();

  return (
    <Container photo={badge_icon} status={status}>
      <BackgroundImage element='figure' src={badge_icon} lazyLoad />
      <main>
        <Badge status={status}>
          <img src={`${API_S3}/default/2656376.png`} alt="" />
          <strong>+{exp_total_value}</strong> Exp
        </Badge>
        <div>
          <h2>{title}</h2>
        </div>
        <Description>
          {description}
        </Description>
        <footer>
          <p>
            <span style={status === 'concluded' ? { background: theme === 'light' ? '#f8f8f8' : '#0b9076'} : { }}>
              {status === 'concluded' ? 'Conquista desbloqueada' : 'Conquista bloqueada'}
            </span>
          </p>
        </footer>
      </main>
    </Container>
  );
}

export default CardAchiviement;