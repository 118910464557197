import styled from 'styled-components';

interface ContainerProps {
  isFixed?: boolean;
  borderRadius?: string;
  zIndex?: number;
  backgroundTransparent?: boolean;
  blur?: boolean;
  background?: string;
  spinnerColor?: string;
}

export const Container = styled.section<ContainerProps>`
  position: relative;
  position: ${props => (props.isFixed ? 'fixed' : 'absolute')};
  padding: 2rem;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.borderRadius || '8px'};
  background: #fff;
  background: ${props => props.background ? props.background : props.theme.cards.backgroundSpinner};
  z-index: ${props => props.zIndex || props.zIndex};
  backdrop-filter: ${props => props.blur && 'blur(77px)'};

  > main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    > p {
      font-size: 11px;
      color: ${props => props.theme.colors.white};
      padding: 0 1rem;
    }

    > div {
      display: block;
      height: 48px;
      width: 48px;
      -webkit-animation: loader-2-1 3s linear infinite;
      animation: loader-2-1 3s linear infinite;
  
      > span {
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 48px;
        width: 48px;
        clip: rect(16px, 48px, 48px, 0);
        -webkit-animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1)
          infinite;
        animation: loader-2-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          height: 48px;
          width: 48px;
          border: 3px solid transparent;
          border-top: 3px solid ${props => props.spinnerColor ? props.spinnerColor : props.theme.menu.buttonColor};
          border-radius: 50%;
          -webkit-animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1)
            infinite;
          animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
        }
  
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          height: 48px;
          width: 48px;
          border-radius: 50%;
        }
      }
    }
  }

  @keyframes loader-2-1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-2-2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-2-3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
