import React, { useLayoutEffect } from 'react';

import { useTheme } from '../../../hooks/theme';
import { useParams } from 'react-router-dom';

import { House } from 'phosphor-react';

import SimulatedModuleReadOnly from '../../../components/SimulatedModuleReadOnly';

import { Container, Header, SectionContainer1,  } from './styles';
import Breadcrumbs from '../../../components/Breadcrumbs';

const SimuladoView: React.FC = () => {
  const { theme } = useTheme();
  const { id } = useParams<{ id: string }>();

  

  return (
    <Container>
      <Header>
        <Breadcrumbs
          icon={House}
          separator="/"
          route={[
            { title: 'Meus simulados', link: '/simulados' },
            { title: `Este simulado`, link: `/simulado/${id}/visualizar` },
          ]}
        />
      </Header>

      <SectionContainer1>
        <SimulatedModuleReadOnly pratice_id={id} />
      </SectionContainer1>
    </Container>
  );
}


export default SimuladoView;