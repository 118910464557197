import styled from 'styled-components';


export const Container = styled.div`
  .disabledd {
    color: ${props => props.theme.colors.white}50;

    &:hover {
      background: none;
      cursor: default;
    }
  }

  .openn {
    border: 2px solid ${props => props.theme.input.borderActive};
  }

  .itens-count {
    background: ${props => props.theme.colors.white}50;
    display: inline-flex;
    padding: 2px 6px;
    border-radius: 20px;
    margin-left: 8px;
    font-size: 11px;
    color: #ffffff;
  }
`;

export const FakeInput = styled.div`
  position: relative;
  cursor: pointer;
  user-select: none;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    writing-mode: horizontal-tb !important;
    border: solid 1px ${props => props.theme.input.border};
    border-radius: 6px;
    background: ${props => props.theme.input.background};
    padding: 0.75rem 1rem;
    font-size: 14px;
    height: 50px;
    width: 100%;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
    transition: all .1s cubic-bezier(0.4,0,0.2,1);

    &:focus {
      outline: none;
      border: 2px solid ${props => props.theme.input.borderActive};
    }

    &:focus ~ label, &:valid ~ label {
      transform: translate(0, -50%);
      background: ${props => props.theme.input.background};
      background: linear-gradient(0deg, ${props => props.theme.input.background} 48%, rgba(0,0,0,0) 60%);
      padding: 0 3px;
      color: ${props => props.theme.input.labelColor};
      font-size: 11px;
      font-weight: bold;
    }    

    > main {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      max-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 16px;
      writing-mode: horizontal-tb !important;
    }

    > footer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      > div {

        > button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
          border: none;
  
          > svg {
            color: #ff0000;
          }
        }
      }
      
      > aside {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 0 6px 12px;
        border-left: 1px solid #ffffff4a;

        > svg {
          color: #fff;
        }
      }
    }
  }

  > label {
    position: absolute;
    transform: translate(10px, -50%);
    background: ${props => props.theme.input.background};
    background: linear-gradient(0deg, ${props => props.theme.input.background} 48%, rgba(0,0,0,0) 60%);
    padding: 0 3px;
    color: ${props => props.theme.input.labelColor};
    font-size: 11px;
    font-weight: bold;
  }
  
`;

export const FlatList = styled.div`
  max-height: 150px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
    width: 22px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${props => props.theme.colors.muted}50;
  }
  
  > ul {
    user-select: none;

    > li {
      list-style: none;

      > label {
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 14px;
        padding: 10px 8px;
        transition: all .1s ease-in-out;
        color: ${props => props.theme.colors.white};

        &:hover {
          background: ${props => props.theme.input.border};
        }
      }
    }
  }

  > footer {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export const MenuNav = styled.nav`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  padding: 0;
  border-radius: ${props => props.theme.radius.secondary};
  overflow: hidden;

  background: ${props => props.theme.input.background};
  border: solid 1px ${props => props.theme.input.border};
  box-shadow: ${props => props.theme.shadows.primary};
  
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  transform: translate(0px, -10px);
  z-index: 9999999;
  
  &.open {
    opacity: 1;
    visibility: visible;
    overflow: visible;
    transform: translateY(53px);
    height: auto;
  }
`;

export const MenuDropDownContainer = styled.div`
  position: relative;
  user-select: none;
`;

export const CloseButton = styled.button`
  position: relative;
  user-select: none;
  border: none;
  background: none;
  margin: 1rem 0;
  width: 150px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
`;