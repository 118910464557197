import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
`;

export const CardTableContainer = styled.section`

`;

export const HeaderSection = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;

  > aside {
    display: flex;
    gap: 1rem;

    > button {
      padding: 12px;
      text-transform: capitalize;
      font-size: 13px;

      > svg {
        margin-right: 0.35rem;
      }
    }
  }
`;

export const StatusBadge = styled.span`
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
`;

export const Poster = styled.img`
  width: 40px;
  margin: 4px 0;
  border-radius: 4px;
`;

export const Line0 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  > form {

    > div {
      margin-bottom: 1rem;

      &:last-child {
        margin: 0;
      }
  
      > input {
        border: solid 1px ${props => props.theme.input.border};
        border-radius: 6px;
        background: ${props => props.theme.input.background};
        padding: 0.75rem 1rem;
        font-size: 14px;
        height: 50px;
        width: 100%;
        font-weight: 500;
        color: ${props => props.theme.colors.white};
        transition: all .1s cubic-bezier(0.4,0,0.2,1);
  
        &:focus {
          outline: none;
          border: 2px solid ${props => props.theme.input.borderActive};
        }
  
        &:focus ~ label, &:valid ~ label {
          transform: translate(-10px, -50%) scale(0.8);
          background: ${props => props.theme.input.background};
          background: linear-gradient(0deg, ${props => props.theme.input.background} 48%, rgba(0,0,0,0) 60%);
          padding: 0 3px;
          color: ${props => props.theme.input.labelColor};
          font-size: 14px;
          font-weight: bold;
        }    
      }
  
      > button {
        border: none;
        border-radius: 8px;
        font-weight: bold;
        align-items: center;
        font-size: 12px;
        transition: all 0.3s ease-in-out 0s;
        text-transform: uppercase;
        background: ${props => props.theme.colors.primary};
        color: rgb(255, 255, 255);
        cursor: pointer;
        height: 50px;
        width: 100%;
  
        &:disabled {
          cursor: not-allowed;
          background: #ccc;
        }
      }

      > p {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 1rem 0 0 0;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;

export const ListFilm = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  padding: 0.85rem 1rem;
  border-radius: 8px;

  &:hover {
    background: ${props => props.theme.input.border};
  }

  > aside {
    display: flex;
    
    > img {
      width: 30px;
      border-radius: 4px;
    }
  }

  > main {

    > h2 {
      font-size: 16px;
    }
  }
  
`;

export const CardUniversity = styled(Link)`
  text-decoration: none;
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    box-shadow :  0 0 5px #11111109,
                  0 0 25px #11111109,
                  0 0 50px #11111109,
                  0 0 100px #11111109;
  }

  text-decoration: none;
  user-select: none;
  
  .rect-progress-bar-percent {
    display: none !important;
  }

  .inner-rect-bar {
    background: #79ffb8  !important;
    border-radius: 10px !important;
  }

  .progress-bar-rect-wrapper {

    > div {
      border-radius: 10px !important;
      overflow: hidden !important;
      background: #cbecf233 !important;
    }
  }

  > div {

    > img {
      max-width: 250px;
      width: 100%;
      height: auto;
      filter: none;
      
      &.svg-light-mode {
        filter: hue-rotate(180deg) brightness(100);
      }

      &.svg-dark-mode {
        filter: none;
      }
    }
  }

  > footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;
  
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 0.25rem;
        gap: 0.25rem;
        
        > h5 {
          font-size: 13px;
        }
  
        > p {
          font-size: 12px;
        }

      }
      
      > footer {
        
        h2 {
          display: none !important;
        }
      }
    }
`;
