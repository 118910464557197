import { IThemeProps } from "./IThemeProps";

const light: IThemeProps = {
  colors: {
    primary: '#2cc292',
    secondary: '#ffffff',
    tertiary: '#13b97d',
    success: '',
    danger: '#d94545',
    warning: '',
    info: '#582CFF',
    light: '',
    white: '#3e3f5e',
    dark: '#ffffff',
    muted: '#9ea4c1',
    gray: '#dadbe7',
    whiteGray: '#adafca',
    purple: '#aa14cb',
  },
  gradients: {
    primary: 'linear-gradient(310deg, #01b574, #72cfad)',
    secondary: 'linear-gradient(-180deg, #ffffff 0%, #ffffff 50%)',
    tertiary: 'linear-gradient(-180deg, #ffffff 0%, #ffffff 50%)',
    rankingGradient: 'radial-gradient(circle,#fafafc  0%, #fafafc 100%)',
  },
  radius: {
    primary: '12px',
    secondary: '6px',
  },
  header: {
    header: '#2cc292',
    border: '#ffffff1c',
    inputSearch: '#ffffff20',
    inputSearchBorder: '#ffffff20',
  },
  menu: {
    buttonBackground: '#ffffff',
    buttonColor: '#2cc292',
    buttonShadow: 'rgb(94 92 154 / 12%) 3px 5px 30px 0px',
    buttonShadowHover: 'none',
  },
  cards: {
    primary: '#ffffff',
    secondary: '#182e12',
    tertiary: '#f8fcff',
    backgroundSpinner: '#ffffff',
    backdropFilter: 'blur(7px) saturate(400%)',
    border: '#1fb3971f',
    modal: '#f0f8ff70',
  },
  modal: {
    background: '#ffffffe6',
    border: '#0a322e40',
    closeButton: '#3e3f5e',
  },
  background: {
    primary: '#f0f8ff',
    secondary: '#ffffff',
    tertiary: '#f7fafc', 
  },
  shadows: {
    primary: 'rgb(94 92 154 / 6%) 0px 0px 40px 0px',
  },
  input: {
    background: '#ffffff',
    border: '#0a322e40',
    borderActive: '#0a322e',
    labelColor: '#0a322e',
  },
  specific: {
    menuTitles: '#03bb85',
    menuBorder: '1px solid #e4f6f2',
    menuButton: '#03bb85',
    menuDropDownAvatarButtonOnHover: '#ffffff20',
    menuButtonTriggerBorder: '#1fb3971f',
    cardGradient3D: 'linear-gradient(140deg, #ffffff80 0%, transparent 54%)',
    cardGradientShadow: '0px 0px 70px #11111140',
    inputSearchHeaderBg: '#46caa0',
    loaderBg: 'radial-gradient(#17b585 0, transparent 100%)',
    clockDown: '#fafafc',
    asideModule: '#ffffff',
    footerModule: '#ffffff',
    cardDashedBorder: '#ffffff4a',
    cardCommunity: '#ffffff',
    svgFilterRecolor: 'invert(20%) sepia(10%) saturate(2301%) hue-rotate(200deg) brightness(95%) contrast(81%)',
    avatarBorderColor: '#ffffff',
    verifyBorderAnimationColor: '#9080fa20',
    wysiwygButtons: '#3e3f5e',
  },
}

export default light;
