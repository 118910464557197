import { ThumbsDown, ThumbsUp } from 'phosphor-react';
import React, { useCallback } from 'react';

import { Container } from './styles';

export type IVoteType = 'upvote' | 'downvote';

interface IToggleLikeAndDeslikeProps {
  upvotes: number;
  downvotes: number;
  userVote: IVoteType | null;
  onVote(vote: IVoteType): void;
  disabled?: boolean;
}

const ToggleLikeAndDeslike: React.FC<IToggleLikeAndDeslikeProps> = ({
  upvotes,
  downvotes,
  userVote,
  onVote,
  disabled = false,
}) => {
  const toggle = useCallback(
    async (voteType: IVoteType) => {
      onVote(voteType);
    },
    [onVote],
  );

  return (
    <Container disabled={disabled}>
      <button
        className={userVote === 'upvote' ? 'checkedTrue' : ''}
        onClick={() => toggle('upvote')}
        disabled={disabled}
      >
        <ThumbsUp size={20} weight="bold" /> {upvotes}
      </button>
      <button
        className={userVote === 'downvote' ? 'checkedFalse' : ''}
        onClick={() => toggle('downvote')}
        disabled={disabled}
      >
        <ThumbsDown size={20} weight="bold" /> {downvotes}
      </button>
    </Container>
  );
};

export default ToggleLikeAndDeslike;
