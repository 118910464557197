import React, { useState, useEffect, useRef, PropsWithChildren } from 'react';

import { FiX } from 'react-icons/fi';

import TooltipAdapter from '../TooltipAdapter';
import ReactTooltip from 'react-tooltip';

import { Container, ModalFixedHeader } from './styles';

interface ModalProps extends PropsWithChildren {
  size?: 'sm' | 'md' | 'lg' | 'full';
  isOpen: boolean;
  title?: string;
  subTitle?: string;
  showCloseButton?: boolean;
  allowOutsideClick?: boolean,
  allowEscapeKey?: boolean,
  background?: string;
  titleColor?: string;
  border?: string;
  show3dAvatar?: boolean;
  height?: string;
  setIsOpen: () => void;
  closeModal: () => void;
}

const Modal: React.FC<ModalProps> = ({ 
  children, 
  size, 
  isOpen,
  title,
  subTitle,
  setIsOpen,
  closeModal,
  background,
  titleColor,
  border,
  showCloseButton = true,
  allowOutsideClick = true,
  allowEscapeKey = true,
  show3dAvatar,
  height,
}) => {

  const [modalStatus, setModalStatus] = useState(isOpen);

  const [isScroll, setIsScroll] = useState(false);

  const mainContentRef = useRef<HTMLElement>(null);

  useEffect(() => {
    ReactTooltip.hide();
    setModalStatus(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setTimeout(async () => {
      if (mainContentRef.current?.clientHeight && mainContentRef.current?.clientHeight > 300) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    }, 7);
  }, [isOpen, isScroll]);

  return (
    <Container
      shouldCloseOnEsc={allowEscapeKey}
      shouldCloseOnOverlayClick={allowOutsideClick}
      ariaHideApp={false}
      closeTimeoutMS={200}
      background={background}
      border={border}
      onRequestClose={setIsOpen}
      isOpen={modalStatus}
      size={size || 'md'}
      show3dAvatar={show3dAvatar}
      height={height}
      style={{
        overlay: {
          backgroundColor: '#fffffff',
          zIndex: 999999999,
          backdropFilter: 'blur(7px) saturate(110%)',
          transition: 'all .2s ease-in-out',
          background: '#00000030',
        },
      }}
    >
      {(showCloseButton && title) && (
        <ModalFixedHeader border={border}>
          <aside>
            <h2 style={titleColor ? { color : titleColor} : {}}>{title}</h2>
            <p>{subTitle}</p>
          </aside>
          <header>
            <TooltipAdapter
              alt="Fechar"
              place="top"
              cursor="pointer"
              onClick={() => { ReactTooltip.hide(); closeModal(); } }
            >
              <FiX strokeWidth={3} size={16} />
            </TooltipAdapter>
          </header>
        </ModalFixedHeader>
      )}
      <main ref={mainContentRef} style={isScroll ? { padding: '1.5rem 1.5rem 0 1.5rem'  } : { padding: '1.5rem 1.5rem 0 1.5rem',  }}>
        {children}
      </main>
    </Container>
  );
};

export default Modal;
