import styled from 'styled-components';

export const Container = styled.div`

`;

export const HeaderSection = styled.section`
  display: flex;
  margin: 1rem 0 1rem 0;
  justify-content: space-between;

  @media (max-width: 600px) {
    padding: 2rem 0 0 0;
    flex-direction: column;
    gap: 1rem;

    > div {

      > button {
        width: 100%;
      }
    }
  }
`;

export const SectionContainer1 = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 34px 28px;
  margin-bottom: 1rem;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};

  > main {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    > svg {
      position: absolute;
      margin-left: 1.2rem;
      color: ${props => props.theme.colors.muted}80;
    }
  
    > input {
      background: ${props => props.theme.input.background};
      border: 1px solid ${props => props.theme.cards.border};
      border-radius: ${props => props.theme.radius.secondary};
      width: 100%;
      padding: 1rem 1rem 1rem 3rem;
      color: ${props => props.theme.colors.white};
      width: 100%;
      animation: 2s infinite ease-in-out pulseAnimation2;

      @media (max-width: 600px) {
        font-size: 12px;
      }
  
      &::placeholder {
        color: ${props => props.theme.colors.white}80;
      }
    }
  }

`;

export const SectionContainer2 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1,1fr);
  padding: 34px 28px;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
`;

export const SectionContainerHeader = styled.section`
  display: grid;
  margin: 2rem 0px 1rem 0;
  align-items: center;
  grid-template-columns: 3fr 0.6fr 200px;
  padding: 0 1.5rem;

  @media (max-width: 600px) {
    grid-template-columns: 2fr 0.5fr;
  }
  
  > aside:nth-child(1) {
    display: flex;
    align-items: center;
    
    > h5 {
      color: ${props => props.theme.colors.muted};
      font-size: 0.7rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  
  > aside:nth-child(2) {
    display: flex;
    align-items: center;
    
    > h5 {
      color: ${props => props.theme.colors.muted};
      font-size: 0.7rem;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
    }
  }
  
  > aside:nth-child(3) {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      display: none;
    }
    
    > h5 {
      color: ${props => props.theme.colors.muted};
      font-size: 0.7rem;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
    }
  }
  `;

export const TopicContainer = styled.main`
  display: grid;
  gap: 1rem;
  margin: 0px;
  align-items: center;
  padding-bottom: 1rem;
  grid-template-columns: 3fr 0.5fr 200px;
  border-bottom: 1px solid ${props => props.theme.cards.border};

  @media (max-width: 600px) {
    grid-template-columns: 2fr 0.5fr;
  }
  
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  > aside {
    display: flex;
    align-items: center;
    
    > div {
      display: flex;
      flex-direction: column;
      
      > a {
        color: ${props => props.theme.colors.white};
        text-decoration: none;
        transition: all 0.25s ease-in-out 0s;
        margin-bottom: 0.5rem;
        
        > h1 {
          font-size: 16px;
          
          > span {
            margin-right: 0.4rem;
            padding: 1px 9px;
            border-radius: 200px;
            background-color: rgb(35, 210, 226);
            color: rgb(255, 255, 255);
            font-size: 0.75rem;
            font-weight: 700;
            line-height: 1em;
            text-transform: uppercase;
            vertical-align: middle;
          }
        }
      }
      
      > p {
        display: flex;
        align-items: center;
        font-size: 0.7rem;
        font-weight: 500;
        color: ${props => props.theme.colors.muted};

        @media (max-width: 600px) {
          font-size: 10px;
        }
        
        > img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin: 0px 6px;
          border: 2px solid ${props => props.theme.colors.muted}20;
        }
      }
    }
  }
  
  > main {
    display: flex;
    align-items: center;
    justify-content: center;
    
    > p {
      font-size: 0.875rem;
      font-weight: 700;
    }
  }
  
  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media (max-width: 600px) {
      display: none;
    }
    
    > div {
      
      > p {
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1;
        margin-left: 5px;
        
        > span {
          font-size: 0.625rem;
          font-weight: 500;
          line-height: 1;
        }
      }
    }
  }
  `;

export const PaginationContainer = styled.section`
  position: relative;
  width: 100%;
  margin-top: 2rem;
  
  > p {
    text-align: center;
    font-size: 12px;
  }
`;

export const ButtonNewTopicContainer = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  `;

export const ModalContainer = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  `;

export const Line1 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  
  > div {
    width: 100%;
  }
  
  > input { 
    width: 100%;
  }
`;

export const Line2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  
  > div {
    width: 100%;
  }
`;

export const Line3 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  
  > div {
    width: 100%;
    
    > button {
      width: 100%;
    }
  }
  `;

export const HeaderSectionContainerInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const AsideIcon = styled.aside`
  position: relative;

  > img {
    width: 195px;
    margin-left: -60px;
  }
`;

export const Main = styled.main`
  position: relative;

  > h1 {
    font-size: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @media (max-width: 700px) {
      font-size: 20px;
    }
  }

  > p {
    font-size: 12px;
    font-weight: 300;
    color: #79ffb8;

    @media (max-width: 700px) {
      font-size: 11px;
      overflow: hidden;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`;
