import styled from 'styled-components';

export const Container = styled.div`
`;

export const FooterConfirmButton = styled.footer`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  > button {
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    background: ${props => props.theme.colors.primary};
    color: #fff;
    padding: 26px 12px;
    margin-bottom: 2rem;
  }
`;

export const DetailsExerciseToggle = styled.details`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  background: ${props => props.theme.colors.muted}20;
  cursor: pointer;
  user-select: none;
  padding: 0 1rem;
  border: 1px solid ${props => props.theme.colors.muted}40;
  margin-bottom: 1rem;
  border-radius: 6px;
  
  > summary {
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    
    > aside {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }

    > main {

      > button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 600;
        background: red;
        border-radius: 4px;
        padding: 10px 12px;
        font-size: 14px;
        border: none;
        color: #fff;
        cursor: pointer;
      }
    }
  }
`;

export const ExerciseContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  cursor: default;
`;

export const Line5 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr;

  > h4 {
    font-size: 14px;
    font-weight: 500;
    margin: 1rem 1rem 0 1rem;
    font-weight: bold;
  }
`;

export const Line7 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr;

  > div {
    width: 100%;

    > h4 {
      font-size: 14px;
      font-weight: 500;
      margin: 1rem;
    }
  }
`;

export const Separator = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
  gap: 1rem;
  width: 100%;

  > h1 {
    font-size: 30px;
    margin-bottom: 1rem;
  }

  > h2 {
    font-size: 14px;
    font-weight: 500;
  }

  > button {
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
  }

  > section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > main {
      position: relative;
      width: 100%;
      padding-left: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      > div {
        width: 200px;
      }
    }
  }

`;

export const Line6 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr;

  > div {
    width: 100%;

    > h4 {
      font-size: 14px;
      font-weight: 500;
      margin: 1rem;
      font-weight: bold;
    }
  }
`;

export const LineInfoQuestions = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0 0 0;
  grid-template-columns: 1fr 1fr 0.5fr 0.5fr;

  > div {
    width: 100%;
  }
`;

export const QuestionContentainer = styled.div`
  padding: 1rem 0;

  > a {
    overflow: hidden;
    width: fit-content;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;

  > aside {
    display: flex;
    gap: 1rem;

    > button {
      padding: 12px;
      text-transform: capitalize;
      font-size: 13px;

      > svg {
        margin-right: 0.35rem;
      }
    }
  }
`;

export const StatusBadge = styled.span`
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
`;

export const Poster = styled.img`
  width: 40px;
  margin: 4px 0;
  border-radius: 4px;
`;

export const Line0 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  > form {

    > div {
      margin-bottom: 1rem;

      &:last-child {
        margin: 0;
      }
  
      > input {
        border: solid 1px ${props => props.theme.input.border};
        border-radius: 6px;
        background: ${props => props.theme.input.background};
        padding: 0.75rem 1rem;
        font-size: 14px;
        height: 50px;
        width: 100%;
        font-weight: 500;
        color: ${props => props.theme.colors.white};
        transition: all .1s cubic-bezier(0.4,0,0.2,1);
  
        &:focus {
          outline: none;
          border: 2px solid ${props => props.theme.input.borderActive};
        }
  
        &:focus ~ label, &:valid ~ label {
          transform: translate(-10px, -50%) scale(0.8);
          background: ${props => props.theme.input.background};
          background: linear-gradient(0deg, ${props => props.theme.input.background} 48%, rgba(0,0,0,0) 60%);
          padding: 0 3px;
          color: ${props => props.theme.input.labelColor};
          font-size: 14px;
          font-weight: bold;
        }    
      }
  
      > button {
        border: none;
        border-radius: 8px;
        font-weight: bold;
        align-items: center;
        font-size: 12px;
        transition: all 0.3s ease-in-out 0s;
        text-transform: uppercase;
        background: ${props => props.theme.colors.primary};
        color: rgb(255, 255, 255);
        cursor: pointer;
        height: 50px;
        width: 100%;
  
        &:disabled {
          cursor: not-allowed;
          background: #ccc;
        }
      }

      > p {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 1rem 0 0 0;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;

export const ListFilm = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  padding: 0.85rem 1rem;
  border-radius: 8px;

  &:hover {
    background: ${props => props.theme.input.border};
  }

  > aside {
    display: flex;
    
    > img {
      width: 30px;
      border-radius: 4px;
    }
  }

  > main {

    > h2 {
      font-size: 16px;
    }
  }
  
`;
