import { Chats } from 'phosphor-react';
import React from 'react';

import { Container, FrontInner } from './styles';

interface ICardProps {
  title: string;
  description: string;
  image: string;
  link: string;
  topicsCount: number;
}

const CardCommunityCategory: React.FC<ICardProps> = ({ title, description, image, link, topicsCount }) => {
  return (
    <Container to={link}>
      <div className="card">
        <div className="content">
          <div className="front">
            <FrontInner>
              <aside>
                <img src={image} alt={title} /> 
              </aside>
              <main>
                <h3 className="title">{title}</h3>
                <p><Chats size={20} weight="duotone" /> {topicsCount} {topicsCount === 1 ? 'tópico encontrado' : 'tópicos econtrados'} </p>
              </main>
            </FrontInner>
          </div>

          <div className="back">
            <h4>{title}</h4>
            <p className="description">
              {description}
            </p>
          </div>

        </div>
      </div>
    </Container>
  );
}

export default CardCommunityCategory;