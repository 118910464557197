import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0 1.5rem;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  height: 100%;
  overflow-x: hidden;
  
  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid ${props => props.theme.cards.border};
    padding: 0 0.5rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    
    > aside {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      
      > aside {
        
        > h1 {
          font-size: 3rem;
        }
      }
      
      > main {
        
        > p {
          font-size: 12px;
          
          &:first-child {
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
    
    > main {
      font-weight: bold;
      text-transform: uppercase;
      
      > p {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  `;

export const MainScrollContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 425px;
  padding: 0;
  overflow-x: hidden;
  padding-bottom: 3rem;

  ::-webkit-scrollbar {
    width: 8px;
    width: 22px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: ${props => props.theme.colors.muted}50;
  }

  > p {
    color: ${props => props.theme.colors.white};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  margin-top: 0.5rem;
  border-bottom: 1px solid ${props => props.theme.cards.border};
  
  > aside {
    width: 100%;

    input {
      
      ::placeholder {
        color: ${props => props.theme.colors.muted};
      }
    }
  }
  
  main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40%;
    
    > button {
      width: 100%;
      height: 50px;
      border: none;
      padding: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background: ${props => props.theme.colors.primary};
      color: #fff;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      font-weight: bold;

      &:hover {
        background: ${props => props.theme.colors.primary}95;
      }
    }
  }
  `;

interface ITaskProps {
  completed: boolean;
  className?: string;
}

export const Task = styled.div<ITaskProps>`
  width: 100%;
  padding: 1rem 0 1rem 0.25rem;
  border-radius: 4px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: overlay .3s ease-in-out;
  gap: 1rem;
  user-select: none;
  border-bottom: 1px solid ${props => props.theme.cards.border};
  font-size: 14px;
  font-weight: 600;
  
  &:last-child {
    border-bottom: none;
  }
  
  > main {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    
    > label {
      display: flex;
      align-items: center;
      text-decoration: ${props => props.completed ? 'line-through' : 'unset'};
      color: ${props => props.completed ? props.theme.colors.muted : props.theme.colors.white};
      gap: 1rem;
      
      > input {
        cursor: pointer;
      }
    }
  }
  
  > aside {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .30rem;
    background: ${props => props.theme.colors.muted}30;
    border-radius: 50%;
    min-width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      
      > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        cursor: pointer;
        display: flex;
        background: transparent;
        border: none;
        z-index: 9;
        color: ${props => props.theme.colors.white};
      }
    }
  }
`;

export const NotTasks = styled.p`
  display: flex;
  margin-top: 2rem;
`;

export const Footer = styled.footer`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 0;
  left: 0;
  background: ${props => props.theme.colors.gray}20;
  backdrop-filter: blur(8px) saturate(80%);
  width: 100%;
  padding: 0.75rem 2.75rem 0.75rem 2.5rem;
  border-top: 1px solid ${props => props.theme.colors.gray}35;
  gap: 0.5rem;
  z-index: 9;
  height: 65px;

> div {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      justify-content: space-between;

      h5 {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        user-select: none;
        white-space: nowrap;
        cursor: pointer;
        transition: all 0.25s ease-in-out;

        &:hover {
          color: ${props => props.theme.colors.tertiary};
        }

        &.current {
          color: ${props => props.theme.colors.tertiary};
        }
      }
    }

    > h5:nth-child(4) {
      display: flex;
      justify-content: flex-end;
    }
  }
`;