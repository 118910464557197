import { createGlobalStyle } from 'styled-components';

import samuquinha from '../assets/samuquinha.png';

const isMobile = window.innerWidth < 600;

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    text-transform: none;
    box-sizing: border-box;
    outline: none;
    font-family: 'Inter', sans-serif !important;
    
    ::selection {
      background-color: #79ffb8;
      color: #fff;
    }
  }
  
  html, #root {
    overflow-x: hidden !important;
  }

  .__react_component_tooltip {
    ${isMobile && `
      background-color: transparent !important;
      color: transparent !important;  
      
      &:before {
        background-color: transparent !important;
      }

      &:after {
        background-color: transparent !important;
      }
    `}
  }

  .react-modal-overlay {
    position: absolute !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  .__react_component_tooltip  {
    z-index: 99999999999999999999999999999 !important;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: all .2s ease-in-out;
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
    z-index: 9999999 !important;
    backdrop-filter: blur(7px) saturate(110%) !important;
    transition: all 0.2s ease-in-out 0s !important;
    background: rgba(0, 0, 0, 0.19) !important;
  }
  
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .toast-samuquinha {
    margin-right: 2.5rem;

    &:before {
      position: absolute;
      content: '';
      width: 60px;
      height: 160px;
      background: url(${samuquinha}) no-repeat;
      background-size: contain;
      right: -40px;
      bottom: -50px;
    }
  }

  .rdw-editor-wrapper {

    img {
      max-width: 60%;
      margin: 1rem 0;
    }
  }

  .place-top::before {
    display: none !important;
  }

  @keyframes in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes glow {
    from {
      border-color: #393;
      box-shadow: 0 0 5px rgb(0 255 0 / 20%), inset 0 0 5px rgb(0 255 0 / 10%), 0 2px 0 #000;
    }

    to {
        border-color: #6f6;
        box-shadow: 0 0 20px rgb(0 255 0 / 60%), inset 0 0 10px rgb(0 255 0 / 40%), 0 2px 0 #000;
    }
  }

  @keyframes pulseAnimation {
    0% {
      box-shadow: 0 0 0 0 #ffffff20;
    }
    70% {
        box-shadow: 0 0 0 15px rgba(255,255,255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255,255,255, 0);
    }
  }
`;