import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0;
  
  > main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 75vw;
    
    @media (max-width: 768px) {
      max-width: 90%;
    }

    > header {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
      margin-bottom: 2rem;

      > p {
        font-size: 12px;
        font-weight: 600;
        color: #3e3f5e;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
        margin-bottom: 1rem;

        > strong {
          text-decoration: none;
          border-radius: 20px;
          background: #6c23c0;
          color: #ffffff;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 12px;
          gap: 0.5rem;
          text-wrap: nowrap;
        }
      }

      > button {
        background: none;
        border: none;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
        font-size: 16px;
        font-weight: 600;
        gap: 0.5rem;
        color: #9ea4c1;
      }
    }

    > h1 {
      font-size: 1.85 rem;
      margin-bottom: 1rem;
      text-align: left;
      color: #3e3f5e !important;
    }

    > footer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: 56.25%; // Isto garante o aspect ratio 16:9

      > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
`;
