import React, { InputHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';

import api from '../../services/api';

import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

import { SearchContainer, Container, SearchResult } from './styles';
import { FiSearch } from 'react-icons/fi';

type IInputSearchHeaderProps = InputHTMLAttributes<HTMLInputElement>;

export interface ISearchProps {
  topics?: {
    id: string;
    lesson_id?: null;
    forum_id: string;
    author_id: string;
    title: string;
    content: string;
    views: number;
    is_pinned: boolean;
    created_at: string;
    updated_at: string;
    forum: {
      id: string;
      cluster_id: string;
      title: string;
      slug: string;
      order: string;
      thumbnail: string;
      description: string;
      created_at: string;
      updated_at: string;
    };
  }[];
  materials?: {
    id: string;
    category_id: string;
    name: string;
    description: string;
    thumbnail: string;
    file: string;
    material_url: string;
    created_at: string;
    updated_at: string;
  }[];
  lessons?: {
    id: string;
    cluster_id: string;
    title: string;
    slug: string;
    order: number;
    duration: string;
    video: string;
    video_provider: string;
    thumbnail: string;
    cluster: {
      title: string;
      thumbnail: string;
      slug: string;
      order: number;
    };
  }[];
  articles?: {
    id: string;
    section_id: string;
    section: {
      slug: string;
    };
    title: string;
    slug: string;
    is_promoted: boolean;
    abstract: string;
    vote_count: number;
    vote_sum: number;
    created_at: string;
    updated_at: string;
    points: number;
  }[];
}


const InputSearchHeader: React.FC<IInputSearchHeaderProps> = ({ ...rest }) => {

  const inputEl = useRef<HTMLInputElement>(null);

  const [isActive, setIsActive] = useState(false);

  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState<ISearchProps>({} as ISearchProps);
  const [searchLoading, setSearchLoading] = useState(false);

  const delayedSearch = useMemo(() =>
    debounce(() => {
      setSearch(searchTerm);
    }, 500),
    [searchTerm],
  );

  useEffect(() => {
    delayedSearch();

    return delayedSearch.cancel;
  }, [searchTerm, delayedSearch]);

  // SEARCH
  useEffect(() => {
    async function loadData() {
      try {
        if (search.length > 3) {
          const response = await api.get('/dashboard/search',
            { params: { search: search } }
          );

          console.log('SEARCH LIST >>>>>>>>>>>>')
          console.log(response.data);
          setSearchResult(response.data);
        } else {
          setSearchResult({});
        }
      } catch (error) {
        console.log(error);
      }
      finally {
        setSearchLoading(false);
      }
    }

    loadData();
  }, [search]);

  return (
    <SearchContainer>
      <Container>
        <input
          {...rest}
          type="text"
          autoComplete="off"
          ref={inputEl}
          onChange={event => {
            setSearchLoading(true);
            setSearchTerm(event.target.value);
          }}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
        />
        <footer>
          <FiSearch strokeWidth={2.5} size={20} />
        </footer>
      </Container>
      <SearchResult isActive={isActive}>
        <ul>
          {!searchResult.lessons?.length && !searchResult.articles?.length && !searchResult.materials?.length && !searchResult.topics?.length && (
            <li>
              {searchLoading && <p>Carregando...</p>}
              {!searchLoading && search.length <= 3 && <p>Busque por palavras-chave...</p>}
              {!searchLoading && search.length > 3 && <p>Poxa, não encontramos a sua busca! 😢</p>}
            </li>
          )}
          <main>
            {(searchResult.lessons && searchResult.lessons?.length > 0) && <div>
              <h5>Vídeos</h5>
              {searchResult.lessons?.map((video, index) => (
                <li key={index}>
                  <Link
                    onClick={() => {
                      if (!inputEl.current) return;

                      inputEl.current.value = '';
                      setSearchTerm('');
                    }}
                    to={`/aulas/${video.cluster.slug}/${video.slug}`}
                  >
                    {video.title}
                  </Link>
                </li>
              ))}
            </div>}

            {(searchResult.materials && searchResult.materials?.length > 0) && <div>
              <h5>Materiais</h5>
              {searchResult.materials?.map((material, index) => (
                <li key={index}>
                  <a
                    onClick={
                      () => {
                        if (!inputEl.current) return;
                      }}
                    href={`${material.material_url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {material.name}
                  </a>
                </li>
              ))}
            </div>}

            {(searchResult.articles && searchResult.articles?.length > 0) && <div>
              <h5>Artigos</h5>
              {searchResult.articles?.map((search, index) => (
                <li key={index}>
                  <Link
                    onClick={
                      () => {
                        if (!inputEl.current) return;
                        inputEl.current.value = '';
                        setSearchTerm('');
                      }}
                    to={`/suporte/artigos/${search?.section?.slug}/${search.slug}/`}
                  >
                    {search.title}
                  </Link>
                </li>
              ))}
            </div>}

            {(searchResult.topics && searchResult.topics?.length > 0) && <div>
              <h5>Tópicos do fórum</h5>
              {searchResult.topics?.map((post, index) => (
                <li key={index}>
                  <Link 
                    onClick={
                      () => { 
                        if (!inputEl.current) return;
                        inputEl.current.value = '';
                        setSearchTerm(''); 
                      }}
                      to={`/comunidade/${post.forum.slug}/topicos/${post.id}`}
                    >
                    {post.title}
                  </Link>
                </li>
              ))}
            </div>}
          </main>
        </ul>
      </SearchResult>
    </SearchContainer>
  );
}

export default InputSearchHeader;
