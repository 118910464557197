import styled from 'styled-components';

interface ISearchResultProps {
  isActive: boolean;
}

export const SearchContainer = styled.main`
  position: relative;
  width: 100%;

  @media screen and (max-width: 980px) {
    position: static;
  }
`;

export const Container = styled.div`
  position: relative;
  font-weight: 600;

  > input {
    position: relative;
    width: 100%;
    padding: 0 3rem 0 1rem;
    height: 44px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    transition: border-color .2s ease-in-out;
    width: 100%;
    color: #fff;
    background: ${props => props.theme.header.inputSearch};
    border: 1px solid ${props => props.theme.header.inputSearchBorder};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.secondary};

    ::placeholder { 
      color: #ffffff80;
      opacity: 1; 
    }

    @media screen and (max-width: 980px) {
      height: 80px;
      border-radius: 0;

      ::placeholder {
        font-size: 11px
      }
    }
  }

  > footer {
    position: absolute;
    top: 0;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    > svg {
      fill: #ffffff20;
      color: ${props => props.theme.colors.secondary};
    }
  }
`;

export const SearchResult = styled.div<ISearchResultProps>`
  position: absolute;
  width: 100%;
  top: 39px;
  padding: 1rem 1.5rem 1rem 1rem;
  border-radius: 0 0 6px 6px;
  z-index: 1;
  background: ${props => props.theme.specific.inputSearchHeaderBg};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  color: ${props => props.theme.colors.white};
  opacity: ${({ isActive }) => isActive ? 1 : 0};
  visibility: ${({ isActive }) => isActive ? 'visible' : 'hidden'};
  transition: all .2s ease-in-out;
  border-top: 1px solid #ffffff0f;

  @media screen and (max-width: 980px) {
    top: 80px;
    position: absolute;
    width: 100vw;
    left: 0;
    border-radius: 0px;
    border: none;
    box-shadow: 0 0 40px 0 rgb(94 92 154 / 30%);
    backdrop-filter: blur(8px);
  }

  > ul {
    list-style: none;

    > li {

      > a {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        transition: all .2s ease-in-out;

        &:hover {
          color: ${props => props.theme.colors.primary}c9;
        }
      }

      > p {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        transition: all .2s ease-in-out;
        margin-top: .3rem;
      }
    }

    > main {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(2, 1fr);

      /* TABLET */
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }

      > div {

        > h5 {
          color: ${props => props.theme.colors.secondary};
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          text-align: left;
          margin-bottom: 0.25rem;
        }

        > li {
          margin-bottom: 0.25rem;

          > a {
            color: #ffffff;
            text-decoration: none;
            font-weight: 500;
            font-size: 12px;
            transition: all .2s ease-in-out;

            &:hover {
              color: #ffffff99;
            }
          }

          > p {
            color: #9aa4bf;
            text-decoration: none;
            font-weight: bold;
            font-size: 12px;
            transition: all .2s ease-in-out;
            margin-top: .3rem;
          }
        }
      }
    }
  }
`;
