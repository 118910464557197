import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { TableColumn } from 'react-data-table-component';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DataTableAdapter from '../../../components/DataTableAdapter';
import DropdownButton from '../../../components/DropdownButton';

import { FiHome, FiSettings } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { CardTableContainer, Container, HeaderSection } from './styles';

import api from '../../../services/api';

import { IUserProps } from '../../../interfaces/User';

import AvatarProgress from '../../../components/AvatarProgress';

const Alunos: React.FC = () => {

  // Modal 
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);

  // Request 
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [totalRecords, setTotalRecords] = useState(0);
  
  const [filterText, setFilterText] = useState('');
  const [usersData, setUsersData] = useState<IUserProps[]>([]);

  // Loaders
  const [loadingDataTable, setLoadingDataTable] = useState(false);

  const userVariations = [
    {
      newbie: {
        label: 'Pagamento pendente',
        background: '#f5a623',
      },
      student: {
        label: 'Aluno',
        background: '#04b387',
      },
      admin: {
        label: 'Administrador',
        background: '#30388c',
      },
    }
  ];

  const columns: TableColumn<IUserProps>[] = [
    {
      name: 'Aluno',
      selector: row => row.full_name,
      cell: (row: IUserProps) => (
        <div style={{ display: 'flex', gap: '0.35rem', alignItems: 'center', padding: '1rem 0' }}>
          <AvatarProgress
            badgeValue={row.level}
            percent={row.current_exp}
            size={40}
            url={row.avatar_url}
          />
          <Link to={`/aluno/${row.id}`}>{row.full_name} {row.nickname && `(${row.nickname})`}</Link>
        </div>
      ),
    },
    {
      name: 'Cpf',
      selector: row => row.cpf,
      cell: (row: IUserProps) => (
        <span>{row.cpf}</span>
      ),
    },
    {
      name: 'Telefone',
      selector: row => row.phone_number,
      cell: (row: IUserProps) => (
        <span>{row.phone_number}</span>
      ),
    },
    {
      name: 'E-mail',
      selector: row => row.email,
      cell: (row: IUserProps) => (
        <span>{row.email}</span>
      ),
    },
    {
      name: 'Cargo',
      selector: row => row.role,
      cell: (row: IUserProps) => (
        <>
          {row.role === 'newbie' && (
            <span style={{ background: userVariations[0].newbie.background, color: '#fff', padding: '0.25rem 0.5rem', borderRadius: '0.25rem' }}>
              {userVariations[0].newbie.label}
            </span>
          )}
          {row.role === 'student' && (
            <span style={{ background: userVariations[0].student.background, color: '#fff', padding: '0.25rem 0.5rem', borderRadius: '0.25rem' }}>
              {userVariations[0].student.label}
            </span>
          )}
          {row.role === 'admin' && (
            <span style={{ background: userVariations[0].admin.background, color: '#fff', padding: '0.25rem 0.5rem', borderRadius: '0.25rem' }}>
              {userVariations[0].admin.label}
            </span>
          )}
        </>
      ),
    },
    {
      name: 'Data Cadastro',
      selector: row => row.created_at,
      cell: (row: IUserProps) => (
        new Date(row.created_at).toLocaleString('pt-BR')
      ),
    },
    {
      name: (<FiSettings size={16} strokeWidth={2.5} />),
      sortable: false,
      button: true,
      right: true,
      cell: (row: IUserProps, index: number) => (
        <DropdownButton key={index}>
          <ul>
            <li>
              <Link to={`/aluno/${row.id}`}>
                <MdKeyboardArrowRight size={18} />
                Visualizar
              </Link>
            </li>
          </ul>
        </DropdownButton>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoadingDataTable(true);
        const response = await api.get(`/users`, {
          params: {
            pageIndex: page,
            pageSize: perPage,
            search: filterText,
          }
        });

        setUsersData(response.data.records);
        setTotalRecords(response.data.totalRecords);

        console.log(response.data);
      } catch (error) {
        console.log(error);
        setLoadingDataTable(false);
      }
      finally {
        setLoadingDataTable(false);
      }
    })();
  }, [page, perPage, filterText]);

  return (
    <>
      <Container>
        <HeaderSection>
          <Breadcrumbs
            icon={FiHome}
            separator="/"
            route={[{ title: 'Alunos', link: '/alunos' }]}
          />
          <aside>
            {/* <Button
              onClick={() => setModalAddIsOpen(!modalAddIsOpen)}
              color="primary"
              icon={FiUser}
              iconBefore
            >
              Cadastrar aluno
            </Button> */}
          </aside>
        </HeaderSection>

        <CardTableContainer>
          <DataTableAdapter
            title="Alunos"
            subTitle={''}
            columns={columns}
            data={usersData}
            paginationServer={true}
            progressPending={loadingDataTable}
            perPage={perPage}
            totalRows={totalRecords}
            setFilterText={setFilterText}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </CardTableContainer>
      </Container>
    </>
  );
}

export default Alunos;