import styled from 'styled-components';


export const Container = styled.div`
  position: relative;

  > input {
    border: solid 1px ${props => props.theme.input.border};
    border-radius: 6px;
    background: ${props => props.theme.input.background};
    padding: 0.75rem 1rem;
    font-size: 14px;
    height: 50px;
    width: 100%;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
    transition: all .1s cubic-bezier(0.4,0,0.2,1);

    &:focus {
      outline: none;
      border: 2px solid ${props => props.theme.input.borderActive};
    }

    &:focus ~ label, &:valid ~ label {
      transform: translate(-10px, -50%);
      background: ${props => props.theme.input.background};
      background: linear-gradient(0deg, ${props => props.theme.input.background} 48%, rgba(0,0,0,0) 60%);
      padding: 0 3px;
      color: ${props => props.theme.input.labelColor};
      font-size: 11px;
      font-weight: bold;
    }    
  }

  > label {
    position: absolute;
    left: 1rem;
    color: ${props => props.theme.colors.white}80;
    pointer-events: none;
    font-weight: 400;
    font-size: 14px;
    transform: translateY(1rem);
    line-height: 1;
    transition: .25s cubic-bezier(0.4,0,0.2,1);
  }
`;