import styled from 'styled-components';

interface IVoteButtonProps {
  isActive: boolean;
}

export const AbsoluteHeader = styled.div`
  position: absolute;
  left: -50%;
  top: 0;
  width: 200vw;
  height: 500px;
  background: linear-gradient(90deg, #615dfa, #8d7aff);
  margin-top: -40px;
`;

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) { }

  /* MOBILE */
  @media (max-width: 480px) { }
`;

export const ContentGrid2 = styled.div`
  position: relative;
  margin: 1rem 0;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 900px auto;

  /* TABLET */
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }

  > section:nth-child(1) {
    padding: 90px 100px 20px 100px;
    border-radius: 12px;
    background: ${props => props.theme.specific.asideModule};
    color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};

    /* TABLET */
    @media (max-width: 768px) {
      width: 100%;
      padding: 32px 28px;
    }

    > div {
      
      @media (max-width: 767px) {
        display: none;
      }
    }

    > h2 {
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      color: ${props => props.theme.colors.white};

      /* MOBILE */
      @media (max-width: 480px) {
        font-size: .75rem;
      }

      > span {
        color: #adafca;
        text-transform: uppercase;
      }
    }

    > h1 {
      margin-top: 22px;
      font-size: 2.875rem;
      font-weight: 700;
      line-height: .9565217391em;
      color: ${props => props.theme.colors.white};

      /* MOBILE */
      @media (max-width: 480px) {
        font-size: 2.25rem;
      }
    }

    > main {
      display: grid;
      flex-direction: column;
      grid-template-columns: 84px auto;
      grid-gap: 1rem;
      margin-top: 70px;

      /* TABLET */
      @media (max-width: 768px) {
        display: flex;
      }

      > div {

        &:nth-child(1) {
          justify-content: start;
          display: flex;
          flex-direction: column;
          align-items: center;

          /* TABLET */
          @media (max-width: 768px) {
            display: flex;
            flex-direction: row;
          }
        }

        > div {

          /* TABLET */
          @media (max-width: 768px) {
            justify-content: start;
            margin-right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        > p {
          font-size: 1rem;
          font-weight: 500;
          line-height: 2em;
          text-align: left;
          color: ${props => props.theme.colors.white};

          &:not(:first-child) {
            margin: 1rem 0;
          }

          > a {
            font-weight: bold;
          }

          > img {
            height: auto;
            max-width: 100%;
            border-radius: 12px;
          }
        }

        ul, ol {
          margin: 1rem 0;
          padding-left: 2rem;
        }

        li {
          line-height: 1.5;
        }

        h1, h2, h3, h4, h5, h6 {
          margin: 1rem 0;
        }
      }
    }

    > footer {
      border-top: 1px solid #adafca35;
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 0 1rem 0;

      > h4 {
        font-size: 1rem;
      }

      > p {
        margin-top: 1rem;
        color: ${props => props.theme.colors.white};
        margin-top: 2.2rem;
        font-size: .85rem;
        font-weight: bold;

        > a {
          color: ${props => props.theme.colors.primary};
        }
      }

      > div {
        display: flex;
        flex-direction: column;

        > div {

          > p {
            text-align: center;
            color: #adafca;
            font-size: 12px;
            line-height: 1;
          }
        }
      }
    }
  }

  > section:nth-child(2) {
    border-radius: 12px;
    width: 100%;
    color: ${props => props.theme.colors.white};

    > h2 {
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 1rem;

      /* TABLET */
      @media (max-width: 1024px) {
        color: ${props => props.theme.colors.white};
      }
    }

    > section {

      > a {
        display: flex;
        align-items: flex-start;
        text-align: center;
        justify-content: start;
        text-align: left;
        flex-direction: column;
        padding: 32px;
        margin-bottom: 1rem;
        border-radius: 12px;
        text-decoration: none;
        background: ${props => props.theme.specific.asideModule};
        transition: all .2s ease-in-out;
        border: 2px solid ${props => props.theme.cards.border};
        color: ${props => props.theme.colors.white};

        &:hover, &.active {
          border: 2px solid ${props => props.theme.colors.primary};
        }

        > h1 {
          line-height: 1;
          font-size: 1.2rem;
          margin-bottom: 1rem;
          color: ${props => props.theme.colors.white};
        }

        > h2 {
          line-height: 1;
          font-size: 32px;
          margin-bottom: 1rem;
          color: ${props => props.theme.colors.primary};
        }

        > p {
          color: ${props => props.theme.colors.white};
          font-size: .875rem;
          font-weight: 500;
          margin-bottom: 1rem;
          line-height: 1.4285714286em;
        }

        > a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #9aa4bf;
          font-size: .875rem;
          font-weight: 600;

          > span {
            font-size: .65rem;
            margin-top: 3px;
          }
        }

        > h6 {
          background: ${props => props.theme.colors.primary};
          color: #fff;
          border-radius: 4px;
          padding: 2px 6px;
          font-weight: 500;
        }
      }
    }
  }

`;

export const VoteButton = styled.button<IVoteButtonProps>`
  margin: 1rem .5rem;
  background: ${props => props.isActive ? props.theme.colors.primary : 'transparent'};
  border: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.isActive ? '#fff' : props.theme.colors.primary};
  min-width: 90px;
  width: auto;
  padding: .45rem 0;
  border-radius: 6px;
  transition: all .2s ease-in-out;

  &:not(:disabled):hover {
    background: ${props => props.theme.colors.primary};
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
