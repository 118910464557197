import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { Controller, useForm } from 'react-hook-form';
import { ArrowsVertical } from 'phosphor-react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { FiHome } from 'react-icons/fi';
import toast from 'react-hot-toast';

import Breadcrumbs from '../../../components/Breadcrumbs';
import Input from '../../../components/Form/Input';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import WysiwygEditor from '../../../components/WysiwygEditor';
import SimpleMultiSelect from '../../../components/Form/SimpleMultiSelect';
import LoaderBIO from '../../../components/Loader';
import Button from '../../../components/Button';

import { Container, DetailsExerciseToggle, ExerciseContainer, FooterConfirmButton, HeaderSection, Line5, Line6, LineInfoQuestions, Separator } from './styles';

import { IGenericOptions } from '../../../interfaces/IGenericOptions';
import { IClusterProps } from '../../../interfaces/Cluster';
import { loadConfettiAllPage } from '../../../utils/useConfetti';

const SimuladosEdit: React.FC = () => {
  const history = useHistory();
  const { questionId } = useParams<{ questionId: string }>();

  // Validation
  const schema = Joi.object({
    cluster_id: Joi.string().uuid().required().messages({ '*': 'Informe uma área do conhecimento válida.' }),
    exam: Joi.string().required().messages({ '*': 'Informe uma Instituição válida.' }),
    year: Joi.string().required().messages({ '*': 'Informe um ano válido.' }),
    difficulty: Joi.string().required().messages({ '*': 'Informe a dificuldade da questão.' }),
    content: Joi.string().max(3000).required().messages({
      '*': 'Informe um enunciado válido.',
      'string.min': `O campo enunciado deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo enunciado deve ter no máximo {#limit} caracteres`,
    }),
    options: Joi.array().items(Joi.object({
      id: Joi.string().uuid().required().messages({ '*': 'Informe um id da opção válido.' }),
      question_id: Joi.string().uuid().required().messages({ '*': 'Informe um id da questão válido.' }),
      content: Joi.string().max(3000).required().messages({
        '*': 'Informe uma opção válida.',
        'string.min': `O campo opção deve ter no mínimo {#limit} caracteres`,
        'string.max': `O campo opção deve ter no máximo {#limit} caracteres`,
      }),
      created_at: Joi.string().required().messages({ '*': 'Informe uma data de criação válida.' }),
      updated_at: Joi.string().required().messages({ '*': 'Informe uma data de atualização válida.' }),
    })),
    correctOption: Joi.string().required().messages({ '*': 'Informe uma resposta correta.' }),
  });

  const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));

      let newData = data;

      return joiResolver(schema)(newData, context, options);
    },
  });

  // Loaders
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // Data
  const [clustersData, setClustersData] = useState<IGenericOptions[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const [clusterResponse, praticeResponse] = await Promise.all([
          api.get(`/clusters`, { params: { pageIndex: 0, pageSize: 99, search: '' } }),
          api.get(`/questions/${questionId}`),
        ]);

        const clusters = clusterResponse.data?.records;

        reset({
          cluster_id: praticeResponse.data.cluster_id,
          exam: praticeResponse.data.exam,
          year: praticeResponse.data.year,
          difficulty: praticeResponse.data.difficulty,
          content: praticeResponse.data.content,
          options: praticeResponse.data.options.map((option: any) => {
            return {
              id: option.id || option._id || null,
              question_id: option.question_id,
              content: option.content,
              //is_correct: String(option.findIndex((option: any) => option.is_correct === true)),
              created_at: option.created_at || new Date().toISOString(),
              updated_at: option.updated_at || new Date().toISOString(),
            }
          }),
          correctOption: praticeResponse.data.options.findIndex((option: any) => option.is_correct === true).toString(),
        });

        // create a new array of options and values
        const options: IGenericOptions[] = clusters.map((cluster: IClusterProps) => {
          return {
            value: cluster.id,
            label: cluster.title,
          }
        });

        setClustersData(options);
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, [questionId, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoadingSubmit(true);

      const options = data.options.map((option: any, index: number) => {
        return {
          content: option.content,
          is_correct: data.correctOption === index.toString(),
        }
      });

      const { correctOption, ...rest } = data;

      const response = await api.put(`/questions/${questionId}`, {
        ...rest,
        options,
      });

      console.log(response.data);

      if (response.status === 200) {
        toast.success('Questão editada com sucesso!', {
          position: 'bottom-right',
          duration: 5000,
          className: 'toast-samuquinha',
        });

        loadConfettiAllPage();
        history.push('/simulados');
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoadingSubmit(false);
    }
  });

  return (
    <>
      <LoaderBIO isVisible={loading} />

      <Container>
        <HeaderSection>
          <Breadcrumbs
            icon={FiHome}
            separator="/"
            route={[
              { title: 'Questões dos simulados', link: '/simulados' },
              { title: 'Editar questão', link: '/simulados/questoes' },
            ]}
          />
          <aside>
            {/* button? */}
          </aside>
        </HeaderSection>

        <form onSubmit={onSubmit}>
          <DetailsExerciseToggle open>
            <summary>
              <aside>
                <ArrowsVertical size={22} weight="duotone" />
                Editar questão
              </aside>
            </summary>
            <ExerciseContainer>
              <LineInfoQuestions>
                <div>
                  <Controller
                    name={`cluster_id`}
                    control={control}
                    render={({ field }) => {

                      return (
                        <SimpleMultiSelect
                          register={register}
                          name={field.name}
                          label="Área do conhecimento"
                          placeHolder="Selecione..."
                          options={clustersData}
                          setResult={(values) => { setValue(`cluster_id`, values) }}
                          defaultValue={field.value}
                          isMulti={false}
                          required
                        />
                      )
                    }}
                  />
                  <MessageErrorValidator>{errors?.cluster_id?.message as string}</MessageErrorValidator>
                </div>
                <div>
                  <Input
                    register={register}
                    name={`exam`}
                    type="text"
                    label="Instituição"
                    autoComplete="off"
                  />
                  <MessageErrorValidator>{errors?.exam?.message as string}</MessageErrorValidator>
                </div>
                <div>
                  <Input
                    register={register}
                    name={`year`}
                    type="text"
                    label="Ano da questão"
                    autoComplete="off"
                    minLength={4}
                    maxLength={4}
                  />
                  <MessageErrorValidator>{errors?.year?.message as string}</MessageErrorValidator>
                </div>
                <div>
                  <Controller
                    name={`difficulty`}
                    control={control}
                    render={({ field }) => {

                      return (
                        <SimpleMultiSelect
                          register={register}
                          name={field.name}
                          label="Dificuldade"
                          placeHolder="Selecione..."
                          options={[
                            { value: 'easy', label: 'Fácil' },
                            { value: 'medium', label: 'Médio' },
                            { value: 'hard', label: 'Difícil' },
                          ]}
                          setResult={(values) => { setValue(`difficulty`, values) }}
                          defaultValue={field.value}
                          isMulti={false}
                          required
                        />
                      )
                    }}
                  />
                  <MessageErrorValidator>{errors?.difficulty?.message as string}</MessageErrorValidator>
                </div>
              </LineInfoQuestions>
              <Line5>
                <h4>Enunciado da questão:</h4>
                <div>
                  <Controller
                    name={`content`}
                    control={control}
                    render={({ field }) => (
                      <WysiwygEditor
                        setResult={(values) => { setValue(`content`, values) }}
                        minEditorHeight={200}
                        uploadRoute={`/exercises/upload`}
                        defaultValue={field.value}
                        name={field.name}
                      />
                    )}
                  />
                  <MessageErrorValidator>{errors?.content?.message as string}</MessageErrorValidator>
                </div>
              </Line5>
              <Line6>
                <div>
                  <h4>Alternativa A:</h4>
                  <Controller
                    name={`options[0][content]`}
                    control={control}
                    render={({ field }) => (
                      <WysiwygEditor
                        setResult={(values) => { setValue(`options[0][content]`, values) }}
                        minEditorHeight={200}
                        uploadRoute={`/exercises/upload`}
                        defaultValue={field.value}
                        name={field.name}
                      />
                    )}
                  />
                  <MessageErrorValidator>{(errors?.options as any)?.length > 0 && (errors?.options as any)[0]?.content?.message as string}</MessageErrorValidator>
                </div>
                <div>
                  <h4>Alternativa B:</h4>
                  <Controller
                    name={`options[1][content]`}
                    control={control}
                    render={({ field }) => (
                      <WysiwygEditor
                        setResult={(values) => { setValue(`options[1][content]`, values) }}
                        minEditorHeight={200}
                        uploadRoute={`/exercises/upload`}
                        defaultValue={field.value}
                        name={field.name}
                      />
                    )}
                  />
                  <MessageErrorValidator>{(errors?.options as any)?.length > 0 && (errors?.options as any)[1]?.content?.message as string}</MessageErrorValidator>
                </div>
                <div>
                  <h4>Alternativa C:</h4>
                  <Controller
                    name={`options[2][content]`}
                    control={control}
                    render={({ field }) => (
                      <WysiwygEditor
                        setResult={(values) => { setValue(`options[2][content]`, values) }}
                        minEditorHeight={200}
                        uploadRoute={`/exercises/upload`}
                        defaultValue={field.value}
                        name={field.name}
                      />
                    )}
                  />
                  <MessageErrorValidator>{(errors?.options as any)?.length > 0 && (errors?.options as any)[2]?.content?.message as string}</MessageErrorValidator>
                </div>
                <div>
                  <h4>Alternativa D:</h4>
                  <Controller
                    name={`options[3][content]`}
                    control={control}
                    render={({ field }) => (
                      <WysiwygEditor
                        setResult={(values) => { setValue(`options[3][content]`, values) }}
                        minEditorHeight={200}
                        uploadRoute={`/exercises/upload`}
                        defaultValue={field.value}
                        name={field.name}
                      />
                    )}
                  />
                  <MessageErrorValidator>{(errors?.options as any)?.length > 0 && (errors?.options as any)[3]?.content?.message as string}</MessageErrorValidator>
                </div>
                <div>
                  <h4>Alternativa E:</h4>
                  <Controller
                    name={`options[4][content]`}
                    control={control}
                    render={({ field }) => (
                      <WysiwygEditor
                        setResult={(values) => { setValue(`options[4][content]`, values) }}
                        minEditorHeight={200}
                        uploadRoute={`/exercises/upload`}
                        defaultValue={field.value}
                        name={field.name}
                      />
                    )}
                  />
                  <MessageErrorValidator>{(errors?.options as any)?.length > 0 && (errors?.options as any)[4]?.content?.message as string}</MessageErrorValidator>
                </div>
              </Line6>
              <Separator>
                <section>
                  <main>
                    Qual é a alternativa correta?
                    <Controller
                      name={`correctOption`}
                      control={control}
                      render={({ field }) => {

                        return (
                          <SimpleMultiSelect
                            register={register}
                            name={field.name}
                            label="Alternativa correta"
                            placeHolder="Selecione..."
                            options={[
                              { value: '0', label: 'A' },
                              { value: '1', label: 'B' },
                              { value: '2', label: 'C' },
                              { value: '3', label: 'D' },
                              { value: '4', label: 'E' },
                            ]}
                            setResult={(values) => { setValue(`correctOption`, values) }}
                            defaultValue={field.value}
                            isMulti={false}
                            required
                          />
                        )
                      }}
                    />
                    <MessageErrorValidator>{errors?.correctOption?.message as string}</MessageErrorValidator>
                  </main>
                </section>
              </Separator>

            </ExerciseContainer>
          </DetailsExerciseToggle>
          <FooterConfirmButton>
            <Button type="submit" color="primary" loading={loadingSubmit} disabled={loadingSubmit}> Editar questão </Button>
          </FooterConfirmButton>
        </form>
      </Container>
    </>
  );
}

export default SimuladosEdit;