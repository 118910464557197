import React, { HTMLInputTypeAttribute, InputHTMLAttributes, useState } from 'react';
import { IconBaseProps } from 'react-icons';

import { mask } from "mask-input-dynamic";

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  type: HTMLInputTypeAttribute;
  icon?: React.ComponentType<IconBaseProps>;
  register: any;
  ref?: HTMLInputElement,
  setResult(values: any): any;
  pattern?: string;
  defaultValue?: string;
  stylePattern?:  'date' | 'time' | 'fone' | 'cpf' | 'cep' | 'cnpj'; 
}

const InputMask: React.FC<InputProps> = ({ name, label, register, required, setResult, pattern, defaultValue, stylePattern, ...rest }) => {

  const [maskedValue, setMaskedValue] = useState<any>(defaultValue);
 
  return (
    <Container>
      <input
        {...register(name, { required })}
        {...rest}
        onChange={(e) => {
          setMaskedValue(mask(e.target.value, { pattern: pattern, style: stylePattern, }))
          setResult(maskedValue)
        }}
        value={maskedValue}
      />
      <label htmlFor={name}>{label}</label>
    </Container>
  );
};

export default InputMask;