import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 12px 12px 0 0;
  overflow: hidden;

  /* Customize focus-visible for keyboard */
  *:focus-visible {
    border-radius: 4px;
    animation-name:  showFocus;
    animation-duration: 0.32s;
    animation-fill-mode: forwards;
  }

  /* Remove outline for non-keyboard :focus */
  *:focus {
    outline: none;
  }

  summary {
    color: ${props => props.theme.colors.white};
    position: relative;
    padding: 28px 26px 28px 60px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    list-style-type: none;
    border-bottom: 1px solid #adafca1a;
    &::-webkit-details-marker { display:none; }
    user-select: none;

    > svg {
      margin-right: 0.5rem;
      position: absolute;
      top: 28px;
      left: 28px;
      margin-top: 4px;
      color: ${props => props.theme.specific.menuButton};
    }

    > p {
      margin-top: 4px;
      color: ${props => props.theme.colors.whiteGray};
      font-size: 11px;
      font-weight: 400;
      line-height: 1.3333333333em;
      padding: 0;
      width: 85%;
      user-select: none;
    }
  }

  /* Content in Accordion fades in */
  details[open] > div {
    opacity: 0;
    padding: 28px 26px 28px 60px;
    background: ${props => props.theme.cards.tertiary};
    animation-name: showContent;
    animation-duration: 0.6s;
    animation-delay: 0.16s;
    animation-fill-mode: forwards;
    user-select: none;

    > ul {
      list-style: none;

      > li {

        > a {
          display: block;
          font-size: 11px;
          font-weight: 600;
          line-height: 1.5rem;
          text-decoration: none;
          color: ${props => props.theme.colors.white};
          transition: all .2s ease-in-out;

          &:hover {
            transform: translate(4px);
            color: ${props => props.theme.colors.tertiary};
          }

          &.active {
            color: ${props => props.theme.colors.tertiary};
          }
        }
      }
    }
  }

  /* Arrow for Accordion */
  details summary:after {
    content: '';
    position: absolute;
    top: 30px;
    right: 30px;
    width: 4px;
    height: 4px;
    border: solid;
    border-color: $acc-arrow-color;
    border-width: 0 2px 2px 0;
    animation-name: flipArrowDown;
    animation-duration: 0.32s;
    animation-fill-mode: forwards;
  }

  details[open] summary:after {
    margin-top: 6px;
    animation-name: flipArrowUp;
    animation-duration: 0.24s;
    animation-fill-mode: forwards;
  }

  /* Animation for Focus */
  @keyframes showFocus {
    from {
      box-shadow: none;
    }
    to {
      box-shadow: 0 0 0 2px $acc-focus-color;
    }
  }

  /* Animation for Content */
  @keyframes showContent {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Animation for Arrow */
  @keyframes flipArrowUp { 100% { -webkit-transform: rotate(-135deg); transform:rotate(-135deg); } }
  @keyframes flipArrowDown { 100% { -webkit-transform: rotate(45deg); transform:rotate(45deg); } }
`;
