import React, { useCallback, useEffect, useState } from 'react';
import { IAchievementsProps } from '../../interfaces/Achievements';
import { loadConfettiAllPage } from '../../utils/useConfetti';
import ModalConfirm from '../ModalConfirmation';

import { Container, ModalAchiviementContainer } from './styles';

interface IModalAchiviementProps {
  achiviement: IAchievementsProps;
}

const ModalAchiviement: React.FC<IModalAchiviementProps> = ({ achiviement }) => {
  const [finishConfirmModal, setFinishConfirmModal] = useState<boolean>(true);

  const onConfirmModal = useCallback(() => {
    setFinishConfirmModal(false);
  }, []);

  useEffect(() => {
    loadConfettiAllPage();
  }, []);

  return (
    <Container>
      <ModalConfirm
        confirmButtonColor="#8f7ff9"
        confirmText="Bora pra próxima!"
        cancelText="Cancelar"
        onConfirm={onConfirmModal}
        showCancelButton={false}
        isOpen={finishConfirmModal}
        hideConfirmButton={false}
        isLoading={false}
        setIsOpen={() => setFinishConfirmModal(!finishConfirmModal)}
        closeModal={() => setFinishConfirmModal(false)}
        allowOutsideClick={true}
        allowEscapeKey={true}
        height="700px"
      >
        <ModalAchiviementContainer bgImage={achiviement.icon_url}>
          <header />
          <main>
            <h1>{achiviement.title}</h1>
            <p>{achiviement.description}</p>
          </main>
        </ModalAchiviementContainer>
      </ModalConfirm>
    </Container>
  );
}

export default ModalAchiviement;