export const handleVideoThumbinalDependingProvider = (video_provider: string, video: string): string => {
  switch (video_provider) {
    case 'vimeo':
      return `https://vumbnail.com/${video}.jpg`;
    case 'youtube':
      return `https://img.youtube.com/vi/${video}/mqdefault.jpg`;
    case 'panda':
      return `https://b-vz-cb1dc143-4cf.tv.pandavideo.com.br/${video}/thumbnail.jpg`;
    default:
      return `https://b-vz-cb1dc143-4cf.tv.pandavideo.com.br/${video}/thumbnail.jpg`;
  }
};