import React, { useCallback, useContext, useState } from 'react';

import api from '../../services/api';

import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { TopicContext } from '../../pages/user/ComunidadeFeed';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ReactMarkdown from 'react-markdown';
import { CalendarBlank, ChatCenteredDots, Eye } from 'phosphor-react';
import toast from 'react-hot-toast';

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import AvatarProgress from '../AvatarProgress';
import BadgeUserRole from '../BadgeUserRole';
import ModalConfirm from '../ModalConfirmation';
import Modal from '../Modal';
import Input from '../Form/Input';
import MessageErrorValidator from '../MessageErrorValidator';
import WysiwygInnerModal from '../WysiwygInnerModal';
import AwesomeButtonAdapter from '../AwesomeButtonAdapter';

import {
  Container,
  Line1,
  Line2,
  Line3,
  MainContent,
  ModalContainer,
} from './styles';

import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import { IEvolutionProps } from '../../interfaces/Evolutions';
import { Role } from '../../interfaces/Role';

interface ICardCommunityTopicProps {
  id: string;
  slug: string;
  title: string;
  content: string;
  created_at: string;
  views: number;
  author: {
    id: string;
    show_name: string;
    avatar_url: string;
    level: number;
    role_name: string;
    current_exp: number;
    role: Role;
    evolution: IEvolutionProps
    university?: {
      id: string;
      name: string;
      acronym: string;
      created_at: string;
      updated_at: string;
    };
    career?: {
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
    };
  };
}

const CardCommunityTopic: React.FC<ICardCommunityTopicProps> = ({
  id,
  slug,
  title,
  content,
  created_at,
  views,
  author,
}) => {
  const { user, WithAuthorization } = useAuth();
  const history = useHistory();

  // Validation
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log(
        'validation result',
        await joiResolver(schema)(data, context, options),
      );
      return joiResolver(schema)(data, context, options);
    },
  });

  // Joi Validation Schema
  const schema = Joi.object({
    title: Joi.string().min(5).required().messages({
      '*': 'Informe um título válido.',
      'string.min': `O campo deve ter no mínimo {#limit} caracteres`,
    }),
    description: Joi.string().min(5).max(1500).required().messages({
      '*': 'Informe uma descrição válida.',
      'string.min': `O campo descrição deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo descrição deve ter no máximo {#limit} caracteres`,
    }),
  });

  // Modals
  const [modalEditTopicIsOpen, setModalEditTopicIsOpen] = useState(false);
  const [confirmRemoveTopicModal, setConfirmRemoveTopicModal] = useState(false);

  // Context
  const { topicData, setTopicData } = useContext(TopicContext);

  const handleRemoveTopic = useCallback(
    async (topic_id: string) => {
      try {
        const response = await api.delete(`/forums/${slug}/topics/${topic_id}`);
        console.log(response.data);

        if (response.status === 200) {
          history.push(`/comunidade/${slug}`);
          toast.error('Tópico excluído com sucesso!', {
            position: 'bottom-right',
            duration: 6000,
            className: 'toast-samuquinha',
          });
        }
      } catch (error) {
        console.log(error);
        toast.error('Ops! Algo deu errado. 🤷‍♂️', {
          position: 'bottom-center',
          duration: 6000,
        });
      }
    },
    [slug, history],
  );

  const onSubmit = handleSubmit(async data => {
    try {
      console.log(data);

      const response = await api.put(`/forums/${slug}/topics/${id}`, {
        title: capitalizeFirstLetter(data.title),
        content: capitalizeFirstLetter(data.description),
      });

      console.log(response.data);

      if (response.status === 200) {
        toast.success('Tópico editado com sucesso!', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });

        setTopicData({
          ...topicData,
          title: capitalizeFirstLetter(data.title),
          content: capitalizeFirstLetter(data.description),
        });

        setModalEditTopicIsOpen(false);
        reset();
      }
    } catch (error) {
      console.log(error);
      toast.error('Ops! Algo deu errado.', {
        position: 'bottom-center',
        duration: 6000,
      });
    }
  });

  const scrollToBottom = useCallback(() => {
    const mainScroll = document.querySelector('#main-scroll');

    if (mainScroll) {
      mainScroll.scrollBy({
        left: 0,
        top: mainScroll.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <>
      <Container>
        <header>
          <aside>
            <p>
              <CalendarBlank size={20} weight="duotone" />{' '}
              {created_at &&
                format(parseISO(created_at), "EEEE, dd 'de' MMMM", {
                  locale: ptBR,
                })}
            </p>
          </aside>
          <main>
            <button onClick={scrollToBottom}>Responder</button>
            {author?.id === user.id && (
              <button
                onClick={() => setModalEditTopicIsOpen(!modalEditTopicIsOpen)}
              >
                Editar
              </button>
            )}
            {author?.id === user.id ? (
              <button
                onClick={() =>
                  setConfirmRemoveTopicModal(!confirmRemoveTopicModal)
                }
              >
                Excluir
              </button>
            ) : (
              <WithAuthorization roles={['admin']}>
                <button
                  onClick={() =>
                    setConfirmRemoveTopicModal(!confirmRemoveTopicModal)
                  }
                >
                  Excluir
                </button>
              </WithAuthorization>
            )}
          </main>
        </header>
        <main>
          <aside>
            <AvatarProgress
              badgeValue={author.level}
              percent={author.current_exp}
              size={128}
              url={author.avatar_url}
              currentEvolutionUrl={author?.evolution?.icon_url}
            />
            <h3>{author?.show_name}</h3>
            {author?.career?.name && <p>{author?.career?.name}</p>}
            <BadgeUserRole role={'author'} />
            {author?.role === 'admin' && <BadgeUserRole role={'admin'} />}
          </aside>
          <MainContent>
            <main>
              <h2>{topicData.title}</h2>
              <ReactMarkdown disallowedElements={['a', 'link', 'anchor']}>
                {topicData.content?.replace(/\\s/g, '\n')}
              </ReactMarkdown>
            </main>
            <footer>
              <p>
                <Eye size={20} weight="duotone" /> {views}{' '}
                {views > 1 ? 'alunos' : 'aluno'} já{' '}
                {views > 1 ? 'visualizaram' : 'visualizou'} este tópico.
              </p>
            </footer>
          </MainContent>
        </main>
      </Container>

      {/* START MODAL CONFIRM */}
      <ModalConfirm
        title="Excluir tópico?"
        confirmText="Confirmar"
        cancelText="Cancelar"
        text="Tem certeza de que deseja excluir este tópico?"
        onConfirm={() => handleRemoveTopic(id)}
        isOpen={confirmRemoveTopicModal}
        isLoading={false}
        setIsOpen={() => setConfirmRemoveTopicModal(!confirmRemoveTopicModal)}
        closeModal={() => setConfirmRemoveTopicModal(false)}
      ></ModalConfirm>
      {/* END MODAL CONFIRM */}

      {/* START MODAL EDIT TOPIC */}
      <Modal
        isOpen={modalEditTopicIsOpen}
        setIsOpen={() => setModalEditTopicIsOpen(!modalEditTopicIsOpen)}
        closeModal={() => setModalEditTopicIsOpen(false)}
        size="lg"
        title="Editar tópico"
      >
        <ModalContainer>
          <form onSubmit={onSubmit}>
            <Line1>
              <Input
                register={register}
                type="text"
                name="title"
                defaultValue={title}
                label="Título da postagem *"
                autoComplete="off"
              />
              {errors.title && (
                <MessageErrorValidator>
                  {errors.title.message as string}
                </MessageErrorValidator>
              )}
            </Line1>
            <Line2>
              <WysiwygInnerModal
                maxLength={1500}
                placeholder="Formule melhor sua pergunta aqui..."
                setResult={values => {
                  setValue('description', values);
                }}
                defaultResult={content}
              />
              {errors.description && (
                <MessageErrorValidator>
                  {errors.description.message as string}
                </MessageErrorValidator>
              )}
            </Line2>
            <Line3>
              <AwesomeButtonAdapter type="primary" ripple={true}>
                Editar tópico{' '}
                <ChatCenteredDots size={32} color="#aa14cb" weight="duotone" />
              </AwesomeButtonAdapter>
            </Line3>
          </form>
        </ModalContainer>
      </Modal>
      {/* END MODAL EDIT TOPIC */}
    </>
  );
};

export default CardCommunityTopic;
