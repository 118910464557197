import styled from 'styled-components';

import { maxWidthContent } from '../../../utils/maxWidthContent';

export const Container = styled.main`
  position: relative;
  grid-area: 2 / 2 / 2 / 2;
  width: 100%;
  height: 100%;
  padding: 2.35rem 1rem 2rem 1rem;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
    width: 22px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: #29c191;
  }
`;

export const InnerContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Center = styled.section`
  position: relative;
  max-width: ${maxWidthContent};
  width: 100%;
  margin: 0 auto;
`;
