import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';

import Breadcrumbs from '../../../components/Breadcrumbs';
import InputDebounceAdapter from '../../../components/InputDebounceAdapter';

import { Container, HeaderSection, SectionContainer1, SearchContainer, PaginationContainer } from './styles';

import { IMoviesProps } from '../../../interfaces/Movie';
import CardMovieThumbnail from '../../../components/CardMovieThumbnail';
import Pagination from '../../../components/Pagination';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import { House, MagnifyingGlass, Popcorn } from 'phosphor-react';
import LoaderSpinner from '../../../components/LoaderSpinner';
import NotFoundRegister from '../../../components/NotFoundRegister';

export interface IDataMovieProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: (IMoviesProps)[];
}

const Sasaflix: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [movies, setMovies] = useState<IDataMovieProps>({} as IDataMovieProps);

  // pagination
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    (async () => {
      try {

        setLoading(true);

        const response = await api.get('/movies', {
          params: {
            pageIndex: currentPage - 1,
            pageSize: 18,
            search: search,
          }
        });

        console.log(response.data);
        setMovies(response.data);

        //pagination
        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentPage, search]);


  const handleFilterModule = useCallback((value: string) => {
    setSearch(value);
    setCurrentPage(1);
  }, []);

  return (
    <Container>

      <HeaderSectionContainer padding="1rem 2rem">
        <h1><Popcorn weight="duotone" /> SasaFLIX</h1>
        <p>Sugestões de filmes e séries relacionadas ao mundo da biologia e vestibulares.</p>
      </HeaderSectionContainer>

      <HeaderSection>
        <Breadcrumbs
          icon={House}
          separator="/"
          route={[{ title: 'SasaFLIX', link: '/sasaflix' }]}
        />
        <SearchContainer>
          <InputDebounceAdapter
            type="text"
            debounceTimeout={300}
            minLength={3}
            onChange={(event) => handleFilterModule(event.target.value)}
            placeholder="Busque por filmes..."
          />
          <MagnifyingGlass weight="duotone" />
        </SearchContainer>
      </HeaderSection>

      <SectionContainer1 style={loading ? { height: '250px', overflow: 'hidden' } : {}}>
        {loading && (<LoaderSpinner blur />)}

        {movies.records?.map((movie) => (
          <CardMovieThumbnail
            key={movie.id}
            id={movie.id}
            name={movie.name}
            age_group={movie.age_group}
            cover={movie.cover}
            duration={movie.duration}
            rating={movie.vote_average}
            year={movie.year}
            vote_average={movie.vote_average}
            height="350px"
          />
        ))}
      </SectionContainer1>

      {movies.records?.length === 0 && (
        <NotFoundRegister
          emoji="😢"
          description={
            search.length > 0 ?
              `Nenhum registro encontrado para a busca "${search}".`
              :
              `Nenhum registro encontrado.`
          }
        />
      )}

      {!loading && (
        <PaginationContainer>
          <p>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} filmes ou séries`}</p>
          {totalRecords !== 0 && (
            <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          )}
        </PaginationContainer>
      )}
    </Container>
  );
}

export default Sasaflix;