import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import api from '../../../services/api';
import { API_TMDB } from '../../../services/api_tmdb';

import { useTheme } from '../../../hooks/theme';
import { useHistory, useParams } from 'react-router-dom';

import Slider from "react-slick";
import ReactStars from 'react-stars';
import toast from 'react-hot-toast';

import Modal from '../../../components/Modal';
import TooltipAdapter from '../../../components/TooltipAdapter';
import HetaryGroup from '../../../components/CardMovie/HetaryGroup';
import LoaderBIO from '../../../components/Loader';

import { CardContainer, ChannelsContainer, Container, Description, FakeBg, MainContent, MainHeader, ModalContainer, PLayerLogo, RatingContainer, SampleNextArrow, SamplePrevArrow, SliderContainer, TrailerButtonContainer } from './styles';

import { IMoviesProps } from '../../../interfaces/Movie';

import { maxWidthContent } from '../../../utils/maxWidthContent';
import { FilePdf } from 'phosphor-react';
import CardMovieThumbnail from '../../../components/CardMovieThumbnail';

const Filme: React.FC = () => {
  const { id: movieId } = useParams<{ id: string }>();
  const { theme } = useTheme();
  const history = useHistory();

  // Modals
  const [modalMovieTrailer, setModalMovieTrailer] = useState(false);

  // Loaders
  const [loadingMovie, setLoadingMovie] = useState(false);

  // Datas
  const [movieData, setMovieData] = useState<IMoviesProps>({} as IMoviesProps);
  const [movieSuggestionsData, setMovieSuggestionsData] = useState<IMoviesProps[]>([]);

  useLayoutEffect(() => {
    const mainContentSection: any = document.querySelector('#main-scroll');
    const mainContentCenter: any = document.querySelector('#content-center');
    const mainFooter: any = document.querySelector('#main-footer-container');

    if (mainContentSection && mainContentCenter && mainFooter) {
      mainContentSection.style.padding = '0px';
      mainContentCenter.style.maxWidth = '100%';
      mainFooter.style.display = 'none';
    }

    return () => {
      if (mainContentSection && mainContentCenter) {
        mainContentSection.style.padding = '2.35rem 1rem 2rem 2rem';
        mainContentCenter.style.maxWidth = maxWidthContent;
        mainFooter.style.display = 'block';
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!movieId) return;
        setLoadingMovie(true);

        const response = await api.get(`/movies/${movieId}`);

        setMovieData(response.data);
      } catch (error: any) {
        console.log(error);
        setLoadingMovie(false);

        if (error.response.status === 400) {
          toast.error('Desculpe, este filme não foi encontrado! 😥', {
            duration: 5000,
            position: 'bottom-right',
            className: 'toast-samuquinha',
          });

          history.push('/sasaflix');
        }
      }
      finally {
        setLoadingMovie(false);
      }
    })();
  }, [movieId, history]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/movies/random`, {
          params: { quantity: 18 }
        });

        setMovieSuggestionsData(response.data);
      } catch (error) {
        console.log(error);
        toast.error('Erro ao carregar filmes sugeridos');
      }
    })();
  }, []);

  const handleOpenRatingModal = useCallback(async (newRating: number) => {

    if (movieData.user_vote) {
      toast.error('Ops! Você já opinou sobre esse filme ', {
        duration: 3000,
        position: 'bottom-right',
        className: 'toast-samuquinha',
      });
      return;
    }

    const response = await api.post(`/movies/${movieId}/vote`, {
      stars: newRating,
    });

    console.log(Number(response.data));

    setMovieData((oldMovieData) => ({
      ...oldMovieData,
      user_vote: String(newRating),
      vote_average: Number(response.data),
      vote_count: Number(oldMovieData.vote_count + 1),
    }));
  }, [movieId, movieData.user_vote]);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow>{/* <FiArrowLeft size={22} /> */}</SamplePrevArrow>,
    nextArrow: <SampleNextArrow>{/* <FiArrowRight size={22} /> */}</SampleNextArrow>,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <LoaderBIO isVisible={loadingMovie || !movieData.id} />
      <FakeBg style={theme === 'dark' ? { backgroundColor: '#009576' } : { backgroundColor: '#f7fafc' }} />
      <Container>
        <MainHeader cover_url={API_TMDB + 'w1920_and_h800_multi_faces' + movieData.backdrop}>
          <section>
            <header>
              <main>
                <section>
                  <aside onClick={() => setModalMovieTrailer(!modalMovieTrailer)}>
                    <CardMovieThumbnail
                      key={movieData.id}
                      id={movieData.id}
                      name={movieData.name}
                      age_group={movieData.age_group}
                      cover={movieData.cover}
                      duration={movieData.duration}
                      rating={movieData.vote_average}
                      year={movieData.year}
                      vote_average={movieData.vote_average}
                      height="400px"
                      isHighlight
                    />
                  </aside>
                  <main>
                    <h1>{movieData.name} ({movieData.year})</h1>
                    <RatingContainer>
                      <p><HetaryGroup age_group={movieData.age_group} /></p>
                      <p className="duration">{movieData.duration_formatted}</p>
                      <TooltipAdapter alt={
                          !movieData.user_vote ? 'Avalie este filme' : 
                          `${Number(movieData?.vote_count)} voto${Number(movieData.vote_count) === 1 ? '' : 's'}`
                        } 
                        place="top" 
                        cursor={!movieData.user_vote ? 'pointer' : 'default'}
                      >
                        <ReactStars
                          count={5}
                          edit={movieData.user_vote ? false : true}
                          size={32}
                          color1={theme === 'dark' ? '#fff' : '#adafca'}
                          color2="#e8f262"
                          half={true}
                          value={Number(movieData?.user_vote)}
                          onChange={(newRating: number) => handleOpenRatingModal(newRating)}
                        />
                      </TooltipAdapter>
                      <p>{Number(movieData.vote_count)} voto{Number(movieData.vote_count) === 1 ? '' : 's'}</p>
                    </RatingContainer>
                    <Description>{movieData.description}</Description>
                    {movieData.channels && movieData.channels.length > 0 && (
                      <ChannelsContainer>
                        {movieData.material_url && (
                          <main>
                            <a href={movieData.material_url} title="Lista de exercícios sobre o filme" target="_blank" rel="noreferrer">
                              <FilePdf weight="duotone" size={20} /> Lista de exercícios sobre o filme
                            </a>
                          </main>
                        )}
                        <aside>
                          <header>
                            <h3>Disponível em:</h3>
                          </header>
                          <main>
                            {movieData.channels?.map((channel) => (
                              <a key={channel.id} href={channel.link} target="_blank" rel="noreferrer">
                                <PLayerLogo src={channel.logo_url} alt={channel.name} />
                              </a>
                            ))}
                          </main>
                        </aside>
                      </ChannelsContainer>
                    )}
                  </main>
                </section>
                <MainContent>
                  <SliderContainer>
                    {movieSuggestionsData && <Slider {...settings}>
                      {movieSuggestionsData.map((suggest) => (
                        <CardContainer key={suggest.id}>
                          <CardMovieThumbnail
                            key={suggest.id}
                            id={suggest.id}
                            name={suggest.name}
                            age_group={suggest.age_group}
                            cover={suggest.cover}
                            duration={suggest.duration}
                            rating={suggest.vote_average}
                            year={suggest.year}
                            vote_average={suggest.vote_average}
                            height="350px"
                          />
                        </CardContainer>
                      ))}
                    </Slider>}
                  </SliderContainer>
                </MainContent>
              </main>
            </header>
          </section>
        </MainHeader>
      </Container>

      {/* MODALS */}

      <Modal
        isOpen={modalMovieTrailer}
        setIsOpen={() => setModalMovieTrailer(!modalMovieTrailer)}
        closeModal={() => setModalMovieTrailer(false)}
        size="lg"
        height="520px"
      >
        <ModalContainer>
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/${movieData.trailer}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </ModalContainer>
      </Modal>
    </>
  );
}

export default Filme;