import styled from 'styled-components';

interface IInputAnimateDropdownProps {
  padding?: string;
  margin?: string;
  onlyWhite?: boolean | false;
}

interface ISearchResultProps {
  isActive: boolean;
}

export const Container = styled.div<IInputAnimateDropdownProps>`
  position: relative;
  line-height: 14px;
  display: inline-block;
  margin-top: 1.5rem;
  width: 100%;
  padding: ${props => props.padding ? props.padding : null};
  margin: ${props => props.margin ? props.margin : null};
`;

export const Input = styled.input<IInputAnimateDropdownProps>`
  width: 100%;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  border: 1px solid ${props => props.theme.input.border};
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.input.background};
  font-size: 1rem;
  font-weight: 700;
  transition: .3s ease-in-out;

  &:focus {
    border-color: ${props => props.theme.input.borderActive};
  }

  &:valid + label {
    font-size: .8rem;
    transform: translateY(-1.7rem);
  }

  &:invalid + label {
    transform: translateY(0);
  }

  &:focus + label {
    font-size: .8rem;
    transform:translateY(-1.7rem);
  }
`;

export const Label = styled.label<IInputAnimateDropdownProps>`
  color: #adafca;
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  top: 20px;
  left: 1rem;
  padding: 0 5px;
  pointer-events: none;
  background: ${props => props.theme.input.background};
  background: linear-gradient(0deg, ${props => props.theme.input.background} 48%, rgba(0,0,0,0) 60%);
  transition: .3s ease;
  transform: translateY(0);
`;

export const SearchResult = styled.div<ISearchResultProps>`
  position: absolute;
  width: 100%;
  top: 48px;
  padding: 1.5rem 1rem;
  border-radius: 0 0 12px 12px;
  z-index: 1;
  border: 1px solid #9aa4bf30;
  background: ${props => props.theme.input.background};
  color: ${props => props.theme.colors.white};
  opacity: ${({ isActive }) => isActive ? 1 : 0};
  visibility: ${({ isActive }) => isActive ? 'visible' : 'hidden'};
  transition: all .2s ease-in-out;

  > ul {
    list-style: none;

    > li {

      > a {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
        transition: all .2s ease-in-out;

        &:hover {
          color: ${props => props.theme.colors.primary}c9;
        }
      }

      > p {
        color: #9aa4bf;
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
        transition: all .2s ease-in-out;
        margin-top: .3rem;
      }
    }
  }
`;
