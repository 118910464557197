import styled from 'styled-components';

interface ICardAchiviementProps {
  photo: string;
  status: 'pending' | 'concluded';
}

export const Container = styled.div<ICardAchiviementProps>`
  border-radius: 8px;
  background: ${props => props.theme.specific.asideModule};
  border-radius: ${props => props.theme.radius.primary};
  color: ${props => props.theme.colors.white};
  height: 100%;
  transition: all .25s ease-in-out;
  
  ${({ status }) => status === 'pending' && `
    opacity: 0.5;
  `}

&:hover {
  opacity: 1;
}

> div {
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  
  > figure {
    height: 210px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: url(${props => props.photo}) center center / cover no-repeat;

    ${({ status }) => status === 'pending' && `
      filter: grayscale(100%);
    `}
    }
  }
  
  > main {
    position: relative;
    padding: 28px 28px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 91.6666666667%;
    padding: 28px;
    margin: -4rem auto 1.25rem auto;
    border-radius: 8px;
    background: ${props => props.theme.specific.asideModule};
    
    > button {
      background-color: ${props => props.theme.colors.primary};
      display: flex;
      height: 48px;
      border-radius: 10px;
      color: ${props => props.theme.colors.white};
      font-size: .875rem;
      font-weight: 700;
      text-align: center;
      line-height: 48px;
      cursor: pointer;
      text-decoration: none;
      transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
      align-items: center;
      justify-content: center;
      margin: 1rem 0 0 0;
      
      &:hover {
        background-color: ${props => props.theme.colors.info};
      }
    }
    
    > div > h2 {
      margin-top: 1rem;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.2em;
      min-height: 77px;
      color: ${props => props.theme.colors.white};
      text-align: center;
      
      /* TABLET */
      @media (max-width: 768px) {}
      /* MOBILE */
      @media (max-width: 480px) {
        min-height: unset;
      }
    }
    
    > div > h2 {
      margin-top: 1rem;
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.2em;
    }
    
    > p {
      font-size: .875rem;
      line-height: 1.7142857143em;
      height: 77px;
      font-weight: 500;
      text-align: center;
      color: ${props => props.theme.colors.white};
    }
    
    > footer {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      padding: 1.5rem 0 0 0;
      
      > p {
        
        > span {
          font-size: 10px;
          font-weight: 700;
          text-transform: uppercase;
          margin-top: 1.6rem;
          color: ${props => props.status === 'concluded' ? 'none' : props => props.theme.colors.white}40;
          background: ${props => props.theme.cards.primary}80;
          border: 1px solid #a4a4a430;
          padding: 4px 10px;
          border-radius: 25px;
          letter-spacing: 0.5px;
        }
      }
    }
  }
`;

export const Description = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: ${props => props.theme.colors.muted};
`;

interface IBadgeProps {
  status: 'pending' | 'concluded';
}

export const Badge = styled.div<IBadgeProps>`
  position: absolute;
  display: flex;
  flex-direction: row;
  right: -7px;
  top: 10px;
  background: #9e90fd;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 11px;
  color: #fff;

  > img {
    width: 16px;
    margin-right: 4px;
  }

  > strong {
    margin-right: 2px;
  }
`;