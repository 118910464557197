import styled from 'styled-components';

export const Container = styled.a`
  position: relative;
  padding: 2rem 1.8rem;
  display: flex;
  height: 100%;
  width: 170px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #948cbf1c;
  border-radius: ${props => props.theme.radius.secondary};
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  transition: all .2s ease-in-out;
  
  
  &:hover {
    background: #a39ad020;
    transform: translateY(-6px);
  }

  > header {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    width: fit-content;
    background: #e4defa;
    border-radius: ${props => props.theme.radius.secondary};
  } 
  
  > main {
    display: flex;
    justify-content: center;
    text-align: center;

    > h2 {
      font-size: 14px;
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 52px;
    }
  }
`;

export const ButtonDelete = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 40px;
  height: 40px;
  background: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
`;