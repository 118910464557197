import React from 'react';

import { useHistory } from 'react-router-dom';

import { Container } from './styles';

import { IProviders } from '../../../interfaces/Providers';
import { ArrowLeft, MonitorPlay } from 'phosphor-react';

interface IAulaLiteProps {
  videoUrl: string;
  provider: IProviders;
}

const AulaLite: React.FC<IAulaLiteProps> = () => {
  const router = useHistory();

  const quryParams = new URLSearchParams(window.location.search);
  const videoUrl = quryParams.get('videoUrl');
  const provider = quryParams.get('provider');
  const title = quryParams.get('title');

  if (!videoUrl || !provider || !title) {
    router.push('/');
  }

  return (
    <Container>
      <main>
        <header>
          <p><strong><MonitorPlay size={18} color="#ffffff" weight="duotone" /> Lite mode</strong></p>
          <button onClick={() => window.history.back()}>
            <ArrowLeft weight="duotone" />
            Voltar para a plataforma
          </button>
        </header>

        <h1>{title}</h1>
        <footer>
          {(provider === 'panda' && videoUrl) && (
            <iframe
              src={videoUrl}
              title="Plataforma Biologia"
              allowFullScreen
            />
          )}
          {(provider === 'vimeo' && videoUrl) && (
            <iframe
              src={videoUrl}
              title="Plataforma Biologia"
              allowFullScreen
            />
          )}
        </footer>
      </main>
    </Container>
  );
}

export default AulaLite;