import styled from 'styled-components';

export const Container = styled.div`
position: relative;
  margin-top: -14px;

  > h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: 20px;
  flex: 1;
  max-width: 450px;

  > svg {
    margin-right: 1rem;
    position: absolute;
    color: ${props => props.theme.colors.muted}80;
  }

  > input {
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
    color: ${props => props.theme.colors.white};
    width: 100%;
    max-width: 300px;
    transition: all .3s ease-in-out;

    &:focus {
      max-width: 450px;
    }
  }
`;

export const SectionContainer1 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(6, 1fr);

  @media (max-width: 1380px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 660px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 468px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PaginationContainer = styled.section`
  position: relative;
  width: 100%;
  margin-top: 2rem;

  > p {
    text-align: center;
    font-size: 12px;
  }
`;