import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`

`;

export const MainContent = styled.section`
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
  padding: 0 2rem;
  grid-template-columns: 1.15fr 5fr;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
  
  > aside {
    
    > div {
      position: sticky;
      top: -1rem;
      
      background: ${props => props.theme.cards.primary};
      border: 1px solid ${props => props.theme.cards.border};
      box-shadow: ${props => props.theme.shadows.primary};
      border-radius: ${props => props.theme.radius.primary};
    }
  }
  
  > main {
    display: flex;
    flex-direction: column;
    padding: 0.4rem 0;
  }
`;

export const SectionNotifications = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }

  > aside {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  > main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ContentGrid3 = styled.section`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
`;

export const CardNotification = styled(Link)`
  display: flex;
  align-items: center;
  padding: 1rem 1.2rem;
  border-radius: 12px;
  margin-bottom: 1rem;
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  gap: 0.5rem;
  transition: all .2s ease-in-out;

  &:hover {
    transform: translateY(-3px);
  }

  > p {
    color: ${props => props.theme.colors.textColor};
    font-size: .875rem;
    font-weight: 500;
    line-height: 1rem;
    margin-left: .7rem;
    flex: 1;

    > span:first-child {
      color: ${props => props.theme.colors.textColor};
      font-weight: 700;
    }

    > span:last-child {
      display: block;
      margin-top: 8px;
      color: #adafca; /* AQUI */
      font-size: .75rem;
      font-weight: 500;
    }
  }

  > span {
    margin-left: 20px;
    font-size: 22px;
  }
`;

export const PaginationContainer = styled.section`
  position: relative;
  width: 100%;
  margin-top: 2rem;

  > p {
    text-align: center;
    font-size: 12px;
  }
`;