import React, { useState } from 'react';

import { useForm } from 'react-hook-form';

import api from '../../services/api';

import { joiResolver } from '@hookform/resolvers/joi';
import MessageErrorValidator from '../MessageErrorValidator';
import Joi from 'joi';
import toast from 'react-hot-toast';
import { Bug, Confetti } from 'phosphor-react';

import ModalConfirm from '../ModalConfirmation';

import { Container, ModalConfirmationContainer } from './styles';
import Textarea from '../Form/Textarea';

interface IReportQuestionProps {
  requestRoute: string;
  questionId: string;
}

interface IReportQuestionsMemoryData {
  questionId: string;
}

const ReportQuestion: React.FC<IReportQuestionProps> = ({ requestRoute, questionId }) => {

  // Validation
  const schema = Joi.object({
    reason: Joi.string().min(5).max(300).required().messages({
      '*': 'Informe uma descrição válida.',
      'string.min': `O campo descrição deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo descrição deve ter no máximo {#limit} caracteres`,
    }),
  });

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));
      return joiResolver(schema)(data, context, options);
    },
  });

  const [modalReportQuestion, setModalReportQuestion] = useState(false);
  const [reportedQuestions, setReportedQuestions] = useState<IReportQuestionsMemoryData[]>([]);

  const handleReportQuestion = handleSubmit(async (data) => {
    try {
      const response = await api.post(requestRoute, {
        reason: data.reason,
      });

      if (response.status === 204) {
        toast.success('Questão reportada com sucesso!', {
          duration: 6000,
          position: 'bottom-right',
          className: 'toast-samuquinha',
        });

        reset({ reason: '' });
        setModalReportQuestion(false);

        setReportedQuestions([...reportedQuestions, { questionId }]);
      }

    } catch (error: any) {
      console.log(error);
      toast.error('Ops! Algo deu errado.', { duration: 6000, position: 'bottom-center' });
    }
  });

  return (
    <>
      <Container>
        {!reportedQuestions.find((question) => question.questionId === questionId) ? (
          <p>
          <Bug weight="duotone" size={22} />
          Encontrou algum problema com a questão? <span onClick={() => setModalReportQuestion(true)}>Reporte para o suporte técnico.</span>
        </p>
        ) : ( 
          <p>
            <Confetti weight="duotone" size={22} /> 
            Questão reportada com sucesso!
          </p>
        )}
      </Container>

      {/* START MODAL REPORT QUESTION */}
      <ModalConfirm
        title="Reportar questão"
        confirmText="REPORTAR QUESTÃO"
        cancelText="Voltar"
        showCancelButton={false}
        confirmButtonColor="#03bb85"
        onConfirm={handleReportQuestion}
        isOpen={modalReportQuestion}
        isLoading={false}
        setIsOpen={() => setModalReportQuestion((oldValue) => !oldValue)}
        closeModal={() => setModalReportQuestion(false)}
        height="auto"
      >
        <ModalConfirmationContainer>
          <h3>Conte ao nosso suporte o que há de errado com a questão no campo abaixo:</h3>
          <div>
            <Textarea register={register} name="reason" type="text" label="Descreva o problema" autoComplete="off" />
            <MessageErrorValidator>{errors?.reason?.message as string}</MessageErrorValidator>
          </div>
        </ModalConfirmationContainer>
      </ModalConfirm>
      {/* END MODAL CONFIRM REPORT QUESTION */}
    </>
  );
}

export default ReportQuestion;