import styled from 'styled-components';

interface InputProps {
  setResult(values: any): any;
}

export const Container = styled.div<InputProps>`
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }

  input {
    border: solid 1px ${props => props.theme.input.border};
    border-radius: 6px;
    background: ${props => props.theme.input.background};
    padding: 0.75rem 1rem;
    font-size: 14px;
    height: 50px;
    width: 100%;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
    transition: all .1s cubic-bezier(0.4,0,0.2,1);

    &:focus {
      outline: none;
      border: 2px solid ${props => props.theme.input.borderActive};
    }
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    visibility: visible;
    transform: translate(8px, -50%) scale(0.8);
    background: ${props => props.theme.input.background};
    background: linear-gradient(0deg, ${props => props.theme.input.background} 48%, rgba(0,0,0,0) 60%);
    padding: 0 3px;
    color: ${props => props.theme.input.labelColor};
    font-size: 14px;
    font-weight: bold;
  }
`;