import { CaretDown, File, House, Tag } from 'phosphor-react';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import api from '../../../services/api';

import Breadcrumbs from '../../../components/Breadcrumbs';

import { ComingSoon, Container, FooterSection, Line0, ModalContainer, ModalContainer2, PlanCard, Section1, Section2, SectionContainer0, SectionContainer1, SectionContainer2, SectionContainerConfirm, SectionContainerPlans } from './styles';
import { toast } from 'react-hot-toast';
import Button from '../../../components/Button';
import { loadConfettiAllPage } from '../../../utils/useConfetti';
import ReactApexChart, { Props } from 'react-apexcharts';
import { useTheme } from '../../../hooks/theme';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { IProductPros } from '../../../interfaces/Product';
import { FiPlus, FiSettings, FiTrash2 } from 'react-icons/fi';
import Modal from '../../../components/Modal';
import TooltipAdapter from '../../../components/TooltipAdapter';
import SimpleMultiSelect from '../../../components/Form/SimpleMultiSelect';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import Input from '../../../components/Form/Input';

interface IFecturedCampaigns {
  campaign: {
    id: string;
    name: string;
    cover: string;
    mobile_cover: string;
    created_at: string;
    updated_at: string;
    cover_url: string;
    mobile_cover_url: string;
  }
  featureds?: {
    product: {
      id: string;
      title: string;
      duration: number;
      price: number;
      active: boolean;
      created_at: string;
      updated_at: string;
      price_formatted: string;
    }
    has_coupon: boolean;
    amount: number;
    amount_formatted: string;
    installment: number;
    installment_formatted: string;
    checkout_url: string;
    coupon?: {
      id: string;
      code: string;
      discount_type: 'percentage' | 'flat';
      discount_amount: number;
      expires_at: string;
      featured: string;
      active: boolean;
      created_at: string;
      updated_at: string;
    }
  }[];
}

interface ICouponProps {
  id: string;
  code: string;
  discount_type: 'percentage' | 'flat';
  discount_amount: number;
  expires_at: string;
  featured: boolean;
  active: boolean;
  created_at: string;
  updated_at: string;
  products: {
    id: string;
    title: string;
    duration: number;
    price: number;
    active: boolean;
    created_at: string;
    updated_at: string;
    price_formatted: string;
  }[];
}

interface IFormDataProps {
  name: string;
  cover: File;
  cover_url: string;
  product_id: string;
  coupon_id: string;
  mobile_cover: File;
  mobile_cover_url: string;
}

const Dashboard: React.FC = () => {
  const { theme } = useTheme();
  const coverDesktopInput = useRef<HTMLInputElement>({} as HTMLInputElement);
  const coverMobileInput = useRef<HTMLInputElement>({} as HTMLInputElement);

  const [featuredCampaigns, setFeaturedCampaigns] = useState<IFecturedCampaigns>({} as IFecturedCampaigns);
  const [allCoupons, setAllCoupons] = useState<ICouponProps[]>([]);

  const [currentCampaign, setCurrentCampaign] = useState<IFormDataProps>({} as IFormDataProps);

  const [isLoading, setIsLoading] = useState(false);

  // Modal 
  const [modalManagerCuponsIsOpen, setModalManagerCuponsIsOpen] = useState(false);
  const [modaladdCuponsIsOpen, setModaladdCuponsIsOpen] = useState(false);
  const [filteredCouponId, setFilteredCouponId] = useState('');

  // Graphic options
  const lineChartOptionsDashboard: Props = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: 'dark',
      y: {
        formatter: function (value: number) {
          return value.toFixed(0) + ' vendas';
        },
      },
      x: {
        formatter: function (value: string) {
          return `dia ${value}`;
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'name',
      categories: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
      ],
      labels: {
        style: {
          colors: theme === 'dark' ? '#dadbe790' : '#dadbe7',
          fontSize: '10px',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme === 'dark' ? '#dadbe790' : '#dadbe7',
          fontSize: '10px',
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: theme === 'dark' ? '#dadbe750' : '#dadbe7',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ['#2bd7bd'],
    },
    colors: ['#2bd7bd'],
  };

  // Validation
  const schema = Joi.object({
    cover: Joi.any(),
    featuredCoupons: Joi.array().items(Joi.object({
      product_id: Joi.string().required().messages({ '*': 'O campo é obrigatório' }),
      coupon_id: Joi.string().allow(''),
    })),
    mobile_cover: Joi.any(),
  });

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data)
      console.log('validation result', await joiResolver(schema)(data, context, options))
      return joiResolver(schema)(data, context, options)
    },
  });

  // Validation
  const schema2 = Joi.object({
    code: Joi.string().min(3).required().messages({ '*': 'O campo é obrigatório' }),
    discount_amount: Joi.number().required().messages({ '*': 'O campo é obrigatório' }),
    discount_type: Joi.string().required().valid('flat', 'percentage').messages({ '*': 'O campo é obrigatório' }),
    expires_at: Joi.string().allow(''),
    product_ids: Joi.array().items(Joi.string()).min(1).required().messages({ '*': 'Selecione ao menos 1 plano' }),
  });

  const { register: register2, unregister: unregister2, handleSubmit: handleSubmit2, setValue: setValue2, formState: { errors: errors2 }, reset: reset2 } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result 2: ', await joiResolver(schema2)(data, context, options));
      return joiResolver(schema2)(data, context, options);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const [responseFeacturedCampaigns, responseAllCoupons] = await Promise.all([
          api.get('/products/featured'),
          api.get('/coupons', {
            params: {
              pageIndex: 0,
              pageSize: 777,
              onlyActive: true,
              search: '',
            }
          }),
        ]);

        setFeaturedCampaigns(responseFeacturedCampaigns.data);
        setAllCoupons(responseAllCoupons.data.records);
      } catch (error) {
        console.log(error);
      } finally {
        console.log('Dashboard finally');
      }
    })();
  }, [ currentCampaign]);

  const checkIfDimensionIsValid = useCallback(async (image: File, validDimension: { width: number, height: number }) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    return new Promise((resolve, reject) => {
      reader.onload = async (e: any) => {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          if (img.width === validDimension.width && img.height === validDimension.height) {
            console.log('valid');
            resolve(true);
          } else {
            toast.error(`Erro! A imagem deve ter exatamente ${validDimension.width}x${validDimension.height}px.`, {
              duration: 5000,
              position: 'bottom-right',
              style: {
                background: '#ff0000',
                color: '#fff',
              }
            });
            resolve(false);
          }
        }
      }
    });
  }, []);

  const handleSetCurrentCampaign = useCallback((type: 'cover' | 'mobile_cover', e: React.ChangeEvent<HTMLInputElement>) => {
    const image = e.target.files && e.target.files[0];

    if (!image) return;

    if (type === 'cover') {
      if (image.size > 0.9 * 1024 * 1024) {
        toast.error('Erro! A imagem deve ter no máximo 900kb', {
          duration: 5000,
          position: 'bottom-right',
          style: {
            background: '#ff0000',
            color: '#fff',
          }
        });

        coverDesktopInput.current.value = '';

        return;
      }

      checkIfDimensionIsValid(image, { width: 1920, height: 830 }).then((isValid) => {
        if (!isValid) {
          coverDesktopInput.current.value = '';
          return;
        }

        setCurrentCampaign((oldCampaign) => ({
          ...oldCampaign,
          cover: image,
        }));

        setFeaturedCampaigns((oldCampaign) => ({
          ...oldCampaign,
          campaign: {
            ...oldCampaign.campaign,
            cover_url: URL.createObjectURL(image),
          }
        }));
      });
    } else {
      if (image.size > 0.4 * 1024 * 1024) {
        toast.error('Erro! A imagem deve ter no máximo 400kb', {
          duration: 5000,
          position: 'bottom-right',
          style: {
            background: '#ff0000',
            color: '#fff',
          }
        });

        coverMobileInput.current.value = '';
        return;
      }

      checkIfDimensionIsValid(image, { width: 403, height: 554 }).then((isValid) => {
        if (!isValid) {
          coverMobileInput.current.value = '';
          return;
        }

        setCurrentCampaign((oldCampaign) => ({
          ...oldCampaign,
          mobile_cover: image,
        }));

        setFeaturedCampaigns((oldCampaign) => ({
          ...oldCampaign,
          campaign: {
            ...oldCampaign.campaign,
            mobile_cover_url: URL.createObjectURL(image),
          }
        }));
      });
    }
  }, [checkIfDimensionIsValid]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsLoading(true);

     if (!currentCampaign.cover || !currentCampaign.mobile_cover) {
        toast.error('Por favor, adicione novos criativos para editar a campanha 📷', {
          duration: 5000,
          position: 'bottom-right',
          style: {
            background: '#ff0000',
            color: '#fff',
          }
        });

        return;
      } 

      const formData = new FormData();
      formData.append('name', `Campanha ${new Date().toLocaleString('pt-BR')}`);
      formData.append('cover', currentCampaign.cover);
      formData.append('mobile_cover', currentCampaign.mobile_cover);

      data.featuredCoupons.forEach((feature: any, index: number) => {
        console.log('feature: ', feature);
        formData.append(`featuredCoupons[${index}][product_id]`, feature.product_id);
        formData.append(`featuredCoupons[${index}][coupon_id]`, feature.coupon_id);
      });

      const response = await api.post(`/campaigns`, formData);
      console.log(response.data);

      if (response.status === 200) {
        toast.success('Campanha criada com sucesso!', {
          duration: 6000,
          position: 'bottom-right',
          className: 'toast-samuquinha',
        });

        loadConfettiAllPage();

        setCurrentCampaign({} as IFormDataProps);

        coverDesktopInput.current.value = '';
        coverMobileInput.current.value = '';
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error('Erro ao criar campanha. Por favor, entre em contato com o suporte tecnico.', {
        duration: 5000,
        position: 'bottom-right',
        style: {
          background: '#ff0000',
          color: '#fff',
        },
      });
    }
    finally {
      setIsLoading(false);
    }
  });

  const handleInstallments = useCallback(async (product: IProductPros, coupon: ICouponProps | null) => {
    try {
      const response = await api.get(`/products/${product.id}/installments`, {
        params: {
          coupon: coupon?.code,
        }
      });

      const { amount: installment, total: amount } = response.data.at(-1);

      console.log('response: ', response.data);

      setFeaturedCampaigns((oldCampaign) => ({
        ...oldCampaign,
        featureds: oldCampaign?.featureds?.map((feature) => {
          if (feature.product.id === product.id) {
            return {
              ...feature,
              amount: amount,
              installment: installment,
              amount_formatted: (amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
              installment_formatted: (installment / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
            }
          }

          return feature;
        })
      }));

    } catch (error) {
      console.log(error);
    }
  }, []);

  const onSubmitCupouns = handleSubmit2(async (data) => {
    console.log('data2: ', data);
    try {
      const response = await api.post('coupons', {
        code: data.code,
        discount_amount: Number(data.discount_amount * 100),
        discount_type: data.discount_type,
        product_ids: data.product_ids,
        expires_at: null,
        featured: false,
      });

      console.log('response: ', response.data);
      
      if (response.status === 200) {
        toast.success('Cupom criado com sucesso!', {
          duration: 6000,
          position: 'bottom-right',
          className: 'toast-samuquinha',
        });

        loadConfettiAllPage();

        setAllCoupons((oldCoupons) => [...oldCoupons, response.data]);

        reset2();

        setModaladdCuponsIsOpen(false);
      }
    } catch (error: any) {
      console.log(error);

      if (error.response.data.error === 'coupon-already-exists') {
        toast.error('Erro ao tentar criar o cupom de desconto! O código escolhido já existe. ', {
          position: 'bottom-right',
          duration: 5000,
          className: 'toast-samuquinha',
        });

        return;
      }

      toast.error('Erro ao criar cupom. Por favor, entre em contato com o suporte tecnico.', {
        duration: 5000,
        position: 'bottom-right',
        icon: null,
        style: {
          background: '#ff0000',
          color: '#fff',
        },
      });
    }
  });

  const handleDeleteCoupon = useCallback(async (coupon_id: string, isMultiProcucts: boolean) => {
    if (window.confirm(isMultiProcucts ? `Este cupom pertence a mais de um produto. Deseja realmente excluir o cupom?` : `Deseja realmente excluir o cupom?`)) {
      try {
        const response = await api.delete(`/coupons/${coupon_id}`);

        if (response.status === 200) {
          toast.success('Cupom excluído com sucesso!', {
            duration: 6000,
            position: 'bottom-right',
            className: 'toast-samuquinha',
          });

          setFeaturedCampaigns((oldCampaign) => ({
            ...oldCampaign,
            featureds: oldCampaign?.featureds?.filter((feature) => feature?.coupon?.id !== coupon_id)
          }));

          setAllCoupons((oldCoupons) => oldCoupons.filter((coupon) => coupon.id !== coupon_id));
        }
      } catch (error) {
        console.log(error);
        toast.error('Erro ao excluir cupom. Por favor, entre em contato com o suporte tecnico.', {
          duration: 5000,
          position: 'bottom-right',
          icon: null,
          style: {
            background: '#ff0000',
            color: '#fff',
          },
        });
      }
    }
  }, []);

  return (
    <>
      <Container>
        <form onSubmit={onSubmit}>
          <SectionContainer0>
            <Breadcrumbs
              icon={House}
              separator="/"
              route={[
                { title: 'Dashboard', link: '/' },
              ]}
            />
            <aside>
              <a href="https://drive.google.com/file/d/1MQlzeLDykSdsJcKgIo-fqlb3EXBQ_VXI/view?usp=share_link" target="_blank" rel="noreferrer">
                <File size={20} weight="duotone" />
                Download do template (.PSD)
              </a>
            </aside>
          </SectionContainer0>
          <SectionContainer1>
            <aside onClick={() => coverDesktopInput.current.click()}>
              <input type="file" name="cover" ref={coverDesktopInput} onChange={(e) => handleSetCurrentCampaign('cover', e)} accept="image/png, image/jpeg" />
              <h1>Campanha desktop (1920x830px)</h1>
              <img src={featuredCampaigns?.campaign?.cover_url} alt="" loading="lazy" />
            </aside>
            <main onClick={() => coverMobileInput.current.click()}>
              <input type="file" name="mobile_cover" ref={coverMobileInput} onChange={(e) => handleSetCurrentCampaign('mobile_cover', e)} accept="image/png, image/jpeg" />
              <h1>Campanha mobile (403x554px)</h1>
              <img src={featuredCampaigns?.campaign?.mobile_cover_url} alt="" loading="lazy" />
            </main>
          </SectionContainer1>
          <SectionContainerPlans style={{ gridTemplateColumns: `repeat(${featuredCampaigns?.featureds?.length}, 1fr)` }}>
            {featuredCampaigns?.featureds?.sort((b, a) => a.product.duration - b.product.duration).map((featured, index) => (
              <PlanCard>
                <header>
                  <h1>{featured.product.title} ({featured.product.duration} meses) </h1>
                  {featured.product.price_formatted === featured.amount_formatted ? (
                    <div>
                      <p></p>
                      <p>
                        <strong>{featured.amount_formatted}</strong>
                      </p>
                      <p>
                        <span>
                          ou 12x de {featured.installment_formatted}
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p>
                        de <span style={{ textDecoration: 'line-through' }}>{featured.product.price_formatted}</span>
                      </p>
                      <p>
                        por <strong>{featured.amount_formatted}</strong>
                      </p>
                      <p>
                        <span>
                          ou 12x de {featured.installment_formatted}
                        </span>
                      </p>
                    </div>
                  )}
                </header>
                <main>
                  <h1><Tag size={20} weight="duotone" /> Cupom de desconto:</h1>
                  <input
                    {...register(`featuredCoupons[${index}][product_id]`)}
                    name={`featuredCoupons[${index}][product_id]`}
                    type="hidden"
                    value={featured.product.id}
                  />
                  <footer>

                    <aside>
                      {allCoupons.length > 0 && (
                        <select
                          {...register(`featuredCoupons[${index}][coupon_id]`)}
                          name={`featuredCoupons[${index}][coupon_id]`}
                          defaultValue={allCoupons.find((coupon) => coupon.id === featured.coupon?.id)?.id}
                          onChange={(e) => {

                            handleInstallments(featured.product, allCoupons.find((coupon) => coupon.id === e.target.value) || null);
                          }}
                        >
                          <option value="">Nenhum cupom de desconto aplicado</option>

                          {allCoupons?.filter((coupon) => coupon.products.find((product) => product.id === featured.product.id)).map((coupon, index) => {

                            return (
                              <option
                                key={index}
                                value={coupon?.id}
                              >
                                {coupon?.code} - {coupon.discount_type === 'percentage' ?
                                  `${coupon.discount_amount / 100}% de desconto`
                                  :
                                  `${(coupon.discount_amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} de desconto
                            `}
                              </option>
                            )
                          }
                          )}
                        </select>
                      )}
                      <CaretDown weight="duotone" />
                    </aside>
                    <TooltipAdapter alt="Gerenciar cupons" place="top">
                      <Button
                        color="primary"
                        iconBefore
                        onClick={() => {
                          setModalManagerCuponsIsOpen(true);
                          setFilteredCouponId(featured?.product?.id);
                        }}
                      >
                        <FiSettings />
                      </Button>
                    </TooltipAdapter>
                    <TooltipAdapter alt="Cadastrar cupom" place="top">
                      <Button
                        color="primary"
                        iconBefore
                        onClick={() => {
                          setModaladdCuponsIsOpen(true);
                          setFilteredCouponId(featured?.product?.id);
                        }}
                      >
                        <FiPlus />
                      </Button>
                    </TooltipAdapter>
                  </footer>
                </main>
              </PlanCard>
            ))}
          </SectionContainerPlans>
          <SectionContainerConfirm>
            <aside>
              <p>
                Confirme todos os dados da campanha (artes desktop e mobile), os {featuredCampaigns.featureds?.length} planos, seus respectivos cupons de desconto e clique em <strong>Salvar</strong> para atualizar os preços no site de vendas
              </p>
            </aside>
            <main>
              <Button
                type="submit"
                color="primary"
                disabled={isLoading}
                loading={isLoading}
              >
                Salvar
              </Button>
            </main>
          </SectionContainerConfirm>
        </form>
        <SectionContainer2>
          <ComingSoon>
            <p>Em breve</p>
          </ComingSoon>

          <header>
            <h1>Gráfico de vendas</h1>
            <div>
              <select name="mouth">
                <option value="1">Janeiro</option>
                <option value="2">Fevereiro</option>
                <option value="3">Março</option>
                <option value="4">Abril</option>
                <option value="5">Maio</option>
                <option value="6">Junho</option>
                <option value="7">Julho</option>
                <option value="8">Agosto</option>
                <option value="9">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </select>
              <CaretDown weight="duotone" />
            </div>
          </header>
          <ReactApexChart
            options={lineChartOptionsDashboard}
            series={[
              {
                name: 'Vendas do dia',
                data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 100)),
              }
            ]}
            type="area"
            width="100%"
            height="100%"
          />
        </SectionContainer2>
      </Container>


      {/* START MODAL GERENCIAR CUPONS */}
      <Modal
        isOpen={modalManagerCuponsIsOpen}
        setIsOpen={() => setModalManagerCuponsIsOpen(!modalManagerCuponsIsOpen)}
        closeModal={() => setModalManagerCuponsIsOpen(false)}
        size="lg"
        title="Gerenciar cupons de desconto"

      >
        <ModalContainer>
          <Line0>
            <main>
              {allCoupons.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Desconto</th>
                      <th>Produtos</th>
                      <th><FiSettings /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCoupons.filter((coupon) => coupon.products.find((product) => product.id === filteredCouponId)).map((coupon, index) => (
                      <tr key={index}>
                        <td style={{ fontSize: '12px' }}><strong>{coupon.code}</strong></td>
                        <td style={{ fontSize: '12px' }}>{coupon.discount_type === 'percentage' ?
                          `${coupon.discount_amount / 100}% de desconto`
                          :
                          `${(coupon.discount_amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} de desconto
                          `}
                        </td>
                        <td>
                          <>
                            {coupon.products.map((product: IProductPros, index) => (
                              <>
                                <p style={{ fontSize: '12px' }} key={index}>{product.title}</p>
                              </>
                            ))}
                          </>
                        </td>
                        <td>
                          <TooltipAdapter alt="Excluir cupom?" place="top">
                            <button onClick={() => handleDeleteCoupon(coupon.id, coupon.products.length > 1)}>
                              <FiTrash2 />
                            </button>
                          </TooltipAdapter>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </main>
          </Line0>
        </ModalContainer>
      </Modal>
      {/* ENDMODAL GERENCIAR CUPONS */}

      {/* START MODAL ADD CUPONS */}
      <Modal
        isOpen={modaladdCuponsIsOpen}
        setIsOpen={() => setModaladdCuponsIsOpen(!modaladdCuponsIsOpen)}
        closeModal={() => setModaladdCuponsIsOpen(false)}
        size="lg"
        title="Cadastrar novo cupom de desconto"
      >
        <ModalContainer2>
          <form onSubmit={onSubmitCupouns}>
            <Section1>
              <div>
                {featuredCampaigns.featureds && (
                  <SimpleMultiSelect
                    register={register2}
                    name="product_ids"
                    label="Plano(s)"
                    selectAllLabel="Todos os planos"
                    placeHolder="Selecione..."
                    defaultValue={filteredCouponId}
                    options={featuredCampaigns.featureds?.map((feature) => {
                      const opts = {
                        value: feature.product.id,
                        label: feature.product.title,
                      }

                      return opts;
                    })}
                    setResult={(values) => { setValue2('product_ids', values) }}
                    isMulti={true}
                    required
                  />
                )}
                {errors2?.product_ids && <MessageErrorValidator>{errors2?.product_ids?.message as string}</MessageErrorValidator>}
              </div>
              <div>
                <Input
                  register={register2}
                  type="text"
                  name="code"
                  label="Código do cupom"
                  placeholder="Ex: 10OFF"
                  required
                  uppercase
                />
                {errors2?.code && <MessageErrorValidator>{errors2?.code?.message as string}</MessageErrorValidator>}
              </div>
            </Section1>
            <Section2>
              <div>
                <SimpleMultiSelect
                  register={register2}
                  name="discount_type"
                  label="Tipo de desconto"
                  selectAllLabel="Todas"
                  placeHolder="Selecione..."
                  defaultValue={filteredCouponId}
                  options={[
                    { value: 'percentage', label: 'Porcentagem' },
                    { value: 'flat', label: 'Valor fixo' },
                  ]}
                  setResult={(values) => { setValue2('discount_type', values) }}
                  isMulti={false}
                  required
                />
                {errors2?.discount_type && <MessageErrorValidator>{errors2?.discount_type?.message as string}</MessageErrorValidator>}
              </div>
              <div>
                <Input
                  register={register2}
                  type="number"
                  name="discount_amount"
                  label="Valor do desconto"
                  placeholder='Ex: 15'
                  required
                />
                {errors2?.discount_amount && <MessageErrorValidator>{errors2?.discount_amount?.message as string}</MessageErrorValidator>}
              </div>
            </Section2>
            <FooterSection>
              <Button
                type="submit"
                color="primary"
              >
                Cadastrar cupom
              </Button>
            </FooterSection>
          </form>
        </ModalContainer2>
      </Modal>
      {/* ENDMODAL ADD CUPONS */}
    </>
  );
}

export default Dashboard;