import styled from 'styled-components';

export const Container = styled.div`
`;

export const CardTableContainer = styled.section`
  margin-bottom: 1rem;
`;

export const BtnEdit = styled.button`
  background: #948cbf1c;
  border-radius: ${props => props.theme.radius.secondary};
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  transition: all .2s ease-in-out;
  border: none;
  padding: 0.5rem 1rem;
  width: 170px;
  margin-top: 1rem;
  text-align: left;
  font-weight: 600;

  
  div {
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.white};
    text-align: left;
    gap: 0.5rem;
    font-weight: 600;
    line-height: 1;
    font-size: 14px;

    > svg {
      color: ${props => props.theme.colors.white};
      fill: ${props => props.theme.colors.white};
    }
  } 

`;

export const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  > p {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: center;

  > div {

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      background: none;
      border: none;
      cursor: pointer;
      color: ${props => props.theme.colors.white};
      font-weight: bold;
      margin-top: 1rem;
    }
  }

  > aside {
    display: flex;
    gap: 1rem;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${props => props.theme.colors.primary};
      color: #ffffff;
      border-radius: 6px;
      text-decoration: none;
      font-weight: 600;
      padding: 12px;
      font-size: 13px;

      > svg {
        margin-right: 0.35rem;
      }
    }
  }
`;

export const StatusBadge = styled.span`
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
`;

export const LessonInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  gap: 1rem;

  > span {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    color: ${props => props.theme.colors.muted};
  }
`;

export const ListDndContainer = styled.section`
  margin-bottom: 0.5rem;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
`;

export const DrpableContext = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
`;

export const DndItem = styled.div`
  position: relative;
  width: 100%;
  //background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.secondary};
  padding: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 16px 110px 5fr 12fr 1fr 1fr;
  gap: 1rem;
  align-items: center;

  .description {
    font-size: 11px;
  }

  .center {
    text-align: center;
  }

  .provider {
    background: ${props => props.theme.colors.muted}50;
    padding: 5px 8px;
    border-radius: 4px;
  }

  .actions {

    > div {
      border-radius: 6px;
    }

    button {
      background: ${props => props.theme.specific.menuButton};
      color: ${props => props.theme.background.tertiary};
    }

    ul, div, li, a {
      background: ${props => props.theme.specific.menuButton};
      color: ${props => props.theme.background.tertiary};
      
      &:hover {
        color: ${props => props.theme.background.tertiary};
      }

      svg {
        color: ${props => props.theme.background.tertiary};
      }
    }
  }

   div {
     color: ${props => props.theme.colors.muted};

     svg {
      color: ${props => props.theme.colors.muted};
    }

    a {
      color: ${props => props.theme.colors.white};
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
    }

    span {
      line-height: 1;
    }
  }
`;

export const FakeMaterial = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 6px;
  animation: pulse 1.5s infinite;

    @keyframes pulse {
      0% {
        -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}50;
        box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}50;
      }
      70% {
          -moz-box-shadow: 0 0 0 10px ${props => props.theme.colors.muted}10;
          box-shadow: 0 0 0 10px ${props => props.theme.colors.muted}10;
      }
      100% {
          -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}10;
          box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}10;
      }
    }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    border-radius: 6px;
    z-index: 9;
  }
`;

export const Poster = styled.img`
  width: 100px;
  margin: 4px 0;
  border-radius: 4px;
`;

export const HeaderStatistics = styled.header`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > h1 {
      font-size: 20px;
    }

    > p {
      font-size: 14px;
      color: ${props => props.theme.colors.muted};
      margin-bottom: 1rem;
    }
  }

`;

export const Line0 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  > form {

    > div {
      margin-bottom: 1rem;

      &:last-child {
        margin: 0;
      }
  
      > input {
        border: solid 1px ${props => props.theme.input.border};
        border-radius: 6px;
        background: ${props => props.theme.input.background};
        padding: 0.75rem 1rem;
        font-size: 14px;
        height: 50px;
        width: 100%;
        font-weight: 500;
        color: ${props => props.theme.colors.white};
        transition: all .1s cubic-bezier(0.4,0,0.2,1);
  
        &:focus {
          outline: none;
          border: 2px solid ${props => props.theme.input.borderActive};
        }
  
        &:focus ~ label, &:valid ~ label {
          transform: translate(-10px, -50%) scale(0.8);
          background: ${props => props.theme.input.background};
          background: linear-gradient(0deg, ${props => props.theme.input.background} 48%, rgba(0,0,0,0) 60%);
          padding: 0 3px;
          color: ${props => props.theme.input.labelColor};
          font-size: 14px;
          font-weight: bold;
        }    
      }
  
      > button {
        border: none;
        border-radius: 8px;
        font-weight: bold;
        align-items: center;
        font-size: 12px;
        transition: all 0.3s ease-in-out 0s;
        text-transform: uppercase;
        background: ${props => props.theme.colors.primary};
        color: rgb(255, 255, 255);
        cursor: pointer;
        height: 50px;
        width: 100%;
  
        &:disabled {
          cursor: not-allowed;
          background: #ccc;
        }
      }

      > p {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 1rem 0 0 0;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;

export const ListFilm = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  padding: 0.85rem 1rem;
  border-radius: 8px;

  &:hover {
    background: ${props => props.theme.input.border};
  }

  > aside {
    display: flex;
    
    > img {
      width: 30px;
      border-radius: 4px;
    }
  }

  > main {

    > h2 {
      font-size: 16px;
    }
  }
  
`;

export const ModalContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  transition: all .2s ease-in-out;
  padding: 0.85rem 1rem;
  border-radius: 8px;
  flex-direction: column;

  > div {
    width: 100%;

    > button {
      width: 100%;
      border-radius: 6px;
      margin-top: 0.5rem;
    }
  }

  .dropzone {
    min-height: 165px !important;
  }
`;
