import React, { useCallback, useState } from 'react';

import { useAuth } from '../../hooks/auth';

import api from '../../services/api';
import { API_S3 } from '../../services/api_s3';

import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { toast } from 'react-hot-toast';

import MessageErrorValidator from '../MessageErrorValidator';
import LoaderSpinner from '../LoaderSpinner';

import { Container, MessageContainer, ModalAlertContainer, ModalHome, Section2 } from './styles';

import { loadConfettiAllPage } from '../../utils/useConfetti';

const ModalFirstAccess: React.FC = () => {
  const { user, updateUser } = useAuth();

  // Validation
  const schema = Joi.object({
    description: Joi.string().min(20).max(3500).messages({
      '*': 'Informe uma descrição válida.',
      'string.min': `O campo descrição deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo descrição deve ter no máximo {#limit} caracteres`,
    }),
  });

  const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));

      return joiResolver(schema)(data, context, options);
    },
  });

  // Modals
  const [openModal, setOpenModal] = useState(() => {
    if (user.id && !user?.hasTimeTrunk) {
      return true;
    }

    return false;
  });

  const [step2, setStep2] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await api.post('/profiles/time-trunks', {
        message: data.description,
      });

      console.log(response.data);

      setOpenModal(false);
      setStep2(false);
      setLoading(false);
      reset();

      updateUser((oldUser) => ({
        ...oldUser,
        hasTimeTrunk: true,
      }));

      toast.success('Parabéns! Sua mensagem para o seu futuro eu foi enviada com sucesso. Mal podemos esperar para lhe enviar seu e-mail daqui a 1 ano e ver como você se desenvolveu e como seus desejos e objetivos mudaram ao longo do tempo. Não deixe de seguir seus sonhos e trabalhar duro para alcançá-los. 😉', {
        duration: 10000,
        position: 'bottom-right',
        className: 'toast-samuquinha',
        icon: null,
      });

      loadConfettiAllPage();
    } catch (error) {
      setOpenModal(false);
      setStep2(false);
      setLoading(false);

      toast.error('Ocorreu um erro ao enviar a mensagem. Tente novamente mais tarde.', {
        duration: 5000,
        position: 'bottom-right',
        className: 'toast-samuquinha',
      })
    }
  });

  const handleChangeToStep2 = useCallback(() => {
    setStep2(true);

    const delay = new Promise((resolve) => setTimeout(resolve, 1600));
    delay.then(() => {
      setLoading(true);
    });
  }, []);

  return (
    <>
      <Container>
        <ModalHome
          onRequestClose={() => setOpenModal(false)}
          isOpen={openModal}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          closeTimeoutMS={300}
          overlayClassName="react-modal-overlay"
        >
          <ModalAlertContainer>
            <MessageContainer>
              <main>
                <header >
                  <img src={`${API_S3}/default/bau-do-tempo.png`} alt="" style={{ zIndex: 9, }} />
                  <h1>Baú do tempo</h1>
                </header>
                {(step2 && !loading) && <LoaderSpinner background="#8f7ff9" spinnerColor="#ffffff" blur borderRadius="16px" zIndex={1} />}
                {!step2 ? (
                  <>
                    <main>
                      <p>
                        Olá, {user.first_name}! <strong>Bem-vindo(a) à Plataforma Biolândia!</strong> Estamos emocionados em oferecer a você a
                        oportunidade de deixar uma mensagem para seu futuro eu. O nosso <strong>"Baú do tempo"</strong> é uma
                        forma divertida de ver como você se desenvolveu e como seus desejos e objetivos mudaram
                        ao longo do tempo. <br /><br />

                        Para participar, <strong>basta clicar no botão "Vamos lá"</strong> e preencher o formulário com uma mensagem de como você se imagina daqui a 1 ano.
                        Esperamos que você aproveite essa experiência e não hesite em sonhar junto com a gente. 😉
                      </p>
                      <footer>
                        <button type="button" onClick={handleChangeToStep2}>Vamos lá!</button>
                        <p onClick={() => setOpenModal(false)}>Deixar para depois</p>
                      </footer>
                    </main>
                  </>
                ) : (
                  <Section2>
                    <form onSubmit={onSubmit}>
                      <h1>
                        Escreva uma breve descrição do seu futuro eu daqui a 1 ano.
                        Pense sobre como você se vê, quais são os seus objetivos e desejos,
                        e como você espera ter evoluído. Seja criativo e não tenha medo de ser
                        ambicioso. 💚
                      </h1>
                      <div>
                        <textarea
                          {...register('description')}
                          autoComplete="off"
                          placeholder="Lembre-se que essa mensagem será enviada por e-mail para você daqui a 1 ano, então aproveite essa oportunidade para se inspirar a alcançar seus sonhos..."
                          rows={6}
                        />
                        {errors?.description?.message && <MessageErrorValidator color="#ffffff">{errors?.description?.message as string} ❌</MessageErrorValidator>}
                      </div>
                      <footer>
                        <button type="submit">Quero realizar meus sonhos! 🎉</button>
                      </footer>
                    </form>
                  </Section2>
                )}
              </main>
            </MessageContainer>
          </ModalAlertContainer>
        </ModalHome>
      </Container>
    </>
  );
}

export default ModalFirstAccess;