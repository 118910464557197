import styled from 'styled-components';

export const Container = styled.div`
  border: 4px solid ${({ theme }) => theme.cards.border};
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
`;

export const TimeContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  font-size: 16px;
  font-weight: 600;

  > p {
    width: 100%;
    text-align: center;
    font-size: 8px;
    color: #fff;
  }
`;

export const Timer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const ModalContainer = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > p {
    font-size: 12px;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }
`;
