import React, { useRef } from 'react';

//@ts-ignore
import ProgressBar from 'react-animated-progress-bar';

import { Image } from 'react-image-and-background-image-fade';
import { animated } from 'react-spring';
import { use3dEffect } from 'use-3d-effect';

import { Container } from './styles';

interface ICardDiscipline3DProps {
  to: string;
  cardBackground: string;
  progress: number;
  icon: string;
  moduleName: string;
  alt: string;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

const CardDiscipline3D: React.FC<ICardDiscipline3DProps> = ({
  to,
  alt,
  progress,
  icon,
  moduleName,
}) => {
  const ref = useRef(null);
  const noRef = useRef(null);
  const scrollRef = document.querySelector('#main-scroll');
  const { style, ...mouseHandlers } = use3dEffect(
    getWindowDimensions().width > 960 ? ref : noRef,
  );

  return (
    <>
      <Container to={to}>
        <animated.div
          ref={ref}
          style={{ padding: '1rem', ...style }}
          {...mouseHandlers}
        >
          <header>
            {/* <img src={icon} alt={moduleName} /> */}
            <Image src={icon} lazyLoad={true} alt={moduleName} />
          </header>
          <footer>
            <div>
              <h5>{moduleName}</h5>
              <p>{progress.toFixed(0)}%</p>
            </div>
            <footer>
              <ProgressBar
                width="100%"
                height="12px"
                rect
                scrollArea={scrollRef}
                percentage={progress.toFixed(0)}
                rectPadding="2px"
                trackPathColor="#ffffff20"
                bgColor="#6befb0"
                trackBorderColor="transparent"
              />
            </footer>
          </footer>
        </animated.div>
      </Container>
    </>
  );
};

export default CardDiscipline3D;
