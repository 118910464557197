import styled from 'styled-components';

export interface ITooltipAdapterProps {
  textColor?: string;
  cursor?: 'default' | 'pointer' | 'not-allowed' | 'help';
}

export const Container = styled.div<ITooltipAdapterProps>` 
  color:  ${props => props.textColor ? props.textColor : '#000'};
  cursor: ${props => props.cursor ? props.cursor : 'default'};

  &.custom-tooltip-adapter {
    z-index: 9;
  }
`;
