import React from 'react';

import { Container } from './styles';

import avatar from '../../assets/samuquinha.png';

const Samuquinha: React.FC = () => {
  return (
    <Container>
      <img src={avatar} alt="Avatar Samuel Cunha" />
    </Container>
  );
}

export default Samuquinha;