import styled from 'styled-components';

export const Container = styled.div`

  .dropzone {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #ffffff26;
    border: 2px dashed ${props => props.theme.colors.muted};
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    min-height: 250px;
    transition: all .25s ease-in-out;
    cursor: pointer;
    
    &:hover {
      background: transparent;
    }

    > main {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
      > svg {
        margin-bottom: 2rem;
      }
      
      > p {
        font-size: 14px;
        margin-top: 0.5rem;
      }
      
      > h5 {
        margin-top: 1rem;
        font-weight: 400;
      }
    }
  }
`;

export const ListFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  
  > ul {
    list-style: none !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    > li {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }
`;
