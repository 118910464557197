import React, { useEffect, useState } from 'react';


import { Container, RatingContent } from './styles';
import { Link } from 'react-router-dom';
import { FiPlay } from 'react-icons/fi';
import { Star } from 'phosphor-react';
import HetaryGroup from './HetaryGroup';

interface ICardMovieThumbnailProps {
  id: string;
  name: string;
  duration: number;
  age_group: '18' | '16' | '14' | '12' | '10' | 'L';
  rating: number;
  year: string | number;
  cover: string;
  description?: string;
  height?: string;
  isHighlight?: boolean;
  vote_average: number;
}

const CardMovieThumbnail: React.FC<ICardMovieThumbnailProps> = ({ id, name, duration, age_group, rating, year, cover, description, height, vote_average, isHighlight }) => {

  const [voteAverage, setVoteAverage] = useState(vote_average);

  useEffect(() => {
    setVoteAverage(vote_average);
  }, [vote_average]);

  return (
    <Container to={`/sasaflix/${id}`} cover={cover} height={height}>
      <main>
        <section>
          <RatingContent>
            <div>
              <Star weight="fill" size={18} />
              <p>{Number(String(voteAverage)).toFixed(1)}</p>
            </div>
          </RatingContent>
          <HetaryGroup age_group={age_group} />
        </section>
        <div>
          <FiPlay strokeWidth={2.5} size={24} />
        </div>
        {isHighlight && (
          <footer>
            Assista ao trailer
          </footer>
        )}
      </main>
      {!isHighlight && (
        <footer>
          <Link to={`/sasaflix/${id}`}>{name}</Link>
        </footer>
      )}

    </Container>
  );
}

export default CardMovieThumbnail;
