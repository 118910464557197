import React, { HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  type: HTMLInputTypeAttribute;
  icon?: React.ComponentType<IconBaseProps>;
  ref?: HTMLInputElement,
  disabledd?: boolean;
}

const InputNotRegister: React.FC<InputProps> = ({ name, type, label, required, disabledd, ref, ...rest }) => {
  
  return (
    <Container disabledd={disabledd}>
      <input name={name} id={name} type={type} {...rest} />
      <label htmlFor={name}>{label}</label>
    </Container>
  );
};

export default InputNotRegister;