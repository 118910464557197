import React, { useCallback, useEffect, useState } from 'react';

import api from '../../services/api';

import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import LoaderSpinner from '../LoaderSpinner';
import { CaretCircleLeft, CaretCircleRight, Confetti, Prohibit, SmileySad } from 'phosphor-react';

import Button from '../Button';

import { CardQuestion, ExerciseContainer, FooterButtons, InputGroup, ModalContainer } from './styles';
import ReportQuestion from '../ReportQuestion';
import ModalConfirm from '../ModalConfirmation';
import NotFoundRegister from '../NotFoundRegister';
import { useHistory } from 'react-router-dom';

export interface IPraticeProps {
  id: string;
  user_id: string;
  total_hits: number;
  total_questions: number;
  is_finished: boolean;
  expires_at?: null;
  created_at: string;
  updated_at: string;
  answers: (IAnswersProps)[];
  questions: (IQuestionsProps)[];
  is_available: boolean;
}

interface IAnswersProps {
  id: string;
  practice_id: string;
  question_id: string;
  selected_option: string;
  correct_option: string;
  is_correct: boolean;
  created_at: string;
  updated_at: string;
}

export interface IQuestionsProps {
  id: string;
  cluster_id: string;
  content: string;
  total_hits: number;
  total_misses: number;
  year: string;
  difficulty: string;
  exam: string;
  created_at: string;
  updated_at: string;
  options: (OptionsEntity)[];
}

export interface OptionsEntity {
  id: string;
  question_id: string;
  content: string;
  created_at: string;
  updated_at: string;
}

interface IExerciseModuleProps {
  pratice_id: string;
}

const SimulatedModuleReadOnly: React.FC<IExerciseModuleProps> = ({ pratice_id }) => {
  const history = useHistory();

  // Loading
  const [loading, setLoading] = useState(true);

  // Data
  const [exercisesData, setExercisesData] = useState<IQuestionsProps[]>([]);
  const [anwsersData, setAnwsersData] = useState<IAnswersProps[]>([]);

  // States
  const [currentExercise, setCurrentExercise] = useState(0);
  const [checkedAnswer, setCheckedAnswer] = useState('');

  // Modals
  const [modalNotAnswered, setModalNotAnswered] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/practices/${pratice_id}`);

        console.log(response.data);

        if (response.status === 200) {
          setExercisesData(response.data?.questions);
          setAnwsersData(response.data?.answers);

          if (response.data?.answers.length === 0) {
            setModalNotAnswered(true);
          }
        }
      } catch (error: any) {
        setLoading(false);
        console.log(error);
        if (error.response.status === 400) {
          toast.error('Ops! Simulado não encontrado! 😥', {
            duration: 5000,
            position: 'bottom-right',
            className: 'toast-samuquinha',
          });

          history.push('/simulados');
        }
      }
      finally {
        setLoading(false);
      }
    })();
  }, [pratice_id, history]);

  const handleNextQuestion = useCallback(() => {
    if (currentExercise < exercisesData.length - 1) {
      setCurrentExercise(currentExercise + 1);
      setCheckedAnswer('');
    }
    else {
      toast.error('Não há mais exercícios nesta aula. 🤷‍♂️', {
        duration: 6000,
        position: 'bottom-center'
      });
    }
  }, [currentExercise, exercisesData.length]);

  const handleBackQuestion = useCallback(() => {
    if (currentExercise > 0) {
      setCurrentExercise(currentExercise - 1);
      setCheckedAnswer('');
    }
    else {
      toast.error('Não há mais exercícios nesta aula. 🤷‍♂️', {
        duration: 6000,
        position: 'bottom-center'
      });
    }
  }, [currentExercise]);

  useEffect(() => {
    const exercise = exercisesData[currentExercise];

    if (!exercise) return;

    const question = anwsersData.find((question: IAnswersProps) => question.question_id === exercise.id);

    if (question) {
      setCheckedAnswer(question.selected_option);
    }
    else {
      setCheckedAnswer('');
    }
  }, [currentExercise, exercisesData, anwsersData]);

  return (
    <>
      <ExerciseContainer>
        {loading && (<LoaderSpinner />)}

        <header>
          {exercisesData?.map((exercise, index) => (
            <CardQuestion
              current={exercise.id === exercisesData[currentExercise].id}
              onClick={() => { setCurrentExercise(index); }}
              /* check if question is corrected */
              className={anwsersData.find((question: IAnswersProps) => question.question_id === exercise.id)?.is_correct ? 'correct' : 'incorrect'}
            >
              <p>{index + 1}</p>
            </CardQuestion>
          ))}
        </header>
        <main>
          <div>
            <h6>Questão {currentExercise + 1}</h6>
            <h5>
              {exercisesData[currentExercise]?.exam} ({exercisesData[currentExercise]?.year})
            </h5>
            {(exercisesData[currentExercise]?.content) && (<ReactMarkdown rehypePlugins={[rehypeRaw]}>{exercisesData[currentExercise]?.content?.replace(/\\s/g, '\n')}</ReactMarkdown>)}
          </div>
        </main>
        <footer style={true ? { cursor: 'not-allowed' } : { cursor: 'default' }}>
          {exercisesData[currentExercise]?.options.map((option, index) => (
            option?.content && (
              <InputGroup
                readOnly={true}
                value={option?.id}
                correctAnswer={anwsersData[currentExercise]?.correct_option || null}
                checkedAnswer={checkedAnswer}
              >
                <input
                  id={`radio-${index}`}
                  name={`radio-${index}`}
                  type="radio"
                  checked={checkedAnswer === option?.id}
                  disabled
                />
                <label htmlFor={`radio-${index}`}>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{option?.content?.replace(/\\s/g, '\n')}</ReactMarkdown>
                </label>
              </InputGroup>
            )))}
        </footer>

        {((exercisesData[currentExercise]?.total_hits * 100) / exercisesData[currentExercise]?.total_misses) > 1 &&
          ((exercisesData[currentExercise]?.total_hits * 100) / exercisesData[currentExercise]?.total_misses) <= 100 && (
            <p>
              {((exercisesData[currentExercise]?.total_hits * 100) / exercisesData[currentExercise]?.total_misses) < 50 ? 'Apenas ' : 'Mais de '}
              {((exercisesData[currentExercise]?.total_hits * 100) / exercisesData[currentExercise]?.total_misses).toFixed(0)}% dos alunos da plataforma acertaram essa questão.
              {((exercisesData[currentExercise]?.total_hits * 100) / exercisesData[currentExercise]?.total_misses) < 50 ? ' 😢' : ' 🎉'}
            </p>
          )}

        <ReportQuestion requestRoute={`/questions/${exercisesData[currentExercise]?.id}/report`} questionId={exercisesData[currentExercise]?.id} />
      </ExerciseContainer>

      {!loading && (
        <FooterButtons>

          {(currentExercise > 0) && (
            <Button
              color="tertiary"
              onClick={() => handleBackQuestion()}
            >
              <CaretCircleLeft size={28} weight="fill" />
              Questão anterior
            </Button>
          )}

          {checkedAnswer ? (
            <Button
              color="primary"
              disabled={true}
              className={anwsersData[currentExercise]?.is_correct ? 'correct-cursor ' : 'incorrect-cursor'}
            >
              {(anwsersData[currentExercise]?.is_correct ?
                (<><Confetti size={20} weight="duotone" /> Você acertou</>)
                :
                (<><SmileySad size={20} weight="duotone" /> Você errou</>))
              }
            </Button>)
            : (
              <Button
                color="primary"
                disabled={true}
                className={anwsersData[currentExercise]?.is_correct ? 'correct-cursor ' : 'incorrect-cursor'}
              >
                <Prohibit size={20} weight="duotone" /> Você não respondeu esta pergunta
              </Button>
            )}

          {(currentExercise < exercisesData.length - 1) && (
            <Button
              color="purple"
              onClick={() => handleNextQuestion()}
            >
              Próxima questão
              <CaretCircleRight size={28} weight="fill" />
            </Button>
          )}
        </FooterButtons>
      )}

      {/* START MODAL CONFIRM TIME FINISH */}
      <ModalConfirm
        confirmText="Entendi"
        confirmButtonColor="#2cc292"
        cancelText="Cancelar"
        onConfirm={() => setModalNotAnswered(false)}
        showCancelButton={false}
        isOpen={modalNotAnswered}
        isLoading={false}
        setIsOpen={() => setModalNotAnswered(!modalNotAnswered)}
        closeModal={() => setModalNotAnswered(false)}
        allowOutsideClick={false}
        allowEscapeKey={false}
      >
        <ModalContainer>
          <NotFoundRegister description="Simulado sem respostas" />
          <p>Poxa, você não respondeu todas as questões deste simulado.</p>
        </ModalContainer>
      </ModalConfirm>
      {/* END MODAL CONFIRM TIME FINISH */}
    </>
  );
}

export default SimulatedModuleReadOnly;