import React, { useCallback, useState } from 'react';

import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';

import { Container, CloseButton, Controls } from './styles';

import getCroppedImg from '../../utils/cropImage';
import { X } from 'phosphor-react';

interface IModalCropImageProps {
  image: string;
  barColor?: string;
  buttonColor?: string;
  backdropColor?: string;
  backdropOpacity?: number;
  backdropBlur?: string;
  cropShape?: 'rect' | 'round';
  cropSize: {
    width: number;
    height: number;
  }
  isOpen: boolean;
  setIsOpen(open: boolean): void;
  onSubmit(value: File): void;
}

const ModalCropImage: React.FC<IModalCropImageProps> = ({
  image,
  barColor,
  buttonColor,
  backdropColor,
  backdropBlur,
  cropShape,
  backdropOpacity,
  cropSize,
  isOpen,
  setIsOpen,
  onSubmit,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({} as Area);

  const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleResetConfigs = useCallback(() => {
    setZoom(1);
    setRotation(0);
  }, []);

const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
        cropSize
      )

      handleResetConfigs();

      onSubmit(croppedImage);
      setIsOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image, cropSize, handleResetConfigs, onSubmit, setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    handleResetConfigs();
  }, [setIsOpen, handleResetConfigs]);

  return (
    <>
      {isOpen &&
        <Container barColor={barColor} buttonColor={buttonColor} backdropColor={backdropColor} backdropOpacity={backdropOpacity} backdropBlur={backdropBlur}>
          <CloseButton onClick={onClose}>
            <X />
          </CloseButton>
          <Controls>
            <div>
              <span>Zoom</span>
              <input
                type="range"
                value={zoom}
                min={1}
                max={2}
                step={0.1}
                onChange={(e) => setZoom(Number(e.target?.value))}
              />
            </div>
            <div>
              <span>Rotação</span>
              <input
                type="range"
                value={rotation}
                min={0}
                max={360}
                step={1}
                onChange={(e) => setRotation(Number(e.target?.value))}
              />
            </div>
            <footer>
              <button onClick={showCroppedImage}>Enviar</button>
            </footer>
          </Controls>
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={cropSize.width / cropSize.height}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            restrictPosition={true}
            cropShape={cropShape}
            style={{ cropAreaStyle: { boxShadow: 'none' } }}
          />
        </Container>
      }
    </>
  )
}

export default ModalCropImage;