import styled from 'styled-components';

interface ILoaderProps {
  isVisible: boolean;
}

export const Container = styled.section<ILoaderProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #2cc292;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999999999999999999;
  opacity: ${props => props.isVisible ? 1 : 0};
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  transition: all .35s ease-out;
  
  svg {
    overflow: visible;
  }

  svg * { 
    transform-box: fill-box;
  }

  .relative-center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform-origin: center center;
    margin: 0 auto;
  }

  .st0 {
    opacity: 0.83;
    fill: #FFFFFF;
    enable-background: new;
  }

  .st1 {
    opacity: 0.91;
    fill: #FFFFFF;
    enable-background: new;
  }

  .left-circle {
    transform-origin: center center;
    opacity: 0.82;
    fill: #FFFFFF;
    margin: 0 auto;
    animation: rotates 1s infinite;
    animation-delay: 0.2s;
  }

  .right-circle {
    transform: rotate(180deg);
    transform-origin: center center;
    opacity: 0.82;
    fill: #FFFFFF;
    margin: 0 auto;
    animation: rotates .8s infinite;
    animation-delay: 0.2s;
  }

  .play {
    fill: #FFFFFF;
    transform: scale(0.75);
    transform-origin: center center;
  }

  .st5 {
    opacity: 0.9;fill: #FFFFFF;
    enable-background: new;
  }

  .st6 {
    opacity: 0.78;
    fill: #FFFFFF;
    enable-background: new;
  }

  @keyframes rotates {
    from { 
      transform: rotate(0deg);
    }
    to { 
      transform: rotate(360deg); 
    }
  }
  
  > main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 220px;
    height: auto;  
    padding: 1rem;
    
    /* TABLET */
    @media (max-width: 768px) {
      transform: scale(0.80);
    }

    /* MOBILE */
    @media (max-width: 480px) {
      transform: scale(0.55);
    }
  }
`;