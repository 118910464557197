import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';


import Joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import toast from 'react-hot-toast';

import Breadcrumbs from '../../../components/Breadcrumbs';
import LoaderBIO from '../../../components/Loader';
import Input from '../../../components/Form/Input';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import Textarea from '../../../components/Form/Textarea';
import DropZone from '../../../components/Form/DropZone';
import DataTableAdapter from '../../../components/DataTableAdapter';

import { FiHome } from 'react-icons/fi';
import { TableColumn } from 'react-data-table-component';

import { ButtonSubmitContainer, CardTableContainer, Container, HeaderSection, Line1, Line2, SectionContainer1, Separator } from './styles';

import { Poster } from '../Aulas/styles';

import { loadConfettiAllPage } from '../../../utils/useConfetti';

interface ILessonsProps {
  id: string;
  cluster: {
    order: number;
    title: string;
    slug: string;
  }
  cluster_id: string;
  duration_formatted: string;
  order: number;
  title: string;
  video: string;
  thumbnail?: string;
  thumbnail_url?: string;
}

const PlanoDeEstudosCreate: React.FC = () => {
  const history = useHistory();

  // Validation
  const schema = Joi.object({
    title: Joi.string().min(5).messages({ '*': 'Informe um título válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
    description: Joi.string().min(5).messages({ '*': 'Informe uma descrição válida.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
    items: Joi.array().required().items(Joi.object({
      lesson_id: Joi.string().required(),
      order: Joi.number().required(),
    })),
  });

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));
      return joiResolver(schema)(data, context, options);
    },
  });

  // Data
  const [filesData, setFilesData] = useState<File[]>([]);
  const [lessonsData, setLessonsData] = useState<ILessonsProps[]>([]);
  const [filteredLessonsData, setFilteredLessonsData] = useState<ILessonsProps[]>([]);
  const [selectedRows, setSelectedRows] = useState<ILessonsProps[]>([]);

  // Loaders
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  

  useEffect(() => {
    register('items');
    setValue('items', selectedRows.map((item) => ({ lesson_id: item.id, order: 1 })));
  }, [register, setValue, selectedRows]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/clusters/lessons/options');
        setLessonsData(response.data);
        setFilteredLessonsData(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const columns: TableColumn<ILessonsProps>[] = [
    {
      name: 'Título da aula',
      selector: row => row.title,
      sortable: true,
      cell: (row: ILessonsProps) => (
        <>
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Poster src={row?.thumbnail_url} alt={row.title} />
            <div>
              <a href={`https://vimeo.com/${row.video}`} target="_blank" rel="noreferrer">{row.title}</a>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Módulo',
      sortable: true,
      cell: (row: ILessonsProps) => (
        <>
          <p style={{ padding: '1rem 0' }}>
            <Link to={`/aulas/${row.cluster.slug}`}>
              {row.cluster.title}
            </Link>
          </p>
        </>
      ),
    },
    {
      name: 'Duração da aula',
      cell: (row: ILessonsProps) => (
        <>
          <p style={{ padding: '1rem 0' }}>{row.duration_formatted}</p>
        </>
      ),
    },
  ];

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoadingSubmit(true);
      if (filesData.length === 0) {
        toast.error('É necessário adicionar uma imagem para o plano de estudos.', {
          duration: 6000,
          position: 'bottom-right',
          className: 'toast-samuquinha',
        });

        return;
      }

      if (selectedRows.length === 0) {
        toast.error('É necessário adicionar ao menos uma aula ao plano de estudos.', {
          duration: 6000,
          position: 'bottom-right',
          className: 'toast-samuquinha',
        });

        return;
      }

      const formData = new FormData();

      formData.append('title', data.title);
      formData.append('description', data.description);
      formData.append('thumbnail', filesData[0]);

      selectedRows && selectedRows.forEach((lesson: ILessonsProps, index) => {
        formData.append(`items[${index}][lesson_id]`, lesson.id);
        formData.append(`items[${index}][order]`, '1');
      });

      const response = await api.post('/study-plans', formData);
      console.log(response.data);

      if (response.status === 200) {
        toast.success('Plano de estudos criado com sucesso.', {
          duration: 5000,
          position: 'bottom-right',
          className: 'toast-samuquinha',
        });

        loadConfettiAllPage();

        reset();
        setFilesData([]);
        setSelectedRows([]);
        history.push('/plano-de-estudos');
      }
    }
    catch (error) {
      console.log(error);
      toast.error('Ocorreu um erro ao criar o plano de estudos.', {
        duration: 6000,
        position: 'bottom-right',
        className: 'toast-samuquinha',
      });

      setLoadingSubmit(false);
    }
    finally {
      setLoadingSubmit(false);
    }
  });

  const handleFilterLessons = useCallback(async (value: string) => {
    // search lessons by value title on keyup

    if (value) {
      const filteredLessons = lessonsData.filter((lesson) => {
        return lesson.title.toLowerCase().includes(value.toLowerCase()) || lesson.cluster.title.toLowerCase().includes(value.toLowerCase());
      });

      setFilteredLessonsData(filteredLessons);
    }

    if (!value) {
      setFilteredLessonsData(lessonsData);
    }
  }, [lessonsData]);

  return (
    <>
      <LoaderBIO isVisible={loading} />

      <Container>
        <HeaderSection>
          <Breadcrumbs
            icon={FiHome}
            separator="/"
            route={[
              { title: 'Plano de estudos', link: '/plano-de-estudos' },
              { title: 'Novo plano', link: '/plano-de-estudos/novo' },
            ]}
          />
          <aside>
            {/* <Button type="button" color="primary" onClick={() => setModalCreatePlanIsOpen(true)}>Adicionar plano de estudos</Button> */}
          </aside>
        </HeaderSection>

        <SectionContainer1>
          <form onSubmit={onSubmit}>
            <Separator>
              <h2>Cadastrar novo plano de estudos</h2>
            </Separator>
            <Line1>
              <div>
                <Input
                  register={register}
                  name="title"
                  type="text"
                  label="Nome do Plano de Estudos"
                  autoComplete="off"
                />
                <MessageErrorValidator>{errors?.title?.message as string}</MessageErrorValidator>
              </div>
            </Line1>
            <Line1>
              <div>
                <Textarea
                  register={register}
                  name="description"
                  type="text"
                  label="Descrição do Plano de Estudos"
                  autoComplete="off"
                />
                <MessageErrorValidator>{errors?.description?.message as string}</MessageErrorValidator>
              </div>
            </Line1>
            <Line1>
              <div>
                <DropZone
                  setFilesData={setFilesData}
                  maxSize={1024 * 1024 / 2} // 1/2 mb
                  icons={false}
                  placeHolder="Selecione uma imagem para o plano de estudos (Tamanho recomendado 600x320px)"
                  isMulti={false}
                  accept={{
                    'image/png': ['.png', '.jpg', '.jpeg', '.webp'],
                  }}
                />
              </div>
            </Line1>
            <Line2>
              <div>
                <h6>Pesquise pelo título da aula</h6>
                <input
                  type="text"
                  placeholder="Pesquise pelo título da aula"
                  onChange={(event) => handleFilterLessons(event.target.value)}
                />
              </div>
            </Line2>
            <CardTableContainer>
              <DataTableAdapter
                title="Listagem de aulas"
                subTitle={'Busque para adicionar aulas ao plano de estudos'}
                columns={columns}
                data={filteredLessonsData}
                paginationServer={false}
                selectableRows={true}
                setSelectedRows={setSelectedRows}
              />
            </CardTableContainer>
            <ButtonSubmitContainer>
              <Button type="submit" color="primary" loading={loadingSubmit}>Cadastrar plano de estudos</Button>
            </ButtonSubmitContainer>
          </form>
        </SectionContainer1>

      </Container>
    </>
  );
}

export default PlanoDeEstudosCreate;