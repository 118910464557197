import React, { createContext, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { House } from 'phosphor-react';

import Breadcrumbs from '../../../components/Breadcrumbs';
import LoaderSpinner from '../../../components/LoaderSpinner';
import CardCommunityTopic from '../../../components/CardCommunityTopic';
import CardCommunityAnswer from '../../../components/CardCommunityAnswer';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import Pagination from '../../../components/Pagination';
import Wysiwyg from '../../../components/Wysiwyg';

import {
  Container,
  HeaderSection,
  PaginationContainer,
  SectionContainer2,
  SectionContainer3,
  SectionContainerHeader,
  VerifiedContainer,
  WysiwygContainer,
} from './styles';
import {
  AsideIcon,
  HeaderSectionContainerInner,
  Main,
} from '../ComunidadeTopicos/styles';

import { Author } from '../../../interfaces/Author';
import { IAnswersProps } from '../../../interfaces/Answer';
import { toast } from 'react-hot-toast';

export interface ITopicProps {
  id: string;
  lesson_id?: string;
  forum_id: string;
  author_id: string;
  title: string;
  content: string;
  views: number;
  is_pinned: boolean;
  created_at: string;
  updated_at: string;
  forum: Forum;
  author: Author;
  answers: Answers;
}

export interface Forum {
  id: string;
  cluster_id: string;
  title: string;
  slug: string;
  order: string;
  thumbnail: string;
  description: string;
  created_at: string;
  updated_at: string;
  thumbnail_url: string;
}

export interface Answers {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: IAnswersProps[];
}

interface ITopicContext {
  topicData: ITopicProps;
  setTopicData: (topic: ITopicProps) => void;
}

interface IAnswerContext {
  answersData: IAnswersProps[];
  setAnswersData: (answers: IAnswersProps[]) => void;
}

// Hooks for context
export const TopicContext = createContext({} as ITopicContext);
export const AnswerContext = createContext({} as IAnswerContext);

const ComunidadeFeed: React.FC = () => {
  const { slug, id } = useParams<{ slug: string; id: string }>();
  const history = useHistory();
  
  // Loading
  const [loading, setLoading] = useState(false);

  // Data
  const [topicData, setTopicData] = useState<ITopicProps>({} as ITopicProps);
  const [answersData, setAnswersData] = useState<IAnswersProps[]>([]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/forums/${slug}/topics/${id}`, {
          params: {
            pageIndex: currentPage - 1,
            pageSize: 6,
          },
        });

        console.log(response.data);

        setTopicData(response.data);
        setAnswersData(response.data?.answers?.records);

        //pagination
        setShowingTo(response.data?.answers.showingTo);
        setShowingFrom(response.data?.answers.showingFrom);
        setMaxPages(response.data?.answers.maxPages);
        setTotalRecords(response.data?.answers.totalRecords);
      } catch (error: any) {
        setLoading(false);
        console.log(error.response.data);

        toast.error('Desculpe, este tópico não foi encontrado! 😥', {
          duration: 5000,
          position: 'bottom-right',
          className: 'toast-samuquinha',
        });

        history.push('/comunidade');
      } finally {
        setLoading(false);
      }
    })();
  }, [slug, id, currentPage, history]);

  return (
    <>
      <Container>
        <HeaderSectionContainer padding="1rem 2rem" margin="1rem 0 2rem 0">
          <HeaderSectionContainerInner>
            <AsideIcon>
              <img src={topicData?.forum?.thumbnail_url} alt="" />
            </AsideIcon>
            <Main>
              <h1>{topicData?.forum?.title}</h1>
              <p>{topicData?.forum?.description}</p>
            </Main>
          </HeaderSectionContainerInner>
        </HeaderSectionContainer>
        <TopicContext.Provider value={{ topicData, setTopicData }}>
          <HeaderSection>
            <Breadcrumbs
              icon={House}
              separator="/"
              route={[
                { title: 'Comunidade', link: '/comunidade' },
                {
                  title: topicData?.forum?.title,
                  link: `/comunidade/${topicData?.forum?.slug}`,
                },
                { title: topicData.title, link: topicData.id },
              ]}
            />
          </HeaderSection>

          <SectionContainerHeader>
            <aside>
              <h5>Autor</h5>
            </aside>
            <aside>
              <h5>Postagem</h5>
            </aside>
          </SectionContainerHeader>

          <SectionContainer2
            style={loading ? { height: '240px', overflow: 'hidden' } : {}}
          >
            {loading && <LoaderSpinner />}

            <CardCommunityTopic
              id={topicData?.id}
              slug={topicData?.forum?.slug}
              title={topicData?.title}
              content={topicData?.content}
              views={topicData?.views}
              created_at={topicData?.created_at}
              author={{
                id: topicData?.author?.id,
                show_name: topicData?.author?.show_name,
                avatar_url: topicData?.author?.avatar_url,
                role_name: topicData?.author?.role_name,
                level: topicData?.author?.level,
                career: topicData?.author?.career,
                current_exp: topicData?.author?.current_exp,
                role: topicData?.author?.role,
                evolution: topicData?.author?.evolution,
              }}
            />
          </SectionContainer2>
        </TopicContext.Provider>

        <AnswerContext.Provider value={{ answersData, setAnswersData }}>
          {answersData.length > 1 && (
            <>
              {answersData.slice(0, 1).map(() => {
                const answerVerified = answersData.find(
                  answer => answer.is_verified,
                );

                if (!answerVerified) {
                  return null;
                }

                return (
                  <>
                    <SectionContainerHeader
                      id="replys-content"
                      style={
                        loading || !answersData.length
                          ? { display: 'none', overflow: 'hidden' }
                          : {}
                      }
                    >
                      <aside>
                        <h5>Autor</h5>
                      </aside>
                      <aside>
                        <h5>Resposta verificada</h5>
                      </aside>
                    </SectionContainerHeader>

                    <VerifiedContainer>
                      <CardCommunityAnswer
                        id={answerVerified.id}
                        topic_id={answerVerified.topic_id}
                        className="card-verified"
                        is_verified={answerVerified.is_verified}
                        user_vote={answerVerified.user_vote}
                        upvotes={answerVerified.upvotes}
                        downvotes={answerVerified.downvotes}
                        content={answerVerified.content}
                        created_at={answerVerified.created_at}
                        author={{
                          id: answerVerified.author?.id,
                          show_name: answerVerified.author?.show_name,
                          avatar_url: answerVerified.author?.avatar_url,
                          current_exp: answerVerified.author?.current_exp,
                          role: answerVerified.author?.role,
                          role_name: answerVerified.author?.role_name,
                          level: answerVerified.author?.level,
                          career: answerVerified.author?.career,
                          evolution: answerVerified.author?.evolution,
                        }}
                      />
                    </VerifiedContainer>
                  </>
                );
              })}
            </>
          )}

          <SectionContainerHeader
            id="replys-content"
            style={
              loading || !answersData.length
                ? { display: 'none', overflow: 'hidden' }
                : {}
            }
          >
            <aside>
              <h5>Autor</h5>
            </aside>
            <aside>
              <h5>Resposta</h5>
            </aside>
          </SectionContainerHeader>

          <SectionContainer3
            id="lastc"
            style={loading ? { display: 'none', overflow: 'hidden' } : {}}
          >
            {loading && <LoaderSpinner />}

            {answersData?.map((answer, index) => (
              <CardCommunityAnswer
                key={index}
                id={answer.id}
                topic_id={answer.topic_id}
                className={
                  answersData.length > 1 && index === 0
                    ? 'first'
                    : answersData.length > 1 && index < answersData.length - 1
                    ? 'rect'
                    : answersData.length > 1 && index === answersData.length - 1
                    ? 'last'
                    : ''
                }
                is_verified={answer.is_verified}
                upvotes={answer.upvotes}
                downvotes={answer.downvotes}
                content={answer.content}
                user_vote={answer.user_vote}
                created_at={answer.created_at}
                author={{
                  id: answer.author?.id,
                  show_name: answer.author?.show_name,
                  avatar_url: answer.author?.avatar_url,
                  current_exp: answer.author?.current_exp,
                  role: answer.author?.role,
                  role_name: answer.author?.role_name,
                  level: answer.author?.level,
                  career: answer.author?.career,
                  evolution: answer.author?.evolution,
                }}
              />
            ))}
          </SectionContainer3>

          {!loading && answersData && answersData.length > 0 && (
            <PaginationContainer>
              <p>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} tópicos`}</p>
              {totalRecords !== 0 && (
                <Pagination
                  count={maxPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </PaginationContainer>
          )}

          <WysiwygContainer>
            <Wysiwyg
              placeholder="Escreva sua resposta aqui..."
              maxLength={1500}
              requestRoute={`/forums/topics/${id}/answers`}
            />
          </WysiwygContainer>
        </AnswerContext.Provider>
      </Container>
    </>
  );
};

export default ComunidadeFeed;
