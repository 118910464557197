import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .react-select-adapter__input-container {
    position: relative;
    color: ${props => props.theme.colors.white};
  }

  .react-select-adapter__placeholder {
    color: ${props => props.theme.colors.white};
    font-weight: 500;
    color: #3e3f5e;
  } 

  .react-select-adapter__control {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px ${props => props.theme.input.border};
    background: ${props => props.theme.input.background};
    color: ${props => props.theme.colors.white};
    border-radius: 6px;
    padding: 0 0.25rem 0 1rem;
    font-size: 14px;
    height: 50px;
    width: 100%;
    
    &:focus {
      border: 2px solid ${props => props.theme.input.borderActive};
    }
    
    &:hover {
      border: solid 1px ${props => props.theme.input.border};
    }

    &--is-focused {
      box-shadow: none;
    }
  }

  .react-select-adapter__input-container {
    position: relative;
  }

  .react-select-adapter__single-value {
    color: ${props => props.theme.colors.white};
  }

  .react-select-adapter__value-container {
    padding: 0;
  }

  .react-select-adapter__menu {
    font-size: 14px;
    padding: 0;
    background: ${props => props.theme.input.background};

    .react-select-adapter__option {
      cursor: pointer;
      
      &:hover {
        background: #00000020;
        color: ${props => props.theme.colors.white};
      }

      &--is-selected {
        background: #9080fa;
        color: #ffffff;

        &:hover {
          background: #9080fa;
          color: #ffffff;
        }
      }
    }
  }

  > label {
    position: absolute;
    left: 1rem;
    color: ${props => props.theme.input.labelColor};
    pointer-events: none;
    font-size: 11px;
    font-weight: bold;
    transform: translateY(-55px);
    line-height: 1;
    transition: .25s cubic-bezier(0.4,0,0.2,1);
    background: ${props => props.theme.input.background};
    background: linear-gradient(0deg, ${props => props.theme.input.background} 48%, rgba(0,0,0,0) 60%);
    padding: 0 3px;
  }
`;
