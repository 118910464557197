import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 2rem 1rem 0 1rem;
  border-radius: 8px;

  > p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-size: 12px;
    color: ${props => props.theme.colors.muted}95;
    flex-flow: wrap;
    line-height: 1.5;

    @media (max-width: 424px) {
      font-size: 10px;
    }

    > svg {
      margin-right: 0.5rem;

      @media (max-width: 435px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    > span {
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
      margin-left: 0.25rem;
    }
  }
`;

export const ModalConfirmationContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;

  > h3 {
    font-size: 14px;
    width: 100%;
    text-align: left;
    font-weight: 500;
  }

  > p {
    font-size: 0.9rem;
    width: 100%;
    text-align: left;
    font-weight: 500;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0 0 0;
    width: 100%;

    > div {
      width: 100%;
    }
  }
`;