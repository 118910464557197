import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) { }

  /* MOBILE */
  @media (max-width: 480px) { }
`;

export const ContentGrid1 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  padding: 28px 32px;
  border-radius: 12px;
  grid-template-columns: repeat(1, 1fr);
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};

  /* TABLET */
  @media (max-width: 768px) {
    //grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }

  > div {

    > form {
      display: flex;
      position: relative;

      > div:first-child {
        z-index: 9;
      }
    }
  }
`;

export const ContentGrid2 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: repeat(3, 1fr);

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > a {
    display: flex;
    align-items: flex-start;
    text-align: center;
    justify-content: start;
    text-align: left;
    flex-direction: column;
    padding: 32px;
    border-radius: 12px;
    text-decoration: none;
    transition: all .2s ease-in-out;
    background: ${props => props.theme.cards.primary};
    border: 2px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    color: ${props => props.theme.colors.white};

    &:hover {
      border: 2px solid ${props => props.theme.colors.primary};
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 0.5rem;
    }

    > h1 {
      line-height: 1;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      color: ${props => props.theme.colors.white};
    }

    > h2 {
      line-height: 1;
      font-size: 32px;
      margin-bottom: 1rem;
      color: ${props => props.theme.colors.primary};
    }

    > p {
      color: ${props => props.theme.colors.muted};
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 1rem;
      line-height: 1.4285714286em;
    }

    > a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: ${props => props.theme.colors.muted};
      font-size: .875rem;
      font-weight: 600;

      > span {
        font-size: .65rem;
        margin-top: 3px;
      }
    }
  }

`;

export const SearchButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.primary};
  color: #fff;
  padding: .9rem 1.5em;
  border-radius: 0 12px 12px 0;
  margin-left: -1rem;
  z-index: 9;
  text-decoration: none;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

export const NotFound = styled.div`
  background: ${props => props.theme.cards.primary};
  color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.primary};
  position: absolute;
  z-index: 0;
  width: 100%;
  text-align: center;
  padding: 28px;
  border-radius: 12px;
  font-weight: bold;
  padding-bottom: 2rem;
  font-size: 14px;
`;
