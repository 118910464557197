import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  #error-message {
    text-align: left !important;
    font-size: 12px !important;
    margin-bottom: 1rem;
    color: red;
    font-weight: bold;
  }

  animation: worldTranslate 120s infinite ease-in-out;

  @keyframes worldTranslate {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
  
  &:after {
    content: '';
    position: absolute;
    background: #00000070;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(4px) saturate(180%);
  }
  
  > main {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;  
    z-index: 2;  
    
    > section {
      display: flex;
      flex-direction: row;
      //height: fit-content;
      justify-content: center;
      color: rgb(34, 51, 84);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      overflow: hidden;
      height: 100vh;
      width: 50%;

      @media screen and (max-width: 899px) {
        width: 100%;
      }
      
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
      }
      
      > main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        justify-content: center;
        width: 100%;
        padding: 3rem 10rem;
        background: #dadbe724;
        backdrop-filter: blur(3px);

        @media screen and (min-width: 2200px) {
          padding: 3rem 18rem;
        }

        @media screen and (max-width: 1300px) {
          padding: 3rem 3rem;
        }
        
        > header {
          display: flex;
          justify-content: center;

          > img {
            width: 150px;
            margin-bottom: 2rem;
          }
        }
        
        > div {
          
          > h2 {
            margin-bottom: 1rem;
            color: #fff;
          }
          
          > p {
            margin-bottom: 2rem;
            color: #ffffff;
            text-align: center;
          }
        }

        > form {
          display: flex;
          flex-direction: column;
          
          > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            padding: 1.3rem 1rem 0.8rem 1rem;
            background: #b174fc2b;

            &:nth-child(1) {
              border-radius: 8px 8px 0 0;
              border-bottom: 2px solid #dcdde50d;
            }

            &:nth-child(2) {
              border-radius: 0 0 8px 8px;
            }
            
            > aside {
            
              > svg {
                color: #ffffff;
              }
            }
            
            > main {
              position: relative;
              width: 100%;
              
              > label {
                position: absolute;
                display: block;
                font-size: 12px;
                top: -12px;
                color: #ffffff;
                user-select: none;
              }
              
              > input {
                width: 100%;
                background: none;
                padding: 5px 0;
                border: none;
                color: #fff;
                
                &:-webkit-autofill,
                :-webkit-autofill:hover,
                :-webkit-autofill:focus,
                :-webkit-autofill:active {
                    transition: background-color 7777s ease-in-out 0s;
                    -webkit-text-fill-color: #fff !important;
                }
                                
                &:-webkit-autofill {
                    -webkit-text-fill-color: #ffffff !important;
                }



              }
            }
          }

          > p {
            color: #ffffff;
            margin-top: 1rem;
            font-size: 12px;
            text-align: center;
            margin-bottom: 2rem;
            
            > span {
              font-weight: 900;
              cursor: pointer;
            }
          }
          
          > footer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            
            > button {
              margin-bottom: 1rem;
              text-align: center;
              width: 100%;
              display: flex;
              justify-content: center;

              &:disabled {
                background: #dcdde557 !important;
                color: #ffffff !important;
              }
            }
            
            > p {
              font-size: 8px;
              color: #ffffff80;
            }
          }
        }

      }
    }
  }
`;

export const WorldRotate = styled.section`
  width: 180vh;
  height: 180vh;
  position: absolute;
  background-size: contain;
  right: calc(-50% - -84vh);
  top: calc(-50% - -53vh);
  animation: rotation 50s infinite linear;
  z-index: 2;
  background-repeat: no-repeat;

  @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
  }

  @media screen and (min-width: 2200px) {
    background-size: contain;
    width: 200vh;
    height: 200vh;
    right: calc(-50% - -100vh);
    top: calc(-50% - -40vh);
  }

  @media screen and (max-width: 1800px) {
    background-size: contain;
    width: 160vh;
    height: 160vh;
    right: calc(-50% - -110vh);
    top: calc(-50% - -72vh);
  }

  @media screen and (max-width: 1600px) {
    background-size: contain;
    width: 160vh;
    height: 160vh;
    right: calc(-50% - -70vh);
    top: calc(-50% - -64vh);
  }

  @media screen and (max-width: 1440px) {
    background-size: contain;
    width: 129vh;
    height: 160vh;
    right: calc(-40% - -50vh);
    top: calc(-50% - -68vh);
  }

  @media screen and (max-width: 1300px) {
    background-size: contain;
    width: 119vh;
    height: 160vh;
    right: calc(-40% - -30vh);
    top: calc(-50% - -58vh);
  }

  @media screen and (max-width: 900px) {
    background-size: contain;
    width: 159vh;
    height: 160vh;
    right: calc(-45%);
    top: calc(-50% - -58vh);
  }

  @media screen and (max-width: 899px) {
    display: none;
  }
`;

export const ModalForgetContainer = styled.div`
  font-size: 12px;

  button {
    margin-bottom: 1rem;
  }

  > h1 {
    color: #3e3f5e;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  > p {
    color: #3e3f5e;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  > main {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    #error-message {
      margin-top: -12px !important;
      text-align: left !important;
      font-size: 11px !important;
      color: red;
    }

    > form {
      display: flex;
      flex-direction: column;

      > p {
        color: #3e3f5e;
        margin-bottom: 1rem;
        text-align: center;
        font-size: 10px;
      }

      > label {
        position: absolute;
        margin: 6px 0 0 12px;
        padding: 2px 3px;
        background: #ffffff;
        color: #3e3f5e;
        display: inline;
        font-weight: 600;
        width: fit-content;
        z-index: 9;
      }

      > input {
        position: relative;
        padding: 1rem 1.2rem;
        margin-top: 1rem;
        background: #ffffff12;
        border: 1px solid #3e3f5e;
        border-radius: ${props => props.theme.radius.primary};
        color: #3e3f5e;
        width: 100%;
        transition: all .3s ease-in-out;
        margin-bottom: 1rem;

        &input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active {
          box-shadow: none;
        }

        &::-webkit-autofill {
          -webkit-text-fill-color: ${props => props.theme.colors.white};
        }
      }

      > button {
        display: flex;
        justify-content: center;
        background: #9080fa;

        &:hover {
          background: #7f6bd2;
        }

        &:disabled {
          background: #9080fa !important;
          color: #c6bfe8 !important;
        }
      }
    }
  }
`;