import ReactModal from 'react-modal';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalHome = styled(ReactModal)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  margin: 0 auto;
  width: fit-content;
`;

export const ModalAlertContainer = styled.section`
  margin-bottom: 1rem;
`;

export const MessageContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 280px;
  width: 550px;
  border-radius: 12px;
  z-index: 0;
  padding: 2rem;

  @media (max-width: 768px) {
   scale: 0.8;
  }
  
  @media (max-width: 425px) {
    scale: 0.6;
  }
  
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: #8f7ff9;
    border-radius: 12px;
  }

  > main {
    width: 100%;
    z-index: 1;

    > header {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: -100px;
      position: relative;
      width: 100%;

      > img {
        width: 200px;
        user-select: none;
      }

      > h1 {
        color: #fff;
        margin: 1rem 0 1.5rem 0;
        font-size: 32px;
        text-align: center;
      }

    }
    
    > main {
      position: relative;
      
      > p {
        color: #ffffff;
        text-align: justify;
        font-size: 13px;
        line-height: 1.5;
        font-weight: 500;
      }

      > footer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-top: 2rem;
  
        > button {
          height: 48px;
          border-radius: 6px;
          background: #ffffff;
          color: #8f7ff9;
          font-weight: bold;
          padding: 0 32px;
          text-transform: uppercase;
          font-size: 14px;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
  
          &:hover {
            background: #e9e9e9;
          }
        }
        
        > p {
          margin-top: 0.5rem;
          color: #ffffff;
          text-align: center;
          font-size: 11px;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          text-decoration: underline;
        }
      }
    }
  }
`;

export const Section2 = styled.section`
  position: relative;
  display: flex;
  width: 100%;

  > form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;

    > h1 {
      color: #ffffff;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.5;
      text-align: justify;
    }

    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      > textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        border: 1px solid #4345bc75;
        border-radius: 6px;
        padding: 1rem;
        font-size: 13px;
        background: #ffffff;

        ::placeholder {
          color: #3e3f5e80;
        }
      }
    }

    > footer {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;

      > button {
        height: 48px;
        width: 100%;
        border-radius: 6px;
        background: #ffffff;
        color: #8f7ff9;
        font-weight: bold;
        padding: 0 32px;
        text-transform: uppercase;
        font-size: 14px;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          background: #e9e9e9;
        }
      }
    }
  }
`;