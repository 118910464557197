import React from 'react';

//@ts-ignore
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import { Container } from './styles';

interface IButtonProps {
  active?: boolean;
  blocked?: boolean;
  children?: React.ReactNode;
  className?: string;
  cssModule?: object;
  disabled?: boolean;
  href?: string;
  moveEvents?: boolean;
  placeholder?: boolean;
  ripple?: boolean;
  rootElement?: string;
  size?: string;
  style?: object;
  target?: string;
  title?: string;
  to?: string;
  type: string;
  visible?: boolean;
  action?(): void;
  element?(): void;
  onPress?(): void;
  onReleased?(): void;
}

const AwesomeButtonAdapter: React.FC<IButtonProps> = ({children, type, ripple, onPress, disabled}) => {
  return (
    <Container>
      <AwesomeButton type={type} ripple={ripple} disabled={disabled} onPress={onPress}>{children}</AwesomeButton>
    </Container>
  );
}

export default AwesomeButtonAdapter;