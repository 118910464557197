import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useAuth } from '../../../hooks/auth';
import { Controller, useForm } from 'react-hook-form';

import api from '../../../services/api';

import { FiUser } from 'react-icons/fi';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";
import toast from 'react-hot-toast';
import { Camera, Image } from 'phosphor-react';

import Accordion from '../../../components/Accordion';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import Input from '../../../components/Form/Input';
import InputNotRegister from '../../../components/Form/InputNotRegister';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import Textarea from '../../../components/Form/Textarea';
import { Center } from '../../../layout/components/Content/styles';
import ModalCropImage from '../../../components/ModalCropImage';
import ReactSelectAdapter from '../../../components/Form/ReactSelectAdapter';

import { CardChange, ChangeCoverAndAvatarContainer, Container, FormProfile, Line1, Line2, LineButton, MainContent, ModalContainer } from './styles';
import { IUniversityProps } from '../../../interfaces/University';
import { ICareesProps } from '../../../interfaces/Caree';

import { maxWidthContent } from '../../../utils/maxWidthContent';
import { loadConfettiAllPage } from '../../../utils/useConfetti';
import toBase64 from '../../../utils/toBase64';
import MainHeaderProfile from '../../../components/MainHeaderProfile';


// Validation
const schema = Joi.object({
  nickname: Joi.string().min(2).allow('').messages({ '*': 'Informe um apelido válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  gender: Joi.string().required().allow('').messages({ '*': 'Informe um gênero válido.' }),
  university_id: Joi.allow('').messages({ '*': 'Informe uma Universidade válida.' }),
  career_id: Joi.allow('').messages({ '*': 'Informe um Curso válido.' }),
  about: Joi.string().min(15).allow('').messages({ '*': 'Informe uma descrição válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
});

const Perfil: React.FC = () => {
  const { user, updateUser } = useAuth();

  const { register, handleSubmit, setValue, formState: { errors }, reset, control } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));
      return joiResolver(schema)(data, context, options);
    },
  });

  // Modals
  const [modalIsPublicProfile, setModalIsPublicProfile] = useState(false);

  // Loaders
  const [loadingIsPublicProfileButton, setLoadingIsPublicProfileButton] = useState(false);
  const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);

  // Datas
  const [universitiesData, setUniversitiesData] = useState<IUniversityProps[]>([]);
  const [careesData, setCareesData] = useState<ICareesProps[]>([]);

  const [profilePic, setProfilePic] = useState('');
  const [profileCover, setProfileCover] = useState('');

  const [modalAvatar, setModalAvatar] = useState(false);
  const [modalCover, setModalCover] = useState(false);

  const userAvatarInput = useRef<HTMLInputElement>({} as HTMLInputElement);
  const userCoverInput = useRef<HTMLInputElement>({} as HTMLInputElement);

  useLayoutEffect(() => {
    const mainContentSection: any = document.querySelector('#main-scroll');
    const mainContentCenter: any = document.querySelector('#content-center');
    const mainFooter: any = document.querySelector('#main-footer-container');

    if (mainContentSection && mainContentCenter && mainFooter) {
      mainContentSection.style.padding = '0px';
      mainContentCenter.style.maxWidth = '100%';
      mainFooter.style.display = 'none';
    }

    return () => {
      if (mainContentSection && mainContentCenter) {
        mainContentSection.style.padding = '2.35rem 1rem 2rem 2rem';
        mainContentCenter.style.maxWidth = maxWidthContent;
        mainFooter.style.display = 'block';
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const [careers, universities] = await Promise.all([
          api.get(`/careers/options`),
          api.get(`/universities/options`),
        ]);

        setCareesData(careers.data);
        setUniversitiesData(universities.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleChangePublicProfileState = useCallback(async () => {
    try {
      setLoadingIsPublicProfileButton(true);
      updateUser({ ...user, is_public: !user.is_public });
    } catch (error) {
      setLoadingIsPublicProfileButton(false);
    }
    finally {
      setLoadingIsPublicProfileButton(false);
    }
  }, [updateUser, user]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoadingUpdateProfile(true);

      const response = await api.put(`/profiles`, {
        career_id: data.career_id || null,
        university_id: data.university_id || null,
        nickname: data.nickname || null,
        gender: data.gender || null,
        about: data.about || null,
      });

      if (response.status === 200) {
        toast.success('Perfil atualizado com suecesso!', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });
        updateUser({ ...user, ...response.data });
        //reset();
      }

      console.log(response.data);
    } catch (error) {
      console.log(error);
      setLoadingUpdateProfile(false);
    } finally {
      setLoadingUpdateProfile(false);
    }
  });

  const handleSubmitAvatar = useCallback(async (file: File) => {
    try {
      setLoadingUpdateProfile(true);
      const formData = new FormData();

      formData.append('avatar', file);

      const response = await api.patch('/profiles/avatar', formData);
      console.log(response.data);

      if (response.status === 200) {
        updateUser({ ...user, avatar_url: response.data });
        loadConfettiAllPage();
        toast.success('Avatar atualizado com suecesso!', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });
      }


    } catch (err) {
      console.log(err);
      toast.error('Erro ao atualizar foto de perfil!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });
    }
    finally {
      setLoadingUpdateProfile(false);
    }
  }, [updateUser, user]);

  const handleSubmitCover = useCallback(async (file: File) => {
    try {
      setLoadingUpdateProfile(true);
      const formData = new FormData();

      formData.append('cover', file);

      const response = await api.patch('/profiles/cover', formData);
      console.log(response.data);

      if (response.status === 200) {
        updateUser({ ...user, cover_url: response.data });
        loadConfettiAllPage();
        toast.success('Foto de capa atualizada com suecesso!', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });
      }

    } catch (err) {
      console.log(err);
      toast.error('Erro ao atualizar foto de capa!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });
    }
    finally {
      setLoadingUpdateProfile(false);
    }
  }, [updateUser, user]);

  const handleChangeAvatar = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setLoadingUpdateProfile(true);
    console.log(file);

    if (!file) {
      return;
    }

    const convertedFile = await toBase64(file);
    console.log(convertedFile);
    setProfilePic(convertedFile);
    setModalAvatar(true);
    setLoadingUpdateProfile(false);
  }, []);

  const handleChangeCover = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setLoadingUpdateProfile(true);
    console.log(file);

    if (!file) {
      return;
    }

    const convertedFile = await toBase64(file);
    console.log(convertedFile);
    setProfileCover(convertedFile);
    setModalCover(true);
    setLoadingUpdateProfile(false);
  }, []);

  return (
    <>
      <Container>
        <MainHeaderProfile  />

        <Center>
          <MainContent>
            <aside>
              <Accordion />
            </aside>
            <main>
              <ChangeCoverAndAvatarContainer>
                <CardChange onClick={() => userAvatarInput.current.click()}>
                  <main>
                    <input type="file" name="useravatar" ref={userAvatarInput} onChange={handleChangeAvatar} accept="image/png, image/jpeg" />
                    <Camera size={36} weight="duotone" />
                    <h3>Alterar foto de perfil</h3>
                    <p>150x150px tamanho recomendado</p>
                  </main>
                </CardChange>
                <CardChange onClick={() => userCoverInput.current.click()}>
                  <main>
                    <input type="file" name="usercover" ref={userCoverInput} onChange={handleChangeCover} accept="image/png, image/jpeg" />
                    <Image size={36} weight="duotone" />
                    <h3>Alterar foto de capa</h3>
                    <p>1400x360px tamanho recomendado</p>
                  </main>
                </CardChange>
              </ChangeCoverAndAvatarContainer>
              <section>
                <FormProfile>
                  <header>
                    <h1><FiUser size={20} strokeWidth={2.5} /> Informações pessoais</h1>
                  </header>
                  <form onSubmit={onSubmit}>
                    <Line1>
                      <div>
                        {user.id && (
                          <Input register={register} name="nickname" type="text" label="Como você gostaria de ser chamado?" defaultValue={user.nickname} autoComplete="off" />
                        )}
                        {errors.nickname && <MessageErrorValidator>{errors.nickname.message as string}</MessageErrorValidator>}
                      </div>
                      <div>
                        {user.id && (
                          <Controller
                            name="gender"
                            control={control}
                            defaultValue={user?.gender}
                            render={({ field, fieldState, formState }) => {

                              const genderOptions = [
                                { value: 'male', label: 'Masculino' },
                                { value: 'female', label: 'Feminino' },
                                { value: 'non-binary', label: 'Não-binário' },
                              ];

                              return (
                                <ReactSelectAdapter
                                  {...field}
                                  classNamePrefix="react-select-adapter"
                                  isMulti={false}
                                  options={genderOptions}
                                  value={genderOptions?.find((option) => option.value === field.value)}
                                  placeholder="Selecione..."
                                  label="Gênero"
                                  onChange={(row: any) => {
                                    field.onChange(row.value)
                                  }}
                                />
                              )
                            }}
                          />
                        )}
                        {errors.gender && <MessageErrorValidator>{errors.gender.message as string}</MessageErrorValidator>}
                      </div>

                      <div>
                        <InputNotRegister name="allname" type="text" label="Nome completo" autoComplete="off" value={`${user.first_name} ${user.last_name}`} disabled />
                      </div>
                      <div>
                        <InputNotRegister
                          name="cpf"
                          type="text"
                          label="CPF"
                          autoComplete="off"
                          value={user.cpf}
                          disabled
                        />
                      </div>
                    </Line1>
                    <Line2>
                      <div>
                        {user.id && (
                          <Textarea register={register} name="about" type="text" label="Breve descrição" autoComplete="off" defaultValue={user?.about} />
                        )}
                        {errors.about && <MessageErrorValidator>{errors.about.message as string}</MessageErrorValidator>}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <InputNotRegister
                          name="email"
                          type="text"
                          label="E-mail"
                          autoComplete="off"
                          value={user.email}
                          disabled
                          style={{ gap: '1rem' }}
                        />

                        {user.id && (
                          <Controller
                            name="university_id"
                            control={control}
                            defaultValue={user?.university_id}
                            render={({ field, fieldState, formState }) => {

                              return (
                                <ReactSelectAdapter
                                  {...field}
                                  classNamePrefix="react-select-adapter"
                                  isMulti={false}
                                  options={universitiesData}
                                  value={universitiesData?.find((option) => option.value === field.value)}
                                  placeholder="Selecione..."
                                  label="Universidade"
                                  onChange={(row: any) => {
                                    field.onChange(row.value)
                                  }}
                                />
                              )
                            }}
                          />
                        )}
                        {errors.university_id && <MessageErrorValidator style={{ marginTop: '-12px' }}>{errors.university_id.message as string}</MessageErrorValidator>}

                        {user.id && (
                          <Controller
                            name="career_id"
                            control={control}
                            defaultValue={user?.career_id}
                            render={({ field, fieldState, formState }) => {

                              return (
                                <ReactSelectAdapter
                                  {...field}
                                  classNamePrefix="react-select-adapter"
                                  isMulti={false}
                                  options={careesData}
                                  value={careesData?.find((option) => option.value === field.value)}
                                  placeholder="Selecione..."
                                  label="Carreira"
                                  onChange={(row: any) => {
                                    field.onChange(row.value)
                                  }}
                                />
                              )
                            }}
                          />
                        )}
                        {errors.career_id && <MessageErrorValidator style={{ marginTop: '-12px' }}>{errors.career_id.message as string}</MessageErrorValidator>}
                      </div>
                    </Line2>
                    <LineButton>
                      <Button type="submit" color="primary" loading={loadingUpdateProfile} disabled={loadingUpdateProfile}>Atualizar perfil</Button>
                    </LineButton>
                  </form>
                </FormProfile>
              </section>
            </main>
          </MainContent>
        </Center>
      </Container>

      {/* MODALS */}
      <Modal
        isOpen={modalIsPublicProfile}
        setIsOpen={() => setModalIsPublicProfile(!modalIsPublicProfile)}
        closeModal={() => setModalIsPublicProfile(false)}
        size="md"
        title="Visibilidade do perfil 🔓"
      >
        <ModalContainer>
          <h2>Você está alterando a visibilidade do seu perfil. Veja abaixo o que muda:</h2>
          <ul>
            <li>Suas redações não serão exibidas no Hall da Fama</li>
            <li>Você não aparecerá no Ranking</li>
            <li>Você só poderá visualizar suas redações</li>
            <li>Você não poderá visualizar o Hall da Fama</li>
            <li>Você não poderá visualizar as redações dos outros alunos da Plataforma</li>
          </ul>
          <p><strong>Ao confirmar, esta ação só poderá ser revertida após 15 dias.</strong></p>
          <Button type="button" color="primary" loading={loadingIsPublicProfileButton} disabled={loadingIsPublicProfileButton} onClick={() => handleChangePublicProfileState}>Confirmar</Button>
        </ModalContainer>
      </Modal>

      {/* START MODAL COVER */}
      <ModalCropImage
        image={profileCover}
        cropSize={{ width: 1400, height: 360 }}
        isOpen={modalCover}
        setIsOpen={(value) => setModalCover(value)}
        onSubmit={handleSubmitCover}
      />
      {/* END MODAL COVER */}

      {/* START MODAL PROFILE PIC */}
      <ModalCropImage
        image={profilePic}
        cropSize={{ width: 200, height: 200 }}
        isOpen={modalAvatar}
        setIsOpen={(value) => setModalAvatar(value)}
        onSubmit={handleSubmitAvatar}
        cropShape="round"
      />
      {/* END MODAL PROFILE PIC */}
    </>
  );
}

export default Perfil;