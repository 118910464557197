import React from 'react';

import { HrDivisor } from './styles';

interface IHrProps {
  style?: React.CSSProperties;
}

const Hr: React.FC<IHrProps> = ({ style }) => {
  return (
    <HrDivisor style={style} />
  );
}

export default Hr;