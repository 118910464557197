import React from 'react';

import Select, { Props } from 'react-select';

import { Container } from './styles';

interface IReactSelectAdapterProps extends Props  {
  label?: string;
}

const ReactSelectAdapter: React.FC<IReactSelectAdapterProps> = ({ label, ...rest }) => {
  return (
    <Container>
      <Select 
        {...rest}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
      />
      {label && <label>{label}</label>}
    </Container>
  );
}

export default ReactSelectAdapter;