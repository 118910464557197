import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import { House, YoutubeLogo } from 'phosphor-react';


import Breadcrumbs from '../../../components/Breadcrumbs';
import LoaderBIO from '../../../components/Loader';
import Input from '../../../components/Form/Input';
import SimpleMultiSelect from '../../../components/Form/SimpleMultiSelect';
import MessageErrorValidator from '../../../components/MessageErrorValidator';

import { Container, HeaderSection, Line1, Line2, LineButton, SectionContainer1, Separator2 } from './styles';
import { IGenericOptions } from '../../../interfaces/IGenericOptions';
import Button from '../../../components/Button';
import WysiwygEditor from '../../../components/WysiwygEditor';
import { toast } from 'react-hot-toast';
import { loadConfettiAllPage } from '../../../utils/useConfetti';


interface ISectionProps {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  position: number;
  created_at: string;
  updated_at: string;
}

const SuporteTecnicoArtigosCreate: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();

  // Validation
  const schema = Joi.object({
    section_slug: Joi.string().required().messages({
      '*': 'Informe uma seção válida.',
    }),
    title: Joi.string().min(10).max(100).required().messages({
      '*': 'Informe um título válido.',
      'string.min': `O campo título deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo título deve ter no máximo {#limit} caracteres`,
    }),
    body: Joi.string().min(10).max(10000).required().messages({}),
  });

  const { register, handleSubmit, setValue, getValues, control, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));

      return joiResolver(schema)(data, context, options);
    },
  });

  // Data
  const [sectionsData, setSectionsData] = useState<IGenericOptions[]>([]);
  const [suporteCategory, setSuportCategory] = useState<ISectionProps>({} as ISectionProps);

  // Loaders
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/sections`);

        const sections = response.data;

        setSuportCategory(sections.find((section: ISectionProps) => section.slug === slug));

        // create a new array of options and values
        const options: IGenericOptions[] = sections.map((section: ISectionProps) => {
          return {
            value: section.slug,
            label: section.name,
          }
        });

        setSectionsData(options);
      }
      catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [slug]);


  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log('data', data);

      setLoadingSubmit(true);
      const response = await api.post(`/sections/${data.section_slug}/articles`, {
        title: data.title,
        body: data.body,
        is_promoted: false,
      });

      console.log(response.data);

      reset();

      toast.success('Artigo criado com sucesso!', {
        duration: 6000,
        position: 'bottom-right',
        className: 'toast-samuquinha',
      });

      loadConfettiAllPage();

      history.push(`/suporte/artigos/${slug}`)

    } catch (error) {
      console.log(error);
      toast.error('Erro ao criar artigo!', {
        duration: 6000,
        position: 'bottom-right',
        className: 'toast-samuquinha',
      });
      setLoadingSubmit(false);
    }
    finally {
      setLoadingSubmit(false);
    }
  });

  return (
    <>
      <LoaderBIO isVisible={loading} />

      <Container>
        <HeaderSection>
          <Breadcrumbs
            icon={House}
            separator="/"
            padding="1rem 0"
            route={[
              { title: 'Central de ajuda', link: '/suporte' },
              { title: suporteCategory.name, link: `/suporte/artigos/${slug}` },
              { title: 'Novo artigo', link: '' },
            ]}
          />
        </HeaderSection>

        <SectionContainer1>
          <form onSubmit={onSubmit}>
            <Separator2>
              <div>
                <YoutubeLogo size={20} weight="bold" />
                <h2>Informe os dados do artigo abaixo:</h2>
              </div>
              <aside>
                {/* <Toggle
                  onChange={() => { setValue('is_promoted', !getValues('is_promoted')) }}
                  checked={getValues('is_promoted')}
                  labelLeft="Destaque"
                  labelRight="Sem destaque"
                  onColor="#cde5e1"
                  offColor="#07302b"
                /> */}
              </aside>
            </Separator2>
            <Line1>
              <div>
                <SimpleMultiSelect
                  register={register}
                  name="section_slug"
                  label="Seção"
                  placeHolder="Selecione..."
                  options={sectionsData}
                  setResult={(values) => { setValue('section_slug', values) }}
                  isMulti={false}
                  defaultValue={slug ? slug : undefined}
                  required
                />
                <MessageErrorValidator>{errors?.section_slug?.message as string}</MessageErrorValidator>
              </div>
              <div>
                <Input
                  register={register}
                  name="title"
                  type="text"
                  label="Título do artigo"
                  autoComplete="off"
                />
                <MessageErrorValidator>{errors?.title?.message as string}</MessageErrorValidator>
              </div>
            </Line1>
            <Line2>
              <div>
                <WysiwygEditor
                  name={`body`}
                  setResult={(values) => { setValue(`body`, values) }}
                  minEditorHeight={200}
                  uploadRoute={`/exercises/upload`}
                />
                <MessageErrorValidator>{errors?.content?.message as string}</MessageErrorValidator>
              </div>
            </Line2>

            <LineButton>
              <Button type="submit" loading={loadingSubmit} color="primary">
                Cadastrar Artigo
              </Button>
            </LineButton>
          </form>
        </SectionContainer1>
      </Container>
    </>
  );
}

export default SuporteTecnicoArtigosCreate;