import styled from 'styled-components';

interface ModalAchiviementProps {
  bgImage: string;
}

export const Container = styled.div`

`;

export const ModalAchiviementContainer = styled.section<ModalAchiviementProps>`
  margin: -1.55rem -1.55rem 0 -1.55rem;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url(${props => props.bgImage});
    background-size: cover;
    background-position: center;
    height: 200px;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  > main {
    padding: 1rem 2rem 2rem 2rem;

    > h1 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: ${props => props.theme.colors.white};
    }
    
    > p {
      text-align: justify;
      font-size: 16px;
      margin-bottom: 0.5rem;
      line-height: 1.5;
      display: block;
      height: fit-content;
      color: ${props => props.theme.colors.white};
    }
  }
`;
