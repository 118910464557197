import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/auth';

import { Container, AvatarsContainer, Thead, Head, Footer, Thead2, HeaderMobile } from './styles';

import ranking from '../../assets/ranking.svg';
import AvatarProgress from '../AvatarProgress';
import { CaretDown } from 'phosphor-react';
import api from '../../services/api';
import LoaderSpinner from '../LoaderSpinner';
import TooltipAdapter from '../TooltipAdapter';
import { FiInfo } from 'react-icons/fi';

type IRankingFilterProps = 'month' | 'year';

export interface IRankingProps {
  ranking: {
    points: number;
    position: number;
    user: {
      id: string;
      show_name: string;
      avatar_url: string;
      exp: number;
      level: number;
      next_level: number;
    };
  }[];
  profileRanking: {
    points: number;
    position: number;
  };
}

const CardRanking: React.FC = () => {
  const { user } = useAuth();
  const [rankingFilter, setRankingFilter] =
    useState<IRankingFilterProps>('year');
  const [loading, setLoading] = useState(false);

  const [rankingData, setRankingData] = useState<IRankingProps>(
    {} as IRankingProps,
  );

  const IS_MOBILE_OR_TABLET = window.innerWidth < 1024;

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(`/users/ranking`, {
          params: {
            filter: rankingFilter,
          },
        });
        console.log(response.data);
        setRankingData(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [rankingFilter]);

  return (
    <Container>
      {loading && <LoaderSpinner zIndex={99999} />}
      {rankingData && rankingData.ranking && rankingData.ranking.length > 0 && (
        <>
          {!IS_MOBILE_OR_TABLET && (
            <header>
              <Head>
                <TooltipAdapter
                  alt="As estatísticas do ranking  são <br />atualizadas diariamente. ⏰"
                  place="top"
                  cursor="help"
                >
                  <FiInfo strokeWidth={2.5} size={20} />
                </TooltipAdapter>
                <main>
                  <select
                    onChange={e =>
                      setRankingFilter(e.target.value as IRankingFilterProps)
                    }
                    defaultValue={rankingFilter}
                  >
                    <option value="year">
                      Este ano
                    </option>
                    <option value="month">
                      Este mês
                    </option>
                  </select>
                  <CaretDown weight="duotone" />
                </main>
              </Head>
              <AvatarsContainer>
                <section>
                  <TooltipAdapter alt={`TOP #2: ` + rankingData?.ranking[1]?.user.show_name} place="top">
                    <AvatarProgress
                      size={64}
                      url={rankingData?.ranking[1]?.user.avatar_url}
                      percent={rankingData?.ranking[1]?.user.next_level || 0}
                      badgeValue={rankingData?.ranking[1]?.user.level}
                    />
                  </TooltipAdapter>
                  <h3>
                    {rankingData?.ranking[1]?.points} <br /> <span>pontos</span>
                  </h3>
                </section>

                <section>
                  <TooltipAdapter alt={`TOP #1: ` + rankingData?.ranking[0]?.user.show_name} place="top">
                    <AvatarProgress
                      size={80}
                      url={rankingData?.ranking[0]?.user.avatar_url}
                      percent={rankingData?.ranking[0]?.user.next_level || 0}
                      badgeValue={rankingData?.ranking[0]?.user.level}
                    />
                  </TooltipAdapter>
                  <h3>
                    {rankingData?.ranking[0]?.points} <br /> <span>pontos</span>
                  </h3>
                </section>
                <section>
                  <TooltipAdapter alt={`TOP #3: ` + rankingData?.ranking[2]?.user.show_name} place="top">
                    <AvatarProgress
                      size={64}
                      url={rankingData?.ranking[2]?.user.avatar_url}
                      percent={rankingData?.ranking[2]?.user.next_level || 0}
                      badgeValue={rankingData?.ranking[2]?.user.level}
                    />
                  </TooltipAdapter>
                  <h3>
                    {rankingData?.ranking[2]?.points} <br /> <span>pontos</span>
                  </h3>
                </section>
              </AvatarsContainer>
              <figure>
                <img src={ranking} alt="" />
              </figure>

              <Thead>
                <div>
                  <h5>#</h5>
                  <h5>Aluno</h5>
                  <h5>Pontos</h5>
                  <h5>Level</h5>
                </div>
              </Thead>
            </header>
          )}

          {IS_MOBILE_OR_TABLET && (
            <HeaderMobile>
              <div>
                <h2>Ranking {rankingFilter === 'year' ? 'anual' : 'mensal'}</h2>
                <main>
                  <select
                    onChange={e =>
                      setRankingFilter(e.target.value as IRankingFilterProps)
                    }
                    defaultValue={rankingFilter}
                  >
                    <option value="year">
                      Este ano
                    </option>
                    <option value="month">
                      Este mês
                    </option>
                  </select>
                  <CaretDown weight="duotone" />
                </main>
              </div>
            </HeaderMobile>
          )}
          <main>
            {IS_MOBILE_OR_TABLET ? (
              <>
                {rankingData?.ranking.slice(0, 10).map((ranking, index) => (
                  <div key={index}>
                    <h5>{ranking.position}</h5>
                    <div>
                      <AvatarProgress
                        badgeValue={ranking.user.level}
                        percent={ranking.user.next_level}
                        url={ranking.user.avatar_url}
                        size={40}
                      />
                      <h5>{ranking?.user?.show_name}</h5>
                    </div>
                    <h5>{ranking.points}</h5>
                    {!IS_MOBILE_OR_TABLET && <h5>{ranking?.user?.level}</h5>}
                  </div>
                ))}
              </>
            ) : (
              rankingData?.ranking.slice(3, 10).map((ranking, index) => (
                <div key={index}>
                  <h5>{ranking.position}</h5>
                  <div>
                    <AvatarProgress
                      badgeValue={ranking.user.level}
                      percent={ranking.user.next_level}
                      url={ranking.user.avatar_url}
                      size={40}
                    />
                    <h5>{ranking?.user?.show_name}</h5>
                  </div>
                  <h5>{ranking.points}</h5>
                  <h5>{ranking?.user?.level}</h5>
                </div>
              ))
            )}
          </main>
          <Footer>
            <div>
              <h5>{rankingData?.profileRanking?.position}</h5>
              <div>
                <AvatarProgress
                  badgeValue={user.level}
                  percent={user.current_exp}
                  url={user.avatar_url}
                  size={40}
                />
                <h5>Você</h5>
              </div>
              <h5>{rankingData?.profileRanking.points}</h5>
              {!IS_MOBILE_OR_TABLET && <h5>{user.level}</h5>}
            </div>
          </Footer>
        </>
      )}
    </Container>
  );
};

export default CardRanking;
