import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";

import { TableColumn } from 'react-data-table-component';

import DataTableAdapter from '../../../components/DataTableAdapter';
import DropdownButton from '../../../components/DropdownButton';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

import toast from 'react-hot-toast';
import { FiBook, FiSettings } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { CardTableContainer, Container, HeaderSection, Line0, Line1, Line2, Line3, ModalContainer, Poster, Separator } from './styles';

import api from '../../../services/api';

import ReactStars from 'react-stars';
import { useTheme } from '../../../hooks/theme';
import TooltipAdapter from '../../../components/TooltipAdapter';

import IDataTable from '../../../interfaces/DataTable';
import Input from '../../../components/Form/Input';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import { IGenericOptions } from '../../../interfaces/IGenericOptions';
import Textarea from '../../../components/Form/Textarea';
import DropZone from '../../../components/Form/DropZone';

interface IBooksProps {
  id: string;
  title: string;
  author_name: string;
  number_of_pages: number;
  publishing_year: string;
  summary: string;
  vote_average: number;
  vote_count: number;
  cover: string;
  cover_url: string;
  file: string;
  material_url: string;
  created_at: string;
  updated_at: string;
  genres: string[];
}


const Livros: React.FC = () => {
  const { theme } = useTheme();

  // Validation
  const schema = Joi.object({
    id: Joi.string().optional(),
    title: Joi.string().required().messages({
      'any.required': 'O nome do livro é obrigatório.',
      'string.empty': 'O nome do livro é obrigatório.',
    }),
    author_name: Joi.string().required().messages({
      'any.required': 'O nome do autor é obrigatório.',
      'string.empty': 'O nome do autor é obrigatório.',
    }),
    number_of_pages: Joi.number().required().messages({
      'any.required': 'O número de páginas é obrigatório.',
      'number.base': 'O número de páginas deve ser um número.',
    }),
    publishing_year: Joi.string().required().messages({
      'any.required': 'O ano de publicação é obrigatório.',
      'string.empty': 'O ano de publicação é obrigatório.',
    }),
    summary: Joi.string().required().messages({
      'any.required': 'O resumo é obrigatório.',
      'string.empty': 'O resumo é obrigatório.',
    }),
    genres: Joi.string().required().messages({
      'any.required': 'O gênero é obrigatório.',
      'string.empty': 'O gênero é obrigatório.',
    }),
  });

  const { register, handleSubmit, setValue, watch, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));

      return joiResolver(schema)(data, context, options);
    },
  });

  // Modal 
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // Request 
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [filterText, setFilterText] = useState('');

  //Data
  const [booksData, setBooksData] = useState<IDataTable<IBooksProps>[]>([]);
  const [coverData, setCoverData] = useState<File[]>([]);
  const [fileData, setFileData] = useState<File[]>([]);
  const [genresOptions, setGenresOptions] = useState<IGenericOptions[]>([]);

  // Loaders
  const [loadingCreateBook, setLoadingCreateBook] = useState(false);

  const columns: TableColumn<IBooksProps>[] = [
    {
      name: 'Nome',
      selector: row => row.title,
      width: '30%',
      cell: (row: IBooksProps) => (
        <>
          <div style={{ display: 'flex', gap: '0.35rem', alignItems: 'center' }}>
            <Poster src={row.cover_url} alt={row.title} />
            <div>
              <p><strong>{row.title} ({row.publishing_year})</strong></p>
              <p>
                {row.genres?.map((gern, index) => (
                  <Separator key={index}>
                    {gern}
                    {row.genres && (row.genres?.length - 1 !== index) && (<span>,</span>)}
                  </Separator>
                ))}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Número de páginas',
      cell: (row: IBooksProps) => (
        <span>{row.number_of_pages}</span>
      ),
    },
    {
      name: 'Avaliação',
      selector: row => row.vote_average,
      cell: (row: IBooksProps) => (
        <div style={{ zIndex: 0, }}>
          <TooltipAdapter alt={`${String(row.vote_count)} votos`} place="top">
            <ReactStars
              key={1}
              count={5}
              edit={false}
              size={20}
              color1={theme === 'dark' ? '#fff' : '#adafca'}
              color2="#d3df37"
              half={true}
              value={row.vote_average / 20}
            />
          </TooltipAdapter>
        </div>
      ),
    },
    {
      name: (<FiSettings size={16} strokeWidth={2.5} />),
      sortable: false,
      button: true,
      right: true,
      cell: (row: IBooksProps, index: number) => (
        <DropdownButton key={index}>
          <ul>
            <li>
              <button type="button" onClick={() => {
                setIsEdit(true);
                setValue('id', row.id);
                setValue('title', row.title);
                setValue('author_name', row.author_name);
                setValue('number_of_pages', row.number_of_pages);
                setValue('publishing_year', String(row.publishing_year));
                setValue('summary', row.summary);
                setValue('genres', row.genres.join(', '));
                setCoverData([]);
                setFileData([]);

                setModalAddIsOpen(true)
              }
              }>
                <MdKeyboardArrowRight size={18} />
                Editar
              </button>
            </li>
            <li>
              <button type="button" onClick={() => { window.open(row.material_url, '_blank') }}>
                <MdKeyboardArrowRight size={18} />
                Visualizar exercício
              </button>
            </li>
          </ul>
        </DropdownButton>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {

        const response = await api.get(`/books`, {
          params: {
            pageIndex: page,
            pageSize: perPage,
            search: filterText,
          }
        });

        setBooksData(response.data.records);
        setTotalRows(response.data.totalRecords);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [page, perPage, filterText]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/genres/options`, {
          params: {
            pageIndex: 0,
          }
        });

        console.log(response.data);
        setGenresOptions(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    console.log('data:::: ', data);
    try {
      setLoadingCreateBook(true);
      const formData = new FormData();

      formData.append('title', data.title);
      formData.append('author_name', data.author_name);
      formData.append('number_of_pages', String(data.number_of_pages));
      formData.append('publishing_year', data.publishing_year);
      formData.append('summary', data.summary);
      formData.append('file', fileData[0]);
      formData.append('cover', coverData[0]);

      const genres = data.genres.split(',').map((genre: string) => genre.trim());
      genres.forEach((genre: string) => formData.append('genres[]', genre));

      if (!coverData.length) {
        toast.error('Por favor, adicione a capa do livro.', { position: 'bottom-center', duration: 6000 });
        setLoadingCreateBook(false);
        return;
      }

      if (!fileData.length) {
        toast.error('Por favor, adicione o arquivo de exercícios do livro.', { position: 'bottom-center', duration: 6000 });
        setLoadingCreateBook(false);
        return;
      }

      if (isEdit) {
        // Update book
        const response = await api.put(`/books/${watch('id')}`, formData);
        console.log(response.data);

        if (response.status === 200) {
          setLoadingCreateBook(false);
          setModalAddIsOpen(false);

          setBooksData((oldData) => oldData.map((book: any) => {
            if (book.id === response.data.id) {
              return response.data;
            }
            return book;
          }));
          
          toast.success('Livro atualizado com sucesso!', { position: 'bottom-center', duration: 6000 });
          reset();

          setCoverData([]);
          setFileData([]);
        }

      } else {
        console.log('aqioo');
        // Create book
        const response = await api.post(`/books`, formData);
        console.log(response.data);

        if (response.status === 200) {
          setLoadingCreateBook(false);
          setModalAddIsOpen(false);
          setBooksData((oldData) => [response.data, ...oldData]);
          toast.success('Livro cadastrado com sucesso!', { position: 'bottom-center', duration: 6000 });
          reset();

          setCoverData([]);
          setFileData([]);
        }
      }
    }
    catch (error: any) {
      console.log(error);
      setLoadingCreateBook(false);
      toast.error(`Ops! algo deu errado.`, { position: 'bottom-center', duration: 6000 });
    }
    finally {
      setLoadingCreateBook(false);
    }
  });

  return (
    <>
      <Container>
        <HeaderSection>
          <aside>
            <Button
              onClick={() => {
                setIsEdit(false);
                reset();
                setModalAddIsOpen(!modalAddIsOpen)
              }}
              color="primary"
              icon={FiBook}
              iconBefore
            >
              Cadastrar livro
            </Button>
          </aside>
        </HeaderSection>

        <CardTableContainer>
          <DataTableAdapter
            title="Livros"
            subTitle={''}
            columns={columns}
            data={booksData}
            paginationServer={true}
            perPage={perPage}
            totalRows={totalRows}
            setFilterText={setFilterText}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </CardTableContainer>
      </Container>

      {/* MODALS */}

      <Modal
        isOpen={modalAddIsOpen}
        setIsOpen={() => setModalAddIsOpen(!modalAddIsOpen)}
        closeModal={() => {
          setModalAddIsOpen(false)
        }}
        size="lg"
        title={isEdit ? 'Editar livro' : 'Cadastrar livro'}
      >
        <ModalContainer>
          <form onSubmit={(data) => onSubmit(data)}>
            <Line0>
              <div>
                <Input register={register} name="title" type="text" label="Nome do livro" autoComplete="off" />
                {errors.title && <MessageErrorValidator>{errors.title.message as string}</MessageErrorValidator>}
              </div>
              <div>
                {genresOptions && (
                  <Input register={register} name="genres" type="text" label="Gênero" autoComplete="off" />
                )}
                {errors.genres && <MessageErrorValidator>{errors.genres.message as string}</MessageErrorValidator>}
              </div>
            </Line0>
            <Line1>
              <div>
                <Input register={register} name="author_name" type="text" label="Nome do autor" autoComplete="off" />
                {errors.author_name && <MessageErrorValidator>{errors.author_name.message as string}</MessageErrorValidator>}
              </div>
              <div>
                <Input register={register} name="number_of_pages" type="number" label="Número de páginas" autoComplete="off" />
                {errors.number_of_pages && <MessageErrorValidator>{errors.number_of_pages.message as string}</MessageErrorValidator>}
              </div>
              <div>
                <Input register={register} name="publishing_year" type="number" label="Ano de publicação" autoComplete="off" />
                {errors.publishing_year && <MessageErrorValidator>{errors.publishing_year.message as string}</MessageErrorValidator>}
              </div>
            </Line1>
            <Line2>
              <div>
                <Textarea register={register} name="summary" type="text" label="Resumo do livro" autoComplete="off" />
                {errors.summary && <MessageErrorValidator>{errors.summary.message as string}</MessageErrorValidator>}
              </div>
              <div>
                <DropZone
                  setFilesData={setCoverData}
                  maxSize={1024 * 1024 * 0.5} // 500 kb
                  isMulti={false}
                  icons={false}
                  placeHolder="Arraste e solte ou clique para adicionar a capa do livro"
                  accept={{
                    'image/*': ['.jpg', '.jpeg', '.png'],
                  }}
                />
              </div>
              <div>
                <DropZone
                  setFilesData={setFileData}
                  maxSize={1024 * 1024 * 3} // 3 mb
                  isMulti={false}
                  icons={false}
                  placeHolder="Arraste e solte ou clique para adicionar o .pdf de exercícios sobre o livro"
                  accept={{
                    'application/pdf': ['.pdf'],
                  }}
                />
              </div>
            </Line2>
            <Line3>
              <div>
                <Button
                  type="submit"
                  color="primary"
                  iconBefore
                  loading={loadingCreateBook}
                >
                  {isEdit ? 'Editar livro' : 'Cadastrar livro'}
                </Button>
              </div>
            </Line3>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default Livros;