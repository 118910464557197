import ReactModal from 'react-modal';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalHome = styled(ReactModal)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  margin: 0 auto;
  width: fit-content;
`;

export const ModalAlertContainer = styled.section`
  margin-bottom: 1rem;
`;

export const Poster = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -1.5rem;

  img {
    border-radius: 16px;
    max-width: 550px !important;
    max-height: 550px !important;
    user-select: none;

    @media (max-width: 768px) {
      max-width: 400px !important;
      max-height: 400px !important;
    }

    @media (max-width: 425px) {
      max-width: 300px !important;
      max-height: 300px !important;
    }
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  z-index: 9999999 !important;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  background: #ffffff50;
  backdrop-filter: blur(10px);
  border: 2px solid #ffffff90;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #ffffff;

  &:hover {
    background: #ffffff60;
  }
`;
