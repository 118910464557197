import styled from 'styled-components';

export const HEADER_HEIGHT = '80px';

export const Container = styled.header`
  position: relative;
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: space-between;
  width: 100%; 
  height: auto; 
  height: ${HEADER_HEIGHT};
  padding: 1rem 1.5rem;
  background: ${props => props.theme.header.header};
  backdrop-filter: ${props => props.theme.cards.backdropFilter};
  border-bottom: 1px solid ${props => props.theme.header.border};
  transition: width .3s ease-in-out;
  gap: 1rem;
  z-index: 9999;

  /* TABLET */
  @media (max-width: 980px) {
    padding: 1rem 1.5rem 1rem 0;
  }

  /* TABLET */
  @media (max-width: 1024px) {
    padding: 0 1rem 0 1rem;
  }


  > main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    gap: 0.5rem;

    > aside {
      display: flex;
      justify-content: center;
      width: 100%;
      
      /* MOBILE */
      @media (max-width: 480px) {
        display: none;
      }
    }
  }
`;

export const MenuDropDownContainer = styled.div`
  position: relative;
  user-select: none;
`;

export const MenuNotificationsNav = styled.nav`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  border-radius: ${props => props.theme.radius.secondary};
  overflow: hidden;
  visibility: hidden;
  z-index: -1;
  min-height: 200px;

  svg {
    color: ${props => props.theme.colors.white};
  }

  background: ${props => props.theme.gradients.secondary};
  border: ${props => props.theme.specific.menuBorder};
  box-shadow: ${props => props.theme.shadows.primary};

  opacity: 0;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  transform: translate(0px, -50px);
  width: 350px;
  top: 50px;

  &.open-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem 0 1.5rem 0;

    > h4 {
      color: ${props => props.theme.specific.menuTitles};
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
    }

    > p {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${props => props.theme.colors.white};
      font-size: 0.7rem;
      gap: 0.25rem;
      cursor: pointer;
      transition: all .2s ease-in-out;

      &:hover {
        color: ${props => props.theme.colors.white}90;
      }
    }
  }

  > h4 {
    margin: 1.25rem 0 0.75rem 0;
    color: ${props => props.theme.colors.secondary};
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }

  > p {
    font-size: 0.75rem;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.white};
  }

  > a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
    font-size: 0.75rem;
    
    > main {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      line-height: 1.5;
  
      > div {

        > h4 {
          font-size: 12px;
          margin-bottom: 0.5rem;
        }

        > p {
          font-size: 11px;
        }

        > small {
          color: ${props => props.theme.colors.muted};
          font-size: 11px;
          margin-top: .25rem;
          display: inline-block;
        }
      }
    }

    > section {
      display: flex;
      flex-direction: row;
      gap: 0.75rem;
      justify-content: space-between;
      
      > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        justify-content: flex-start;
        
        > main {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          line-height: 1.5;
          
          margin-left: 0.75rem;

          > h4 {
            font-size: 12px;
          }
  
          > p {
            font-size: 11px;
          }
        }

        > img {
          width: 48px;
        }

        > span {
          display: inline-block;
          white-space: nowrap;
        }
      }
    }
  }

  > footer {
    margin-top: 0.5rem;
    
    > a {
      background: #007361;
      margin: 1rem -1.5rem -1.5rem -1.5rem;
      padding: 1.25rem 1.5rem;
      display: flex;
      justify-content: center;
      text-decoration: none;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      transition: all .2s ease-in-out;

      &:hover {
        background: #296159;
      }
    }
  }

`;

export const MenuNav = styled.nav`
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  padding: 70px 1.5rem 1.5rem 1.5rem;
  border-radius: ${props => props.theme.radius.secondary};
  top: 0;
  background: ${props => props.theme.gradients.tertiary};
  border: ${props => props.theme.specific.menuBorder};
  border-top: 0px;
  box-shadow: ${props => props.theme.shadows.primary};
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  transform: translate(0px, -10px);

  /* TABLET */
  @media (max-width: 980px) {
    width: fit-content;
    transform: translateX(-70px) !important;
    top: 70px;
    left: 0;
    padding-top: 0.5rem;
  }

  &.open-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }

  > h4 {
    margin: 1.25rem 0 0.75rem 0;
    color: ${props => props.theme.specific.menuTitles};
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    white-space: nowrap;     
  }

  > a {
    display: block;
    padding: 0px 0px 0.4rem;
    font-size: 0.75rem;
    font-weight: 600;
    text-decoration: none;
    color: ${props => props.theme.colors.white};
    white-space: nowrap;
    transition: padding 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;

    &:hover {
      padding-left: 4px;
      //color: ${props => props.theme.colors.primary};
    }
  }

  > button {
    border: none;
    border-radius: 6px;
    margin-top: 1.5rem;
    padding: 0.75rem 0.4rem;
    font-weight: bold;
    align-items: center;
    font-size: 0.75rem;
    transition: all 0.35s ease-in-out 0s;
    text-transform: uppercase;
    color: ${props => props.theme.background.tertiary};
    background: ${props => props.theme.specific.menuButton};
    cursor: pointer;
  }
`;

export const HasNotificationBullet = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #aa14cb;
  top: 11px;
  right: 10px;
  border: 3px solid;
  border-color: ${props => props.theme.header.header};
`;

export const AvatarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 8px 10px;
  border-radius: 12px;
  transition: all .2s ease-in-out;
  cursor: pointer;
  color: #fff;
  justify-content: space-between;

  
  @media (max-width: 1024px) {
    padding: 8px 0 8px 8px;
  }
  
  @media (max-width: 980px) {
    gap: 0.5rem;
  }
  
  &:hover {
    background: ${props => props.theme.specific.menuDropDownAvatarButtonOnHover};
    border-radius: 12px;

    &:hover {
      background: none;
    }
  }

  &.closed-menu {
    min-width: 190px !important;

    @media (max-width: 980px) {
      min-width: unset !important;
      width: auto !important;
      gap: 0.5rem;
    }
  }

  &.open-menu {
    background: ${props => props.theme.specific.menuDropDownAvatarButtonOnHover};
    border-radius: 12px;
    color: ${props => props.theme.specific.menuButton};
    min-width: 190px !important;

    @media (max-width: 980px) {
      min-width: unset !important;
      width: auto !important;
      gap: 1rem;
    }

    &.open-menu {
      background: none;

      h2 {
        color: ${props => props.theme.colors.white};
      }
      
      p {
        color: ${props => props.theme.colors.white};
      }
    }
  }

  > aside {
    display: flex;
    gap: .25rem;
    flex-direction: column;

    /* TABLET */
    @media (max-width: 980px) {
      display: none;
    }

    > h2 {
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap;
      display: block;
    }

    > p {
      font-size: 12px;
      font-weight: 300;
      color: ${props => props.theme.colors.gray};
    }
  }

  > div {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    color: ${props => props.theme.colors.gray};
  }

`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

   /* TABLET */
   @media (max-width: 980px) {
    display: none;
  }
`;

export const SectionAchiviementsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;

    > img {
      width: 52px;
      border-radius: 3px;
    }
  }
`;

export const ToggleThemeContainer = styled.div`
  margin: 1rem 0 0 0;

  > h4 {
    margin: 1.25rem 0 0.75rem 0;
    color: ${props => props.theme.specific.menuTitles};
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }
`;

export const NextLevelProgressContainer = styled.div`
  width: 150px;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* TABLET */
  @media (max-width: 980px) {
    display: none;
  }
  
  > header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #fff;
    font-size: 0.6rem;
    margin-bottom: 0.2rem;
    font-weight: 700;
    text-transform: uppercase;
    
    > p {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 5px;
    }
  }
  
  .rect-progress-bar-percent {
    display: none !important;
  }
  
  .inner-rect-bar {
    background: ${props => props.theme.colors.secondary} !important;
    border-radius: 10px !important;
  }
  
  .progress-bar-rect-wrapper {
    
    > div {
      border-radius: 10px;
      overflow: hidden;
      background: #ffffff40 !important;
    }
  }
`;

export const LogoMobileContainer = styled.aside`
  width: auto;
  //margin: 0 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  display: none;
  gap: 1.5rem;

  /* TABLET */
  @media (max-width: 1024px) {
    display: flex;
  }

  @media (max-height: 843px) {
    display: flex;
  }

  > img {
    width: 60px;
    opacity: 1;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      cursor: pointer;
    }
  }
`;

export const AsideMenuMobile = styled.div`
  position: fixed;
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  background: ${props => props.theme.specific.asideModule};
  z-index: 99999999999999;
  padding: 2rem 1rem;
  transition: .5s ease-in-out;
  left: -300px;

  &.sidebar.active {
    left: 0;
  }

  &::-webkit-scrollbar {
    visibility: hidden;
    width: 4px;
    height: 4px;
  }

  &:hover::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    opacity: 1;
    transition: all .3s ease-in-out;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: #cccccc80;
    border-radius: 10px;
  }

  &:hover::-webkit-scrollbar-thumb:hover{
  }

  &:hover::-webkit-scrollbar-track{
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #cccccc10;
  }
  
  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.5rem;


    > a {
      color: ${props => props.theme.colors.white};
      font-weight: 700;
      line-height: 1.2;
      text-decoration: none;

      > span {
        margin-top: 4px;
        color: #8f91ac;
        font-size: .75rem;
        font-weight: 500;
      }
    }
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(110, 97, 198, .5);
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
  &.active {
    visibility: visible;
    opacity: 1;
    transition: all .3s ease-in-out;
  }
`;

export const ArrowBack = styled.button`
  position: absolute;
  height: 15px;
  width: 15px;
  font-size: 14px;
  top: 1.2rem;
  right: 1.2rem;
  border: none;
  background: none;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h4 {
    padding: 0 0 5px 7px;
    color: #adafca;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
  }

  > div {
    margin: .25rem 0;
    width: 100%;
    padding: 12px 1rem;
    display: flex;
    align-items: center;

    &.active {
      border-radius: 12px;
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.gray}40;

      > a, span {
        color: ${props => props.theme.colors.white};
      }
    }

    > a {
      display: flex;
      align-items: center;
      gap: 1rem;
      text-decoration: none;
      transition: all .2s ease-in-out;
      font-weight: 700;
      text-align: left;
      color: ${props =>props.theme.colors.white};

      > span {
        padding-right: 1.5rem;
        font-size: 20px;
        color: ${props =>props.theme.colors.primary};
      }
    }
  }
`;