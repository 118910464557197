import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';

import { House, MagnifyingGlass, Medal } from 'phosphor-react';

import LoaderBIO from '../../../components/Loader';
import Breadcrumbs from '../../../components/Breadcrumbs';
import InputDebounceAdapter from '../../../components/InputDebounceAdapter';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import NotFoundRegister from '../../../components/NotFoundRegister';
import CardAchiviement from '../../../components/CardAchiviement';

import {
  Container,
  HeaderSection,
  SectionContainer1,
  SearchContainer,
} from './styles';

import { IAchievementsProps } from '../../../interfaces/Achievements';
import TooltipAdapter from '../../../components/TooltipAdapter';
import ModalAchiviement from '../../../components/ModalAchiviement';

export interface IPaginationProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: IAchievementsProps[];
}

const Conquistas: React.FC = () => {
  // Loading
  const [loading, setLoading] = useState(false);

  const [achiviementData, setAchiviementData] = useState<IPaginationProps>(
    {} as IPaginationProps,
  );
  const [search, setSearch] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/achievements`, {
          params: {
            pageIndex: 0,
            pageSize: 999,
            search: search,
          },
        });

        console.log(response.data);
        setAchiviementData(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [search]);

  const handleFilterAchievement = useCallback((value: string) => {
    console.log(value);
    setSearch(value);
  }, []);

  return (
    <Container>
      {loading && <LoaderBIO isVisible />}
      <HeaderSectionContainer padding="1rem 2rem">
        <h1>
          <Medal weight="duotone" /> Conquistas
        </h1>
        <p>
          Assista aos vídeos, realize simulados, acumule pontos e desbloqueie
          conquistas!
        </p>
      </HeaderSectionContainer>

      <HeaderSection>
        <Breadcrumbs
          icon={House}
          separator="/"
          route={[{ title: 'Conquistas', link: '/conquistas' }]}
        />
        <SearchContainer>
          <InputDebounceAdapter
            type="text"
            debounceTimeout={300}
            minLength={3}
            onChange={event => handleFilterAchievement(event.target.value)}
            placeholder="Busque por uma conquista..."
          />
          <MagnifyingGlass weight="duotone" />
        </SearchContainer>
      </HeaderSection>
      <SectionContainer1>
        {achiviementData.records &&
          achiviementData.records.length > 0 &&
          achiviementData.records.map(achiviement => (
            <>
              {achiviement?.users?.length > 0 ? (
                <CardAchiviement
                  key={achiviement.id}
                  badge_icon={achiviement.icon_url}
                  status="concluded"
                  description={achiviement.requirement}
                  title={achiviement.title}
                  exp_total_value={achiviement.exp}
                  is_hidden={achiviement.is_hidden}
                />
              ) : (
                <TooltipAdapter
                  alt="Você ainda não desbloqueou esta conquista 🔒"
                  place="top"
                  cursor="help"
                >
                  <CardAchiviement
                    key={achiviement.id}
                    badge_icon={achiviement.icon_url}
                    status="pending"
                    description={achiviement.requirement}
                    title={achiviement.title}
                    exp_total_value={achiviement.exp}
                    is_hidden={achiviement.is_hidden}
                  />
                </TooltipAdapter>
              )}
            </>
          ))}
      </SectionContainer1>

      {achiviementData.records?.length === 0 && <NotFoundRegister />}
    </Container>
  );
};

export default Conquistas;
