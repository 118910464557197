import {
  Book,
  Books,
  Calendar,
  Chats,
  Exam,
  GraduationCap,
  House,
  Medal,
  MonitorPlay,
  Popcorn,
  Student,
  YoutubeLogo,
} from 'phosphor-react';

export interface IMenuLink {
  title: string;
  location: string;
  icon: any;
  badge?: string;
}

const menuLinks: IMenuLink[] = [
  {
    title: 'Dashboard',
    location: '/',
    icon: House,
  },
  {
    title: 'Plano de estudo',
    location: '/plano-de-estudos',
    icon: Student,
  },
  {
    title: 'Todas as Aulas',
    location: '/modulos',
    icon: YoutubeLogo,
  },
  {
    title: 'Vestibulares',
    location: '/vestibulares',
    icon: GraduationCap,
  },
  {
    title: 'Cursos Extras',
    location: '/cursos-extras',
    icon: MonitorPlay,
  },
  {
    title: 'Materiais',
    location: '/materiais',
    icon: Book,
  },
  {
    title: 'SasaFlix',
    location: '/sasaflix',
    icon: Popcorn,
  },
  {
    title: 'SasaBooks',
    location: '/sasabooks',
    icon: Books,
    badge: 'New',
  },
  {
    title: 'Simulados',
    location: '/simulados/novo',
    icon: Exam,
  },
  {
    title: 'Conquistas',
    location: '/conquistas',
    icon: Medal,
  },
  {
    title: 'Comunidade',
    location: '/comunidade',
    icon: Chats,
  },
  {
    title: 'Calendário',
    location: '/calendario',
    icon: Calendar,
  },
];

export default menuLinks;
