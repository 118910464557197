import React from 'react';

import { Switch, Route } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

import Layout from '../layout';

import Dashboard from '../pages/admin/Dashboard';
import Aluno from '../pages/admin/Aluno';
import Alunos from '../pages/admin/Alunos';
import Aulas from '../pages/admin/Aulas';
import Aula from '../pages/admin/Aula';
import AulaCreate from '../pages/admin/AulaCreate';
import Filmes from '../pages/admin/Filmes';
import Modulos from '../pages/admin/Modulos';
import ModulosVestibulares from '../pages/admin/ModulosVestibulares';
import PlanoDeEstudos from '../pages/admin/PlanoDeEstudos';
import PlanoDeEstudosCreate from '../pages/admin/PlanoDeEstudosCreate';
import Materiais from '../pages/admin/Materiais';
import AulaEdit from '../pages/admin/AulaEdit';
import Perfil from '../pages/user/Perfil';
import AlterarSenha from '../pages/user/AlterarSenha';
import Simulados from '../pages/admin/Simulados';
import SimuladosCreate from '../pages/admin/SimuladosCreate';
import SimuladosEdit from '../pages/admin/SimuladosEdit';
import Calendario from '../pages/admin/Calendario';
import SuporteTecnico from '../pages/admin/SuporteTecnico';
import Avisos from '../pages/admin/Avisos';
import SuporteTecnicoArtigos from '../pages/admin/SuporteTecnicoArtigos';
import SuporteTecnicoArtigosCreate from '../pages/admin/SuporteTecnicoArtigosCreate';
import PlanoDeEstudosEdit from '../pages/admin/PlanoDeEstudosEdit';
import Noticias from '../pages/admin/Noticias';
import ModulosCursosExtra from '../pages/admin/ModulosCursosExtra';
import Livros from '../pages/admin/Livros';

const AppRoutes: React.FC = () => (
  <>
    <ScrollToTop>
      <Switch>
        <Layout>
          <Route path="/" exact component={Dashboard} />
          <Route path="/plano-de-estudos" exact component={PlanoDeEstudos} />
          <Route path="/plano-de-estudos/novo" exact component={PlanoDeEstudosCreate} />
          <Route path="/plano-de-estudos/:id/editar" exact component={PlanoDeEstudosEdit} />
          <Route path="/modulos" exact component={Modulos} />
          <Route path="/vestibulares" exact component={ModulosVestibulares} />
          <Route path="/cursos-extras" exact component={ModulosCursosExtra} />
          <Route path="/calendario" exact component={Calendario} />
          <Route path="/cadastrar-aula/" exact component={AulaCreate} />
          <Route path="/cadastrar-aula/:slug" exact component={AulaCreate} />
          <Route path="/aula/editar/:clusterSlug/:id" exact component={AulaEdit} />
          <Route path="/aula/:slug" exact component={Aula} />
          <Route path="/aulas/:slug" exact component={Aulas} />
          <Route path="/aluno/:id" exact component={Aluno} />
          <Route path="/alunos" exact component={Alunos} />
          <Route path="/filmes" exact component={Filmes} />
          <Route path="/livros" exact component={Livros} />
          <Route path="/materiais" exact component={Materiais} />
          <Route path="/simulados/questoes" exact component={SimuladosCreate} />
          <Route path="/simulados/editar/:questionId" exact component={SimuladosEdit} />
          <Route path="/simulados" exact component={Simulados} />
          <Route path="/avisos" exact component={Avisos} />
          <Route path="/suporte" exact component={SuporteTecnico} />
          <Route path="/noticias" exact component={Noticias} />
          <Route path="/suporte/artigos/:slug" exact component={SuporteTecnicoArtigos} />
          <Route path="/suporte/artigos/:slug/novo" exact component={SuporteTecnicoArtigosCreate} />
          
          <Route path="/perfil" exact component={Perfil} />
          <Route path="/alterar-senha" exact component={AlterarSenha} />
        </Layout>
      </Switch>
    </ScrollToTop>
  </>
);

export default AppRoutes;
