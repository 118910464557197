import styled from 'styled-components';

export const Container = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
  height: 124px;
  transition: all .2s ease-in-out;
  
  img {
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in-out;
    transition-delay: 1250ms;
    animation: in .80s;
  }
  
  .vertical {
    width: 70px;
    opacity: 1;
    visibility: visible;
    transition: all .2s ease-in-out;
    transition-delay: 1250ms;
  }
  
  .horizontal {
    width: 210px;
    opacity: 1;
    visibility: visible;
    transition: all .2s ease-in-out;
    transition-delay: 1250ms;
  }

`;
