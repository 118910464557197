import React, { useEffect, useState } from 'react';

import { Container, RatingContent } from './styles';
import { Link } from 'react-router-dom';
import { FiBook } from 'react-icons/fi';
import { Star } from 'phosphor-react';

interface ICardBookThumbnailProps {
  id: string;
  name: string;
  duration: number;
  rating: number;
  year: string | number;
  cover_url: string;
  description?: string;
  height?: string;
  isHighlight?: boolean;
  vote_average: number;
}

const CardBookThumbnail: React.FC<ICardBookThumbnailProps> = ({ id, name, duration, rating, year, cover_url, description, height, vote_average, isHighlight }) => {

  const [voteAverage, setVoteAverage] = useState(vote_average);

  useEffect(() => {
    setVoteAverage(vote_average);
  }, [vote_average]);

  return (
    <Container to={`/sasabooks/${id}`} cover_url={cover_url} height={height} style={ !isHighlight ? { cursor: 'pointer' } : {} }>
      <main>
        <section>
          <RatingContent>
            <div>
              <Star weight="fill" size={18} />
              <p>{Number(String(voteAverage)).toFixed(1)}</p>
            </div>
          </RatingContent>
        </section>
        {!isHighlight && (
          <div>
            <FiBook strokeWidth={2.5} size={24} />
          </div>
        )}
      </main>
      {!isHighlight && (
        <footer>
          <Link to={`/sasabooks/${id}`}>{name}</Link>
        </footer>
      )}

    </Container>
  );
}

export default CardBookThumbnail;
