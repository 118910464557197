import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { useTheme } from '../../../hooks/theme';

import api from '../../../services/api';

import { Chats, House, MagnifyingGlass } from 'phosphor-react';

import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';

import { Container, HeaderSection, SearchContainer, SectionContainer1 } from './styles';

import CardCommunityCategory from './CardCommunityCategory';
import InputDebounceAdapter from '../../../components/InputDebounceAdapter';
import LoaderBIO from '../../../components/Loader';

export interface IPaginationProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: ({
    id: string;
    cluster_id: string;
    title: string;
    slug: string;
    order: string;
    thumbnail: string;
    thumbnail_url: string;
    topicsCount: number;
    description: string;
    created_at: string;
    updated_at: string;
  })[];
}

const Comunidade: React.FC = () => {
  const {theme} = useTheme();

  const [forumsData, setForumsData] = useState<IPaginationProps>({} as IPaginationProps);
  const [search, setSearch] = useState('');

  // Loading
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const mainContentSection: any = document.querySelector('#main-scroll');

    if (mainContentSection && theme === 'dark') {
      mainContentSection.style.background = '#0c3333';
    }

    return () => {
      if (mainContentSection) {
        mainContentSection.style.background = 'unset';
      }
    }
  }, [theme]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(`/forums`, {
          params: {
            pageIndex: 0,
            pageSize: 99,
            search: search,
          }
        });

        console.log(response.data);
        setForumsData(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [search]);
  
  const handleFilterAchievement = useCallback((value: string) => {
    console.log(value);
    setSearch(value);
  }, []);

  return (
    <Container>
      {loading && (<LoaderBIO isVisible />)}
      
      <HeaderSectionContainer padding="1rem 2rem">
        <h1><Chats weight="duotone" /> Comunidade</h1>
        <p>Crie tópicos e discuta com seus colegas sobre biologia e vestibulares!</p>
      </HeaderSectionContainer>

      <HeaderSection>
        <Breadcrumbs
          icon={House}
          separator="/"
          route={[{ title: 'Comunidade', link: '/comunidade' }]}
        />
        <SearchContainer>
          <InputDebounceAdapter
            type="text"
            debounceTimeout={300}
            minLength={3}
            onChange={(event) => handleFilterAchievement(event.target.value)}
            placeholder="Busque por uma categoria..."
          />
          <MagnifyingGlass weight="duotone" />
        </SearchContainer>
      </HeaderSection>

      <SectionContainer1>
        {forumsData.records?.map((cluster) => (
          <CardCommunityCategory 
            key={cluster.id}
            description={cluster.description}
            image={cluster.thumbnail_url} 
            link={`/comunidade/` + cluster.slug}
            title={cluster.title}
            topicsCount={cluster.topicsCount}
          />
        ))}
      </SectionContainer1>
    </Container>
  );
}

export default Comunidade;