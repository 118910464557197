import React, { useCallback, useState, PropsWithChildren } from 'react';

import { File, FileDoc, FileJpg, FilePdf, FilePng, FileSearch } from 'phosphor-react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import toast from 'react-hot-toast';

import { Container, ListFilesContainer } from './styles';

import { formatBytes } from '../../../utils/formatBytes';

interface DropZoneProps extends DropzoneOptions, PropsWithChildren {
  setFilesData: (files: File[]) => void;
  maxSize?: number;
  icons?: boolean;
  placeHolder?: string;
  isMulti?: boolean;
  accept: any;
}

const DropZone: React.FC<DropZoneProps> = ({ setFilesData, maxSize, icons, placeHolder, isMulti, accept }) => {

  const [files, setFiles] = useState<File[]>([]);

  /* retornar icone a depender da extensão do arquivo  */
  const getIcon = useCallback((file: File) => {
    const extension = file.name.split('.').pop();

    switch (extension) {
      case 'pdf':
        return <FilePdf size={24} weight="duotone" />;
      case 'png':
        return <FilePng size={24} weight="duotone" />;
      case 'jpg':
      case 'jpeg':
        return <FileJpg size={24} weight="duotone" />;
      case 'doc':
      case 'docx':
        return <FileDoc size={24} weight="duotone" />;
      default:
        return <File size={24} weight="duotone" />;
    }
  }, []);

  const onDrop = useCallback((acceptedFiles: any, fileRejections: any) => {

    console.log('acceptedFiles', acceptedFiles);
    console.log('fileRejections', fileRejections);

    if (fileRejections.length > 0) {
      fileRejections.forEach((file: any) => {

        if (file.errors[0].code === 'file-invalid-type') {
          toast.error(`O tipo do arquivo (.${file.file.name.split('.').pop()}) não é permitido.`, {
            duration: 5000,
            position: 'bottom-right',
            className: 'toast-samuquinha',
          });
        }

        if (file.errors[0].code === 'file-too-large') {
          toast.error(`O arquivo ${file.file.name} ultrapassa o tamanho máximo permitido de ${formatBytes(maxSize || 0)}.`, {
            duration: 5000,
            position: 'bottom-right',
            className: 'toast-samuquinha',
          });
        }

        if (file.errors[0].code === 'too-many-files') {
          toast.error(`O número máximo de arquivos permitidos é 1.`, {
            duration: 5000,
            position: 'bottom-right',
            className: 'toast-samuquinha',
          });
        }
      });

      return;
    }

    if (!isMulti) {
      setFiles([]);
    }

    setFiles((oldFiles) => [...oldFiles, ...acceptedFiles]);

    setFilesData(acceptedFiles);

  }, [maxSize, setFilesData, isMulti]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    maxSize,
    multiple: isMulti ? true : false,
    accept: accept,
  });

  return (
    <>
      <Container>
        <section className="container">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {files.length > 0 ? (
              files.map((file: any, index) => (
                <ListFilesContainer key={index}>
                  <ul className="files-list">
                    <li>
                      {getIcon(file)}  {file.path} - {formatBytes(file.size)}
                    </li>
                  </ul>
                </ListFilesContainer>
              ))
            ) : (
              <>
                {icons ? (
                  <div style={{ marginBottom: '1rem' }}>
                    <FileJpg size={60} weight="duotone" />
                    <FilePng size={60} weight="duotone" />
                    <FilePdf size={60} weight="duotone" />
                    <FileDoc size={60} weight="duotone" />
                  </div>
                ) : (
                  <FileSearch size={60} weight="duotone" style={{ marginBottom: '1rem' }} />
                )}
                <p>{placeHolder ? placeHolder : `Arraste ou clique para escolher o${isMulti ? 's' : ''} arquivo${isMulti ? 's' : ''}`}</p>
                {maxSize && <h5>(máximo {maxSize / (1024 * 1024)}MB)</h5>}
              </>
            )}
          </div>
        </section>
      </Container>
    </>
  );
}

export default DropZone;