import ReactModal from 'react-modal';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalHome = styled(ReactModal)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  margin: 0 auto;
  width: fit-content;
`;

export const ModalAlertContainer = styled.section`
  margin-bottom: 1rem;
`;

export const Poster = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -1.5rem;

  img {
    border-radius: 16px;
    max-width: 550px !important;
    max-height: 550px !important;
    user-select: none;

    @media (max-width: 768px) {
      max-width: 400px !important;
      max-height: 400px !important;
    }

    @media (max-width: 425px) {
      max-width: 300px !important;
      max-height: 300px !important;
    }
  }
`;

export const MessageContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 280px;
  padding: 1rem 0;
  width: 550px;
  border-radius: 12px;
  z-index: 0;

  @media (max-width: 768px) {
   scale: 0.8;
  }
  
  @media (max-width: 425px) {
    scale: 0.6;
  }
  
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: #8f7ff9;
    border-radius: 12px;
  }

  > main {
    width: 100%;
    padding: 0 2rem 0 0;
    z-index: 1;

    > aside {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: row;
      
      > hgroup {
        position: relative;
        background: #ffffff;
        border-radius: .4em;
        width: 295px;
        padding: 1em;
        z-index: 2;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 0;
          height: 0;
          border: 26px solid transparent;
          border-right-color: #ffffff;
          border-left: 0;
          border-bottom: 0;
          margin-top: -13px;
          margin-left: -26px;
        }

        > h1 {
          font-size: 18px;
          margin-bottom: 0.5rem;
          color: #3e3f5e;
        }

        > p {
          font-size: 14px;
          color: #3e3f5e;
          line-height: 1.25;
          text-align: justify;
        }
      }
    }

    > p {
      z-index: 99;
    }

    > footer {
      padding: 0 3rem;
      position: absolute;
      width: 100%;
      bottom: -4px;
      border-radius: 16px;
    }
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  z-index: 9999999 !important;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  background: #ffffff50;
  backdrop-filter: blur(10px);
  border: 2px solid #ffffff90;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #ffffff;

  &:hover {
    background: #ffffff60;
  }
`;
