import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from '../../../hooks/auth';

import api from '../../../services/api';

import { Book, House } from 'phosphor-react';
import toast from 'react-hot-toast';

import LoaderSpinner from '../../../components/LoaderSpinner';
import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import NotFoundRegister from '../../../components/NotFoundRegister';
import CardModuleCheckbox from '../../../components/CardModuleCheckbox';
import ModalConfirm from '../../../components/ModalConfirmation';

import { Container, HeaderSection, ModalContainer, SectionContainer1 } from './styles';

import { IStudyPlanProps } from '../../../interfaces/StudyPlan';

const PlanoDeEstudos: React.FC = () => {
  const { user, updateUser } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const urlSearchParans = location.search.split('?')[1];
  
  console.log(urlSearchParans);

  // Loading
  const [loading, setLoading] = useState(false);

  // Data
  const [studyPlansData, setStudyPlansData] = useState<IStudyPlanProps[]>([]);

  // Modals
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState<IStudyPlanProps>({} as IStudyPlanProps);

  

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/study-plans`);

        console.log(response.data);
        setStudyPlansData(response.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleSelectStudyPlan = useCallback((currentPlan: IStudyPlanProps) => {
    setCurrentSelectedPlan(currentPlan);
    setModalConfirmationOpen(true);
  }, []);

  const hadleChangeStudyPlan = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.post(`/profiles/study-plans`, {
        study_plan_id: currentSelectedPlan.id,
      });

      if (response.status === 200) {
        updateUser(response.data);
        setModalConfirmationOpen(false);
        setCurrentSelectedPlan({} as IStudyPlanProps);
        toast.success('Plano de estudos alterado com sucesso!', {
          className: 'toast-samuquinha',
          duration: 4000,
          position: 'bottom-right',
        });
        history.push(`/plano-de-estudos/${currentSelectedPlan.slug}/!`)
      }

      console.log(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }, [currentSelectedPlan, updateUser, history]);

  useEffect(() => {
    if (user.study_plan && !!!urlSearchParans) {
      history.push(`/plano-de-estudos/${user.study_plan.slug}/!`)
    }
  }, [user.study_plan, urlSearchParans, history]);

  return (
    <>
      
      <Container>
        <HeaderSectionContainer padding="1rem 2rem">
          <h1><Book weight="duotone" /> Planos de estudo</h1>
          <p>Você pode alterar o seu plano de estudo conforme a sua necessidade e tempo.</p>
        </HeaderSectionContainer>

        <HeaderSection>
          <Breadcrumbs
            icon={House}
            separator="/"
            route={[{ title: 'Planos de Estudo', link: '/plano-de-estudos?change=true' }]}
          />
        </HeaderSection>

        <SectionContainer1 style={loading ? { height: '250px', overflow: 'hidden' } : {}}>
          {loading && (<LoaderSpinner blur={true} />)}
          <form>
            {studyPlansData.length > 0 && studyPlansData?.map((plan: IStudyPlanProps) => (
              user?.study_plan?.id === plan.id ? (
                <CardModuleCheckbox
                  key={plan.id}
                  id={plan.id}
                  inputName="study-plan"
                  title={plan.title}
                  description={plan.description}
                  link={`/plano-de-estudos/${plan.slug}/!`}
                  checked={user?.study_plan?.id === plan.id}
                  background={plan.thumbnail_url}
                  countLessons={plan.countLessons}
                  totalDuration={plan.totalDuration}
                  onClick={() => history.push(`/plano-de-estudos/${plan.slug}/!`)}
                />
              )
              : (
                <CardModuleCheckbox
                  key={plan.id}
                  id={plan.id}
                  inputName="study-plan"
                  title={plan.title}
                  description={plan.description}
                  link={`/plano-de-estudos/${plan.slug}/!`}
                  checked={user?.study_plan?.id === plan.id}
                  background={plan.thumbnail_url}
                  countLessons={plan.countLessons}
                  totalDuration={plan.totalDuration}
                  onClick={() => handleSelectStudyPlan(plan)}
                />
              )
            ))}
          </form>
        </SectionContainer1>

        {(studyPlansData?.length === 0 && !loading) && (
          <NotFoundRegister />
        )}
      </Container>

      {/* START MODAL CONFIRMATION */}
      <ModalConfirm
        confirmText="Confirmar"
        confirmButtonColor="#2cc292"
        cancelText="Cancelar"
        onConfirm={hadleChangeStudyPlan}
        isOpen={modalConfirmationOpen}
        isLoading={false}
        setIsOpen={() => setModalConfirmationOpen((oldValue) => !oldValue)}
        closeModal={() => setModalConfirmationOpen(false)}
        size="sm"
      >
        <ModalContainer>
          <NotFoundRegister 
            description={user.study_plan ? 'Deseja confirmar a alteração do seu plano de estudos?' : 'Deseja escolher este plano de estudos?'} 
            emoji="🤷" 
            style={{ margin: '-2rem 0 -0.5rem 0' }}
          />
          <p>Relaxa, você não perderá o seu progresso nas aulas que já foram assistidas, ok? 😊</p>
        </ModalContainer>
      </ModalConfirm>
      {/* END MODAL CONFIRMATION */}
    </>
  );
}

export default PlanoDeEstudos;