import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) { }

  /* MOBILE */
  @media (max-width: 480px) { }
`;

export const ContentGrid2 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  min-height: 200px;
  grid-template-columns: repeat(3, 1fr);

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > a {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 42px 32px;
    border-radius: 12px;
    text-decoration: none;
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    color: ${props => props.theme.colors.white};
    transition: all .2s ease-in-out;

    &:hover {
      transform: translateY(-.4rem);
    }

    > svg {
      margin-bottom: 1.4rem;
      color: ${props => props.theme.colors.purple};
    }

    > h1 {
      line-height: 1;
      font-size: 20px;
      margin-bottom: 1rem;
      color: ${props => props.theme.colors.textColor};
    }

    > p {
      color: ${props => props.theme.colors.muted};
      font-size: 12px;
      font-weight: 500;
      line-height: 1.4285714286em;
    }
  }

`;
