import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';

import LoaderBIO from '../../../components/Loader';
import CardDiscipline3D from '../../../components/CardDiscipline3D';
import Breadcrumbs from '../../../components/Breadcrumbs';
import InputDebounceAdapter from '../../../components/InputDebounceAdapter';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import NotFoundRegister from '../../../components/NotFoundRegister';

import {
  Container,
  HeaderSection,
  SectionContainer1,
  SearchContainer,
} from './styles';

import { IClusterProps } from '../../../interfaces/Cluster';
import { House, MagnifyingGlass, PlayCircle } from 'phosphor-react';

export interface IPaginationProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: IClusterProps[];
}

const Modulos: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [clustersData, setClustersData] = useState<IPaginationProps>(
    {} as IPaginationProps,
  );
  const [search, setSearch] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/clusters`, {
          params: {
            pageIndex: 0,
            pageSize: 99,
            search: search,
            type: 'main',
          },
        });

        console.log(response.data);
        setClustersData(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [search]);

  const handleFilterModule = useCallback((value: string) => {
    console.log(value);
    setSearch(value);
  }, []);

  return (
    <Container>
      {loading && <LoaderBIO isVisible />}
      <HeaderSectionContainer padding="1rem 2rem">
        <h1>
          <PlayCircle weight="duotone" /> Módulos
        </h1>
        <p>Curso completo de Biologia do zero ao avançado.</p>
      </HeaderSectionContainer>

      <HeaderSection>
        <Breadcrumbs
          icon={House}
          separator="/"
          route={[{ title: 'Módulos', link: '/modulos' }]}
        />
        <SearchContainer>
          <InputDebounceAdapter
            type="text"
            debounceTimeout={300}
            minLength={3}
            onChange={event => handleFilterModule(event.target.value)}
            placeholder="Busque por módulo..."
          />
          <MagnifyingGlass weight="duotone" />
        </SearchContainer>
      </HeaderSection>

      <SectionContainer1>
        {clustersData.records?.map(cluster => (
          <CardDiscipline3D
            key={cluster.id}
            to={`aulas/${cluster.slug}/!`}
            cardBackground="linear-gradient(to right, #71e7dd, #01fff2)"
            progress={cluster?.progress}
            icon={cluster.thumbnail_url}
            moduleName={cluster.title}
            alt={cluster.description}
          />
        ))}
      </SectionContainer1>

      {clustersData.records?.length === 0 && <NotFoundRegister />}
    </Container>
  );
};

export default Modulos;
