import React, { PropsWithChildren } from 'react';

import { Container } from './styles';

interface IHeaderSectionContainerProps extends PropsWithChildren {
  padding?: string;
  margin?: string;
  style?: React.CSSProperties;
}

const HeaderSectionContainer: React.FC<IHeaderSectionContainerProps> = ({ children, padding, margin, style, }) => {
  return (
    <Container padding={padding} margin={margin} style={style}>
      {children}
    </Container>
  );
}

export default HeaderSectionContainer;