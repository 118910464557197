import React, { useCallback, useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DataTableAdapter from '../../../components/DataTableAdapter';
import DropdownButton from '../../../components/DropdownButton';
import Button from '../../../components/Button';
import LoaderSpinner from '../../../components/LoaderSpinner';

import { Eye, House, ThumbsDown, ThumbsUp } from 'phosphor-react';
import { TableColumn } from 'react-data-table-component';
import { FiSettings } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { CardTableContainer, Container, HeaderSection, LessonInfoContainer } from './styles';

import api from '../../../services/api';


interface ISuportArticlesProps {
  id: string;
  section_id: string;
  title: string;
  body: string;
  slug: string;
  is_promoted: boolean;
  abstract: string;
  vote_count: number;
  vote_sum: number;
  views: number;
  created_at: string;
  updated_at: string;
  section: {
    slug: string;
  };
  reading_time: string;
}

interface ICategoriesProps {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  position: number;
  created_at: string;
  updated_at: string;
}

const SuporteTecnicoArtigos: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  // Data
  const [articlesResponse, setArticlesResponse] = useState<ISuportArticlesProps[]>([]);
  const [suporteCategories, setSuportCategories] = useState<ICategoriesProps>({} as ICategoriesProps);

  // Loaders
  const [loading, setLoading] = useState(false);


  const columns: TableColumn<ISuportArticlesProps>[] = [
    {
      name: 'Título',
      selector: row => row.title,
      cell: (row: ISuportArticlesProps) => (
        <>
          <span>{row.title}</span>
        </>
      ),
    },
    {
      name: 'Seção',
      selector: row => row.section.slug,
      cell: (row: ISuportArticlesProps) => (
        <>
          <span>{suporteCategories.name}</span>
        </>
      ),
    },
    {
      name: 'Data de criação',
      selector: row => row.created_at,
      cell: (row: ISuportArticlesProps) => (
        <>
          <span>{new Date(row.created_at).toLocaleString('pt-BR')}</span>
        </>
      ),
    },
    {
      name: '',
      selector: row => row.id,
      cell: (row: ISuportArticlesProps) => (
        <LessonInfoContainer>
          <span><Eye weight="bold" /> {row.views}</span>
          <span>
            <ThumbsUp weight="bold" /> {row.vote_count}
          </span>
        </LessonInfoContainer>
      ),
    }
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(`/sections/${slug}/articles`);

        const [sectionResponse, articlesResponse] = await Promise.all([
          api.get(`/sections/${slug}`),
          api.get(`/sections/${slug}/articles`),
        ]);

        setSuportCategories(sectionResponse.data);
        setArticlesResponse(response.data);

        console.log(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [slug]);

  return (
    <>
      <Container>

        {loading && (<LoaderSpinner />)}

        <HeaderSection>
          <Breadcrumbs
            icon={House}
            separator="/"
            padding="1rem 0"
            route={[
              { title: 'Central de ajuda', link: '/suporte' },
              { title: suporteCategories.name, link: `/suporte/artigos/${slug}` }
            ]}
          />
          <aside>
            <Link to={`/suporte/artigos/${slug}/novo`}>
              Cadastrar novo artigo
            </Link>
          </aside>
        </HeaderSection>

        <CardTableContainer>
          <DataTableAdapter
            title={`Artigos da seção ${suporteCategories.name}`}
            subTitle={''}
            columns={columns}
            data={articlesResponse}
            paginationServer={false}
            perPage={10}
          />
        </CardTableContainer>
      </Container>
    </>
  );
}

export default SuporteTecnicoArtigos;