import React, { PropsWithChildren } from 'react';

import { Container } from './styles';

const CardSeparator: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

export default CardSeparator;