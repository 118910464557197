import styled from 'styled-components';

interface ContainerProps {
  hasError: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;

  .mde-header {
    display: flex !important;
    align-items: center !important;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    border: none;
    border-radius: 4px 4px 0 0;
    background: ${({ theme }) => theme.cards.background};
    cursor: default;
    margin-bottom: 1rem;

    .mde-tabs {
      display: flex;
      flex-direction: row;
      margin-right: 1rem;
      gap: 0.5rem;

      button {
        color: ${({ theme }) => theme.specific.wysiwygButtons};
        border-radius: 6px;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 10px 16px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        text-transform: uppercase;
        font-weight: bold !important;

        &.selected {
          border: 1px solid ${({ theme }) => theme.specific.wysiwygButtons};
        }
      }
    }

    .svg-icon {
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: inherit;
      overflow: visible;
      vertical-align: -0.125em;
    }

    ul.mde-header-group {
      margin: 0;
      padding: 0 8px;
      list-style: none;
      display: flex;
      flex-wrap: nowrap;
      border-right: 1px solid ${({ theme }) => theme.cards.border};
      border-left: 1px solid ${({ theme }) => theme.cards.border};

      &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        border-right: none;
      }

      &.hidden {
        visibility: hidden;
      }
      
      li.mde-header-item {
        display: inline-block;
        position: relative;
        margin: 0 4px;
        button {
          text-align: left;
          cursor: pointer;
          height: 22px;
          padding: 4px;
          margin: 0;
          border: none;
          background: none;
          color: ${({ theme }) => theme.text};
          @keyframes tooltip-appear {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @mixin tooltip-animation {
            animation-name: tooltip-appear;
            animation-duration: 0.2s;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
          }
          &.tooltipped {
            &:hover::before {
              @include tooltip-animation();
              opacity: 0;
              position: absolute;
              z-index: 1000001;
              width: 0;
              height: 0;
              color: rgba(0, 0, 0, 0.8);
              pointer-events: none;
              content: '';
              border: 5px solid transparent;
              top: -5px;
              right: 50%;
              bottom: auto;
              margin-right: -5px;
              border-top-color: rgba(0, 0, 0, 0.8);
            }
            &:hover::after {
              @include tooltip-animation();
              font-size: 11px;
              opacity: 0;
              position: absolute;
              z-index: 1000000;
              padding: 5px 8px;
              color: #fff;
              pointer-events: none;
              content: attr(aria-label);
              background: rgba(0, 0, 0, 0.8);
              border-radius: 3px;
              right: 50%;
              bottom: 100%;
              transform: translateX(50%);
              margin-bottom: 5px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
  /**
   *  Editor
   */

  .react-mde-tabbed-layout {
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.secondary};
    padding: 1rem;
  }

  .svg-icon {
    color: ${({ theme }) => theme.specific.wysiwygButtons};
    font-size: 18px !important;
  }


  .mde-textarea-wrapper {
    position: relative;

    textarea.mde-text {
      color: ${({ theme }) => theme.colors.white};
      border-radius: 6px;
      background: ${({ theme }) => theme.colors.muted}10;
      border: 1px solid ${({ theme }) => theme.colors.muted}20 !important;
      width: 100%;
      padding: 16px;
      vertical-align: top;
      resize: vertical;
      overflow-y: auto;
      font-size: 14px;

      &::placeholder { 
        color: ${({ theme }) => theme.colors.white}80;
        opacity: 1; 
      }
    }
  }
  
  .react-mde {
    //box-shadow: ${({ theme }) => theme.shadow};
    * {
      box-sizing: border-box;
    }
    .invisible {
      display: none;
    }
  }
  /**
   *  Preview
   */
  .mde-preview {
    display: flex;
    .mde-preview-content {
      color: ${({ theme }) => theme.colors.white};
      border-radius: 6px;
      background: ${({ theme }) => theme.colors.muted}10;
      border: 1px solid ${({ theme }) => theme.colors.muted}20 !important;
      width: 100%;
      padding: 16px;
      font-size: 14px;

      p,
      blockquote,
      ul,
      ol,
      dl,
      table,
      pre {
        margin-top: 0;
        margin-bottom: 1.4em;
      }
      ul,
      ol {
        padding-left: 2em;
      }
      a {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
        transition: color 0.2s;
        &:hover {
          color: ${({ theme }) => theme.colors.white}95;
        }
      }
    }
  }
  /**
   *  Suggestions
   */
  ul.mde-suggestions {
    position: absolute;
    min-width: 180px;
    padding: 0;
    margin: 20px 0 0;
    list-style: none;
    cursor: pointer;
    background: #fff;
    border: 1px solid ${({ theme }) => theme.cards.background};
    border-radius: 3px;
    box-shadow: 0 1px 5px rgba(27, 31, 35, 0.15);
    li {
      padding: 4px 8px;
      border-bottom: 1px solid #e1e4e8;
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:hover,
      &[aria-selected='true'] {
        color: white;
        background-color: #0366d6;
      }
    }
  }
  /**
   *  Attachments
   */
  .react-mde .image-tip {
    user-select: none;
    display: flex !important;
    padding: 1rem 4px 0 6px;
    margin: 0;
    font-size: 13px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.muted};
    background-color: ${({ theme }) => theme.cards.background};
    border-radius: 0 0 4px 4px;
    position: relative;
    cursor: pointer;

    .image-input {
      min-height: 0;
      visibility: hidden;
      width: 100% !important;
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px;
    }
  }
`;