import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -14px;

  > h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  margin: 2rem 0;
  justify-content: space-between;
`;

export const ModalContainer = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -2rem;
  margin-top: 1rem;
  z-index: -1;

  > div {

    > p {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }


  > p {
    font-size: 11px;
    color: ${({ theme }) => theme.colors.muted};
    margin-bottom: 4rem;
  }
`;

export const SectionContainer1 = styled.section`
  position: relative;
  
  > form {
    position: relative;
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
  
    /* DESKTOP */
    @media (max-width: 1400px) {
      grid-template-columns: repeat(2, 1fr);
    }
  
    /* TABLET MINI */
    @media (max-width: 950px) {
      grid-template-columns: repeat(1, 1fr);
    }
  
    /* MOBILE */
    @media (max-width: 600px) {
  
    }
  }
`;