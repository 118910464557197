import React from 'react';
import { Link } from 'react-router-dom';

import TooltipAdapter from '../TooltipAdapter';
import { FiChevronRight } from 'react-icons/fi';


import { Container } from './styles';
import { Info } from 'phosphor-react';

interface ICardModuleProps {
  id: string;
  background: string;
  title: string;
  description: string;
  link: string;
  linkTitle?: string;
  icon?: string;
  checked?: boolean;
}

const CardModule: React.FC<ICardModuleProps> = ({ background, title, linkTitle, description, link, icon, checked }) => {

  return (
    <>
      <Container background={background} to={link}>
        <header>
          <div>
            <h2>{title}</h2>
            <aside>
              <TooltipAdapter
                alt="Você pode alterar o seu Planos de Estudos <br /> conforme a sua necessidade e tempo ⏰"
                place="top"
                cursor="help"
                textColor="#dadbe7"
              >
                <Info size={36} weight="duotone" />
              </TooltipAdapter>
            </aside>
          </div>
          <p>{description}</p>
        </header>
        <footer>
          <Link to={link}>{linkTitle || 'Acessar Plano de estudos'} <FiChevronRight /></Link>
        </footer>
      </Container>
    </>
  );
}

export default CardModule;