import ReactModal from 'react-modal';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  width: 100%;
  height: 100%;

  > header {
    display: flex;
    align-items: row;
    justify-content: space-between;
    align-items: center;

    > div {
      color: ${props => props.theme.colors.gray};

      > h2 {
        font-size: 1rem;
        color: ${props => props.theme.colors.white};
      }
    
      > p {
        color: ${props => props.theme.colors.gray};
        font-size: 14px;
      }

      > svg {
        color: ${props => props.theme.colors.muted}80;
      }
    }
  }

  > main {

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      
      .progress-graph {
        transform: rotate(190deg);
      }

      > footer {
        background: #296d62;
        color: #adafca;
        display: flex;
        flex-direction: column;
        margin: -4rem 1rem 0px 1rem;
        width: 100%;
        max-width: 260px;
        padding: 18px 22px;
        border-radius: ${props => props.theme.radius.secondary};
        backdrop-filter: blur(2px);
        text-align: center;
        font-weight: 300;
        z-index: 9;

        > p {
          font-size: 11px;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;

          > svg {
            margin: 0 0.25rem;
          }
        }

        > main {
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-items: center;
        }
    
        .value-percent {
          display: flex;
          position: relative;
          font-weight: 600;
          color: #fff;
          font-size: 2rem;

          &:after {
            position: absolute;
            content: '%';
            color: #72fcce;
            font-size: 15px;
            right: -14px;
            bottom: 5px;
          }
        }
    
        .value {
          width: 45px;
          text-align: center;
        }
      }
    }
  }
`;

export const PesonContainer = styled.section`
  position: absolute;
  z-index: 0;
  margin-bottom: 4rem;
  width: 250px;
  height: 250px;
`;

export const PersonIcon = styled.section`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  transition: background-image 1.2s ease-out;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
`;

export const Modal = styled(ReactModal)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  margin: 0 auto;
  width: fit-content;
`;

export const ModalContainer = styled.section`
  margin-bottom: 1rem;
`;

export const InnerSlider = styled.div`
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > header {
    position: absolute;
    top: 4rem;
    right: 1rem;
    z-index: 9;
  }

  > img {
    margin-top: 3rem;
    user-select: none;

    &.silhete {
      filter: brightness(0%) !important;
    }
  }

  > div {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 170px;
    margin-top: -50px;
    user-select: none;
    
    &:before {
      content: "";
      display: block;
      position: absolute;
      transform: translateY(10%);
      width: 150%;
      left: -25%;
      height: 280px;
      z-index: -1;
      border-radius: 100%/100%;
      background: #ffffff40;
    }

    > span {
      padding: 8px 16px;
      border-radius: 8px;
      
      > h4 {
        display: flex;
        text-align: center;
        width: 100%;
        justify-content: center;
        user-select: none;
        margin-top: -20px;
        margin-bottom: 3px;
      }
  
      > p {
        display: flex;
        font-size: 11px;
        font-weight: 400;
      }
    }

  }
  

`;

export const ModalInner = styled.main`
  position: relative;
  align-items: center;
  height: fit-content;
  width: fit-content;
  border-radius: 16px;

  .swiper {
    width: 300px;
    height: 420px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;

    padding: 32px;
  }

  .swiper-slide:nth-child(1n) {
    background-color: #bd6ad4;
  }

  .swiper-slide:nth-child(2n) {
    background-color: #2ddba7;
  }

  .swiper-slide:nth-child(3n) {
    background-color: #1ca67b;
  }

  .swiper-slide:nth-child(4n) {
    background-color: #977dd2;
  }

  .swiper-slide:nth-child(5n) {
    background-color: #8257d3;
  }

  .swiper-slide:nth-child(6n) {
    background-color: #b99df3;
  }

  .swiper-slide:nth-child(7n) {
    background-color: #9755d6;
  }

  .swiper-slide:nth-child(8n) {
    background-color: #ddafff;
  }

  .swiper-slide:nth-child(9n) {
    background-color: #c292ff;
  }

  .swiper-slide:nth-child(10n) {
    background-color: #b74ed3;
  }
`;


export const CloseBtn = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  z-index: 9999999 !important;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  background: #ffffff50;
  backdrop-filter: blur(10px);
  border: 2px solid #ffffff90;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #ffffff;

  &:hover {
    background: #ffffff60;
  }
`;
