import React from 'react';

import { AvatarContainer, Container, BadgeLevel } from './styles';

import { IAvatarProps } from './interface';

const Avatar: React.FC<IAvatarProps> = ({ url, size, radius, title, badgeValue, badgeColor = '#aa14cb' }) => {
  return (
    <AvatarContainer>
      {badgeValue && <BadgeLevel url={url} size={size} radius={radius} style={{ background: badgeColor }}>{badgeValue}</BadgeLevel>}
      <Container url={url} size={size} radius={radius}>
        <img src={url} alt={title} />
      </Container>
    </AvatarContainer>
  );
}

export default Avatar;