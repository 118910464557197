import React, { useEffect, useRef, useState, PropsWithChildren } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

import { Container } from './styles';

const DropdownButton: React.FC<PropsWithChildren> = ({ children }) => {
  const dropdownButtonRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (!dropdownButtonRef.current || !event.target) {
        return;
      }

      if (dropdownButtonRef.current.contains(event.target as HTMLDivElement)) {
        setIsOpen(!isOpen);
      } else {
        setIsOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <Container isOpen={isOpen} ref={dropdownButtonRef}>
      <button type="button">
        Ações
        <MdKeyboardArrowDown size={20} />
      </button>

      <div>{children}</div>
    </Container>
  );
};

export default DropdownButton;
