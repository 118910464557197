import React, { useLayoutEffect, useState } from 'react';

import { useAuth } from '../../../hooks/auth';
import { useForm } from 'react-hook-form';

import api from '../../../services/api';

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";
import toast from 'react-hot-toast';
import { Key } from 'phosphor-react';

import Accordion from '../../../components/Accordion';
import Button from '../../../components/Button';
import Input from '../../../components/Form/Input';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import { Center } from '../../../layout/components/Content/styles';

import { Container, FormProfile, Line1, LineButton, MainContent } from './styles';

import { maxWidthContent } from '../../../utils/maxWidthContent';
import MainHeaderProfile from '../../../components/MainHeaderProfile';
import { loadConfettiAllPage } from '../../../utils/useConfetti';

const AlterarSenha: React.FC = () => {
  const { user, updateUser } = useAuth();

  // Validation
  const schema = Joi.object({
    old_password: Joi.string().min(6).required().messages({ '*': 'Informe uma senha válida.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
    password: Joi.string().min(6).required().messages({ '*': 'Informe um senha válida.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
    password_confirmation: Joi.any().valid(Joi.ref('password')).required().messages({ 'any.only': 'As senhas não coincidem' }),
  });
  
  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));
      return joiResolver(schema)(data, context, options);
    },
  });

  // Modals

  // Loaders
  const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);

  

  useLayoutEffect(() => {
    const mainContentSection: any = document.querySelector('#main-scroll');
    const mainContentCenter: any = document.querySelector('#content-center');
    const mainFooter: any = document.querySelector('#main-footer-container');

    if (mainContentSection && mainContentCenter && mainFooter) {
      mainContentSection.style.padding = '0px';
      mainContentCenter.style.maxWidth = '100%';
      mainFooter.style.display = 'none';
    }

    return () => {
      if (mainContentSection && mainContentCenter) {
        mainContentSection.style.padding = '2.35rem 1rem 2rem 2rem';
        mainContentCenter.style.maxWidth = maxWidthContent;
        mainFooter.style.display = 'block';
      }
    }
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoadingUpdateProfile(true);

      const response = await api.patch(`/profiles/password`, { /* FIXME: essa rota nao existe */
        old_password: data.old_password,
        password: data.password,
        password_confirmation: data.password_confirmation,
      });

      toast.success('Senha atualizada com suecesso!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });

      loadConfettiAllPage();

      updateUser({ ...user, ...response.data });
      reset({
        old_password: '',
        password: '',
        password_confirmation: '',
      });

      console.log(response.data);
    } catch (error: any) {
      console.log(error);
      setLoadingUpdateProfile(false);

      if (error.response.data.error === 'old-password-does-not-match') {
        toast.error('Ops! A senha atual informada não está correta. 😐', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });

        return;
      }

      toast.error('Ops! Ocorreu um erro ao atualizar a senha. 😐', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });

    } finally {
      setLoadingUpdateProfile(false);
    }
  });

  return (
    <>
      <Container>
        <MainHeaderProfile />

        <Center>
          <MainContent>
            <aside>
              <Accordion />
            </aside>
            <main>
              <section>
                <FormProfile>
                  <header>
                    <h1><Key size={20} weight="duotone" /> Alterar senha</h1>
                  </header>
                  <form onSubmit={onSubmit}>
                    <Line1>
                      <div>
                        <Input register={register} name="old_password" type="password" label="Senha atual" autoComplete="off" />
                        <MessageErrorValidator>{errors?.old_password?.message as string}</MessageErrorValidator>
                      </div>
                      <div>
                        <Input register={register} name="password" type="password" label="Nova senha" autoComplete="off"/>
                        <MessageErrorValidator>{errors?.password?.message as string}</MessageErrorValidator>
                      </div>
                      <div>
                        <Input register={register} name="password_confirmation" type="password" label="Confirme a nova senha" autoComplete="off" />
                        <MessageErrorValidator>{errors?.password_confirmation?.message as string}</MessageErrorValidator>
                      </div>
                    </Line1>
                    <LineButton>
                      <Button type="submit" color="primary" loading={loadingUpdateProfile} disabled={loadingUpdateProfile}>Alterar senha</Button>
                    </LineButton>
                  </form>
                </FormProfile>
              </section>
            </main>
          </MainContent>
        </Center>
      </Container>
    </>
  );
}

export default AlterarSenha;