import styled from 'styled-components';

import { maxWidthContent } from '../../../utils/maxWidthContent';

const MAIN_HEADER_HEIGHT = 800;


export const Container = styled.div`
  .slick-track {
    display: flex !important;
    gap: 1rem;
  }

  .slick-slider {
    position: relative !important;
  }
`;

interface IMainHeaderProps {
  cover_url: string;
}

export const MainHeader = styled.section<IMainHeaderProps>`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: ${props => props.theme.cards.primary};

  &:after {
    content: "";
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    background: #052824;
    background: linear-gradient(180deg, #052824 0%, rgba(255,255,255,0) 100%);
    z-index: 0;
  }

  > section {
    width: 104%;
    background-image: linear-gradient(transparent, ${props => props.theme.background.tertiary} 95%), url(${props => props.cover_url});
    background-position: center center;
    background-size: cover;

    > header {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      max-width: ${maxWidthContent};
      height: ${MAIN_HEADER_HEIGHT}px;
      margin: 0 auto;
      padding: 2rem 0 0 0;

      @media (max-width: 767px) {
        height: 100%;
      }
  
      > main {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        z-index: 9;
        
        @media (max-width: 1670px) {
          padding: 0 2rem;
        }
        
        @media (max-width: 768px) {
          padding: 0 1rem;
        }

        > section:nth-child(1) {
          width: 100%;
          display: flex;
          flex-direction: row;
          padding: 1rem 2rem;
          gap: 2rem;
          
          @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            min-height: 500px;
          }
          
          @media (max-width: 478px) {
            padding: 1rem;
          }

          > aside {
            height: 400px;
            width: 250px;

            @media (max-width: 767px) {
              display: none;
            }
          }

          > main {
            width: 50%;
            
            @media (max-width: 767px) {
              width: 100%;
            }

            > h1 {
              font-size: 50px;
              color: #ffffff;
              font-weight: 800;
              
              @media (max-width: 767px) {
                font-size: 24px;
              }
            }
            
            > p {
              color: #ffffff;
            }
          }

        }
  
        > section:nth-child(2) {
          width: 100%;
          padding: 1rem 2rem;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
`;

export const ModalContainer = styled.section`
  margin: 0 1rem 1rem 0;

  > h2 {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  
  > ul {
    margin-left: 1.25rem;
    font-size: 13px;
    margin-bottom: 1rem;
  }

  > p {
    font-size: 12px;
  }

  > button {
    margin: 1rem 0 0 0;
    width: 100%;
  }
`;

export const FakeBg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
`;

export const RatingContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;

  > p {
    font-size: 11px;
    margin-top: 10px;
    color: #ffffff80;

    &.duration {
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;

    }
  }
`;

export const ChannelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;

  > aside {
    display: flex;
    flex-direction: column;

    > header {
  
      > h3 {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0.5rem;
      }
    }
    
    > main {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
    }
  }

  > main {
    display: flex;
    align-items: center;
    
    > a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 0.8rem;
      border-radius: 6px;
      text-decoration: none;
      color: #ffffff;
      font-size: 12px;
      font-weight: 600;
      background: #ffffff20;
      border: 1px solid ${props => props.theme.cards.border};
      box-shadow: ${props => props.theme.shadows.primary};
      border-radius: ${props => props.theme.radius.secondary};
      transition: all 0.2s ease-in-out;

      &:hover {
        background: #ffffff30;
      }
    }
  }
`;

export const TrailerButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Description = styled.p`
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 1rem;
  line-height: 1.5;
`;

export const MainContent = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  padding: 0 2rem;

  /* TABLET */
  @media (max-width: 1300px) {
    padding: 0 1rem;
  }

  @media (max-width: 767px) {
    margin-top: -50px;
  }
`;

export const SliderContainer = styled.div`
  position: absolute;
  width: 100%;
/* 
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 150px;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to left,transparent 0%, #090029 100%);
    z-index: 999999;
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 150px;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(to right,transparent 0%, #090029 100%);
    z-index: 999999;
  }
 */
`;


export const SamplePrevArrow = styled.div`
  /* display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: green;
  position: absolute;
  height: 2rem;
  width: 2rem;
  top: 50%;
  left: 0;
  z-index: 999999999; */
  display: none;
`;

export const SampleNextArrow = styled.div`
  /* display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: blue;
  position: absolute;
  height: 2rem;
  width: 2rem;
  top: 50%;
  right: 0;
  z-index: 999999999; */
  display: none;
`;

export const CardContainer = styled.main`
  margin-bottom: 2rem;
`;

export const PLayerLogo = styled.img`
  width: 32px;
  border-radius: 4px;
`;