import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  height: 100%;
  overflow: hidden;
  
  > header {
    display: flex;
    flex-direction: column;
    background: ${props => props.theme.gradients.rankingGradient};
    border-bottom: 1px solid ${props => props.theme.cards.border};
    padding: 0 1.5rem 0 1.5rem;  
    
    > figure {
      z-index: 9;
      margin-top: -70px;
      margin-bottom: -15px;
      user-select: none;
      
      > img {
        width: 100%;
        user-select: none;
        //height: 155px;
      }
    }
  }
  
  > main {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem 3rem 1.5rem;
    min-height: 300px;
    height: 250px;
    overflow-y: scroll;

    @media (max-width: 768px) {
      position: relative;
      padding: 1rem 1.5rem 3rem 1rem;
      min-height: 610px;

      > div:nth-child(1) {
        margin-top: 4rem;
      }
    }

    @media (max-width: 500px) {
      position: relative;
      padding: 1rem 1.5rem 3rem 1rem;
      min-height: 450px;

      > div:nth-child(1) {
        margin-top: 4rem;
      }
    }

    ::-webkit-scrollbar {
      width: 22px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 8px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: ${props => props.theme.colors.muted}50;
    }

    > header {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: -1rem;
      margin-bottom: 1.5rem;
      margin-right: -1rem;
      position: sticky;
      top: 0;
      background: ${props => props.theme.cards.primary};
      z-index: 9;
      padding: 0 0 1.5rem 0;
      background: red;

      > div {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > h2 {
          font-size: 16px;
        }
  
        > main {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          z-index: 1;
  
          > svg {
            margin-right: 0.75rem;
            position: absolute;
            color: ${props => props.theme.colors.muted}80;
            z-index: -1;
          }
        
          > select {
            padding: 0.5rem 2.5rem 0.5rem 0.7rem;
            background: ${props => props.theme.cards.primary};
            background: transparent;
            border: 1px solid ${props => props.theme.cards.border};
            box-shadow: ${props => props.theme.shadows.primary};
            border-radius: ${props => props.theme.radius.primary};
            color: ${props => props.theme.colors.white};
            width: 120px;
            height: 38px;
            font-size: 12px;
        
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
        
            > option {
              color: ${props => props.theme.colors.white};
              background: ${props => props.theme.modal.background};
            }
          }
        }
      }
    }

    > div {
      display: grid;
      align-items: center;
      grid-template-columns: 0.8fr 250px 1fr 1fr;
      width: 100%;
      padding: 0 0 0 1rem;

      &:last-child {
        margin-bottom: 1.5rem;
      }

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        margin: 0.5rem 0;
        
        > img {
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 6px;
        }
      }

      > h5:nth-child(4) {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem -0.5rem -3rem 0;
  z-index: 999;

  > div {

    > svg {
      color: ${props => props.theme.colors.muted}80;
    }
  }


  > main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    > svg {
      margin-right: 0.75rem;
      position: absolute;
      color: ${props => props.theme.colors.muted}80;
      z-index: -1;
    }
  
    > select {
      padding: 0.5rem 2.5rem 0.5rem 0.7rem;
      background: ${props => props.theme.cards.primary};
      background: transparent;
      border: 1px solid ${props => props.theme.cards.border};
      box-shadow: ${props => props.theme.shadows.primary};
      border-radius: ${props => props.theme.radius.primary};
      color: ${props => props.theme.colors.white};
      width: 120px;
      height: 42px;
  
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
  
      > option {
        color: ${props => props.theme.colors.white};
        background: ${props => props.theme.modal.background};
      }
    }
  }

  > div {
    z-index: 999;
    margin: unset;
  }
`;

export const AvatarsContainer = styled.div`
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  > section {
    display: flex;
    flex-direction: column;
  }

  > section:nth-child(1) {
    display: flex;
    justify-content: center;
    padding-top: 65px;
    z-index: 99;

    > h3 {
      position: absolute;
      margin-top: 280px;
      font-size: 16px;
      font-weight: bold;
      line-height: 0.9;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100px;
      transform:  translateZ(0px) skewX(8deg);
      color: #fff;

      > span {
        font-size: 10px;
        font-weight: 500;
      }
    }
  }

  > section:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    padding-top: 15px;
    z-index: 99;

    > h3 {
      position: absolute;
      margin-top: 210px;
      font-size: 16px;
      font-weight: bold;
      line-height: 0.9;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 85px;
      color: #fff;

      > span {
        font-size: 10px;
        font-weight: 500;
      }
    }
  }

  > section:nth-child(3) {
    display: flex;
    justify-content: center;
    padding-top: 90px;
    z-index: 99;

    > h3 {
      position: absolute;
      margin-top: 260px;
      font-size: 16px;
      font-weight: bold;
      line-height: 0.9;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transform:  translateZ(0px) skewX(-8deg);
      color: #fff;

      > span {
        font-size: 10px;
        font-weight: 500;
      }
    }
  }
`;


export const Thead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0.75rem 1rem;
  margin-bottom: -26px;
  z-index: 999;
  width: 100%;
  color: #fff;
  background: #9047c1;
  border: 3px solid #ffffff1a;
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.secondary};
  
  > div {
    display: grid;
    align-items: center;
    grid-template-columns: 0.4fr 250px 1fr 1fr;
    width: 100%;
    
    > h5:nth-child(4) {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const Thead2 = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1rem 1rem;
  margin-bottom: -26px;
  z-index: 999;
  width: 100%;
  
  > div {
    display: grid;
    align-items: center;
    grid-template-columns: 0.8fr 250px 1fr 1fr;
    width: 100%;
    
    > h5:nth-child(4) {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const Footer = styled.footer`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 0;
  background: ${props => props.theme.colors.gray}20;
  backdrop-filter: blur(8px) saturate(80%);
  width: 100%;
  padding: 0.75rem 2.75rem 0.75rem 2.5rem;
  border-top: 1px solid ${props => props.theme.colors.gray}35;
  gap: 0.5rem;
  overflow: hidden;
  border-radius: 0 0 12px 12px;

> div {
    display: grid;
    align-items: center;
    grid-template-columns: 0.8fr 250px 1fr 1fr;
    width: 100%;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      
      > img {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 6px;
      }
    }

    > h5:nth-child(4) {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const HeaderMobile = styled.header`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  background: ${props => props.theme.colors.gray}20;
  backdrop-filter: blur(8px) saturate(80%);
  width: 100%;
  padding: 0.75rem 2.75rem 0.75rem 2.5rem;
  border-bottom: 1px solid ${props => props.theme.colors.gray}35;
  gap: 0.5rem;
  overflow: hidden;
  z-index: 999;
  padding: 1rem !important;
  border-radius: 12px 12px 0 0;

  > div {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > h2 {
      font-size: 16px;
    }

    > main {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      z-index: 1;

      > svg {
        margin-right: 0.75rem;
        position: absolute;
        color: ${props => props.theme.colors.muted}80;
        z-index: -1;
      }
    
      > select {
        padding: 0.5rem 2.5rem 0.5rem 0.7rem;
        background: ${props => props.theme.cards.primary};
        background: transparent;
        border: 1px solid ${props => props.theme.cards.border};
        box-shadow: ${props => props.theme.shadows.primary};
        border-radius: ${props => props.theme.radius.primary};
        color: ${props => props.theme.colors.white};
        width: 120px;
        height: 38px;
        font-size: 12px;
    
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
    
        > option {
          color: ${props => props.theme.colors.white};
          background: ${props => props.theme.modal.background};
        }
      }
    }
  }
`;