import React, { useCallback, useEffect, useState } from 'react';

import api from '../../services/api';

import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { CaretCircleRight, ChartLineUp, Confetti, Pencil, SmileySad } from 'phosphor-react';

import LoaderSpinner from '../LoaderSpinner';
import Button from '../Button';
import Modal from '../Modal';

import { CardQuestion, ExerciseContainer, FooterButtons, InputGroup, ModalContainer } from './styles';

import { loadConfettiAllPage } from '../../utils/useConfetti';

import { IExercisesProps } from '../../interfaces/exercise';
import ReportQuestion from '../ReportQuestion';
import BadgeExp from '../BadgeExp';
import exp from '../../utils/expValues';

interface IExerciseModuleProps {
  lessson_id: string;
}

const ExerciseModule: React.FC<IExerciseModuleProps> = ({ lessson_id }) => {

  // Loading
  const [loading, setLoading] = useState(true);
  const [loadingAnwser, setLoadingAnwser] = useState(false);

  // Data
  const [exercisesData, setExercisesData] = useState<IExercisesProps[]>([]);

  // States
  const [currentExercise, setCurrentExercise] = useState(0);
  const [checkedAnswer, setCheckedAnswer] = useState('');
  const [readOnly, setReadOnly] = useState(false);

  // Modal
  const [modalResolutionIsOpen, setModalResolutionIsOpen] = useState(false);
  const [modalFinalResultsIsOpen, setModalFinalResultsIsOpen] = useState(false);

  const handleChangeAnswer = useCallback((answer: string) => {
    if (!readOnly) {
      setCheckedAnswer(answer);
    }
  }, [readOnly]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(`/exercises/`, {
          params: {
            lesson_id: lessson_id,
          }
        });

        console.log(response.data);
        setExercisesData(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error('Erro ao carregar exercícios');
      }
      finally {
        setLoading(false);
      }
    })();
  }, [lessson_id]);

  useEffect(() => {
    const exercise = exercisesData[currentExercise];

    if (!exercise) return;

    if (exercise.user_result) {
      setReadOnly(true);
      setCheckedAnswer(exercise.user_result?.selected_option || '');
    }
    else {
      setReadOnly(false);
      setCheckedAnswer('');
    }
  }, [exercisesData, currentExercise]);

  const handleCheckAnswerIsCorrect = useCallback(async () => {
    if (!checkedAnswer) {
      toast.error('Selecione uma resposta antes de responder a questão. ', {
        duration: 6000,
        position: 'bottom-center',
      });

      return;
    }

    try {
      setLoadingAnwser(true);
      const response = await api.post(`exercises/${exercisesData[currentExercise].id}/results`, {
        selected_option: checkedAnswer,
      });

      const { exerciseResult } = response.data;

      setExercisesData(oldExercisesData => {
        const newExercisesData = oldExercisesData.map((exercise) => {
          if (exercise.id === exerciseResult.exercise_id) {
            exercise.user_result = exerciseResult;

            if (exerciseResult.is_correct) {
              loadConfettiAllPage();
            }
          }

          return exercise;
        });

        return newExercisesData;
      });

      setReadOnly(true);
    } catch (error: any) {
      console.log(error);
      setLoadingAnwser(false);
      if (error.response.data.error === 'exercise-already-answered') {
        toast.error('Ops! Este exercício já foi respondido.', {
          duration: 6000,
          position: 'bottom-center'
        });
      }
      else {
        toast.error('Ocorreu um erro ao enviar resposta.', {
          duration: 6000,
          position: 'bottom-center'
        });
      }
    }
    finally {
      setLoadingAnwser(false);
    }

  }, [exercisesData, currentExercise, checkedAnswer]);

  const handleNextQuestion = useCallback(() => {
    if (currentExercise < exercisesData.length - 1) {
      setCurrentExercise(currentExercise + 1);
    }
    else {
      toast.error('Não há mais exercícios nesta aula. 🤷‍♂️', {
        duration: 6000,
        position: 'bottom-center'
      });
    }
  }, [currentExercise, exercisesData.length]);

  const handleShowModalResults = useCallback(() => {
    setModalFinalResultsIsOpen(oldValue => !oldValue);
  }, []);

  return (
    <>
      <ExerciseContainer>
        {loading && (<LoaderSpinner />)}
          <BadgeExp
            exp={exp.exerciseCorrect}
            text="para cada questão correta"
            style={{
              margin: '-50px 0px 20px 0px',
              display: 'flex',
              justifyContent: 'flex-end',
              boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.75)',
            }}
          />
        <header>
          {exercisesData?.map((exercise, index) => (
            <CardQuestion
              className={
                (!exercise?.user_result ? 'blank' : '') ||
                (exercise?.user_result?.is_correct ? 'correct' : 'incorrect')
              }
              current={exercise.id === exercisesData[currentExercise].id}
              onClick={() => setCurrentExercise(index)}
            >
              <p>{index + 1}</p>
            </CardQuestion>
          ))}
        </header>
        <main>
          <div>
            <h5>{exercisesData[currentExercise]?.exam} ({exercisesData[currentExercise]?.year})</h5>
            {(exercisesData[currentExercise]?.content) && (<ReactMarkdown rehypePlugins={[rehypeRaw]}>{exercisesData[currentExercise]?.content?.replace(/\\s/g, '\n')}</ReactMarkdown>)}
          </div>
        </main>
        <footer style={readOnly ? { cursor: 'not-allowed' } : { cursor: 'default' }}>
          {exercisesData[currentExercise]?.options.map((option, index) => (
            option?.content && (
              <InputGroup
                readOnly={readOnly}
                value={option?.id}
                correctAnswer={exercisesData[currentExercise]?.user_result?.correct_option || null}
                checkedAnswer={exercisesData[currentExercise]?.user_result?.selected_option || checkedAnswer}
              >
                <input
                  id={`radio-${index}`}
                  name={`radio-${index}`}
                  type="radio"
                  checked={checkedAnswer === option?.id}
                  onChange={() => { handleChangeAnswer(option?.id) }}
                />
                <label htmlFor={`radio-${index}`}>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{option?.content?.replace(/\\s/g, '\n')}</ReactMarkdown>
                </label>
              </InputGroup>
            )))}
        </footer>

        <ReportQuestion requestRoute={`/exercises/${exercisesData[currentExercise]?.id}/report`} questionId={exercisesData[currentExercise]?.id} />
      </ExerciseContainer>

      {!loading && (
        <FooterButtons>
          {!loadingAnwser ? (
            <Button
              color="primary"
              onClick={handleCheckAnswerIsCorrect}
              disabled={readOnly}
              className={(exercisesData[currentExercise]?.user_result?.is_correct) ? 'correct-cursor' : (exercisesData[currentExercise]?.user_result?.is_correct === false) ? 'incorrect-cursor' : ''}
            >
              {exercisesData[currentExercise]?.user_result != null ?
                (exercisesData[currentExercise].user_result?.is_correct ?
                  (<><Confetti size={20} weight="duotone" /> Você acertou</>) :
                  (<><SmileySad size={20} weight="duotone" /> Você errou</>)) :
                'Responder questão'}
            </Button>
          ) : (
            <Button color="primary" disabled>
              Validando resposta...
            </Button>
          )}

          {(readOnly && exercisesData[currentExercise]?.resolution) && (
            <Button
              color="primary"
              className="success"
              onClick={() => setModalResolutionIsOpen(true)}
            >
              <Pencil size={20} weight="duotone" />
              Ver resolução
            </Button>
          )}

          {(currentExercise < exercisesData.length - 1 && readOnly) && (
            <Button
              color="purple"
              onClick={handleNextQuestion}
            >
              Próxima questão
              <CaretCircleRight size={20} weight="duotone" />
            </Button>
          )}

          {((currentExercise === exercisesData.length - 1) && !exercisesData.find((exercise) => exercise.user_result === null)) && (
            <Button
              color="purple"
              onClick={handleShowModalResults}
            >
              <ChartLineUp size={20} weight="duotone" />
              Visualizar resultados
            </Button>
          )}
        </FooterButtons>
      )}

      {/* START MODAL SHOW RESOLUTION */}
      <Modal
        isOpen={modalResolutionIsOpen}
        setIsOpen={() => setModalResolutionIsOpen((oldValue) => !oldValue)}
        closeModal={() => setModalResolutionIsOpen(false)}
        size="lg"
        height="500px"
        title="Resolução"
      >
        <ModalContainer>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{exercisesData[currentExercise]?.resolution?.replace(/\\s/g, '\n')}</ReactMarkdown>
        </ModalContainer>
      </Modal>
      {/* END MODAL SHOW RESOLUTION */}

      {/* START MODAL SHOW FINAL RESULTS */}
      <Modal
        isOpen={modalFinalResultsIsOpen}
        setIsOpen={() => setModalFinalResultsIsOpen((oldValue) => !oldValue)}
        closeModal={() => setModalFinalResultsIsOpen(false)}
        size="md"
        height="520px"
        title="Resultados"
      >
        <ModalContainer>
          <main>
            <h1>{exercisesData.filter((exercise) => exercise.user_result?.is_correct).length / exercisesData.length * 100 > 49 ? '🎉' : '😥'}</h1>
            <p>
              Você acertou {exercisesData.filter((exercise) => exercise.user_result?.is_correct).length} de {exercisesData.length} questões!
            </p>
          </main>
        </ModalContainer>
      </Modal>
      {/* END MODAL SHOW FINAL RESULTS */}
    </>
  );
}

export default ExerciseModule;