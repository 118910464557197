import styled from 'styled-components';

export const Container = styled.div`
`;

export const SectionContainer1 = styled.section`
  position: relative;
  width: 100%;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  padding: 2rem;
`;

export const HeaderSection = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
  }
`;

export const StatusBadge = styled.span`
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
`;

export const Poster = styled.div`
  margin-bottom: 2rem;
  position: relative;

  > img {
    border-radius: 6px;
  }
`;

export const BtnRemove = styled.div`
  position: absolute;
  right: -15px;
  top: -15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  background: #ff000080;
  padding: 1rem;
  backdrop-filter: blur(4px);
  cursor: pointer;
`;


export const ListFilm = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  padding: 0.85rem 1rem;
  border-radius: 8px;

  &:hover {
    background: ${props => props.theme.input.border};
  }

  > aside {
    display: flex;
    
    > img {
      width: 30px;
      border-radius: 4px;
    }
  }

  > main {

    > h2 {
      font-size: 16px;
    }
  }
`;

export const Separator = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
  gap: 1rem;
  width: 100%;

  > h1 {
    font-size: 30px;
    margin-bottom: 1rem;
  }

  > h2 {
    font-size: 14px;
    font-weight: 500;
  }

  > button {
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
  }

  > section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > main {
      position: relative;
      width: 100%;
      padding-left: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;

      > div {
        width: 200px;
      }
    }
  }

`;

export const LineButton = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-columns: 1fr;
  margin-top: -2rem;

  > section {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    gap: 1rem;
    width: 100%;
    background: ${props => props.theme.colors.muted}20;
    cursor: pointer;
    user-select: none;
    padding: 1.2rem;
    border: 3px dashed ${props => props.theme.colors.muted}40;
    margin-bottom: 1rem;
    border-radius: 6px;
    transition: all .2s ease-in-out;

    &:hover {
      background: ${props => props.theme.colors.muted}40;
    }
  }

  > button {
    margin-top: 2rem;
    height: 60px;
  }
`;

export const Line1 = styled.section`  
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 2fr;
`;

export const Line2 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr;
`;

export const Line4 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  grid-template-columns: 1fr;
`;
