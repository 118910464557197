import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import toast from 'react-hot-toast';
import { ChatCenteredDots, House, MagnifyingGlass } from 'phosphor-react';
import { formatDistance, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import InputDebounceAdapter from '../../../components/InputDebounceAdapter';
import NotFoundRegister from '../../../components/NotFoundRegister';
import Pagination from '../../../components/Pagination';
import LoaderSpinner from '../../../components/LoaderSpinner';
import AwesomeButtonAdapter from '../../../components/AwesomeButtonAdapter';
import Modal from '../../../components/Modal';
import Input from '../../../components/Form/Input';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import WysiwygInnerModal from '../../../components/WysiwygInnerModal';
import AvatarProgress from '../../../components/AvatarProgress';

import {
  AsideIcon,
  ButtonNewTopicContainer,
  Container,
  HeaderSection,
  HeaderSectionContainerInner,
  Line1,
  Line2,
  Line3,
  Main,
  ModalContainer,
  PaginationContainer,
  SectionContainer1,
  SectionContainer2,
  SectionContainerHeader,
  TopicContainer,
} from './styles';

import { loadConfettiAllPage } from '../../../utils/useConfetti';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';

import { ITopicsProps } from '../../../interfaces/Topics';
import { IClusterProps } from '../../../interfaces/Cluster';

export interface IForumProps {
  id: string;
  cluster_id: string;
  title: string;
  slug: string;
  order: string;
  thumbnail: string;
  description: string;
  created_at: string;
  updated_at: string;
  cluster?: IClusterProps;
  topics: ITopicsProps;
}

const ComunidadeTopicos: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();

  // Validation
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log(
        'validation result',
        await joiResolver(schema)(data, context, options),
      );
      return joiResolver(schema)(data, context, options);
    },
  });

  // Joi Validation Schema
  const schema = Joi.object({
    title: Joi.string().min(5).required().messages({
      '*': 'Informe um título válido.',
      'string.min': `O campo deve ter no mínimo {#limit} caracteres`,
    }),
    description: Joi.string().min(5).max(1500).required().messages({
      '*': 'Informe uma descrição válida.',
      'string.min': `O campo descrição deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo descrição deve ter no máximo {#limit} caracteres`,
    }),
  });

  // Modals
  const [modalCreateTopicIsOpen, setModalCreateTopicIsOpen] = useState(false);

  // Loading
  const [loading, setLoading] = useState(false);

  // Data
  const [forumData, setForumData] = useState<IForumProps>({} as IForumProps);
  const [topicsData, setTopicsData] = useState<ITopicsProps>(
    {} as ITopicsProps,
  );

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  //Filter
  const [search, setSearch] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/forums/${slug}`, {
          params: {
            pageIndex: currentPage - 1,
            pageSize: 10,
            search: search,
          },
        });

        console.log(response.data);

        setForumData(response.data);
        setTopicsData(response.data?.topics);

        //pagination
        setShowingTo(response.data?.topics.showingTo);
        setShowingFrom(response.data?.topics.showingFrom);
        setMaxPages(response.data?.topics.maxPages);
        setTotalRecords(response.data?.topics.totalRecords);
      } catch (error) {
        setLoading(false);
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [slug, currentPage, search]);

  const handleFilterAchievement = useCallback((value: string) => {
    console.log(value);
    setSearch(value);
  }, []);

  const onSubmit = handleSubmit(async data => {
    try {
      console.log(data);
      const response = await api.post(`/forums/${slug}/topics`, {
        title: capitalizeFirstLetter(data.title),
        content: capitalizeFirstLetter(data.description),
        lesson_id: null,
      });

      console.log(response.data);

      if (response.status === 200) {
        toast.success('Tópico cadastrado com sucesso!', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });

        history.push(`/comunidade/${slug}/topicos/${response.data.id}`);

        loadConfettiAllPage();
      }

      reset();
      setModalCreateTopicIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error('Ops! Algo deu errado.', {
        position: 'bottom-center',
        duration: 6000,
      });
    }
  });

  return (
    <>
      <Container>
        <HeaderSectionContainer padding="1rem 2rem">
          <HeaderSectionContainerInner>
            <AsideIcon>
              <img src={forumData?.cluster?.thumbnail_url} alt="" />
            </AsideIcon>
            <Main>
              <h1>{forumData.title}</h1>
              <p>{forumData.description}</p>
            </Main>
          </HeaderSectionContainerInner>
        </HeaderSectionContainer>

        <HeaderSection>
          <Breadcrumbs
            icon={House}
            separator="/"
            route={[
              { title: 'Comunidade', link: '/comunidade' },
              { title: forumData.title, link: forumData.slug },
            ]}
          />
          <AwesomeButtonAdapter
            type="primary"
            ripple={true}
            onPress={() => setModalCreateTopicIsOpen(!modalCreateTopicIsOpen)}
          >
            Criar novo tópico{' '}
            <ChatCenteredDots size={32} color="#aa14cb" weight="duotone" />
          </AwesomeButtonAdapter>
        </HeaderSection>

        <SectionContainer1>
          <main>
            <MagnifyingGlass weight="duotone" size={20} />
            <InputDebounceAdapter
              type="text"
              debounceTimeout={300}
              minLength={3}
              onChange={event =>
                handleFilterAchievement(String(event.target.value))
              }
              placeholder="Antes de criar um novo tópico, pesquise se a sua dúvida já foi respondida anteriormente..."
            />
          </main>
        </SectionContainer1>

        <SectionContainerHeader>
          <aside>
            <h5>Tópico</h5>
          </aside>
          <aside>
            <h5>Respostas</h5>
          </aside>
          <aside>
            <h5>Última interação</h5>
          </aside>
        </SectionContainerHeader>

        <SectionContainer2
          style={loading ? { height: '250px', overflow: 'hidden' } : {}}
        >
          {loading && <LoaderSpinner />}

          {topicsData?.records?.map(topic => (
            <TopicContainer key={topic.id}>
              <aside>
                <div>
                  <Link to={`/comunidade/${slug}/topicos/${topic.id}`}>
                    <h1>
                      {topic.is_new && <span>Novo</span>}
                      {topic.title}
                    </h1>
                  </Link>
                  <p>
                    Criado por:
                    <img
                      src={topic.author?.avatar_url}
                      alt=""
                    />
                    {topic.author?.show_name} — há{' '}
                    {formatDistance(parseISO(topic.created_at), new Date(), {
                      locale: ptBR,
                    })}
                  </p>
                </div>
              </aside>

              <main>
                <p>{topic.answers_count}</p>
              </main>
              {topic.last_answer ? (
                <footer>
                  <div>
                    <AvatarProgress
                      percent={topic.last_answer?.author?.current_exp || 0}
                      badgeValue={topic.last_answer?.author?.level || 0}
                      size={80}
                      url={topic.last_answer?.author.avatar_url}
                    />
                  </div>
                  <div>
                    <p>
                      <span>Última interação</span>
                      <br /> {topic.last_answer?.author.show_name}{' '}
                    </p>
                  </div>
                </footer>
              ) : (
                <footer>
                  <div>
                    <AvatarProgress
                      percent={topic?.author?.current_exp || 0}
                      badgeValue={topic?.author?.level || 0}
                      size={70}
                      url={topic.author?.avatar_url}
                    />
                  </div>
                  <div>
                    <p>
                      <span>Última interação</span>
                      <br /> {topic.author?.show_name}
                    </p>
                  </div>
                </footer>
              )}
            </TopicContainer>
          ))}

          {topicsData?.records?.length === 0 && (
            <>
              <NotFoundRegister
                emoji="😢"
                description={
                  search.length > 0
                    ? `Nenhum registro encontrado para a busca "${search}".`
                    : `Nenhum registro encontrado.`
                }
              />
              <ButtonNewTopicContainer>
                <AwesomeButtonAdapter
                  type="primary"
                  ripple={true}
                  onPress={() =>
                    setModalCreateTopicIsOpen(!modalCreateTopicIsOpen)
                  }
                >
                  Criar novo tópico em "{forumData.title}"
                </AwesomeButtonAdapter>
              </ButtonNewTopicContainer>
            </>
          )}
        </SectionContainer2>

        {!loading && topicsData.records && topicsData.records.length > 0 && (
          <PaginationContainer>
            <p>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} tópicos`}</p>
            {totalRecords !== 0 && (
              <Pagination
                count={maxPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </PaginationContainer>
        )}
      </Container>

      {/* MODAL CREATE TOPIC */}

      <Modal
        isOpen={modalCreateTopicIsOpen}
        setIsOpen={() => setModalCreateTopicIsOpen(!modalCreateTopicIsOpen)}
        closeModal={() => setModalCreateTopicIsOpen(false)}
        size="lg"
        title="Criar novo tópico"
      >
        <ModalContainer>
          <form onSubmit={onSubmit}>
            <Line1>
              <Input
                register={register}
                name="title"
                type="text"
                label="Título da postagem *"
                autoComplete="off"
              />
              {errors.title && (
                <MessageErrorValidator>
                  {errors.title.message as string}
                </MessageErrorValidator>
              )}
            </Line1>
            <Line2>
              <WysiwygInnerModal
                maxLength={1500}
                placeholder="Formule melhor sua pergunta aqui..."
                setResult={values => {
                  setValue('description', values);
                }}
              />
              {errors.description && (
                <MessageErrorValidator>
                  {errors.description.message as string}
                </MessageErrorValidator>
              )}
            </Line2>
            <Line3>
              <AwesomeButtonAdapter type="primary" ripple={true}>
                Criar novo tópico{' '}
                <ChatCenteredDots size={32} color="#aa14cb" weight="duotone" />
              </AwesomeButtonAdapter>
            </Line3>
          </form>
        </ModalContainer>
      </Modal>

      {/* END MODAL CREATE TOPIC */}
    </>
  );
};

export default ComunidadeTopicos;
