import styled from 'styled-components';

import badgeBg from '../../assets/badge.svg';

interface IAvatarProgressProps {
  url: string;
  size: number;
  title?: string;
  badgeValue?: string;
}

interface IPhotoProps {
  size: number;
  url: string;
}

export const AvatarContainer = styled.section<IAvatarProgressProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background: ${props => props.theme.colors.background};
  z-index: 0;
  user-select: none;
`;

export const BadgeLevel = styled.span<IPhotoProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 5px;
  right: 0px;
  width: ${props => props.size - (0.70 *  props.size)}px;
  height: ${props => props.size - (0.70 *  props.size)}px;
  border-radius: 50%;
  font-size: ${props => props.size - (0.90 *  props.size)}px;
  font-weight: 900;
  color: #fff;
  z-index: 9;
  background: url(${badgeBg}) no-repeat center;
`;

export const ProgressInnerAvatar = styled.div<IPhotoProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.url}); 
  background-color: ${props => props.theme.specific.avatarBorderColor};
  background-position: 0% -50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: ${props => props.size - (0.10 *  props.size)}px;
  height: ${props => props.size - (0.10 *  props.size)}px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
  border: 2px solid ${props => props.theme.specific.avatarBorderColor};
  transition: background .2s ease-in-out;
`;
