import React, { PropsWithChildren } from 'react';

import { useTheme } from '../hooks/theme';

import Header from './components/Header';
import Aside from './components/Aside';
import Content from './components/Content';

import { Grid } from './layout-styles';
import SupportFloatingBadge from '../components/SupportFloatingBadge';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { theme } = useTheme();

  return(
    <Grid id="grid" style={theme === 'dark' ? {background:  '#0c3333', backgroundImage: 'radial-gradient(at 99% 99%, hsl(162.00, 77%, 30%) 0, transparent 59%), radial-gradient(at 82% 65%, hsl(218.00, 39%, 11%) 0, transparent 55%)'} : { background: '#f7fafc' }}>
      <Header />
      <Aside />
      <Content>
        {children}
      </Content>
      <SupportFloatingBadge />
    </Grid>
  );
}

export default Layout;