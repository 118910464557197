import React, { useRef, useState, useEffect } from 'react';
import ReactMde, { SaveImageHandler } from 'react-mde';
import ReactMarkdown from 'react-markdown';
import { fromBuffer } from 'file-type';

import api from '../../services/api';

import { Container } from './styles';
import toast from 'react-hot-toast';
import rehypeRaw from 'rehype-raw';

interface EditorProps {
  name: string;
  minEditorHeight?: number;
  setResult(value: string): void;
  uploadRoute: string;
  defaultValue?: string;
}

const WysiwygEditor: React.FC<EditorProps> = ({ minEditorHeight, uploadRoute, setResult, defaultValue }) => {
  const editorRef = useRef(null);

  const [content, setContent] = useState('');
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write');

  useEffect(() => {
    setResult && setResult(content);
  }, [content, setResult]);

  useEffect(() => {
    defaultValue && setContent(defaultValue);
  }, [defaultValue]);

  const saveImage: SaveImageHandler = async function* (data: ArrayBuffer) {

    try {
      const fileType = await fromBuffer(data);
      console.log('data: ', data)

      if (!fileType) {
        throw new Error();
      }

      const arrayBufferView = new Uint8Array(data);

      const file = new File(
        [arrayBufferView],
        `${String(new Date().getTime())}.${fileType.ext}`,
        { type: fileType.mime },
      );

      console.log('file: ', file);

      const formData = new FormData();
      formData.append("file", file);

      const response = await api.patch(uploadRoute, formData);
      
      yield response.data;
      
      return true;
    } catch (error: any) {

      console.log('erro::  ', error.response.data.error);

      if (error.response.data.error === 'file-size-too-big') {
        toast.error('Arquivo muito grande (MAX 1MB)', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });

        return false;
      }

      toast.error('Ocorreu um erro no upload, tente novamente mais tarde.', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });

      console.log(error);
      yield '';
      return false;
    }
  };

  return (
    <Container hasError={false} style={{ marginBottom: '24px' }}>
      <ReactMde
        ref={editorRef}
        value={content}
        onChange={setContent}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        toolbarCommands={[
          ['bold', 'italic', 'strikethrough'],
          ['link', 'image'],
          ['quote'],
          ['unordered-list', 'ordered-list'],
        ]}
        minEditorHeight={minEditorHeight}
        minPreviewHeight={minEditorHeight}
        generateMarkdownPreview={markdown => {
          return Promise.resolve(
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {markdown?.replace(/\\s/g, '\n')}
            </ReactMarkdown>
          );
        }}
        l18n={{
          write: 'Escrever',
          preview: 'Visualizar',
          pasteDropSelect:
            'Anexe arquivos arrastando, clicando ou colando aqui. (Máx.: 1MB).',
          uploadingImage: 'Carregando imagem...',
        }}
        childProps={{
          writeButton: {
            tabIndex: -1,
          },
        }}
        paste={{ saveImage }}
      />
    </Container>
  );
};

export default WysiwygEditor;