import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Link)`
  position: fixed;
  right: -200px;
  top: 100px;
  padding: 1rem;
  width: 260px;
  border-top-left-radius: 32px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  background: ${({ theme }) => theme.colors.primary};
  z-index: 9999;
  transition: all .25s ease-in-out;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 425px) {
    display: none;
  }

  &:hover {
    transform: translateX(-190px);
  }

  > aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  > main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > p {
      font-size: 14px;
      color: #fff;
    }
  }
`;
