import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 1.5rem;
  right: 1.25rem;
  background: ${props => props.theme.colors.primary};
  border: 3px solid hsla(0,0%,100%,.16);
  padding: 10px 16px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  z-index: 9;
  transition: all 0.2s ease-in-out;
  animation: pulseAnimationCss 2s infinite;
  
  &:hover {
    background: ${props => props.theme.colors.tertiary};
  }

  @keyframes pulseAnimationCss {
    0% {
      -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}50;
      box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}50;
    }
    70% {
      -moz-box-shadow: 0 0 0 6px ${props => props.theme.colors.muted}10;
      box-shadow: 0 0 0 6px ${props => props.theme.colors.muted}10;
    }
    100% {
      -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}10;
      box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}10;
    }
  }
  
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.35rem;
  }
`;