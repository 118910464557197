import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import { Book, Exam, House } from 'phosphor-react';

import LoaderSpinner from '../../../components/LoaderSpinner';
import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import NotFoundRegister from '../../../components/NotFoundRegister';
import Pagination from '../../../components/Pagination';
import CardSimulatesProgress from '../../../components/CardSimulatesProgress';
import AwesomeButtonAdapter from '../../../components/AwesomeButtonAdapter';

import { Container, HeaderSection, SectionContainer1, PaginationContainer } from './styles';

export interface ISimulatedProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records: {
    id: string;
    user_id: string;
    total_hits: number;
    total_questions: number;
    is_finished: true,
    expires_at: string;
    created_at: string;
    updated_at: string;
    is_available: boolean;
  }[];
}

const Simulados: React.FC = () => {
  const history = useHistory();

  // Loading
  const [loading, setLoading] = useState(false);

  // Data
  const [simulatedData, setSimulatedData] = useState<ISimulatedProps>({} as ISimulatedProps);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/practices`, {
          params: {
            pageSize: 9,
            pageIndex: currentPage - 1,
          }
        });

        console.log(response.data);
        setSimulatedData(response.data);

        //pagination
        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [currentPage]);

  return (
    <Container>
      <HeaderSectionContainer padding="1rem 2rem">
        <h1><Book weight="duotone" /> Simulados</h1>
        <p>Faça simulados periodicamente e some pontos para o ranking!</p>
      </HeaderSectionContainer>

      <HeaderSection>
        <Breadcrumbs
          icon={House}
          separator="/"
          route={[{ title: 'Simulados', link: '/simulados' }]}
        />
        <aside>
          <AwesomeButtonAdapter
            type="primary"
            ripple={true}
            onPress={() => history.push('/simulados/novo')}
          >
            Gerar novo simulado <Exam size={32} color="#aa14cb" weight="duotone" />
          </AwesomeButtonAdapter>
        </aside>
      </HeaderSection>

      <SectionContainer1 style={loading ? { height: '250px', overflow: 'hidden' } : {}}>
        {loading && (<LoaderSpinner blur={true} />)}

        {simulatedData.records?.map((simulate, index) => (
          <CardSimulatesProgress
            key={simulate.id}
            id={simulate.id}
            totalQuestions={simulate.total_questions}
            totalCorrects={simulate.total_hits}
            title={`Simulado ${index + 1}`}
            created_at={simulate.created_at}
            expires_at={simulate.expires_at}
            className={(index === 0 && currentPage === 1) ? 'pulseAnimation' : ''}
            isAvailable={simulate.is_available}
            isFinished={simulate.is_finished}
          />
        ))}
      </SectionContainer1>

      {simulatedData.records?.length === 0 && (
        <NotFoundRegister description="Você ainda não realizou nenhum simulado." />
      )}

      {!loading && (<PaginationContainer>
        <p>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} simulados`}</p>
        {totalRecords !== 0 && (
          <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        )}
      </PaginationContainer>)}
    </Container>
  );
}

export default Simulados;