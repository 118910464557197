import React, { PropsWithChildren } from 'react';

import Footer from '../Footer';

import { Container, InnerContainer, Center } from './styles';

const Content: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container id="main-scroll">
      <InnerContainer>
        <Center id="content-center">
          {children}
        </Center>
      </InnerContainer>

      <Footer />
    </Container>
  );
}

export default Content;