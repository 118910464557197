import { FileDoc, Image, Link, FilePdf, IconContext, IconProps, FileVideo, X } from 'phosphor-react';
import React from 'react';

import { ButtonDelete, Container } from './styles';

import { AttachmentsType } from '../../interfaces/Attachments';

interface ICardMaterialProps {
  href: string;
  title: string;
  type: AttachmentsType;
  onDelete?: () => void;
}

interface IcardVariationsProps {
  [key: string]: {
    color: string;
    icon: React.ComponentType<IconProps>;
    alt: string;
  };
}

const cardVariations: IcardVariationsProps = {
  pdf: {
    color: '#F44336',
    icon: FilePdf,
    alt: 'Visualizar pdf',
  },
  doc: {
    color: '#2196F3',
    icon: FileDoc,
    alt: 'Visulizar documento',
  },
  img: {
    color: '#4CAF50',
    icon: Image,
    alt: 'Abrir imagem',
  },
  link: {
    color: '#FF9800',
    icon: Link,
    alt: 'Abir link',
  },
  video: {
    color: '#9C27B0',
    icon: FileVideo,
    alt: 'Reproduzir vídeo',
  },
};


const CardMaterial: React.FC<ICardMaterialProps> = ({ title, type, href, onDelete }) => {
  return (
    <div style={{ position: 'relative' }}>
    {onDelete && <ButtonDelete onClick={onDelete}><X color="#ffffff" weight="bold" /></ButtonDelete>}
    <Container href={href} target="_blank" rel="noreferrer">
      <header style={{ background: cardVariations[type].color + '20' }}>
        <IconContext.Provider value={{ weight: 'duotone', size: 30, }}>
          {React.createElement(cardVariations[type].icon, { color: cardVariations[type].color })}
        </IconContext.Provider>
      </header>
      <main>
        <h2>{title}</h2>
      </main>
    </Container>
    </div>
  );
}

export default CardMaterial;