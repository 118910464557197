import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -14px;

  > h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: space-between;

  > aside {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    margin-left: 1rem;
  }

  @media (max-width: 828px) {
    flex-direction: column;
    align-items: flex-start;

    > div {
      margin-bottom: 1rem;
    }

    > aside {
      width: 100%;
      margin-left: 0;

      > div {
      width: 100%;
        > select, > input {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 425px) {
    > aside {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const CategotyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  > svg {
    margin-right: 0.75rem;
    position: absolute;
    color: ${props => props.theme.colors.muted}80;
  }
  
  > select {
    padding: 0.5rem 2.5rem 0.5rem 0.7rem;
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
    color: ${props => props.theme.colors.white};
    width: 200px;
    height: 42px;

    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    > option {
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.modal.background};
    }
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  > svg {
    margin-right: 1rem;
    position: absolute;
    color: ${props => props.theme.colors.muted}80;
  }

  > input {
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
    color: ${props => props.theme.colors.white};
    width: 320px;
    height: 42px;
  }
`;

export const SectionContainer1 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1220px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 865px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > div {
    background: ${props => props.theme.cards.primary};
    border-radius: ${props => props.theme.radius.primary};
    border: 1px solid ${props => props.theme.cards.border};
    padding: 2rem 1.5rem;
  }
`;

export const PaginationContainer = styled.section`
  position: relative;
  width: 100%;
  margin-top: 2rem;

  > p {
    position: relative;
    text-align: center;
    font-size: 12px;
  }
`;