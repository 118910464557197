import React from 'react';

import { Container } from './styles';

interface ILoaderProps {
  isVisible: boolean;
}

const LoaderBIO: React.FC<ILoaderProps> = ({ isVisible }) => {
  return (
    <Container isVisible={isVisible}>
      <main>
        <svg className="all" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 333 161" xmlSpace="preserve">
          <g>
            <g>
              <path className="st0" d="M160.03,161.12h-37v-97.7h37V161.12z"/>
              <path className="st1" d="M155.03,112.22h-37V2.82h37V112.22z"/>
              <path className="left-circle" d="M215.23,97.82c2.2,5.2,5.3,9.7,9.1,13.5c3.9,3.9,8.4,6.9,13.5,9.1c5.2,2.2,10.7,3.3,16.6,3.3
                c5.9,0,11.4-1.1,16.6-3.3c5.2-2.2,9.7-5.3,13.5-9.1c3.9-3.9,6.9-8.4,9.1-13.5c2.2-5.2,3.3-10.7,3.3-16.6s-1.1-11.4-3.3-16.6
                c-2.2-5.2-5.3-9.7-9.1-13.5c-3.9-3.9-8.4-6.9-13.5-9.1c-5.2-2.2-10.7-3.3-16.6-3.3l-0.1-36.4c10.9,0,21.1,2.1,30.7,6.2
                c9.6,4.2,18,9.8,25.1,16.9c7.2,7.1,12.8,15.4,17,25s6.2,19.9,6.2,30.7c0,10.9-2.1,21.1-6.2,30.7c-4.2,9.6-9.8,18-17,25.1
                s-15.5,12.7-25.1,16.9s-19.8,6.2-30.7,6.2s-21.1-2.1-30.7-6.2c-9.6-4.2-17.9-9.8-25-16.9c-7.1-7.1-12.7-15.4-16.9-25.1
                L215.23,97.82z"
              />
              <path className="right-circle" d="M215.23,97.82c2.2,5.2,5.3,9.7,9.1,13.5c3.9,3.9,8.4,6.9,13.5,9.1c5.2,2.2,10.7,3.3,16.6,3.3
                c5.9,0,11.4-1.1,16.6-3.3c5.2-2.2,9.7-5.3,13.5-9.1c3.9-3.9,6.9-8.4,9.1-13.5c2.2-5.2,3.3-10.7,3.3-16.6s-1.1-11.4-3.3-16.6
                c-2.2-5.2-5.3-9.7-9.1-13.5c-3.9-3.9-8.4-6.9-13.5-9.1c-5.2-2.2-10.7-3.3-16.6-3.3l-0.1-36.4c10.9,0,21.1,2.1,30.7,6.2
                c9.6,4.2,18,9.8,25.1,16.9c7.2,7.1,12.8,15.4,17,25s6.2,19.9,6.2,30.7c0,10.9-2.1,21.1-6.2,30.7c-4.2,9.6-9.8,18-17,25.1
                s-15.5,12.7-25.1,16.9s-19.8,6.2-30.7,6.2s-21.1-2.1-30.7-6.2c-9.6-4.2-17.9-9.8-25-16.9c-7.1-7.1-12.7-15.4-16.9-25.1
                L215.23,97.82z"
              />
              <path className="play" d="M282.73,85.72l-42.8,26.7c-0.9,0.5-1.8,0.8-2.8,0.8c-0.9,0-1.4-0.2-2.2-0.7c-1.7-0.9-2.4-2.7-2.4-4.7v-53.3
                c0-1.9,0.7-3.7,2.4-4.7c1.7-0.9,3.6-0.9,5.2,0.1l42.7,26.7c1.6,1,2.5,2.7,2.5,4.5C285.23,83.12,284.23,84.82,282.73,85.72
                L282.73,85.72z"/>
              <path className="st0" d="M37.13,38.12V2.82h5.2c0,0,20.5-0.7,31.4,8c10.3,8.1,12,13,14.8,20.6c5.6,15-1.2,33.9-10.6,42.7
                c-17.2,16.3-40.8,14.6-40.8,14.6v-35.4h5.4c3.6-1.7,11.8-0.3,11.8-7.8c0-6.2-8.3-7.4-12-7.4h-5.6H37.13z"/>
              <path className="st5" d="M100.63,83.22c-3.8-7-8.9-13.1-15.5-18c0,0-16-12.2-48-12.3v35.7h5h0.4h3.8c3.1,0,6.2,0.6,9.1,1.5
                s5.5,2.2,7.7,3.8c2.2,1.6,3.9,3.5,5.2,5.6s1.9,4.4,1.9,6.7c0,2.3-0.6,4.5-1.9,6.6s-3,3.9-5.2,5.5c-2.2,1.6-4.8,3.1-7.7,4
                s-5.9,1.7-9.1,1.7h-9.2v35.4h9.3c8.2,0,16-1.3,23.3-4.1s13.6-6.5,19.1-11.4c5.5-4.8,9.8-10.5,12.9-16.9c3.1-6.5,4.7-13.4,4.7-20.8
                C106.43,97.92,104.53,90.32,100.63,83.22z"/>
              <rect x="0.13" y="2.82" className="st6" width="37.1" height="156.6"/>
            </g>
          </g>
        </svg>
      </main>
    </Container>
  );
}

export default LoaderBIO;