import React, { useEffect, useState } from 'react';

import Breadcrumbs from '../../../components/Breadcrumbs';
import LoaderSpinner from '../../../components/LoaderSpinner';

import { FiHome } from 'react-icons/fi';

import Parser from 'rss-parser';

import { Container, Feed, HeaderSection, Notice, } from './styles';

interface IRRSFeedProps {
  title: string;
  description: string;
  link: string;
  pubDate: string;
  content: string;
  contentSnippet: string;
  guid: string;
  isoDate: string;
  categories: string[];
}

const Noticias: React.FC = () => {

  const [loading, setLoading] = useState(false);

  const [rssFeed, setRssFedd] = useState<IRRSFeedProps[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const parser = new Parser();
  
      const CORS_PROXYS = [
        'https://cors-anywhere.herokuapp.com/',
        'https://cors-proxy.htmldriven.com/?url=',
        'https://cors.bridged.cc/',
        'https://api.codetabs.com/v1/proxy/?quest=',
        'https://cors.io/?',
        'https://cors.io/?u=',
        'https://cors.io/?url=',
        'https://cors.io/',
        'https://cors.isomorphicgo.com/',
        'https://cors.now.sh/',
        'https://corsproxy.github.io/',
        'https://api.allorigins.win/get?url=',
        'https://api.allorigins.win/raw?url=',
        'https://api.allorigins.win/',
        'https://cors-proxy.htmldriven.com/',
        'https://www.allow-cors.org/',
      ];
  
      const BASE_URL = 'https://g1.globo.com/dynamo/educacao/rss2.xml';
      
      try {
        let feed = await parser.parseURL(CORS_PROXYS[0] + BASE_URL);
   
         for (let i = 0; i < CORS_PROXYS.length; i++) {
          const feed = await parser.parseURL(CORS_PROXYS[i] + BASE_URL);
          if (feed) {
            console.log(CORS_PROXYS[i] + BASE_URL);
            console.log(feed);
            break;
          }
        }
        
        setRssFedd(feed?.items as IRRSFeedProps[]);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, []);


  return (
    <>
      <Container>
        <HeaderSection>
          <Breadcrumbs
            icon={FiHome}
            separator="/"
            route={[{ title: 'Noticias', link: '/noticias' }]}
          />
        </HeaderSection>
        <Feed style={loading? { height: '400px', overflow: 'hidden', } : {}}>  
          {loading && <LoaderSpinner />}

          {rssFeed.map((item) => {
            const hasImage = item.content.includes('img');

            let imagesUrl: string[] = [];

            if (hasImage) {
              imagesUrl = item.content.match(/<img.*?src="(.*?)"/) || [];
            }

            return (
              <Notice>
                {imagesUrl[0] && (
                  <aside style={{ backgroundImage: `url(${imagesUrl[1]})`}}>
                    
                  </aside>
                )}
                <main>
                  <h1>{item.title}</h1>
                  <p>{item.contentSnippet.slice(0, 450)}...</p>
                  {/* format isoDate pt-br */}

                  
                  <a href={item.link} target="_blank" rel="noreferrer">Acessar noticia</a>
                </main>
                <footer>
                  <p>{new Date(item.isoDate).toLocaleDateString('pt-br')}</p>
                </footer>
              </Notice>
            )
          }
          )}
        </Feed>
      </Container>
    </>
  );
}

export default Noticias;