import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Link)`
  text-decoration: none;
  user-select: none;
  
  .rect-progress-bar-percent {
    display: none !important;
  }

  .inner-rect-bar {
    background: #79ffb8  !important;
    border-radius: 10px !important;
  }

  .progress-bar-rect-wrapper {

    > div {
      border-radius: 10px !important;
      overflow: hidden !important;
      background: #cbecf233 !important;
    }
  }
  
  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: ${props => props.theme.colors.white};
    cursor: pointer;
    transition: background .2s ease-in-out;

    &:hover {
      //background: ${props => props.theme.specific.cardGradient3D};
      box-shadow :  0 0 5px #11111120,
                    0 0 25px #11111120,
                    0 0 50px #11111120,
                    0 0 200px #11111120;
    }

    > header {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 1rem;
  
      > img {
        width: 100%;
      }

      > div {
        height: 223px;
        width: 100%;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 1440px) {
          height: 181px;
        }

        @media (max-width: 1024px) {
          height: 183px;
        }

        @media (max-width: 768px) {
          height: 277px;
        }

        @media (max-width: 320px) {
          height: 271px;
        }

        > figure, img {
          max-width: 250px;
          width: 100%;
          height: auto;
          border-radius: 12px;
        }
      }
    }
  
    > footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;
  
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 0.25rem;
        gap: 0.25rem;
  
        > h5 {
          font-size: 13px;
        }
  
        > p {
          font-size: 12px;
        }
      }
  
      > footer {
       
      }
    }
  }
`;
