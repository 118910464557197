import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ICardModuleProps {
  background: string;
}

export const Container = styled(Link)<ICardModuleProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  background: ${props => props.theme.cards.primary};
  backdrop-filter: ${props => props.theme.cards.backdropFilter};
  border-radius: ${props => props.theme.radius.primary};
  width: 100%;
  height: 100%;
  color: #ffffff;
  background: url(${props => props.background}) !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  transition: all 1.25s ease-in-out;
  min-height: 320px;
  text-decoration: none;

  @media (max-width: 768px) {
    flex: 1;
  }

  &:hover {
    cursor: pointer;
    
    &:before {
      opacity: 1;
      background-image: linear-gradient(90deg, #000, transparent);
      backdrop-filter: blur(2px);
    }

    a svg {
      margin-left: 0.25rem;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(90deg, #011212, transparent);
    transition: all 0.3s ease-in-out 0s;
    opacity: 0.8;
  }

  > header {

    > div {
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h2 {
        color: #ffffff;
        font-size: 2rem;
      }

      > aside {
        background: #0a2823;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        transition: all .2s ease-in-out;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        > svg {
          padding-top: 2px;
        }

        &:hover {
          background: ${props => props.theme.colors.primary};
          
        }
      }
    }
  
    > p { 
      color: ${props => props.theme.colors.gray};
    }
  }

  > footer {

    > a {
      display: flex;
      align-items: center;
      flex-direction: row;
      color: #fff;
      text-decoration: none;

      > svg {
        font-size: 18px;
        transition: all .2s ease-in-out;
      }
    }
  }


`;
