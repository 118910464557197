import styled from 'styled-components';

export const Container = styled.div`

`;

export const StatusBadge = styled.span`
  cursor: default;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  padding: 4px 8px;
  line-height: 1;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  vertical-align: baseline;
  border-radius: 4px;
`;

export const HeaderSection = styled.header`
  position: relative;
  height: 250px;
  padding: 2rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background: ${props => props.theme.cards.primary};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  backdrop-filter: blur(10px) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    background: #052824;
    background: linear-gradient(90deg, #000000 0%, rgba(255,255,255,0) 100%);
    z-index: 0;
  }

  > main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    z-index: 9;

    > div {

      > h2 {
        font-size: 1.5rem;
        color: #ffffff;
        margin-bottom: 0.5rem;
      }

      > h6 {
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
`;

export const MainContent = styled.section`
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
  padding: 0 2rem;
  grid-template-columns: 1fr;

  > main {

    > section {
      position: relative;
      display: flex;
      padding: 2rem;
  
      background: ${props => props.theme.cards.primary};
      border: 1px solid ${props => props.theme.cards.border};
      box-shadow: ${props => props.theme.shadows.primary};
      border-radius: ${props => props.theme.radius.primary};
    }
  }
`;


export const FormProfile = styled.section`
  width: 100%;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    > h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;

      > svg {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const Line1 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1rem;
`;

export const Line0Modal = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;

  label {
    display: flex;
  }
`;

export const FooterButtons = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
`;

export const Line1Modal = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 1.2fr;
  margin-bottom: 1rem;
`;

export const Line2 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 1rem;
  
  textarea {
    height: 182px;
  }
`;

export const TransactionsContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    > h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;

      > svg {
        margin-right: 0.5rem;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    width: 100%;
    background: ${props => props.theme.colors.muted}20;
    padding: 1rem;
    border-radius: 6px;

    > header {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr 0.75fr 0.5fr 0.5fr 0.5fr;
      gap: 1rem;

      > h4 {
        font-size: 14px;

        &.center {
          text-align: center;
        }
      }
    }

    > main {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 0.5fr 0.5fr 0.75fr 0.5fr 0.5fr 0.5fr;
      gap: 1rem;

      > div {
        color: ${props => props.theme.colors.muted};
        font-size: 12px;

        &.center {
          text-align: center;
        }
      }
    }
  }
`;

export const LoginLogsContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    > h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;

      > svg {
        margin-right: 0.5rem;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    width: 100%;
    background: ${props => props.theme.colors.muted}20;
    padding: 1rem;
    border-radius: 6px;

    > header {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
      gap: 1rem;

      > h4 {
        font-size: 14px;

        &.center {
          text-align: center;
        }
      }
    }

    > main {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
      gap: 1rem;

      > div {
        color: ${props => props.theme.colors.muted};
        font-size: 12px;

        &.center {
          text-align: center;
        }
      }
    }
  }
`;

export const LineButton = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-top: 2rem;
`;

export const HeaderSectionBread = styled.section`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
`;

export const ModalContainer = styled.section`
  position: relative; 
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  #error-message {
    text-align: left !important;
    display: flex !important;
  }
 
  > p {
    font-size: 14px;
    text-align: left;
  }

  > main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const LineInp = styled.section`
  position: relative;
  width: 100%;
  display: flex; 
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  > div:first-child {
    width: 100%;
  }

  > div:last-child {

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      border-radius: 6px;
      margin-left: -72px;
      background: transparent;

      > svg {
        margin: 0;
        color: red;
      }
    }
  }
`;

export const PaginationContainer = styled.section`
  position: relative;
  width: 100%;
  margin: 2rem 0;
  
  > p {
    text-align: center;
    font-size: 12px;
  }
`;