import { useState, useEffect } from "react";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */

export const useDetectOutsideClick = (el: any, initialState: any) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e: any) => {

      function hasParentOfType(node: ParentNode | null, type: any): boolean {

        if (!node) {
          return false
        }

        if (type !== 'BODY' && node.nodeName === 'BODY') return false;
        if (type === node.nodeName) return true;
        
        return hasParentOfType(node.parentNode, type);
      }

      const hasLink = hasParentOfType(e.target, 'A'); // A = HTMLAnchorElement

      //console.log('clicou em um link');
      // If target is Link, close dropdown
      if (hasLink) {
        setIsActive(!isActive);
        return;
      }

      //console.log('clicou fora');
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive);
        return;
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener("click", onClick);
      //console.log('abriu o menu')
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};
