import React from 'react';

import { Switch, Route } from 'react-router-dom';

import ScrollToTop from './ScrollToTop';

import Layout from '../layout';

import Dashboard from '../pages/user/Dashboard';
import Modulos from '../pages/user/Modulos';
import ModulosVestibulares from '../pages/user/ModulosVestibulares';
import AulasModulo from '../pages/user/AulasModulo';
import Materiais from '../pages/user/Materiais';
import SasaFlix from '../pages/user/Sasaflix';
import Conquistas from '../pages/user/Conquistas';
import Calendario from '../pages/user/Calendario';
import Perfil from '../pages/user/Perfil';
import Filme from '../pages/user/Filme';
import Comunidade from '../pages/user/Comunidade';
import ComunidadeTopicos from '../pages/user/ComunidadeTopicos';
import ComunidadeFeed from '../pages/user/ComunidadeFeed';
import Simulado from '../pages/user/Simulado';
import SimuladosCreate from '../pages/user/SimuladosCreate';
import Simulados from '../pages/user/Simulados';
import SimuladoView from '../pages/user/SimuladoView';
import PlanoDeEstudos from '../pages/user/PlanoDeEstudos';
import PlanoDeEstudosAula from '../pages/user/PlanoDeEstudosAula';
import AlterarSenha from '../pages/user/AlterarSenha';
import SuporteTecnico from '../pages/user/SuporteTecnico';
import SuporteTecnicoTopicos from '../pages/user/SuporteTecnicoTopicos';
import SuporteTecnicoPost from '../pages/user/SuporteTecnicoPost';
import Notificacoes from '../pages/user/Notificacoes';
import AulaLite from '../pages/user/AulaLite';
import ModulosCursosExtras from '../pages/user/ModulosCursosExtras';
import SasaBook from '../pages/user/SasaBook';
import Livro from '../pages/user/Livro';

const AppRoutes: React.FC = () => (
  <>
    <ScrollToTop>
      <Switch>
        <Route path="/video-lite" exact component={AulaLite} />
        
        <Layout>
          <Route path="/" exact component={Dashboard} />
          <Route path="/alterar-senha" exact component={AlterarSenha} />
          <Route path="/modulos" exact component={Modulos} />
          <Route path="/vestibulares" exact component={ModulosVestibulares} />
          <Route path="/cursos-extras" exact component={ModulosCursosExtras} />
          <Route path="/aulas/:slug/:lessonSlug" exact component={AulasModulo} />
          <Route path="/materiais" exact component={Materiais} />
          <Route path="/sasaflix" exact component={SasaFlix} />
          <Route path="/sasaflix/:id" exact component={Filme} />
          <Route path="/sasabooks" exact component={SasaBook} />
          <Route path="/sasabooks/:id" exact component={Livro} />
          <Route path="/simulados" exact component={Simulados} />
          <Route path="/simulados/novo" exact component={SimuladosCreate} />
          <Route path="/simulado/:id/visualizar" exact component={SimuladoView} />
          <Route path="/simulado/:id" exact component={Simulado} />
          <Route path="/conquistas" exact component={Conquistas} />
          <Route path="/comunidade" exact component={Comunidade} />
          <Route path="/comunidade/:slug" exact component={ComunidadeTopicos} />
          <Route path="/comunidade/:slug/topicos/:id" exact component={ComunidadeFeed} />
          <Route path="/calendario" exact component={Calendario} />
          <Route path="/perfil" exact component={Perfil} />
          <Route path="/notificacoes" exact component={Notificacoes} />
          <Route path="/plano-de-estudos" exact component={PlanoDeEstudos} />
          <Route path="/plano-de-estudos/:slug/:clusterSlug?/:lessonSlug?" exact component={PlanoDeEstudosAula} />
          <Route path="/suporte" exact component={SuporteTecnico} />
          <Route path="/suporte/artigos/:slug" exact component={SuporteTecnicoTopicos} />
          <Route path="/suporte/artigos/:slug/:article_slug" exact component={SuporteTecnicoPost} />
        </Layout>
      </Switch>
    </ScrollToTop>
  </>
);

export default AppRoutes;
