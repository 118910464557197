import styled from 'styled-components';

export const HrDivisor = styled.hr`
  width: 100%;
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.08);
  border-right: 0px solid rgba(0, 0, 0, 0.08);
  border-left: 0px solid rgba(0, 0, 0, 0.08);
  height: 0.0625rem;
  margin: 1rem 0px;
  border-bottom: none;
  opacity: 0.25;
  background-color: transparent;
  background-image: linear-gradient(to right,${props => props.theme.colors.muted}80, ${props => props.theme.colors.white}80 ,${props => props.theme.colors.muted}80) !important;
`;
