import React, { HTMLInputTypeAttribute, TextareaHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons';

import { Container } from './styles';

interface ITextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label: string;
  type: HTMLInputTypeAttribute;
  icon?: React.ComponentType<IconBaseProps>;
  register: any;
  ref?: HTMLInputElement,
}

const Textarea: React.FC<ITextareaProps> = ({ name, type, label, register, required, ref, ...rest }) => {
  
  return (
    <Container>
      <textarea name={name} id={name} type={type} ref={ref} {...register(name, { required })} {...rest}></textarea>
      <label htmlFor={name}>{label}</label>
    </Container>
  );
};

export default Textarea;