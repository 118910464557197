import styled from 'styled-components';

interface IModalContainerProps {
  confirmButtonColor?: string;
}

export const Content = styled.div<IModalContainerProps>`
  text-align: center;

  > p {
    text-align: left;
    margin-bottom: 2rem;
  }

  > footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    gap: 1rem;
    padding-bottom: 1rem;

    > button {
      border-radius: 8px;
      ${({ confirmButtonColor }) => confirmButtonColor ? `background-color: ${confirmButtonColor};` : '#d94545'}
      
      &:hover {
        ${({ confirmButtonColor }) => confirmButtonColor ? `background-color: ${confirmButtonColor}98;` : '#d9454580'}
      }
    }
  }
`;

export const CloseBtnContainer = styled.header`
  position: relative;
  width: 100%;
  display: none;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;

  @media (max-width: 550px) {
    display: flex;
  }
`;

export const CloseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  top: -10px;
  right: -10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.white}99;
  }
`;