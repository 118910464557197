import styled from 'styled-components';

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: inline-block !important;

  > button {
    display: flex;
    align-items: center;

    background: ${({ theme }) => theme.colors.primary};
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-weight: bold;
    padding: 6px 12px;

    > svg {
      margin-left: 4px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  > div {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    box-shadow: ${({ theme }) => theme.shadow};
    display: ${props => (props.isOpen ? 'block' : 'none')};
    padding: 8px 0;
    position: absolute;
    right: 0;
    top: calc(100% + 3px);
    width: 210px;
    z-index: 999;

    > ul {
      display: flex;
      list-style: none;
      flex-direction: column;
      gap: 0.5rem;

      > li a,
      > li button {
        padding: 0.5rem 1rem;
        color: #fff;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        text-align: left;
        transition: padding 0.2s;
        border: none;
        background: none;
        text-decoration: none;
        width: 100%;
        display: flex;
        justify-content: initial;
        cursor: pointer;

        &:hover {
          color: #fff;
          padding-left: 24px;
        }

        svg {
          margin-right: 4px;
        }

        span {
          display: flex;
          align-items: center;
          flex: 1;
        }
      }
    }
  }
`;
