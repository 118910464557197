import React, { useCallback, useEffect, useRef, useState } from 'react';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';
import { useTheme } from '../../../hooks/theme';
import { useDashboard } from '../../../hooks/dashboard';
import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick';

import { Link, useLocation } from 'react-router-dom';
import { ArrowArcLeft, Check, List } from 'phosphor-react';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { formatDistance, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Toggle from '../../../components/Toggle';
import InputSearchHeader from '../../../components/InputSearchHeader';
import ButtonIconSquare from '../../../components/ButtonIconSquare';
import Hr from '../../../components/Hr';
import AvatarProgress from '../../../components/AvatarProgress';
import LoaderSpinner from '../../../components/LoaderSpinner';
import NotFoundRegister from '../../../components/NotFoundRegister';

import {
  FiAward,
  FiBell,
  FiChevronDown,
  FiMessageSquare,
} from 'react-icons/fi';

import logoWhiteVertical from '../../../assets/logo/logo-vertical-white.svg';

import {
  Container,
  AvatarContainer,
  RightContainer,
  ToggleThemeContainer,
  NextLevelProgressContainer,
  MenuDropDownContainer,
  MenuNav,
  MenuNotificationsNav,
  LogoMobileContainer,
  AsideMenuMobile,
  ArrowBack,
  Menu,
  Backdrop,
  SectionAchiviementsContainer,
  HasNotificationBullet,
} from './styles';
import menuLinks from '../../../routes/menu/MenuUserLinks';
import menuAdminLinks from '../../../routes/menu/menuAdminLinks';

import {
  IIconProps,
  INotificationsProps,
} from '../../../interfaces/Notifications';
import { useSocket } from '../../../hooks/socket';
import CountUpAdapter from '../../../components/CountUpAdapter';
import ProgressBar from '../../../components/ProgressBar';
import { toast } from 'react-hot-toast';

type INotificationsType = 'forum' | 'general' | 'achievement';

const Header: React.FC = () => {
  const { user, logout, WithAuthorization } = useAuth();
  const { register } = useSocket();
  const { toggleTheme, theme } = useTheme();
  const { toggleDashboard, dashboard } = useDashboard();
  const location = useLocation();
  const localtionSplited = location.pathname;

  // Menu user dropdown
  const menuNavRef = useRef<HTMLDivElement>(null);
  const [menuNavIsOpen, setMenuNavIsOpen] = useDetectOutsideClick(
    menuNavRef,
    false,
  );

  const openMenuContextAvatar = useCallback(() => {
    console.log('clickou');

    setMenuNavIsOpen((oldValue: any) => !oldValue);
  }, [setMenuNavIsOpen]);

  useEffect(() => {
    register({
      event: 'notification',
      listener: (notification: INotificationsProps) => {
        if (notification) {
          const types = {
            forum: () => {
              setForumNotifications(oldNotifications => [
                notification,
                ...oldNotifications,
              ]);
            },
            general: () => {
              setGeneralNotifications(oldNotifications => [
                notification,
                ...oldNotifications,
              ]);
            },
            achievement: () => {
              setAchievementNotifications(oldNotifications => [
                notification,
                ...oldNotifications,
              ]);
            },
          };

          types[notification.type]();
        }
      },
    });
  }, [register]);

  useEffect(() => {
    console.log('menuNavIsOpen', menuNavIsOpen);
  }, [menuNavIsOpen]);

  // Button all notifications
  const buttonAllNotificationsRef = useRef<HTMLDivElement>(null);
  const [menuNotificationsIsOpen, setMenuNotificationsIsOpen] =
    useDetectOutsideClick(buttonAllNotificationsRef, false);
  const openMenuNotifications = () =>
    setMenuNotificationsIsOpen(!menuNotificationsIsOpen);

  // Button all notifications
  const buttonAllRewardsRef = useRef<HTMLDivElement>(null);
  const [menuRewardsIsOpen, setMenuRewardsIsOpen] = useDetectOutsideClick(
    buttonAllRewardsRef,
    false,
  );
  const openMenuRewards = () => setMenuRewardsIsOpen(!menuRewardsIsOpen);

  // Button all forum notifications
  const buttonForumRef = useRef<HTMLDivElement>(null);
  const [menuForumIsOpen, setMenuForumIsOpen] = useDetectOutsideClick(
    buttonForumRef,
    false,
  );
  const openMenuForum = () => setMenuForumIsOpen(!menuForumIsOpen);

  // Mobile menu
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(oldValue => !oldValue);

  // Loading
  const [notificationsLoading, setNotificationsLoading] = useState(false);

  // Data
  const [forumNotifications, setForumNotifications] = useState<
    INotificationsProps[]
  >([]);
  const [generalNotifications, setGeneralNotifications] = useState<
    INotificationsProps[]
  >([]);
  const [achievementNotifications, setAchievementNotifications] = useState<
    INotificationsProps[]
  >([]);

  useEffect(() => {
    (async () => {
      try {
        setNotificationsLoading(true);

        const [
          forumNotificationsResponse,
          generalNotificationsResponse,
          achievementNotificationsResponse,
        ] = await Promise.all([
          api.get(`/notifications`, {
            params: {
              pageIndex: 0,
              pageSize: 4,
              type: 'forum',
            },
          }),
          api.get(`/notifications`, {
            params: {
              pageIndex: 0,
              pageSize: 4,
              type: 'general',
            },
          }),
          api.get(`/notifications`, {
            params: {
              pageIndex: 0,
              pageSize: 4,
              type: 'achievement',
            },
          }),
        ]);

        setForumNotifications(forumNotificationsResponse.data.records);
        setGeneralNotifications(generalNotificationsResponse.data.records);
        setAchievementNotifications(
          achievementNotificationsResponse.data.records,
        );
      } catch (error) {
        console.log('error notifications: ', error);
      } finally {
        setNotificationsLoading(false);
      }
    })();
  }, []);

  const handleClearNotifications = useCallback(async (notification: INotificationsProps, type: INotificationsType) => {
    try {
      if (notification.viewed_at) return; // Se já foi visto, não faz nada

      const response = await api.put(`/notifications/${notification.id}`);
      console.log('response clear notifications: ', response.data);

      switch (type) {
        case 'forum':
          setForumNotifications(oldNotifications => {
            const index = oldNotifications.findIndex(
              oldNotification => oldNotification.id === notification.id,
            );

            oldNotifications[index].viewed_at = response.data.viewed_at;

            return [...oldNotifications];
          });
          break;

        case 'general':
          setGeneralNotifications(oldNotifications => {
            const index = oldNotifications.findIndex(
              oldNotification => oldNotification.id === notification.id,
            );

            oldNotifications[index].viewed_at = response.data.viewed_at;

            return [...oldNotifications];
          });
          break;

        case 'achievement':
          setAchievementNotifications(oldNotifications => {
            const index = oldNotifications.findIndex(
              oldNotification => oldNotification.id === notification.id,
            );

            oldNotifications[index].viewed_at = response.data.viewed_at;

            return [...oldNotifications];
          });
          break;

        default:
          break;
      }
    } catch (error) {
      console.log('error clear notifications: ', error);
    }
  }, []);

  const handleClearAllNotifications = useCallback(async (type: INotificationsType) => {
    try {
      const response = await api.delete(`/notifications`, {
        params: {
          type: type,
        }
      });
      console.log('response clear all notifications: ', response.data);

      switch (type) {
        case 'forum':
          setForumNotifications(oldNotifications => {
            oldNotifications.forEach(notification => {
              notification.viewed_at = String(new Date());
            });

            return [...oldNotifications];
          });

          setMenuForumIsOpen(false);
          toast.success('Todas as notificações foram marcadas como lidas! 😉', {
            position: 'bottom-right',
            duration: 6000,
            className: 'toast-samuquinha',
          });

          break;

        case 'general':
          setGeneralNotifications(oldNotifications => {
            oldNotifications.forEach(notification => {
              notification.viewed_at = String(new Date());
            });

            return [...oldNotifications];
          });

          setMenuNotificationsIsOpen(false);
          toast.success('Todas as notificações foram marcadas como lidas! 😉', {
            position: 'bottom-right',
            duration: 6000,
            className: 'toast-samuquinha',
          });

          break;

        case 'achievement':
          setAchievementNotifications(oldNotifications => {
            oldNotifications.forEach(notification => {
              notification.viewed_at = String(new Date());
            });

            return [...oldNotifications];
          });

          setMenuRewardsIsOpen(false);
          toast.success('Todas as notificações foram marcadas como lidas! 😉', {
            position: 'bottom-right',
            duration: 6000,
            className: 'toast-samuquinha',
          });
          break;

        default:
          break;
      }
    } catch (error) {
      console.log('error clear all notifications: ', error);
    }
  }, [setMenuForumIsOpen, setMenuNotificationsIsOpen, setMenuRewardsIsOpen]);

  return (
    <>
      <Container>
        <LogoMobileContainer>
          <img
            className="vertical"
            src={logoWhiteVertical}
            alt="Logo Biolândia"
          />
          <div>
            <List
              size={26}
              weight="bold"
              color="#ffffff"
              onClick={showSidebar}
            />
          </div>
        </LogoMobileContainer>
        <main>
          <aside>
            <InputSearchHeader placeholder="Busque por vídeos, materiais, artigos ou tópicos do fórum..." />
          </aside>
          <RightContainer>
            <NextLevelProgressContainer>
              <header>
                <p>
                  LEVEL {user.level} <span> <CountUpAdapter value={user?.exp || 0} durationInSeconds={1.5} /> EXP</span>
                </p>
              </header>
              {user.id && (
                <ProgressBar
                  width="100%"
                  height="6px"
                  percentage={Number(user?.exp % 100)}
                  bgColor="#ffffff45"
                  barColor={theme === 'dark' ? '#6befb0' : '#ffffff'}
                  rectBorderRadius='12px'
                  trackBorderColor="transparent"
                  style={{ marginTop: '8px' }}
                />
              )}
            </NextLevelProgressContainer>

            <div style={{ position: 'relative' }} ref={buttonForumRef}>
              <ButtonIconSquare
                icon={FiMessageSquare}
                onClick={() => {
                  openMenuForum();
                }}
                className={`${menuForumIsOpen ? 'open-menu' : 'closed-menu'}`}
              >
                {forumNotifications.filter(
                  findNotification => !findNotification.viewed_at,
                ).length > 0 && <HasNotificationBullet />}
              </ButtonIconSquare>

              <MenuNotificationsNav
                className={`${menuForumIsOpen ? 'open-menu' : 'closed-menu'}`}
                style={{ left: '-303px' }}
              >
                <header>
                  <h4>Fórum de dúvidas</h4>
                  <p onClick={() => handleClearAllNotifications('forum')}>
                    <Check weight="bold" /> 
                    Marcar todas como lidas
                  </p>
                </header>

                {forumNotifications.length > 0 ? (
                  forumNotifications.map((notification, index) => (
                    <>
                      <Link
                        key={index}
                        to={notification?.link || '/'}
                        onClick={() => handleClearNotifications(notification, 'forum')}
                      >
                        <section>
                          <div>
                            <AvatarProgress
                              badgeValue={
                                (notification.icon as IIconProps).level || 0
                              }
                              percent={
                                (notification.icon as IIconProps).exp % 100 || 0
                              }
                              size={64}
                              url={(notification.icon as IIconProps).avatar}
                            />
                            <main>
                              <h4>{`${notification.title} ${notification.exp_reward ?
                                `(+${notification.exp_reward} exp)` : ''
                                }`}</h4>
                              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                {notification?.content?.replace(/\\s/g, '\n')}
                              </ReactMarkdown>
                            </main>
                          </div>
                        </section>
                      </Link>
                      <Hr
                        style={
                          forumNotifications.length - 1 === index
                            ? { display: 'none' }
                            : {}
                        }
                      />
                    </>
                  ))
                ) : (
                  <NotFoundRegister description="Nenhuma notificação encontrada." />
                )}
                <footer>
                  <Link to="/notificacoes">
                    Visualizar todas as notificações
                  </Link>
                </footer>
              </MenuNotificationsNav>
            </div>

            <div style={{ position: 'relative' }} ref={buttonAllRewardsRef}>
              <ButtonIconSquare
                icon={FiAward}
                onClick={() => {
                  openMenuRewards();
                }}
                className={`${menuRewardsIsOpen ? 'open-menu' : 'closed-menu'}`}
              >
                {achievementNotifications.filter(
                  findNotification => !findNotification.viewed_at,
                ).length > 0 && <HasNotificationBullet />}
              </ButtonIconSquare>

              <MenuNotificationsNav
                className={`${menuRewardsIsOpen ? 'open-menu' : 'closed-menu'}`}
                style={{ left: '-303px' }}
              >
                <header>
                  <h4>Conquistas</h4>
                  <p onClick={() => handleClearAllNotifications('achievement')}>
                    <Check weight="bold" /> 
                    Marcar todas como lidas
                  </p>
                </header>

                {achievementNotifications.length > 0 ? (
                  achievementNotifications.map((notification, index) => (
                    <>
                      <Link to="/conquistas"
                        key={index}
                        onClick={() => handleClearNotifications(notification, 'achievement')}
                      >
                        <SectionAchiviementsContainer>
                          <div>
                            <img
                              src={notification.icon as string}
                              alt={notification.title}
                            />
                            <main>
                              <h4>
                                {`${notification.title} (+${notification.exp_reward} exp)`}
                              </h4>
                              <p>{notification?.content}</p>
                            </main>
                          </div>
                        </SectionAchiviementsContainer>
                      </Link>
                      {achievementNotifications.length !== index + 1 && <Hr />}
                    </>
                  ))
                ) : (
                  <NotFoundRegister description="Nenhuma conquista encontrada." />
                )}

                <footer style={notificationsLoading ? { display: 'none' } : {}}>
                  <Link to="/conquistas">Visualizar todas as conquistas</Link>
                </footer>
              </MenuNotificationsNav>
            </div>

            <div
              ref={buttonAllNotificationsRef}
              style={{ position: 'relative' }}
            >
              <ButtonIconSquare
                icon={FiBell}
                onClick={() => {
                  openMenuNotifications();
                }}
                className={`${menuNotificationsIsOpen ? 'open-menu' : 'closed-menu'
                  }`}
              >
                {generalNotifications.filter(
                  findNotification => !findNotification.viewed_at,
                ).length > 0 && <HasNotificationBullet />}
              </ButtonIconSquare>

              <MenuNotificationsNav
                className={`${menuNotificationsIsOpen ? 'open-menu' : 'closed-menu'
                  }`}
                style={{ left: '-303px' }}
              >
                {notificationsLoading && <LoaderSpinner />}
                <header>
                  <h4>Notificações</h4>
                  <p onClick={() => handleClearAllNotifications('general')}>
                    <Check weight="bold" /> 
                    Marcar todas como lidas
                  </p>
                </header>
                {generalNotifications.length > 0 ? (
                  generalNotifications.map((notification, index) => (
                    <>
                      <Link 
                        key={index} 
                        to={notification?.link || '/'} 
                        onClick={() => handleClearNotifications(notification, 'general')}
                      >
                        <main>
                          <div>
                            <h4>{`${notification.title} ${notification.exp_reward ?
                              `(+${notification.exp_reward} exp)` : ''
                              }`}</h4>
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                              {notification?.content?.replace(/\\s/g, '\n')}
                            </ReactMarkdown>
                            <small>
                              há{' '}
                              {formatDistance(
                                parseISO(notification?.created_at),
                                new Date(),
                                { locale: ptBR },
                              )}
                            </small>
                          </div>
                        </main>
                      </Link>
                      <Hr
                        style={
                          generalNotifications.length - 1 === index
                            ? { display: 'none' }
                            : {}
                        }
                      />
                    </>
                  ))
                ) : (
                  <NotFoundRegister description="Nenhuma notificação encontrada." />
                )}
                <footer style={notificationsLoading ? { display: 'none' } : {}}>
                  <Link to="/notificacoes">
                    Visualizar todas as notificações
                  </Link>
                </footer>
              </MenuNotificationsNav>
            </div>
          </RightContainer>
          <MenuDropDownContainer ref={menuNavRef}>
            <AvatarContainer
              onClick={openMenuContextAvatar}
              className={`${menuNavIsOpen ? 'open-menu' : 'closed-menu'}`}
            >
              <AvatarProgress
                percent={user.current_exp}
                badgeValue={user.level}
                size={55}
                url={user.avatar_url}
              />
              <aside>
                <h2>{user.show_name}</h2>
                <p>{user.evolution?.name}</p>
              </aside>
              <div>
                <FiChevronDown strokeWidth={2} />
              </div>
            </AvatarContainer>

            <MenuNav
              className={`${menuNavIsOpen ? 'open-menu' : 'closed-menu'}`}
            >
              <h4>Meu perfil</h4>
              <Link to="/perfil">Visualizar perfil</Link>
              <Link to="/conquistas">Minhas conquistas</Link>
              <h4>Segurança</h4>
              <Link to="/alterar-senha">Alterar senha</Link>
              <h4>Plano</h4>
              <Link to="/plano-de-estudos?change=true">
                Alterar plano de estudos
              </Link>
              {/* <Link to="/estatisticas">Estender assinatura 💚</Link> */}

              <ToggleThemeContainer>
                <h4>Alterar tema</h4>
                <Toggle
                  onChange={toggleTheme}
                  checked={theme === 'light'}
                  labelLeft="dark"
                  labelRight="light"
                  onColor="#cde5e1"
                  offColor="#07302b"
                />
              </ToggleThemeContainer>

              <WithAuthorization roles={['admin']}>
                <ToggleThemeContainer>
                  <h4>Alterar sistema</h4>
                  <Toggle
                    onChange={toggleDashboard}
                    checked={dashboard === 'student'}
                    labelLeft="Admin"
                    labelRight="Plat"
                    onColor="#cde5e1"
                    offColor="#07302b"
                  />
                </ToggleThemeContainer>
              </WithAuthorization>

              <button className="logout-button" onClick={logout}>
                Sair
              </button>
            </MenuNav>
          </MenuDropDownContainer>
        </main>
      </Container>

      <AsideMenuMobile className={sidebar ? 'sidebar active' : 'sidebar'}>
        <ArrowBack onClick={showSidebar}>
          <span className="icon-icon-back-arrow">
            {' '}
            <ArrowArcLeft weight="duotone" size={20} />{' '}
          </span>
        </ArrowBack>
        <div style={{ padding: '0 1rem', gap: '1rem' }}>
          <AvatarProgress
            percent={user.current_exp}
            badgeValue={user.level}
            size={60}
            url={user.avatar_url}
          />
          <Link to="/perfil" onClick={showSidebar}>
            Olá, {user.show_name}! <br /> <span>Bons estudos! 💚</span>
          </Link>
        </div>
        <Menu>
          {dashboard === 'student'
            ? menuLinks.map((item, index) => (
              <div
                key={index}
                className={
                  localtionSplited === item.location ||
                    (localtionSplited === '' && index === 0)
                    ? 'active'
                    : ''
                }
              >
                <Link
                  data-tooltip={item.title}
                  to={`${item.location}`}
                  onClick={showSidebar}
                >
                  <item.icon size={25} weight="duotone" />
                  {item.title}
                </Link>
              </div>
            ))
            : menuAdminLinks.map((item, index) => (
              <div
                key={index}
                className={
                  localtionSplited === item.location ||
                    (localtionSplited === '' && index === 0)
                    ? 'active'
                    : ''
                }
              >
                <Link
                  data-tooltip={item.title}
                  to={`${item.location}`}
                  onClick={showSidebar}
                >
                  <item.icon size={25} weight="duotone" />
                  {item.title}
                </Link>
              </div>
            ))}
        </Menu>
      </AsideMenuMobile>
      <Backdrop
        className={sidebar ? 'active' : 'no-active'}
        onClick={showSidebar}
      />
    </>
  );
};

export default Header;
