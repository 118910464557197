import React, { InputHTMLAttributes, useEffect, useRef, useState, useMemo } from 'react';
import {Link} from 'react-router-dom'

import { debounce } from 'lodash';

import { Container, Label, Input, SearchResult } from './styles';
import api from '../../services/api';

interface IInputAnimateDropdownProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  padding?: string;
  margin?: string;
  onlyWhite?: boolean;
  autoFocus?: boolean;
}

interface ISearchProps {
  id: string;
  section_id: string;
  title: string;
  slug: string;
  section: {
    slug: string;
  };
  is_promoted: boolean;
  abstract: string;
  vote_count: number;
  vote_sum: number;
  created_at: string;
  updated_at: string;
  points: number;
}

const InputAnimateDropdown: React.FC<IInputAnimateDropdownProps> = ({label, padding, margin, onlyWhite, autoFocus, defaultValue, ...rest}) => {
  const inputEl = useRef<HTMLInputElement>(null);

  const [isActive, setIsActive] = useState(false);

  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState<ISearchProps[]>([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const delayedSearch = useMemo(() =>
    debounce(() => {
      setSearch(searchTerm);
    }, 500),
    [searchTerm],
  );

  useEffect(() => {
    delayedSearch();

    return delayedSearch.cancel;
  }, [searchTerm, delayedSearch]);

  // SEARCH
  useEffect(() => {
    async function loadData() {
      try {
        if (search.length > 3) {
          const response = await api.get('/sections/search',
            {params: { query: search }}
          );

          console.log('SEARCH LIST >>>>>>>>>>>>')
          console.log(response.data);
          setSearchResult(response.data);
        } else {
          setSearchResult([]);
        }
      } catch (error) {
        console.log(error);
      }
      finally {
        setSearchLoading(false);
      }
    }

    loadData();
  }, [search]);

  useEffect(() => {
    autoFocus && inputEl.current?.focus();
  }, [autoFocus])

  return (
    <>
      <Container padding={padding} margin={margin}>
        <Input
          {...rest}
          onlyWhite={onlyWhite}
          defaultValue={defaultValue}
          autoComplete="off"
          ref={inputEl}
          onChange={event => {
            setSearchLoading(true);
            setSearchTerm(event.target.value);
          }}
          onFocus={() => setIsActive(true)}
          onBlur={() => setIsActive(false)}
        />
        <Label onlyWhite={onlyWhite}>{label}</Label>
      </Container>
      <SearchResult isActive={isActive}>
        <ul>
          {!searchResult.length && (
            <li>
              {searchLoading && <p>Carregando...</p>}
              {!searchLoading && search.length <= 3 && <p>Busque por palavras-chave...</p>}
              {!searchLoading && search.length > 3 && <p>Poxa, não encontramos nenhum artigo para a sua busca! 😢</p>}
            </li>
          )}
          {searchResult.map((article, index) => (
            <li key={index}>
              <Link to={`/suporte/artigos/${article.section.slug}/${article.slug}/`}>{index + 1}. {article.title}</Link>
            </li>
          ))}
        </ul>
      </SearchResult>
    </>
  );
}

export default InputAnimateDropdown;
