import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { API_S3 } from '../../../services/api_s3';

export const Container = styled.div`

`;

export const AvatarContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${props => props.theme.radius.primary};
  justify-content: space-between;
  width: 100%;

  > aside {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;

    > div {

      > img {
        margin: -55px 0 -37px 0;
      }
    }
    
    > header {
      display: flex;
      gap: .5rem;
      flex-direction: column;

      > h1 {
        font-size: 40px;
        color: #fff;

        @media (max-width: 425px) {
          font-size: 28px;
        }
      }
  
      > p {
        font-size: 12px;
        font-weight: 300;
        color: #79ffb8;
      }
    }
  }

  > main {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > header {
      position: absolute;
      text-align: right;
      top: -4rem;
      right: 0;
      background: red;
      padding: 0.5rem 0.75rem;
      border-radius: ${props => props.theme.radius.secondary};
      border: 1px solid ${props => props.theme.cards.border};
      background: ${props => props.theme.cards.primary};
      
      > h4 {
        display: flex;
        gap: 0.25rem;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        //color: #fff;
      }
    }
  }
`;

export const SectionContainer1 = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 375px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionContainer2 = styled.section`
  display: grid;
  grid-template-columns: 1.8fr 1fr 1.25fr;
  gap: 1rem;
  align-items: start;
  margin-bottom: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1.8fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionContainer3 = styled.section`
  display: grid;
  grid-template-columns: 1.7fr 1.1fr 0.6fr;
  gap: 1rem;
  align-items: start;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 425px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const SectionContainer4 = styled.section`
  display: grid;
  //grid-template-columns: 1.8fr 1.35fr 1.2fr;
  grid-template-columns: minmax(465px, 1fr) minmax(465px, 465px) 1fr;
  gap: 1rem;
  align-items: start;
  margin-bottom: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const CardExamNotFound = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  background: ${props => props.theme.cards.primary};
  border-radius: ${props => props.theme.radius.primary};
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
  text-decoration: none;
  
  &:hover {
    
    > main {
      transform: scale(1.10);
    }
  }
  
  > main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${API_S3}/default/not-exams.jpg) no-repeat center center;
    //transition: all .2s ease-in-out 0s;
    transition: all .35s cubic-bezier(0.645, 0.045, 0.355, 1);
    overflow: hidden;
  
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      inset: 0px;
      background-image: linear-gradient(to bottom, transparent 0%, #021614 88%);
      opacity: 0.90;
      transition: all .3s ease-in-out 0s;
    }
  }

  > header {
    position: relative;
    width: 100%;
    display: flex;
    z-index: 9;
  }

  > footer {
    position: relative;
    width: 100%;
    z-index: 9;
    margin-bottom: 0.75rem;

    > h2 {
      color: #ffffff;
      text-align: center;
      font-size: 18px;
      margin-bottom: 6px;
    }

    > p {
      color: #cecece;
      text-align: center;
    }
  }
`;

export const GraphicContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 1.5rem 1rem;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  width: 100%;
  height: 100%;

  > header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem 0 1rem;

    > div {

      > svg {
        color: ${props => props.theme.colors.muted}80;
      }
    }

    > h2 {
      color: ${props => props.theme.colors.white};
      font-size: 1rem;
    }
  }
`;
