import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  color: ${({ theme }) => theme.colors.white} !important;
  user-select: none;
  
  .card {
    width: 100%;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    position: relative;
    transition: all 1.25s;
    height: 210px;
    transform-style: preserve-3d;
    user-select: none;
    
    background: ${props => props.theme.specific.cardCommunity};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
  }
  
  .card:hover .content {
    transform: rotateY(0.5turn);
  }
  
  .front img {
    width: 180px;

    /* MOBILE */
    @media (max-width: 550px) {
      width: 130px;
    }
  }
  
  .front,
  .back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 2em;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .back {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    > h4 {
      width: 100%;
      text-align: left;
      transform: translateZ(10rem);
    }
  }
  
  .title {
    transform: translateZ(10rem);
    font-size: 25px;
    text-align: left;
  }
  
  .subtitle {
    transform: translateZ(2rem);
  }
  
  .back {
    transform: rotateY(0.5turn);
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
  }
  
  .description {
    transform: translateZ(10rem);
    text-align: justify;
    font-size: 12px;
  }
  `;

export const FrontInner = styled.main`
  transform: translateZ(10rem);
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  width: 100%;

  /* MOBILE */
  @media (max-width: 550px) {
    display: flex;
    justify-content: space-around;
  }

  > aside {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  > main {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    gap: 0.5rem;

    > p {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;
      font-size: 12px;
      color: ${props => props.theme.colors.muted};
    }
  }
`;