import React, { FormEvent, useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";

import { TableColumn } from 'react-data-table-component';

import DataTableAdapter from '../../../components/DataTableAdapter';
import DropdownButton from '../../../components/DropdownButton';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

import toast from 'react-hot-toast';
import { FiSettings, FiUser } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { CardTableContainer, Container, HeaderSection, HetaryGroupContainer, Line0, Line1, Line2, Line3, ListFilm, ModalContainer, MovieOfTheWeek, Poster, Separator } from './styles';

import api from '../../../services/api';

import avatar from '../../../assets/avatar.png';

import MessageErrorValidator from '../../../components/MessageErrorValidator';

import axios from 'axios';

import ReactStars from 'react-stars';
import { useTheme } from '../../../hooks/theme';
import TooltipAdapter from '../../../components/TooltipAdapter';
import HetaryGroup from '../../../components/CardMovie/HetaryGroup';
import Input from '../../../components/Form/Input';
import Textarea from '../../../components/Form/Textarea';
import SimpleMultiSelect from '../../../components/Form/SimpleMultiSelect';

import { IGenericOptions } from '../../../interfaces/IGenericOptions';
import IDataTable from '../../../interfaces/DataTable';
import { IMoviesProps } from '../../../interfaces/Movie';
import { formatDistance, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { loadConfettiAllPage } from '../../../utils/useConfetti';
import DropZone from '../../../components/Form/DropZone';

interface Movie {
  id: string;
  name: string;
  title: string;
  media_type: 'tv' | 'movie';
  poster_path: string;
  release_date: string;
  first_air_date: string;
}

interface IMovieOfTheWeekProps {
  id: string;
  movie_id: string;
  created_at: string;
  updated_at: string;
  movie: IMoviesProps;
}

const MOVIE_API_BASE_URL = 'https://image.tmdb.org/t/p/w400';


const Filmes: React.FC = () => {
  const { theme } = useTheme();

  // Validation
  const schema = Joi.object({
    name: Joi.string().required().messages({ '*': 'Informe um nome válido.' }),
    slogan: Joi.string().required().messages({ '*': 'Informe um slogan válido.' }),
    duration: Joi.number().required().messages({ '*': 'Informe uma duração válida.' }),
    year: Joi.number().required().messages({ '*': 'Informe um ano válido.' }),
    cover: Joi.string().required().messages({ '*': 'Informe uma capa válida.' }),
    backdrop: Joi.string().required().messages({ '*': 'Informe um backdrop válido.' }),
    trailer: Joi.string().required().messages({ '*': 'Informe uma url válida.' }),
    age_group: Joi.string().required().messages({ '*': 'Informe uma classificação válida.' }),
    genre_ids: Joi.array().items(Joi.string()).min(1).required().messages({ '*': 'Informe um gênero válido.' }),
    channel_ids: Joi.array().items(Joi.string()).min(1).required().messages({ '*': 'Informe um canal válido.' }),
    description: Joi.string().required().messages({ '*': 'Informe uma descrição válida.' }),
  });

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));
      return joiResolver(schema)(data, context, options);
    },
  });

  // Modal 
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);

  //Api
  const [movies, setMovies] = useState<Movie[]>([]);
  const [movieSelectedByApi, setMovieSelectedByApi] = useState(false);

  const [movieInputValue, setMovieInputValue] = useState('');

  // Request 
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [filterText, setFilterText] = useState('');

  //Data
  const [moviesData, setMoviesData] = useState<IDataTable<IMoviesProps>[]>([]);
  const [channelsOptions, setChannelsOptions] = useState<IGenericOptions[]>([]);
  const [genresOptions, setGenresOptions] = useState<IGenericOptions[]>([]);
  const [movieOfTheWeek, setMovieOfTheWeek] = useState<IMovieOfTheWeekProps>({} as IMovieOfTheWeekProps);
  const [filesData, setFilesData] = useState<File[]>([]);


  // Loaders
  const [loadingCreateMovie, setLoadingCreateMovie] = useState(false);

  const movieClassificationOptions: any = [
    { label: 'L', value: 'L' },
    { label: '18', value: '18' },
    { label: '16', value: '16' },
    { label: '14', value: '14' },
    { label: '12', value: '12' },
    { label: '10', value: '10' },
  ];

  const columns: TableColumn<IMoviesProps>[] = [
    {
      name: 'Nome',
      selector: row => row.name,
      width: '30%',
      cell: (row: IMoviesProps) => (
        <>
          <div style={{ display: 'flex', gap: '0.35rem', alignItems: 'center' }}>
            <Poster src={row.cover ? MOVIE_API_BASE_URL + row.cover : avatar} alt={row.name} />
            <div>
              <p><strong>{row.name} ({row.year})</strong></p>
              <p>
                {row.genres?.map((gern, index) => (
                  <Separator key={index}>
                    {gern.name}
                    {row.genres && (row.genres?.length - 1 !== index) && (<span>,</span>)}
                  </Separator>
                ))}
              </p>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Duração',
      cell: (row: IMoviesProps) => (
        <span>{row.duration_formatted}</span>
      ),
    },
    {
      name: 'Classificação',
      cell: (row: IMoviesProps) => (
        <HetaryGroupContainer>
          <HetaryGroup age_group={row.age_group} />
        </HetaryGroupContainer>
      ),
    },
    {
      name: 'Canais',
      cell: (row: IMoviesProps) => (
        <div>
          {row.channels?.length ? row.channels?.map((channel) => (
            <>
              <img src={channel.logo_url} alt={channel.name} style={{ width: '28px', borderRadius: '4px', marginRight: '0.5rem' }} />
            </>
          ))
            :
            (
              <span>-</span>
            )
          }
        </div>
      ),
    },
    {
      name: 'Avaliação',
      selector: row => row.vote_average,
      cell: (row: IMoviesProps) => (
        <div style={{ zIndex: 0, }}>
          <TooltipAdapter alt={`${String(row.vote_count)} votos`} place="top">
            <ReactStars
              key={1}
              count={5}
              edit={false}
              size={20}
              color1={theme === 'dark' ? '#fff' : '#adafca'}
              color2="#d3df37"
              half={true}
              value={row.vote_average / 20}
            />
          </TooltipAdapter>
        </div>
      ),
    },
    {
      name: (<FiSettings size={16} strokeWidth={2.5} />),
      sortable: false,
      button: true,
      right: true,
      cell: (row: IMoviesProps, index: number) => (
        <DropdownButton key={index}>
          <ul>
            <li>
              <button type="button" onClick={() => handleFeacturedMovie(row)}>
                <MdKeyboardArrowRight size={18} />
                Marcar como filme destaque
              </button>
            </li>
          </ul>
        </DropdownButton>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {

        const response = await api.get(`/movies`, {
          params: {
            pageIndex: page,
            pageSize: perPage,
            search: filterText,
          }
        });

        setMoviesData(response.data.records);
        setTotalRows(response.data.totalRecords);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [page, perPage, filterText]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoadingCreateMovie(true);
      console.log(data);

      const formData = new FormData();

      formData.append('name', data.name);
      formData.append('tagline', data.slogan);
      formData.append('duration', data.duration);
      formData.append('age_group', data.age_group);
      formData.append('year', data.year);
      formData.append('cover', data.cover);
      formData.append('backdrop', data.backdrop);
      formData.append('trailer', data.trailer);

      data.genre_ids.forEach((genre: any, index: number) => {
        formData.append(`genre_ids[${index}]`, genre);
      });

      data.channel_ids.forEach((channel: any, index: number) => {
        formData.append(`channel_ids[${index}]`, channel);
      });

      formData.append('description', data.description);

      if (filesData[0]) {
        formData.append('file', filesData[0]);
      }
      
      const response = await api.post(`/movies`, formData);

      console.log(response);

      if (response.status === 200) {
        setLoadingCreateMovie(false);
        setModalAddIsOpen(false);
        setMoviesData((oldMoviesData) => [response.data, ...oldMoviesData]);
        toast.success('Filme cadastrado com sucesso!', { position: 'bottom-center', duration: 6000 });
        reset({
          name: '',
          slogan: '',
          duration: '',
          age_group: '',
          year: '',
          cover: '',
          backdrop: '',
          trailer: '',
          genre_ids: [],
          channel_ids: [],
          description: '',
        });

        setFilesData([]);
      }
    }
    catch (error: any) {
      if (error.response) {
        if (error.response.data.validation) {
          console.log(error.response.data.validation.body.message);
          toast.error(`${error.response.data.validation.body.message as string}`, { position: 'bottom-center', duration: 6000 });
        }
        else if (error.response) {
          console.log(error.response.data.error);
          toast.error(`${error.response.data.error}`, { position: 'bottom-center', duration: 6000 });
        }
      }
      else {
        console.log(error);
        console.log(error.response);
        toast.error(`Ops! algo deu errado.`, { position: 'bottom-center', duration: 6000 });
      }
    }
    finally {
      setLoadingCreateMovie(false);
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/channels/options`, {
          params: {
            pageIndex: 0,
          }
        });

        console.log(response.data);
        setChannelsOptions(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/genres/options`, {
          params: {
            pageIndex: 0,
          }
        });

        console.log(response.data);
        setGenresOptions(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const searchMovies = useCallback(async (event: FormEvent) => {
    event.preventDefault();

    const response = await axios.get(
      'https://api.themoviedb.org/3/search/multi',
      {
        params: {
          api_key: '16e1ba7d25c3dada13a581d0cf58de76',
          append_to_response: 'videos,images,person',
          language: 'pt-BR',
          query: movieInputValue,
        },
      },
    );

    console.log(response.data.results);
    setMovies(response.data.results);
    
    if (response.data.results.length === 0) {
      alert('Nenhum filme encontrado com este título. 😢');
    }

  }, [movieInputValue]);

  const getMovieDetails = useCallback(async (id: string, media_type: 'tv' | 'movie') => {
    setMovieSelectedByApi(true);

    const response = await axios.get(
      `https://api.themoviedb.org/3/${media_type}/${id}`, {
      params: {
        api_key: '16e1ba7d25c3dada13a581d0cf58de76',
        append_to_response: 'videos,images,person',
        language: 'pt-BR',
      },
    },
    );

    console.log(response.data);
    const foundedMovie = response.data;

    setValue('name', foundedMovie.title || foundedMovie.name);
    setValue('slogan', foundedMovie.tagline);
    setValue('duration', foundedMovie.runtime);
    setValue('year', foundedMovie.release_date?.split('-')[0]);
    setValue('cover', foundedMovie.poster_path);
    setValue('backdrop', foundedMovie.backdrop_path);
    setValue('description', foundedMovie.overview);
  }, [setValue]);

  useEffect(() => {
    reset();
    setMovieSelectedByApi(false);
    setMovies([]);
  }, [modalAddIsOpen, reset]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/movies/suggestions/last`);

        console.log(response.data);
        setMovieOfTheWeek(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleFeacturedMovie = useCallback(async (movie: IMoviesProps) => {
    try {
      const confirm = window.confirm('Deseja marcar este filme como destaque?');

      if (confirm) {
        const response = await api.post(`/movies/suggestions`, {
          movie_id: movie.id,
        });

        setMovieOfTheWeek({
          id: movie.id,
          movie_id: movie.id,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
          movie: movie,
        });
        console.log(response.data);

        toast.success('Filme marcado como destaque!', { position: 'bottom-right', duration: 6000, className: 'toast-samuquinha' });

        loadConfettiAllPage();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <Container>
        <HeaderSection>
          <MovieOfTheWeek>
            <aside>
              <img src={MOVIE_API_BASE_URL + movieOfTheWeek?.movie?.cover} alt={movieOfTheWeek?.movie?.name} />
            </aside>
            <main>
              <h5>Filme da semana: {movieOfTheWeek?.movie?.name} <TooltipAdapter alt={`${String(movieOfTheWeek.movie?.vote_count)} votos`} place="top">
                <ReactStars
                  key={1}
                  count={5}
                  edit={false}
                  size={20}
                  color1={theme === 'dark' ? '#fff' : '#adafca'}
                  color2="#d3df37"
                  half={true}
                  value={movieOfTheWeek?.movie?.vote_average / 20}
                  onChange={(new_rating: number) => { }}
                />
              </TooltipAdapter>
              </h5>
              {movieOfTheWeek?.created_at && (<p>há {formatDistance(parseISO(movieOfTheWeek?.created_at), new Date(), { locale: ptBR })}</p>)}
            </main>
          </MovieOfTheWeek>
          <aside>
            <Button
              onClick={() => setModalAddIsOpen(!modalAddIsOpen)}
              color="primary"
              icon={FiUser}
              iconBefore
            >
              Cadastrar filme
            </Button>
          </aside>
        </HeaderSection>

        <CardTableContainer>
          <DataTableAdapter
            title="Filmes"
            subTitle={''}
            columns={columns}
            data={moviesData}
            paginationServer={true}
            perPage={perPage}
            totalRows={totalRows}
            setFilterText={setFilterText}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </CardTableContainer>
      </Container>

      {/* MODALS */}

      <Modal
        isOpen={modalAddIsOpen}
        setIsOpen={() => setModalAddIsOpen(!modalAddIsOpen)}
        closeModal={() => setModalAddIsOpen(false)}
        size="lg"
        title="Cadastrar filme"
      >
        <ModalContainer>
          {!movieSelectedByApi ? (
            <Line0>
              <form onSubmit={(event: FormEvent) => searchMovies(event)}>
                <div>
                  <input
                    type="text"
                    minLength={5}
                    onChange={(event) => setMovieInputValue(event.target.value)}
                    placeholder="Busque pelo título do filme..."
                  />
                </div>
                <div>
                  <button type="submit" disabled={movieInputValue === ''}>Buscar Filme</button>
                  <p onClick={() => setMovieSelectedByApi(true)}>Não encontrou?</p>
                </div>
              </form>
              <main>
                {movies.length > 0 ? (
                  <ul>
                    {movies.map((movie, index) => (
                      <ListFilm key={index} onClick={() => getMovieDetails(movie.id, movie.media_type)}>
                        <aside>
                          <img src={MOVIE_API_BASE_URL + movie.poster_path} alt="" />
                        </aside>
                        <main>
                          <h2>{movie.title || movie.name}</h2>
                          <p>{new Date(movie.release_date || movie.first_air_date).toLocaleString('pt-BR', {
                            year: "numeric",
                          })}</p>
                        </main>
                      </ListFilm>
                    ))}
                  </ul>
                ) :
                  (movieSelectedByApi && <p>Nenhum registro encontrado. 😢</p>)
                }
              </main>
            </Line0>
          ) : (
            <form onSubmit={onSubmit}>
              <Line1>
                <div>
                  <Input register={register} name="name" type="text" label="Nome do filme" autoComplete="off" />
                  {errors.name && <MessageErrorValidator>{errors.name.message as string}</MessageErrorValidator>}
                </div>
                <div>
                  <Input register={register} name="slogan" type="text" label="Slogan" autoComplete="off" />
                  {errors.slogan && <MessageErrorValidator>{errors.slogan.message as string}</MessageErrorValidator>}
                </div>
              </Line1>
              <Line2>
                <div>
                  <Input register={register} name="duration" type="number" label="Duração (min)" autoComplete="off" />
                  {errors.duration && <MessageErrorValidator>{errors.duration.message as string}</MessageErrorValidator>}
                </div>
                <div>
                  {genresOptions && (
                    <SimpleMultiSelect
                      register={register}
                      name="genre_ids"
                      label="Gênero"
                      placeHolder="Selecione..."
                      options={genresOptions}
                      setResult={(values) => { setValue('genre_ids', values) }}
                      isMulti={true}
                      required
                    />
                  )}
                  {errors.genre_ids && <MessageErrorValidator>{errors.genre_ids.message as string}</MessageErrorValidator>}
                </div>
                <div>
                  <Input register={register} name="year" type="number" label="Ano" autoComplete="off" />
                  {errors.year && <MessageErrorValidator>{errors.year.message as string}</MessageErrorValidator>}
                </div>
                <div>
                  {movieClassificationOptions && (
                    <SimpleMultiSelect
                      register={register}
                      name="age_group"
                      label="Classificação"
                      placeHolder="Selecione..."
                      options={movieClassificationOptions}
                      setResult={(values) => { setValue('age_group', values) }}
                      isMulti={false}
                      required
                    />
                  )}
                  {errors.age_group && <MessageErrorValidator>{errors.age_group.message as string}</MessageErrorValidator>}
                </div>
              </Line2>
              <Line3>
                <div>
                  <Input register={register} name="cover" type="text" label="Capa" autoComplete="off" />
                  {errors.cover && <MessageErrorValidator>{errors.cover.message as string}</MessageErrorValidator>}
                </div>
                <div>
                  <Input register={register} name="backdrop" type="text" label="Backdrop" autoComplete="off" />
                  {errors.backdrop && <MessageErrorValidator>{errors.backdrop.message as string}</MessageErrorValidator>}
                </div>
                <div>
                  <Input register={register} name="trailer" type="text" label="Trailer" placeholder="Ex: f2lFw3YKEs" autoComplete="off" />
                  {errors.trailer && <MessageErrorValidator>{errors.trailer.message as string}</MessageErrorValidator>}
                </div>
              </Line3>
              <div>
                {channelsOptions && (
                  <SimpleMultiSelect
                    register={register}
                    name="channel_ids"
                    label="Canais"
                    placeHolder="Selecione..."
                    options={channelsOptions}
                    setResult={(values) => { setValue('channel_ids', values) }}
                    isMulti={true}
                    required
                  />
                )}
                {errors.channel_ids && <MessageErrorValidator>{errors.channel_ids.message as string}</MessageErrorValidator>}
              </div>
              <div>
                <Textarea register={register} name="description" type="text" label="Descrição" autoComplete="off" />
                {errors.description && <MessageErrorValidator>{errors.description.message as string}</MessageErrorValidator>}
              </div>
              <div>
                <DropZone
                  setFilesData={setFilesData}
                  maxSize={1024 * 1024 * 1} // 1 mb
                  isMulti={false}
                  icons={false}
                  placeHolder="Arraste e solte ou clique para adicionar o .pdf de exercícios sobre o filme"
                  accept={{
                    'application/pdf': ['.pdf'],
                  }}
                />
              </div>
              <div>
                <button type="submit" style={{ marginBottom: '1.5rem' }} disabled={loadingCreateMovie}>Cadastrar Filme</button>
              </div>
            </form>
          )}
        </ModalContainer>
      </Modal>
    </>
  );
}

export default Filmes;