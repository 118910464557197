import styled from 'styled-components';

import arrow from '../../../assets/small-arrow-icon.svg';

export const Container = styled.div`

  .rdtPicker {
    background: blue !important;
  }

  .rbc-btn-group:nth-child(1) {

    > button:nth-child(1)  {
      display: none;
    }
  }

  .rbc-btn-group:nth-child(3) {

    > button {
      display: none;
    }
  }

  .rbc-label {
    color: ${props => props.theme.colors.white};
    font-weight: 600;
  }

  .rbc-toolbar {
    margin-bottom: 1.8rem;
    flex-direction: row-reverse;
  }

  .rbc-toolbar button {
    border: none;
    font-weight: 700;
    padding: 0 1rem;
    font-size: 20px;
    color: ${props => props.theme.colors.white};

    &:hover, :active, :focus  {
      background: none;
      box-shadow: none;
    }
  }

  .rbc-toolbar .rbc-toolbar-label {
    font-size: 1.25rem;
    font-weight: 700;
    text-align: left;
    text-transform: capitalize;
    color: ${props => props.theme.colors.white};
  }

  .rbc-month-view {
    border-top:  none;
  }

  .rbc-month-header {
    color: ${props => props.theme.colors.white};
  }

  .rbc-header {
    padding: 1.4rem 0;
    color: ${props => props.theme.colors.white};
    font-size: 1rem;
    font-weight: 500;
    border-left: none !important;
    border-right: none !important;
    border-color: ${props => props.theme.colors.white}20;

    > span {
      text-transform: capitalize;
    }
  }

  .rbc-today {
    background: none;
  }

  .rbc-day-bg + .rbc-day-bg {
    border-color: ${props => props.theme.colors.white}20;
  }

  .rbc-row-segment {
    padding: 0 4px 4px 4px;
  }

  .rbc-month-row {
    overflow: visible;
  }

  .rbc-month-row + .rbc-month-row {
    border-color: ${props => props.theme.colors.white}20;
  }

  .rbc-month-view {
    border: none;
  }

  .rbc-date-cell {
    padding: 10px !important;
    font-weight: 700;
    color: ${props => props.theme.colors.white};
  }

  .rbc-off-range {

    > a {
      font-weight: 500 !important;
    }
  }

  .rbc-off-range-bg {
    background: ${props => props.theme.cards.modal};
  }

  .rbc-event {
    //background-color: ${props => props.theme.colors.primary} !important;
  }

  .rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: ${props => props.theme.colors.primary};
    border-radius: 15px;
    padding: 6px 12px;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus, :active {
      outline: none;
    }
  }

  .rbc-show-more {
    background: none;
    color: ${props => props.theme.colors.white};
    font-weight: 500;
    text-decoration: none;
    margin: 7px 0 0 7px;
  }
  
  .rbc-agenda-view table.rbc-agenda-table {
    color: ${props => props.theme.colors.white};
  }

  .rbc-time-view, .rbc-time-header.rbc-overflowing, .rbc-time-header-content, .rbc-time-header.rbc-overflowing {
    border-color: ${props => props.theme.colors.white}20;
  }
  .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: none;
  }

  .rbc-btn-group > button:not(:first-child):not(:last-child) {
    visibility: hidden;
    line-height: 0;
    transition: all .2s;
    cursor: pointer;

    &:after {
      content: '';
      visibility: visible;
      display: flex;
      height: 1rem;
      background: url(${arrow}) no-repeat;
      transform: rotate(180deg);
    }

    &:hover {
      opacity: .5;
    }
  }

  .rbc-btn-group > button:last-child:not(:first-child) {
    visibility: hidden;
    line-height: 0;
    transition: all .2s;
    cursor: pointer;

    &:after {
      content: '';
      visibility: visible;
      display: flex;
      height: 1rem;
      background: url(${arrow}) no-repeat;
    }

    &:hover {
      opacity: .5;
    }
  }

  .rbc-overlay {
    background: ${props => props.theme.cards.primary} !important;
    border-radius: 12px;
  }

  .rbc-overlay-header {
    text-transform: capitalize;
  }

  .rbc-now {
    display: flex;
    justify-content: flex-end;

    > button {
      background-color: ${props => props.theme.colors.primary};
      padding: 5px 6px;
      border-radius: 50%;
      color: #fff;
      position: absolute;
      top: 8px;
      right: le;
      font-size: 11px;
      font-weight: bold;
    }
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  > button {
    padding: 1.2rem 1.5rem;
  }
`;

export const ContentGrid1 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  grid-template-columns: repeat(1, 1fr);
  position: relative;
  
  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  
  /* MOBILE */
  @media (max-width: 480px) {}
  
  > section:nth-child(1) {
    border-radius: 12px;
    padding: 2rem;
    background: ${props => props.theme.cards.primary};
    backdrop-filter: ${props => props.theme.cards.backdropFilter};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
    
    /* MOBILE */
    @media (max-width: 480px) {
      padding: 1rem 0;
    }
  }
`;

export const ModalContainer = styled.section`
  
  > h4 {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 0.75rem;
    font-size: 16px;

    > svg {
      font-size: 16px;
    }
  }

  > p {
    font-size: 14px;
    margin-left: 20px;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.white};

    &:last-child {
      margin-bottom: 0;
    }

    > a {
      color: ${props => props.theme.colors.white};
    }
  }

  > footer {
    margin-top: 2rem;

    > button {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`;

export const Line1 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
`;

export const Line2 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
`;


export const Line5 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
`;

export const Line6 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;

  > button {
    width: 100%;
    height: 50px;
  }
`;