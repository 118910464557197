import React from 'react';

import { Container } from './styles';

interface INotFoundRegisterProps {
  description?: string;
  emoji?: string;
  style?: React.CSSProperties;
}	

const NotFoundRegister: React.FC<INotFoundRegisterProps> = ({ description, style, emoji = '😢' }) => {
  return (
    <Container style={style}>
      {emoji && <h1>{emoji}</h1>}
      <p>{description || 'Nenhum registro encontrado.'}</p>
    </Container>
  );
}

export default NotFoundRegister;