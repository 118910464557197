import React, { useEffect, useState } from 'react';

// import { Container } from './styles';

interface IShowByBreakpointProps {
  size: number;
  children: React.ReactNode;
}

const ShowByBreakpoint: React.FC<IShowByBreakpointProps> = ({
  size,
  children,
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <>{windowSize >= size && children}</>;
};

export default ShowByBreakpoint;
