import React, { useCallback, useContext, useState } from 'react';

import { useAuth } from '../../hooks/auth';
import { AnswerContext } from '../../pages/user/ComunidadeFeed';

import api from '../../services/api';

import { convertToRaw, EditorState, ContentState } from 'draft-js';

// @ts-ignore
import draftToMarkdown from 'draftjs-to-markdown';

import { Editor } from 'react-draft-wysiwyg';

import { Container } from './styles';

import toast from 'react-hot-toast';

import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import AwesomeButtonAdapter from '../AwesomeButtonAdapter';

interface IWysiwygProps {
  title?: string;
  placeholder?: string;
  maxLength: number;
  requestRoute: string;
}

const Wysiwyg: React.FC<IWysiwygProps> = ({ title, placeholder, maxLength, requestRoute }) => {
  const { user } = useAuth();
  const [editor, setEditor] = useState<EditorState>();
  const [loadingReply, setLoadingReply] = useState(false);

  // Context
  const { answersData, setAnswersData } = useContext(AnswerContext);

  async function uploadImageCallBack(file: File) {
    try {
      if (file.size > 1024 * 1024 * 2) {
        throw new Error('exceeded-size-limit');
      }
      const data = new FormData();
      data.append("file", file);

      const response = await api.patch('/forums/upload', data);

      return { data: { link: response.data } };
    } catch (error: any) {
      console.log(error);

      if (error.message === 'exceeded-size-limit') {
        toast.error('😢 Tamanho da imagem excedido. Máximo de 2MB!', {
          position: "bottom-center",
          duration: 6000,
        });
      }
    }
  }

  const handleEditorStateChange = (editorState: EditorState) => {
    if (editor) {
      const contentState = editorState.getCurrentContent();
      const oldContent = editor.getCurrentContent();

      if (contentState === oldContent || contentState.getPlainText().length <= maxLength) {
        setEditor(editorState);
      } else {
        const editorState = EditorState.moveFocusToEnd(
          EditorState.push(
            editor,
            ContentState.createFromText(oldContent.getPlainText()),
            'undo',
          ));
        setEditor(editorState);
      }
    } else {
      setEditor(editorState);
    }
  }

  const handleSubmitReply = useCallback(async (value: EditorState | any) => {
    const content = convertToRaw(value.getCurrentContent());
    console.log('content', content);
    console.log(draftToMarkdown(content));

    try {
      setLoadingReply(true);
      const response = await api.post(requestRoute, {
        content: capitalizeFirstLetter(draftToMarkdown(content)),
      });

      console.log(response.data);

      if (response.status === 200) {
        toast.success('Resposta enviada com sucesso!', { 
          position: 'bottom-right', 
          duration: 6000,
          className: 'toast-samuquinha',
        });

        setLoadingReply(false);

        setAnswersData([
          ...answersData,
          {
            ...response.data,
            author: {
              id: user.id,
              show_name: user.show_name,
              avatar_url: user.avatar_url,
              role_name: user.role_name,
              level: user.level,
              current_exp: user.current_exp,
              career: {
                name: user.career?.name,
              },
            },
            is_verified: false,
            upvotes: 0,
            downvotes: 0,
            user_vote: null,
          }
        ]);

        const mainScroll = document.querySelector('#main-scroll');

        if (mainScroll) {
          document?.querySelector(".last")?.scrollIntoView({ 
            behavior: 'smooth', block: 'center'
          });
        }
      }

      setEditor(EditorState.createEmpty());
    } catch (err) {
      console.log(err);
      setLoadingReply(false);
    } finally {
      setLoadingReply(false);
    }
  }, [requestRoute, answersData, setAnswersData, user]);


  return (
    <Container>
      <header>
        <p>{title ? title : 'Escreva uma resposta'}</p>
      </header>
      <Editor
        editorState={editor}
        onEditorStateChange={(editorState: EditorState) => { handleEditorStateChange(editorState) }}
        toolbarClassName="toolbarClassName2"
        wrapperClassName="wrapperClassName2"
        editorClassName="editorClassName2"
        handlePastedText={() => false}
        localization={{
          locale: 'pt',
        }}
        placeholder={placeholder ? placeholder : ''}
        stripPastedStyles={true}
        toolbar={{
          options: ['inline', 'list', 'emoji', 'image'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered'],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_blank',
            options: ['link', 'unlink'],
            linkCallback: undefined
          },
          emoji: {
            className: 'add',
            component: undefined,
            popupClassName: undefined,
            emojis: [
              '💜', '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇', '🙂', '🙃', '😉', '😌', '😍', '🥰', '😘',
              '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🤩', '🥳', '😏',
              '😱', '🤗', '🤭', '😬', '😲', '🤠', '👋', '🤚', '🖐', '✋', '🖖', '👌', '✌️', '🤞', '🤟', '🤘', '🤙',
              '👈', '👉', '👆', '👇', '☝️', '👍', '👎', '👊', '🤛', '🤜', '👏', '🙌', '🤝', '🙏',
              '✍️', '💅', '💪', '🧠', '👀', '🏆', '🥇', '🥈', '🥉', '🏅', '🖊', '🖋', '✒️', '🖌', '🖍', '📝', '✏️',
            ],
          },
          image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: false,
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            alt: { present: false, mandatory: false },
            defaultSize: {
              width: '100%',
            },
          },
        }}
      />
      <footer>
        <div>
          <p>{editor ? editor?.getCurrentContent().getPlainText().length : 0}/{maxLength}</p>
        </div>
        <div>
          <AwesomeButtonAdapter
            type="primary"
            ripple={true}
            disabled={!loadingReply && editor && editor?.getCurrentContent().getPlainText().length > 0 ? false : true}
            onPress={() => handleSubmitReply(editor)}
          >
            Responder tópico
          </AwesomeButtonAdapter>
        </div>
      </footer>
    </Container>
  );
}

export default Wysiwyg;
