import React, { useEffect, useState, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import api from '../../../services/api';

import { CurrencyCircleDollar, Exam, House, MagnifyingGlass, Question, SmileyWink, Student, UserCircle, YoutubeLogo } from 'phosphor-react';

import InputAnimateDropdown from '../../../components/InputAnimateDropdown';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import LoaderBIO from '../../../components/Loader';
import Breadcrumbs from '../../../components/Breadcrumbs';

import { Container, ContentGrid1, ContentGrid2, SearchButton } from './styles';
import { useTheme } from '../../../hooks/theme';

interface ISuportProps {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  position: number;
}

interface IIconsProps {
  [key: string]: ReactNode;
}

const SuporteTecnico: React.FC = () => {
  const location = useLocation();
  const { theme } = useTheme();
  const [loading, setLoading] = useState(true);
  const [suporteCategories, setSuportCategories] = useState<ISuportProps[]>([]);

  const [search, setSearch] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get('/sections');

        console.log('LIST SUPORT CATEGORIES >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setSuportCategories(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    // get query params with react-router-dom
    const query = new URLSearchParams(location.search);
    const search = query.get('query');

    if (search) {
      setSearch(search);
    }
  }, [location.search]);

  const iconColor = theme === 'dark' ? '#03bb85' : '#aa14cb';

  const icons: IIconsProps = {
    'coins': <CurrencyCircleDollar size={48} weight="duotone" color={iconColor} />,
    'user': <UserCircle size={48} weight="duotone" color={iconColor} />,
    'video': <YoutubeLogo size={48} weight="duotone" color={iconColor} />,
    'styudy-plans': <Student size={48} weight="duotone" color={iconColor} />,
    'exams': <Exam size={48} weight="duotone" color={iconColor} />,
    'outher': <SmileyWink size={48} weight="duotone" color={iconColor} />,
  }

  return (
    <Container>
      <HeaderSectionContainer padding="1rem 2rem">
        <h1><Question weight="duotone" /> Central de ajuda</h1>
        <p>Preparamos uma dezena de artigos para solucionar qualquer probleminha da plataforma!</p>
      </HeaderSectionContainer>
      <Breadcrumbs
        icon={House}
        separator="/"
        padding="1rem 0"
        route={[
          { title: 'Central de ajuda', link: '/suporte' },
        ]}
      />
      <ContentGrid1>
        <div>
          <form onSubmit={e => e.preventDefault()}>
            <InputAnimateDropdown
              label="Qual é a sua dúvida?"
              type="search"
              name="duvida"
              margin="0"
              required
              defaultValue={search}
            />
            <SearchButton>
              <MagnifyingGlass weight="duotone" />
            </SearchButton>
          </form>
        </div>
      </ContentGrid1>
      <ContentGrid2>
        {suporteCategories.map((category, index) => (
          <Link to={`/suporte/artigos/${category.slug}`} key={index}>
            {icons[category.icon]}
            <h1>{category.name}</h1>
            <p>{category.description}</p>
          </Link>
        ))}
        <LoaderBIO isVisible={loading} />
      </ContentGrid2>
    </Container>
  );
}

export default SuporteTecnico;
