import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -14px;

  > h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const SectionContainer1 = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    background: ${props => props.theme.cards.primary};
    border-radius: ${props => props.theme.radius.primary};
    border: 1px solid ${props => props.theme.cards.border};
    padding: 2rem 1.5rem;
  }
`;

export const Header = styled.header`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1.5rem 0 2rem 0;
`;