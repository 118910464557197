import styled from 'styled-components';

export const Container = styled.div`
`;

export const CardTableContainer = styled.section`

`;

export const HeaderSection = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;

  > aside {
    display: flex;
    gap: 1rem;

    > button {
      padding: 16px;
      text-transform: capitalize;
      font-size: 13px;
      border-radius: 6px;

      > svg {
        margin-right: 0.35rem;
      }
    }

    > section {

      > span {
        font-size: 11px;
        margin-right: 1rem;
      }
      
      > select {
        color: ${({ theme }) => theme.colors.white};
        height: 45px;
        width: 180px;
        border: 1px solid ${({ theme }) => theme.cards.border};
        padding: 0 0.5rem;
        border-radius: 6px;
        background: ${({ theme }) => theme.cards.primary};

        > option {
          background: ${({ theme }) => theme.modal.background};
        }
      }
    }

  }
`;

export const StatusBadge = styled.span`
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 12px;
`;

export const Poster = styled.img`
  width: 70px;
  margin: 4px 0;
  border-radius: 4px;
  margin-right: 16px;
`;

export const ButtonContext = styled.button`

  border-radius: 4px;
  margin-right: 16px;
`;
