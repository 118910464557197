import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import { X } from 'phosphor-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { IAnnouncementsProps } from '../../interfaces/Announcements';

import { CloseBtn, Container, MessageContainer, ModalAlertContainer, ModalHome, Poster } from './styles';

import avatar from '../../assets/samuquinha.png';

const ModalHomeAlert: React.FC = () => {

  // Data
  const [announcements, setAnnouncements] = useState<IAnnouncementsProps>({} as IAnnouncementsProps);

  // Modals
  const [openModal, setOpenModal] = useState(true);

  // States
  const [image, setImage] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/announcements/active`);
        console.log(response.data);

        setAnnouncements(response.data);
        const description = response.data.description;

        if (description.includes('![image]')) {
          const imageSplited = description.split('![image]')[1].replace('(', '').replace(')', '');
          console.log('imageSplited', imageSplited);
          setImage(imageSplited);
        }
      } catch (error) {
        console.log(error);
        setOpenModal(false);
      }
    })();
  }, []);

  return (
    <Container>
      <ModalHome
        onRequestClose={() => setOpenModal(false)}
        isOpen={openModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        closeTimeoutMS={300}
        overlayClassName="react-modal-overlay"
      >
        <ModalAlertContainer>
          {image ? (
            <Poster>
              <CloseBtn onClick={() => setOpenModal(!openModal)}><X weight="bold" /></CloseBtn>

              <img src={image} alt={announcements.title} />
            </Poster>
          ) : (
            <MessageContainer>
              <CloseBtn onClick={() => setOpenModal(!openModal)}><X weight="bold" /></CloseBtn>

              <main>
                <aside>
                  <hgroup>
                    <h1>{announcements.title}</h1>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} linkTarget="_blank">{announcements.description?.replace(/\\s/g, '\n')}</ReactMarkdown>
                  </hgroup>
                </aside>
                <footer>
                  <img src={avatar} alt="" />
                </footer>
              </main>
            </MessageContainer>
          )}
        </ModalAlertContainer>
      </ModalHome>
    </Container>
  );
}

export default ModalHomeAlert;