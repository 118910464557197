import styled from 'styled-components';
import { API_S3 } from '../../../services/api_s3';
import { maxWidthContent } from '../../../utils/maxWidthContent';

export const Container = styled.div`
  margin-top: -14px;
  background: url(${API_S3}/samuel/bg-simulate.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 65px);
  height: 100%;

  @media (max-width: 500px) {
    background-position: center right;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #000 0%, rgba(255,255,255,0) 100%);
    z-index: 0;
  }

  .closedd, .openn {
    background: transparent;
    border: 1px solid #ffffff4a;
    
    &:focus {
      border: 1px solid #ffffff4a;
    }

    > main {
      color: #ffffff;
    }

    > aside {
      border-left: 1px solid #ffffff;

      > svg {
        color: #fff;
      }
    }
  }

  .label-multi-select {
    background: #00000008;
    color: #fff;
    backdrop-filter: blur(11px);
  }

  > h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  justify-content: space-between;
`;

export const SectionContainer1 = styled.section`
  position: relative;
  width: 100%;
  max-width: ${maxWidthContent};
  margin: 0 auto;
  padding: 6rem 0;
  z-index: 1;

  @media (min-width: 982px) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const RightContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 3rem;
  padding-left: 3rem;


  @media (min-width: 982px) {
    width: 50%;
    padding-left: 0;
  }

  > h1 {
    color: #ffffff;
    font-size: 60px;
    margin-bottom: 4rem;

    @media (max-width: 981px) {
    font-size: 40px;
    }

    @media (max-width: 500px) {
      font-size: 30px;
    }
  }

  > p {
    color: #ffffff;
    font-size: 12px;
    margin-bottom: 2rem;
    line-height: 1.75;
  }

  > form {
    position: relative;
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;

    @media (max-width: 650px) {
      > div {
        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          > div {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const FormContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Line1 = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
`;

export const Line2 = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1.4fr 1fr 1fr;
  gap: 1rem;
`;

export const Line3 = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 1rem;

  button, div {
    width: 100%;
  }

  .btn-nonee {
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid #ffffff4a !important;
    height: 50px;
    border: none !important;
    font-size: 12px;
    width: fit-content;
    font-weight: 500;
    margin: 0 auto;
    text-decoration: none;
    color: #fff;
    gap: 10px;
    transition: all 0.25s ease-in-out;


    &:hover {
      color: #ffffff95;
    }

    > svg {
      margin: 0 10px 0 0 0 !important;
    }
  }
`;

export const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > h1 {
    font-size: 1.5rem;
    width: 100%;
    text-align: left;
  }

  > p {
    font-size: 1rem;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
  }
`;