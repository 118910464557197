import React from 'react';

import { Container } from './styles';

import { IconBaseProps } from 'react-icons';

interface IButtonIconSquareProps {
  icon: React.ComponentType<IconBaseProps>;
  strokeWidth?: number;
  size?: number;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  children?: React.ReactNode;
}

const ButtonIconSquare: React.FC<IButtonIconSquareProps> = ({
  icon: Icon,
  onClick,
  className,
  strokeWidth = 2.5,
  size = 20,
  children,
}) => {
  return (
    <Container onClick={onClick}>
      <button className={className}>
        {children}
        <Icon strokeWidth={strokeWidth} size={size} />
      </button>
    </Container>
  );
};

export default ButtonIconSquare;
