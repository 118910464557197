import { BellRinging, Book, Books, Calendar, Chat, Exam, FilmSlate, GraduationCap, House,  MonitorPlay,  Student,  UserSquare, YoutubeLogo } from "phosphor-react";
import { IMenuLink } from "./MenuUserLinks";

const menuLinks: IMenuLink[] = [
  {
    "title": 'Dashboard',
    "location": '/',
    "icon": House
  },
  {
    "title": 'Alunos',
    "location": '/alunos',
    "icon": UserSquare
  },
  {
    "title": 'Módulos',
    "location": '/modulos',
    "icon": YoutubeLogo
  },
  {
    "title": 'Plano de Estudos',
    "location": '/plano-de-estudos',
    "icon": Student
  },
  {
    "title": 'Vestibulares',
    "location": '/vestibulares',
    "icon": GraduationCap
  },
  {
    "title": 'Cursos Extras',
    "location": '/cursos-extras',
    "icon": MonitorPlay
  },
  {
    "title": 'Materiais didáticos',
    "location": '/materiais',
    "icon": Book
  },
  {
    "title": 'Filmes',
    "location": '/filmes',
    "icon": FilmSlate
  },
  {
    "title": 'Livros',
    "location": '/livros',
    "icon": Books
  },
  {
    "title": 'Simulados',
    "location": '/simulados',
    "icon": Exam
  },
  {
    "title": 'Calendário',
    "location": '/calendario',
    "icon": Calendar
  },
  {
    "title": 'Avisos',
    "location": '/avisos',
    "icon": BellRinging
  },
  {
    "title": 'Artigos do suporte',
    "location": '/suporte',
    "icon": Chat
  },
]

export default menuLinks;