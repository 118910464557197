import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -14px;

  > h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: 20px;
  flex: 1;
  max-width: 450px;

  > svg {
    margin-right: 1rem;
    position: absolute;
    color: ${props => props.theme.colors.muted}80;
  }

  > input {
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
    color: ${props => props.theme.colors.white};
    width: 100%;
    max-width: 300px;
    transition: all .3s ease-in-out;

    &:focus {
      max-width: 450px;
    }
  }
`;

export const SectionContainer1 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  /* DESKTOP */
  @media (max-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }

  /* TABLET */
  @media (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  /* TABLET MINI */
  @media (max-width: 830px) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* MOBILE */
  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CardExtraCourse = styled(Link)`
  text-decoration: none;
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    box-shadow :  0 0 5px #11111109,
                  0 0 25px #11111109,
                  0 0 50px #11111109,
                  0 0 100px #11111109;
  }

  text-decoration: none;
  user-select: none;
  
  .rect-progress-bar-percent {
    display: none !important;
  }

  .inner-rect-bar {
    background: #79ffb8  !important;
    border-radius: 0px !important;
  }

  .progress-bar-rect-wrapper {

    > div {
      border-radius: 0px !important;
      overflow: hidden !important;
      background: #cbecf233 !important;
    }
  }

  > div {

    > img {
      max-width: fit-content;
      width: 100%;
      height: auto;
      filter: none;
      
      &.svg-light-mode {
        filter: hue-rotate(180deg) brightness(100);
      }

      &.svg-dark-mode {
        filter: none;
      }
    }
  }

  > footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;
  
      > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 1.5rem;
        gap: 0.25rem;
        
        > h5 {
          font-size: 2rem;
          font-weight: 900;
        }
        > p {
          font-size: 0.75rem;
          padding-top: 1rem;
          color: ${props => props.theme.colors.muted};
          font-weight: 500;
        }
      }
      
      > footer {
        margin-top: -10px;


        
        h2 {
          display: none !important;
        }
      }
    }
`;
