import styled from 'styled-components';

export const Container = styled.div`

`;

export const HeaderSection = styled.section`
  display: flex;
  margin: 1rem 0 1rem 0;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const SectionContainer2 = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

export const SectionContainer3 = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {

    &.none {
      border-radius: 12px !important;
    }

    &.first {
      border-radius: 12px 12px 0 0 !important;
      border-bottom: none !important;
    }
    
    &.rect {
      border-radius: 0 !important;
      border-bottom: none !important;
    }
    
    &.last {
      border-radius: 0 0 12px 12px !important;
    }
  }
`;

export const SectionContainerHeader = styled.section`
  display: grid;
  gap: 2rem;
  grid-template-columns: 200px auto;
  margin: 2rem 0px 1rem 0;
  align-items: center;
  padding: 0 1.5rem;

  @media (max-width: 767px) {
    display: none;
  }
  
  > aside:nth-child(1) {
    display: flex;
    align-items: center;
    
    > h5 {
      color: ${props => props.theme.colors.muted};
      font-size: 0.7rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  
  > aside:nth-child(2) {
    display: flex;
    align-items: center;
    
    > h5 {
      color: ${props => props.theme.colors.muted};
      font-size: 0.7rem;
      font-weight: 500;
      text-transform: uppercase;
      text-align: left;
      width: 100%;
    }
  }
  
  > aside:nth-child(3) {
    display: flex;
    align-items: center;
    
    > h5 {
      color: ${props => props.theme.colors.muted};
      font-size: 0.7rem;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
    }
  }
`;

export const PaginationContainer = styled.section`
  position: relative;
  width: 100%;
  margin: 2rem 0;
  
  > p {
    text-align: center;
    font-size: 12px;
  }
`;

export const WysiwygContainer = styled.section`
  position: relative;
  width: 100%;
`;

export const VerifiedContainer = styled.section`
  position: relative;
  width: 100%;

  .card-verified {
    animation: 2s infinite ease-in-out pulseAnimation2;
    margin-bottom: 1rem;
  }
`;