import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";

import { Calendar, dateFnsLocalizer, Event, Messages } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { format, parse, startOfWeek, getDay, startOfMonth, endOfMonth, endOfWeek } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useForm } from 'react-hook-form';
import { FiCalendar, FiLink, FiMessageSquare } from 'react-icons/fi';
import { CalendarBlank, CalendarPlus, House } from 'phosphor-react';
import toast from 'react-hot-toast';

import Breadcrumbs from '../../../components/Breadcrumbs';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import Loader from '../../../components/Loader';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import Input from '../../../components/Form/Input';
import Textarea from '../../../components/Form/Textarea';
import InputDatePicker from '../../../components/Form/InputDatePicker';
import AwesomeButtonAdapter from '../../../components/AwesomeButtonAdapter';

import { ButtonContainer, Container, ContentGrid1, HeaderSection, Line1, Line2, Line5, Line6, ModalContainer } from './styles';

// Interfaces
import { IschedulesProps } from '../../../interfaces/Schedule';
import { useAuth } from '../../../hooks/auth';

// Validation
const schema = Joi.object({
  title: Joi.string().min(5).required().messages({ '*': 'Informe um título válido.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
  start_date: Joi.date().required().messages({ '*': 'Informe uma data válida.' }),
  link: Joi.string().uri().allow('').messages({ '*': 'Informe um link válido válida.' }),
  description: Joi.string().max(300).required().messages({ '*': 'Informe uma descrição válida.', 'string.max': `O campo descrição deve ter no máximo {#limit} caracteres`, }),
});

const Calendario: React.FC = () => {
  const { WithAuthorization } = useAuth();

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data)
      console.log('validation result', await joiResolver(schema)(data, context, options))
      return joiResolver(schema)(data, context, options)
    },
  });

  const [buttonLoading, setButtonLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const [startDate, setStartDate] = useState(startOfWeek(startOfMonth(new Date())));
  const [endDate, setEndDate] = useState(endOfWeek(endOfMonth(new Date())));

  const [events, setEvents] = useState<IschedulesProps[]>([]);

  const [currentOpenEvent, setCurrentOpenEvent] = useState<IschedulesProps>();

  // Modal 
  const [modalAddEventIsOpen, setModalAddEventIsOpen] = useState(false);
  const [modalShowEventIsOpen, setModalShowEventIsOpen] = useState(false);

  // Get window dimensions
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(`/appointments`, {
          params: {
            from: startDate,
            to: endDate,
          }
        });

        console.log(response.data);
        setEvents(response.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [startDate, endDate]);

  // Remove Event from calendar
  const handleRemoveFromCalendar = useCallback(async (id: string) => {
    try {
      setButtonLoading(true);
      await api.delete(`/appointments/${id}`);

      toast.error('Lembrete excluído com sucesso!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });

      setModalShowEventIsOpen(false);

      setEvents(oldEvents =>
        oldEvents.filter(events => events.id !== id),
      );

    } catch (error) {
      setButtonLoading(false);
      console.log(error);
    }
    finally {
      setButtonLoading(false);
    }
  }, []);

  // Modal open single event
  const handleOpenEvent = useCallback((event: any) => {
    setModalShowEventIsOpen(!modalShowEventIsOpen);
    setCurrentOpenEvent(event);
  }, [modalShowEventIsOpen]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setButtonLoading(true);

      console.log(data);
      const response = await api.post(`/appointments`, {
        title: data.title,
        start_date: data.start_date,
        end_date: data.start_date,
        link: data.link || null,
        description: data.description,
      });

      console.log(response.data);
      setEvents(oldEvents => [...oldEvents, response.data]);

      toast.success('Lembrete cadastrado com sucesso!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });
      reset();
      setModalAddEventIsOpen(false);
    } catch (error) {
      console.log(error);
      toast.error('Ops! Algo deu errado.', { position: 'bottom-center', });
      setButtonLoading(false);
    }
    finally {
      setButtonLoading(false);
    }
  });

  const locales = { 'pt-BR': ptBR };
  const localizer = dateFnsLocalizer({ format, parse, startOfWeek, getDay, locales });
  const messages: Messages = {
    allDay: 'Dia Inteiro',
    previous: '<',
    next: '>',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento',
    showMore: ((total) => `+ ${total} evento${total > 1 && 's'}`),
  }

  useEffect(() => {
    reset();
  }, [modalAddEventIsOpen, reset]);


  return (
    <>
      <Container>
        <HeaderSectionContainer padding="1rem 2rem">
          <h1><CalendarBlank weight="duotone" /> Calendário</h1>
          <p>Gerencie e crie facilmente eventos ou lembretes!</p>
        </HeaderSectionContainer>

        <HeaderSection>
          <Breadcrumbs
            icon={House}
            separator="/"
            route={[{ title: 'Calendário', link: '/calendario' }]}
          />
          <ButtonContainer>
            <AwesomeButtonAdapter
              type="primary"
              ripple={true}
              onPress={() => setModalAddEventIsOpen(!modalAddEventIsOpen)}
            >
              Criar novo evento global
              <CalendarPlus size={32} color="#ffffff" weight="fill" />
            </AwesomeButtonAdapter>
          </ButtonContainer>
        </HeaderSection>

        <ContentGrid1>
          <section>
            <Calendar
              popup={true}
              culture="pt-BR"
              defaultView="month"
              events={events.map(({ start_date: start, end_date: end, ...rest }) => ({ start, end, ...rest }))}
              messages={messages}
              localizer={localizer}
              startAccessor="start"
              onNavigate={(date, view, action) => {
                if (view === 'month') {
                  setStartDate(startOfWeek(startOfMonth(date)));
                  setEndDate(endOfWeek(endOfMonth(date)));
                }
              }}
              view="month"
              views={["month"]}
              endAccessor="end"
              onSelectEvent={(event: Event) => handleOpenEvent(event)}
              formats={{
                weekdayFormat: (date) => {
                  const formattedDate = windowDimensions.width < 780 ? format(date, 'EEEEEE', { locale: ptBR }) : format(date, 'EEEE', { locale: ptBR });
                  return formattedDate;
                }
              }}

              style={{ height: 777, }}
              eventPropGetter={(event) => {
                // @ts-ignore
                if (event.title === 'Data do fim do seu plano') {
                  return { style: { backgroundColor: '#ff0000' } }
                }
                // @ts-ignore
                if (!event.id) {
                  return { style: { backgroundColor: '#f09c54' } }
                }
                //@ts-ignore
                return { style: { backgroundColor: event.user_id === null ? '#2cc292' : '#aa14cb' } }
              }}
            />
          </section>
          <Loader isVisible={loading} />
        </ContentGrid1>
      </Container>

      {/* MODALS */}

      {/* MODAL ADD EVENT */}
      <Modal
        isOpen={modalAddEventIsOpen}
        setIsOpen={() => setModalAddEventIsOpen(!modalAddEventIsOpen)}
        closeModal={() => setModalAddEventIsOpen(false)}
        size="md"
        title="Cadastrar lembrete"
        subTitle="Gerencie e crie facilmente seus eventos ou lembretes!"
      >
        <ModalContainer>
          <form onSubmit={onSubmit}>
            <Line1>
              <div>
                <Input register={register} name="title" type="text" label="Nome do evento*" autoComplete="off" />
                {errors.title && <MessageErrorValidator>{errors.title.message as string}</MessageErrorValidator>}
              </div>
              <div>
                <InputDatePicker
                  register={register}
                  name="start_date"
                  label="Data e hora*"
                  setResult={(values) => { setValue('start_date', values) }}
                />
                {errors.start_date && <MessageErrorValidator>{errors.start_date.message as string}</MessageErrorValidator>}
              </div>
            </Line1>
            <Line2>
              <div>
                <Input register={register} name="link" type="text" label="Link (opcional)" autoComplete="off" />
                {errors.link && <MessageErrorValidator>{errors.link.message as string}</MessageErrorValidator>}
              </div>
            </Line2>
            <Line5>
              <Textarea register={register} name="description" type="text" label="Descreva o evento" autoComplete="off" />
              {errors.description && <MessageErrorValidator>{errors.description.message as string}</MessageErrorValidator>}
            </Line5>
            <Line6>
              <Button type="submit" color="primary" loading={buttonLoading} disabled={buttonLoading}>Cadastrar Lembrete</Button>
            </Line6>
          </form>
        </ModalContainer>
      </Modal>

      {/* MODAL SHOW EVENT */}

      <Modal
        isOpen={modalShowEventIsOpen}
        setIsOpen={() => setModalShowEventIsOpen(!modalShowEventIsOpen)}
        closeModal={() => setModalShowEventIsOpen(false)}
        size="lg"
        title={currentOpenEvent?.title}
      >
        <ModalContainer>
          <h4><FiCalendar strokeWidth={3} /> Data e hora:</h4>
          {/* @ts-ignore */}
          <p>{currentOpenEvent?.start && format(new Date(currentOpenEvent?.start), "EEEE, dd 'de' MMMM 'às' HH:mm", { locale: ptBR })}</p>
          <h4><FiLink strokeWidth={3} /> Link:</h4>
          <p>{currentOpenEvent?.link ? <a href={currentOpenEvent?.link}>{currentOpenEvent?.link}</a> : 'Você não definiu nenhum link para este evento.'}</p>
          <h4><FiMessageSquare strokeWidth={3} /> Descrição:</h4>
          <p>{currentOpenEvent?.description || 'Você não definiu nenhuma descrição para este evento.'}</p>
          <footer>

            <WithAuthorization roles={['admin']}>
              {currentOpenEvent?.id && (
                <Button
                  type="button"
                  color="error"
                  onClick={() => handleRemoveFromCalendar(currentOpenEvent?.id)}
                  loading={buttonLoading}
                  disabled={buttonLoading}
                >
                  Remover do calendário
                </Button>
              )}
            </WithAuthorization>
          </footer>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default Calendario;