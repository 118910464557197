import styled from 'styled-components';

export const Container = styled.div`
  margin-top: -14px;

  > h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

export const HeaderSection = styled.section`
  display: flex;
  flex-direction: row;
  margin: 2rem 0;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;

    button {
      width: 100%;
    }
  }
`;

export const SectionContainer1 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 600px) {

    > div {
      height: auto !important;

      > main {
        display: flex;
        flex-direction: column;
        width: 100%;

        > div:nth-child(1) {
          display: flex;
          flex-direction: column !important;
          gap: 1rem;
          margin-bottom: 1rem;
        }

        > div {
          width: 100%;
          display: flex;
          justify-content: center;

          > div {
            width: 180px !important;
            height: 180px !important;

            h2 {
              font-size: 2.8rem;
            }

            p {
              font-size: 14px;
            }
          }

          &:nth-child(2) {
            margin: 0 0 1.5rem 0;
          }
        }
      }
    }
  }
`;

export const PaginationContainer = styled.section`
  position: relative;
  width: 100%;
  margin-top: 2rem;

  > p {
    position: relative;
    text-align: center;
    font-size: 12px;
  }
`;