import styled from 'styled-components';

export const Container = styled.div`
  .aws-btn {
    --button-default-height: 50px;
    --button-default-font-size: 14px;
    --button-default-border-radius: 10px;
    --button-horizontal-padding: 32px;
    --button-raise-level: 0px;
    --button-hover-pressure: 0;
    --transform-speed: 0.185s;

    --button-primary-color: #9080fa;
    --button-primary-color-dark: #9080fa;
    --button-primary-color-light: #ffffff;
    --button-primary-color-hover: #9080fa;
    --button-primary-border: 2px solid #9080fa;

    --button-secondary-color: #2cc292;
    --button-secondary-color-dark: #11bda6;
    --button-secondary-color-light: #ffffff;
    --button-secondary-color-hover: #3f7c72;
    --button-secondary-border: 1px solid #28d5be;

    --button-anchor-color: #293b83;
    --button-anchor-color-dark: #772250;
    --button-anchor-color-light: #b33377;
    --button-anchor-color-hover: #293b83;
    --button-anchor-border: 1px solid #9f2d6a;
  }

  display: flex;
  align-items: center;
  
  svg {
    margin: 0 0 0 10px !important;
  }

  .aws-btn--disabled .aws-btn__content {
    background: #ffffff00;
    color: #fff;
    cursor: not-allowed;
  }
`;
