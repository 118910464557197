import { Link } from 'react-router-dom';
import styled from 'styled-components';


interface IMovieCardProps {
  cover_url: string;
  height?: string;
}

export const Container = styled(Link)<IMovieCardProps>`
  position: relative;
  text-decoration: none;
  margin-bottom: 1rem;
  cursor: default;  

  > main {
    position: relative;
    background-image: url(${props => props.cover_url});
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100%;
    background-position: center center;
    width: 100%;
    height: ${props => props.height ? props.height : '350px'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: ${props => props.theme.colors.shadowBox};
    transition: all .2s ease-in-out;
    background-size: cover;
    margin-bottom: 0.5rem;
    overflow: hidden;

    &:hover {
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      inset: 0px;
      background-image: linear-gradient(to bottom,#000000 -15%,transparent 25%);
      opacity: 1;
      transition: all .3s ease-in-out 0s;
      border-radius: 7px;
    }

    &:hover {

      &::before {
        content: "";
        position: absolute;
        inset: 0px;
        background-image: linear-gradient(to bottom, transparent, ${props => props.theme.colors.bgBox} 100%);
        opacity: 1;
        transition: all 0.3s ease-in-out 0s;
        border-radius: 0;
        border-radius: 7px;
      }

      > div {
        background: ${props => props.theme.colors.primary};
        border: 4px solid #ffffff;
        color: #ffffff;
      }
    }

    > section {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    > div {
      width: 64px;
      height: 64px;
      border: 4px solid #ffffff77;
      color: #ffffff77;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      transition: all .3s ease-in-out;
      position: absolute;
      top: 40%;

      &:hover {
        background: ${props => props.theme.colors.primary};
        border: 4px solid #ffffff;
        color: #ffffff;
      }
    }

    > footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 8px 4px;
      background: #00000057;
      backdrop-filter: blur(4px);
      color: #fff;
      transition: all .25s ease-in-out;
    }
  }

  > header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: red;
    z-index: 9;

    > p {

    }
  }

  > footer {
    position: relative;
    z-index: 9;
    margin-top: .5rem;

    > a {
      color: ${props => props.theme.colors.white};
      font-size: 14px;
      font-weight: 600;
      text-decoration: none;
      margin-bottom: 0.25rem;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 20ch;
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const RatingContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    > p {
      font-size: 11px;
      line-height: 2;
    }
  }

  /* MOBILE */
  @media (max-width: 330px) {
    display: none;
  }

  > span {
    margin-left: .5rem;
    margin-top: 2px;
    font-size: 13px;
    font-weight: bold;
    color: ${props => props.theme.colors.gray};
  }

  > div {

    > span {
      line-height: 1.35;
    }
  }
`;

export const ModalMoviesDetails = styled.div`
  display: flex;
  margin: -32px -53px;
  overflow: hidden;

  > main {
    position: relative;
    border-radius: 10px;
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center center;
    background-size: cover;
    overflow: hidden;

    &:after {
      content: '';
      background: #21114780;
      position: absolute;
      width: 110%;
      height: 110%;
      left: -15px;
      top: -15px;
      backdrop-filter: blur(5px);
      border-radius: 10px;
    }

    > section {
      z-index: 999;
    }
  }

`;
