import styled from 'styled-components';

export const Container = styled.div`
  //margin-bottom: 7rem;
`;

export const MainContent = styled.section`
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
  padding: 0 1rem;
  grid-template-columns: 1.15fr 5fr;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }

  > aside {
    
    > div {
      position: sticky;
      top: -1rem;
      
      background: ${props => props.theme.cards.primary};
      border: 1px solid ${props => props.theme.cards.border};
      box-shadow: ${props => props.theme.shadows.primary};
      border-radius: ${props => props.theme.radius.primary};
    }
  }

  > main {

    > section {
      display: flex;
      padding: 2rem;
  
      background: ${props => props.theme.cards.primary};
      border: 1px solid ${props => props.theme.cards.border};
      box-shadow: ${props => props.theme.shadows.primary};
      border-radius: ${props => props.theme.radius.primary};
    }
  }
`;

export const ModalContainer = styled.section`

  > h2 {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  
  > ul {
    margin-left: 1.25rem;
    font-size: 13px;
    margin-bottom: 1rem;
  }

  > p {
    font-size: 12px;
  }

  > button {
    margin: 1rem 0 0 0;
    width: 100%;
  }
`;

export const FormProfile = styled.section`
  position: relative;
  width: 100%;

  > header {

    > h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      margin-bottom: 2rem;

      > svg {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const Line1 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const Line2 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
  }
  
  textarea {
    height: 182px;
  }
`;

export const LineButton = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  > button {
    
    &:hover {
      background: #21a57b;
    }
  }
`;

export const ChangeCoverAndAvatarContainer = styled.main`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
  gap: 1rem;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CardChange = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  padding: 2rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;

  &:hover {
    transform: translateY(-0.3rem);
  }

  > main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    align-items: center;
    text-align: center;

    > input {
      visibility: hidden;
      display: none;
    }

    > svg {
      margin-bottom: 1rem;
    }

    > h3 {
      font-size: 18px;
      margin-bottom: 0.5rem;
    }

    > p {
      font-size: 12px;
      font-weight: 400;
      color: ${props => props.theme.colors.muted};
    }
  }
`;