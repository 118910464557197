import React, { useCallback, useRef, useState } from 'react';

import api from '../../services/api';

import { useAuth } from '../../hooks/auth';

import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import toast from 'react-hot-toast';
import { FiImage } from 'react-icons/fi';

import AvatarProgress from '../AvatarProgress';
import ModalCropImage from '../ModalCropImage';

import toBase64 from '../../utils/toBase64';
import { loadConfettiAllPage } from '../../utils/useConfetti';

import { AvatarContainer, Bio, ChangeCover, FloatValidation, MainHeader } from './styles';

const MainHeaderProfile: React.FC = () => {
  const { user, updateUser } = useAuth();

  // Refs
  const userAvatarInput = useRef<HTMLInputElement>({} as HTMLInputElement);
  const userCoverInput = useRef<HTMLInputElement>({} as HTMLInputElement);

  // States
  const [profilePic, setProfilePic] = useState('');
  const [profileCover, setProfileCover] = useState('');

  // Modals
  const [modalAvatar, setModalAvatar] = useState(false);
  const [modalCover, setModalCover] = useState(false);

  const handleSubmitAvatar = useCallback(async (file: File) => {
    try {
      const formData = new FormData();

      formData.append('avatar', file);

      const response = await api.patch('/profiles/avatar', formData);
      console.log(response.data);

      if (response.status === 200) {
        updateUser({ ...user, avatar_url: response.data });
        loadConfettiAllPage();
        toast.success('Avatar atualizado com suecesso!', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });
      }


    } catch (err) {
      console.log(err);
      toast.error('Erro ao atualizar foto de perfil!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });
    }
    finally {
    }
  }, [updateUser, user]);

  const handleSubmitCover = useCallback(async (file: File) => {
    try {
      const formData = new FormData();

      formData.append('cover', file);

      const response = await api.patch('/profiles/cover', formData);
      console.log(response.data);

      if (response.status === 200) {
        updateUser({ ...user, cover_url: response.data });
        loadConfettiAllPage();
        toast.success('Foto de capa atualizada com suecesso!', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });
      }

    } catch (err) {
      console.log(err);
      toast.error('Erro ao atualizar foto de capa!', {
        position: 'bottom-right',
        duration: 6000,
        className: 'toast-samuquinha',
      });
    }
  }, [updateUser, user]);

  const handleChangeAvatar = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    console.log(file);

    if (!file) {
      return;
    }

    const convertedFile = await toBase64(file);
    console.log(convertedFile);
    setProfilePic(convertedFile);
    setModalAvatar(true);
  }, []);

  const handleChangeCover = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    console.log(file);

    if (!file) {
      return;
    }

    const convertedFile = await toBase64(file);
    console.log(convertedFile);
    setProfileCover(convertedFile);
    setModalCover(true);
  }, []);

  return (
    <>
      <MainHeader cover_url={user.cover_url}>
        <section>
          <header>
            <main>
              <section>
                {user.transaction_valid_until && (
                  <FloatValidation style={{ background: '#14ff4670' }}>Plano válido até {format(parseISO(user?.transaction_valid_until), "dd'/'MM'/'Y", { locale: ptBR })}</FloatValidation>
                )}
              </section>
              <section>
                <ChangeCover onClick={() => userCoverInput.current.click()}><FiImage strokeWidth={2.5} /> Trocar foto de capa</ChangeCover>
              </section>
            </main>
            <footer>
                <AvatarContainer onClick={() => userAvatarInput.current.click()}>
                  <AvatarProgress
                    badgeValue={user.level}
                    percent={user.current_exp}
                    size={128}
                    url={user.avatar_url}
                  />
                  <Bio>
                    <h2>{user.show_name}</h2>
                    <p>{user.career?.name}</p>
                    <p>{user.university?.name}</p>
                  </Bio>
                </AvatarContainer>
            </footer>
          </header>
        </section>
      </MainHeader>

      <input type="file" name="useravatar" ref={userAvatarInput} onChange={handleChangeAvatar} accept="image/png, image/jpeg" style={{ display: 'none'}} />
      <input type="file" name="usercover" ref={userCoverInput} onChange={handleChangeCover} accept="image/png, image/jpeg" style={{ display: 'none'}} />

      {/* START MODAL COVER */}
      <ModalCropImage
        image={profileCover}
        cropSize={{ width: 1400, height: 360 }}
        isOpen={modalCover}
        setIsOpen={(value) => setModalCover(value)}
        onSubmit={handleSubmitCover}
      />
      {/* END MODAL COVER */}

      {/* START MODAL PROFILE PIC */}
      <ModalCropImage
        image={profilePic}
        cropSize={{ width: 200, height: 200 }}
        isOpen={modalAvatar}
        setIsOpen={(value) => setModalAvatar(value)}
        onSubmit={handleSubmitAvatar}
        cropShape="round"
      />
      {/* END MODAL PROFILE PIC */}
    </>
  );
}

export default MainHeaderProfile;