import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Exam } from 'phosphor-react';

import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import SimulatedModule from '../../../components/SimulatedModule';
import FloatTimeCountDown from '../../../components/FloatTimeCountDown';

import { Container, SectionContainer1, } from './styles';
import api from '../../../services/api';

const Simulado: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [expiresAt, setExpiresAt] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/practices/${id}`);

        console.log(response.data);

        if (response.status === 200) {
          setExpiresAt(response.data.expires_at);
        }

      } catch (error) {
        console.log(error);
      }

    })();
  }, [id]);

  return (
    <Container>
      <HeaderSectionContainer 
        padding="1rem 2.25rem 1rem 2rem" 
        style={{ 
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '2rem',
        }}
      >
        <aside>
          <h1><Exam weight="duotone" /> Simulado</h1>
          <p>Você tem 3 minutos para fazer cada questão.</p>
        </aside>
        {expiresAt && (<FloatTimeCountDown expires_at={expiresAt} pratice_id={id} />)}
      </HeaderSectionContainer>

      <SectionContainer1>
        <SimulatedModule pratice_id={id} />
      </SectionContainer1>
    </Container>
  );
}

export default Simulado;