import React, { PropsWithChildren } from 'react';

import { ErrorParagraph } from './styles';

interface IMessageErrorValidatorProps extends PropsWithChildren {
  animaton?: boolean;
  color?: string;
  style?: React.CSSProperties;
}

const MessageErrorValidator: React.FC<IMessageErrorValidatorProps> = ({ children, color, style, animaton = true }) => {

  return (
    <ErrorParagraph id="error-message" animaton={animaton} color={color} style={style}>
      {children}
    </ErrorParagraph>
  );
}

export default MessageErrorValidator;