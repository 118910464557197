import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  
  > section {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    backdrop-filter: blur(6px);
    background: ${props => props.theme.colors.gray}20;
    border: 1px solid ${props => props.theme.cards.border};
    border-radius: 12px;

    > main {
      width: 100%;
      border-radius: 12px;

      > a {
        transition: all 0.2s ease-in-out;

        &:hover {
          opacity: 0.75;
        }

        > div {
          
          > figure {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
        }
      }
      
      > section {
        position: relative;
        padding: 2rem;
        gap: 0.75rem;
        display: flex;
        flex-direction: column;
        

        > header {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          font-weight: bold;
          color: ${props => props.theme.colors.muted} !important;
          
          a, p, span {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            font-size: 11px;
            color: ${props => props.theme.colors.muted} !important;
            text-transform: uppercase;
            font-size: 10px;
          }

          > span {
            content: '/';
            margin: 0 0.5rem;
            text-decoration: none !important;
          }
          > p {
            color: red;
            color: ${props => props.theme.colors.muted} !important;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }

          }
        }
        
        > a {
          color: ${props => props.theme.colors.white};
          font-size: 1rem;
          font-weight: bold;
          text-decoration: none;
        }

        > p {
          color: ${props => props.theme.colors.white};
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
`;
