import React, { useCallback, useEffect, useState } from 'react';

import api from '../../services/api';

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import ViewedLessonButton from '../../pages/user/AulasModulo/ViewedLessonButton';
import Input from '../Form/Input';
import MessageErrorValidator from '../MessageErrorValidator';
import TooltipAdapter from '../TooltipAdapter';

import { CheckCircle, ClipboardText, Confetti, Trash } from 'phosphor-react';

import { Container, Footer, Form, MainScrollContainer, NotTasks, Task } from './styles';

import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';

interface ITodoListProps {
  id: string;
  description: string;
  background: string;
  is_completed: boolean;
}

const TodoListModule: React.FC = () => {

  // Validation
  const schema = Joi.object({
    description: Joi.string().min(5).max(100).required().messages({
      '*': 'Informe uma tarefa válida.',
      'string.min': `O campo descrição deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo descrição deve ter no máximo {#limit} caracteres`,
    }),
  });

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));
      return joiResolver(schema)(data, context, options);
    },
  });

  const [todoList, setTodoList] = useState<ITodoListProps[]>([]);
  const [filter, setFilter] = useState<'all' | 'completed' | 'notCompleted'>('all');

  const date = new Date();
  const filteredTodoList = todoList.filter((item) => {
    if (filter === 'completed') {
      return item.is_completed;
    }
    if (filter === 'notCompleted') {
      return !item.is_completed;
    }

    return true;
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/tasks`);
        console.log(response.data);
        setTodoList(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleRemoveFromTodo = useCallback(async (index: number, id: string) => {
    try {
      await api.delete(`/tasks/${id}`);
      setTodoList(todoList.filter((_, i) => i !== index));

      toast.success('Tarefa removida com sucesso!', {
        duration: 5000,
        position: 'bottom-right',
        className: 'toast-samuquinha',
      });
    } catch (error) {
      console.log(error);
      toast.error('Erro ao remover tarefa');
    }
  }, [todoList]);

  const handleCompletedTask = useCallback(async (task: ITodoListProps) => {
    try {
      await api.patch(`/tasks/${task.id}`);
      const newTodoList = todoList.map((item) => {
        if (item.id === task.id) {
          item.is_completed = !item.is_completed;
        }
        return item;
      });
      setTodoList(newTodoList);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao completar tarefa');
    }
  }, [todoList]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);

      const response = await api.post(`/tasks`, {
        description: capitalizeFirstLetter(data.description),
      });

      if (response.status === 200) {
        setFilter('all');
        console.log(response.data);
        setTodoList([...todoList, response.data]);

        reset({
          description: '',
        });

        const focus = document.querySelector('#description') as HTMLInputElement;
        focus.focus();

      }
    } catch (error: any) {
      console.log('error:::::::: ', error.response.data);
    }
  });

  return (
    <Container>
      <header>
        <aside>
          <aside>
            <h1>{date.getDate()}</h1>
          </aside>
          <main>
            <p>{date.toLocaleString('default', { month: 'short' })}</p>
            <p>{date.getFullYear()}</p>
          </main>
        </aside>
        <main>
          <p>{date.toLocaleString('default', { weekday: 'long' })}</p>
        </main>
      </header>

      <Form onSubmit={onSubmit}>
        <aside>
          <Input register={register} id="description" name="description" type="text" label="Descreva sua tarefa" autoComplete="off" placeholder="Criar nova tarefa..." />
          <MessageErrorValidator>{errors?.description?.message as string}</MessageErrorValidator>
        </aside>
        <main>
          <button type="submit">Adicionar tarefa</button>
        </main>
      </Form>

      <MainScrollContainer style={filteredTodoList.length > 5 ? { overflowY: 'scroll', padding: '0 0.5rem 4rem 0.5rem' } : {}}>
        {filteredTodoList.length > 0 ?
          filteredTodoList.map((task, index) => (
            <Task key={index} completed={task.is_completed}>
              <main>
                <label htmlFor={String(index)} onChange={() => { handleCompletedTask(task); }}>
                  <TooltipAdapter alt="Marcar como concluída" place="top">
                    <ViewedLessonButton
                      isViewed={task.is_completed}
                      onClick={() => handleCompletedTask(task)}
                    />
                  </TooltipAdapter>
                  {task.description}
                </label>
              </main>
              <aside onClick={() => handleRemoveFromTodo(index, task.id)}>
                <TooltipAdapter alt="Excluir tarefa" place="top" cursor="pointer">
                  <button>
                    <Trash weight="bold" size={16} />
                  </button>
                </TooltipAdapter>
              </aside>
            </Task>
          ))
          :
          <NotTasks>- Adicione tarefas ou metas diárias no campo acima. 🎉</NotTasks>
        }
      </MainScrollContainer>
      <Footer>
        <div>
          <div style={{ justifyContent: 'flex-start' }}>
            <h5 className={filter === 'all' ? 'current' : ''}  onClick={() => setFilter('all')}><ClipboardText size={25} weight="duotone" /> Todas: ({todoList.length})</h5>
          </div>
          <div style={{ justifyContent: 'center' }}>
            <h5 className={filter === 'notCompleted' ? 'current' : ''} onClick={() => setFilter('notCompleted')}><CheckCircle size={25} weight="duotone" /> Ativas ({todoList.filter((task) => !task.is_completed).length})</h5>
          </div>
          <div style={{ justifyContent: 'flex-end' }}>
            <h5 className={filter === 'completed' ? 'current' : ''} onClick={() => setFilter('completed')}><Confetti size={25} weight="duotone" /> Concluídas ({todoList.filter((task) => task.is_completed).length})</h5>
          </div>
        </div>
      </Footer>
    </Container>
  );
}

export default TodoListModule;