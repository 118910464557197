import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { TableColumn } from 'react-data-table-component';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DataTableAdapter from '../../../components/DataTableAdapter';
import DropdownButton from '../../../components/DropdownButton';

import { FiHome, FiSettings } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { CardTableContainer, Container, HeaderSection, Poster } from './styles';

import api from '../../../services/api';

import IDataTable from '../../../interfaces/DataTable';
import { IClusterProps } from '../../../interfaces/Cluster';
import LoaderBIO from '../../../components/Loader';

const ModulosVestibulares: React.FC = () => {

  // Loaders
  const [loading, setLoading] = useState(false);

  // Request 
  const [perPage, setPerPage] = useState(777);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [filterText, setFilterText] = useState('');

  const [clusterData, setClusterData] = useState<IDataTable<IClusterProps>[]>([]);
  
  const columns: TableColumn<IClusterProps>[] = [
    {
      name: 'Nome',
      selector: row => row.title,
      cell: (row: IClusterProps) => (
        <>
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Poster src={row.thumbnail_url} alt={row.title} />
            <div>
              <Link to={`/aulas/${row.slug}`}>{row.title}</Link>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Descrição',
      cell: (row: IClusterProps) => (
        <>
          <p style={{ padding: '1rem 0' }}>{row.description}</p>
        </>
      ),
    },
    {
      name: (<FiSettings size={16} strokeWidth={2.5} />),
      sortable: false,
      button: true,
      right: true,
      cell: (row: IClusterProps, index: number) => (
        <DropdownButton key={index}>
          <ul>
            <li>
              <Link to={`/aulas/${row.slug}`}>
                <MdKeyboardArrowRight size={18} />
                Visualizar
              </Link>
            </li>
          </ul>
        </DropdownButton>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(`/clusters`, {
          params: {
            pageIndex: page,
            pageSize: perPage,
            search: filterText,
            type: 'exam',
          }
        });

        setClusterData(response.data.records);

        setTotalRows(response.data.totalRecords);
        console.log(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [page, perPage, filterText]);

  return (
    <>
      <LoaderBIO isVisible={loading} />
      
      <Container>
        <HeaderSection>
          <Breadcrumbs
            icon={FiHome}
            separator="/"
            route={[{ title: 'Vestibulares', link: '/vestibulares' }]}
          />
          <aside>
            {/* button? */}
          </aside>
        </HeaderSection>

        <CardTableContainer>
          <DataTableAdapter
            title="Módulos"
            subTitle={''}
            columns={columns}
            data={clusterData}
            paginationServer={true}
            perPage={perPage}
            totalRows={totalRows}
            setFilterText={setFilterText}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </CardTableContainer>
      </Container>
    </>
  );
}

export default ModulosVestibulares;