import React, { useEffect, useLayoutEffect, useState } from 'react';

import api from '../../../services/api';

import { Link, useHistory } from 'react-router-dom';
import { useTheme } from '../../../hooks/theme';

import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { ArrowArcLeft, Exam } from 'phosphor-react';


import LoaderBIO from '../../../components/Loader';
import SimpleMultiSelect from '../../../components/Form/SimpleMultiSelect';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import AwesomeButtonAdapter from '../../../components/AwesomeButtonAdapter';
import ModalConfirm from '../../../components/ModalConfirmation';

import { Container, FormContainer, Line1, Line2, Line3, ModalContainer, RightContainer, SectionContainer1 } from './styles';

import { maxWidthContent } from '../../../utils/maxWidthContent';

interface IFilterProps {
  exams: (SelectEntity)[];
  years: (SelectEntity)[];
  difficulties: (SelectEntity)[];
  clusters: (SelectEntity)[];
}
interface SelectEntity {
  value: string;
  label: string;
}


const SimuladosCreate: React.FC = () => {
  const history = useHistory();

  // Validation
  const schema = Joi.object({
    amount: Joi.number().required().messages({ '*': 'Obrigatório' }),
    cluster_ids: Joi.array().items(Joi.string()).min(1).required().messages({ '*': 'Obrigatório.' }),
    difficulties: Joi.array().items(Joi.string()).min(1).required().messages({ '*': 'Obrigatório.' }),
    exams: Joi.array().items(Joi.string()).min(1).required().messages({ '*': 'Obrigatório.' }),
    years: Joi.array().items(Joi.string()).min(1).required().messages({ '*': 'Obrigatório.' }),
  });

  const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));
      return joiResolver(schema)(data, context, options);
    },
  });

  const { theme } = useTheme();

  // Loading
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  // Data
  const [exams, setExams] = useState<SelectEntity[]>([]);
  const [years, setYears] = useState<SelectEntity[]>([]);
  const [difficulties, setDifficulties] = useState<SelectEntity[]>([]);
  const [clusters, setClusters] = useState<SelectEntity[]>([]);

  // Filters
  const [requestedQuestions, setRequestedQuestions] = useState(0);
  const [foundedQuestions, setFoundedQuestions] = useState(0);


  // Modals
  const [showModalEnoughtQuestions, setShowModalEnoughtQuestions] = useState(false);

  

  useLayoutEffect(() => {
    const mainContentSection: any = document.querySelector('#main-scroll');
    const mainContentCenter: any = document.querySelector('#content-center');
    const mainFooter: any = document.querySelector('#main-footer-container');

    if (mainContentSection && mainContentCenter && mainFooter) {
      mainContentSection.style.padding = '0px';
      mainContentCenter.style.maxWidth = '100%';
      mainFooter.style.display = 'none';
    }

    return () => {
      if (mainContentSection && mainContentCenter) {
        mainContentSection.style.padding = '2.35rem 1rem 2rem 2rem';
        mainContentCenter.style.maxWidth = maxWidthContent;
        mainFooter.style.display = 'block';
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<IFilterProps>(`/practices/filters`);

        setExams(response.data.exams);
        setYears(response.data.years);
        setDifficulties(response.data.difficulties);
        setClusters(response.data.clusters);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log(data);
      setButtonLoading(true);

      const response = await api.post(`/practices`, {
        amount: foundedQuestions > 0 ? foundedQuestions : data.amount,
        minutes_per_question: 3,
        cluster_ids: data.cluster_ids,
        difficulties: data.difficulties,
        exams: data.exams,
        years: data.years,
      });

      if (response.status === 200) {
        console.log(response.data);

        history.push(`/simulado/${response.data.id}`);

        reset();
      }
    } catch (error: any) {
      console.log('error:::::::: ', error.response.data);
      setButtonLoading(false);

      if (error.response.data.error.message === 'not-enough-questions') {
        setShowModalEnoughtQuestions(true);
        setRequestedQuestions(error.response.data.error.requested);
        setFoundedQuestions(error.response.data.error.founded);
        return;
      }
    }
    finally {
      setButtonLoading(false);
    }
  });

  return (
    <>
      <Container>
        {loading && (<LoaderBIO isVisible />)}

        <SectionContainer1>
          <RightContainer>
            <h1>Gere novos simulados quando quiser 🎉</h1>
            <form onSubmit={onSubmit}>
              <FormContainer>
                <Line1>
                  <div>
                    <SimpleMultiSelect
                      register={register}
                      name="exams"
                      label="Instituição"
                      placeHolder="Selecione..."
                      options={exams}
                      setResult={(values) => { setValue('exams', values) }}
                      isMulti={true}
                      selectAll={true}
                      selectAllLabel="Todas as instituições"
                      clearnable
                      required
                    />
                    <MessageErrorValidator>{errors?.exams?.message as string}</MessageErrorValidator>
                  </div>
                  <div>
                    <SimpleMultiSelect
                      register={register}
                      name="years"
                      label="Ano"
                      placeHolder="Selecione..."
                      options={years}
                      setResult={(values) => { setValue('years', values) }}
                      selectAll={true}
                      selectAllLabel="Todos os anos"
                      isMulti={true}
                      required
                      clearnable
                    />
                    <MessageErrorValidator>{errors?.years?.message as string}</MessageErrorValidator>
                  </div>
                </Line1>

                <Line2>
                  <div>
                    <SimpleMultiSelect
                      register={register}
                      name="cluster_ids"
                      label="Área do conhecimento"
                      selectAll
                      selectAllLabel="Todas"
                      placeHolder="Selecione..."
                      options={clusters}
                      setResult={(values) => { setValue('cluster_ids', values) }}
                      isMulti={true}
                      required
                      clearnable
                    />
                    <MessageErrorValidator>{errors?.cluster_ids?.message as string}</MessageErrorValidator>
                  </div>
                  <div>
                    <SimpleMultiSelect
                      register={register}
                      name="difficulties"
                      label="Dificuldade das questões"
                      selectAll={true}
                      selectAllLabel="Todas"
                      placeHolder="Selecione..."
                      options={difficulties}
                      setResult={(values) => { setValue('difficulties', values) }}
                      isMulti={true}
                      required
                    />
                    <MessageErrorValidator>{errors?.difficulties?.message as string}</MessageErrorValidator>
                  </div>
                  <div>
                    <SimpleMultiSelect
                      register={register}
                      name="amount"
                      label="Quantidade de questões"
                      placeHolder="Selecione..."
                      options={[
                        { value: '10', label: '10' },
                        { value: '20', label: '20' },
                        { value: '30', label: '30' },
                        { value: '40', label: '40' },
                        { value: '50', label: '50' },
                        { value: '60', label: '60' },
                      ]}
                      setResult={(values) => { setValue('amount', values) }}
                      isMulti={false}
                      required
                    />
                    <MessageErrorValidator>{errors?.amount?.message as string}</MessageErrorValidator>
                  </div>
                </Line2>
                <Line3>
                  <AwesomeButtonAdapter
                    type="primary"
                    ripple={true}
                  >
                    GERAR SIMULADO
                    <Exam weight="fill" size={28} />
                  </AwesomeButtonAdapter>
                </Line3>
                <Line3>
                  <Link
                    to="/simulados"
                    className='btn-nonee'
                  >
                    <ArrowArcLeft weight="fill" size={22} />
                    VISUALIZAR RESULTADOS ANTERIORES
                  </Link>
                </Line3>
              </FormContainer>
            </form>

          </RightContainer>
        </SectionContainer1>
      </Container>

      {/* START MODAL CONFIRM */}
      <ModalConfirm
        confirmText={foundedQuestions > 0 ? 'Continuar mesmo assim' : 'Entendi...'}
        cancelText="Cancelar"
        showCancelButton={foundedQuestions > 0 && true} 
        onConfirm={foundedQuestions > 0 ? onSubmit : () => setShowModalEnoughtQuestions(false)}
        confirmButtonColor="#03bb85"
        isOpen={showModalEnoughtQuestions}
        isLoading={false}
        setIsOpen={() => { setShowModalEnoughtQuestions(!showModalEnoughtQuestions); setFoundedQuestions(0); setRequestedQuestions(0) }}
        closeModal={() => setShowModalEnoughtQuestions(false)}
      >
        <ModalContainer>
          {foundedQuestions > 0 ? (
            <>
              <h1>Ops! 😢</h1>
              <p>Infelizmente encotramos apenas {foundedQuestions} das {requestedQuestions} questões solicitadas em seus critérios de busca.</p>
            </>
          ) : (
            <>
              <h1>Poxa! 😢</h1>
              <p>Infelizmente não encontramos nenhum resultado para a sua busca.</p>
            </>
          )}
        </ModalContainer>
      </ModalConfirm>
      {/* END MODAL CONFIRM */}
    </>
  );
}

export default SimuladosCreate;