import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import { convertToRaw, EditorState, ContentState, convertFromRaw } from 'draft-js';

// @ts-ignore
import { mdToDraftjs } from "draftjs-md-converter";

// @ts-ignore
import draftToMarkdown from 'draftjs-to-markdown';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Container } from './styles';
import toast from 'react-hot-toast';

interface IWysiwygProps {
  placeholder?: string;
  maxLength: number;
  required?: boolean;
  setResult(values: any): any;
  defaultResult?: string;
}

const WysiwygInnerModal: React.FC<IWysiwygProps> = ({ placeholder, maxLength, setResult, defaultResult }) => {
  const [editor, setEditor] = useState<EditorState>(EditorState.createEmpty());

  async function uploadImageCallBack(file: File) {
    try {
      if (file.size > 1024 * 1024 * 2) {
        throw new Error('exceeded-size-limit');
      }
      const data = new FormData();
      data.append("file", file); 

      const response = await api.patch('/forums/upload', data);

      return { data: { link: response.data } };
    } catch (error: any) {
      console.log(error);

      if (error.message === 'exceeded-size-limit') {
        toast.error('😢 Tamanho da imagem excedido. Máximo de 2MB', { position: 'bottom-center', duration: 6000, });
      }
    }
  }

  const handleEditorStateChange = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const oldContent = editor.getCurrentContent();

    if (contentState === oldContent || contentState.getPlainText().length <= maxLength) {
      setEditor(editorState);
    } else {
      const editorState = EditorState.moveFocusToEnd(
        EditorState.push(
          editor,
          ContentState.createFromText(oldContent.getPlainText()),
          'undo',
        )
      );
      setEditor(editorState);
    }
  }

  useEffect(() => {
    const content = draftToMarkdown(convertToRaw(editor.getCurrentContent()));
    if (content === '\n') {
      setResult('');
    } else {
      setResult(content);
    }
  }, [setResult, editor]);

  useEffect(() => {
    defaultResult && setEditor(EditorState.createWithContent(convertFromRaw(mdToDraftjs(defaultResult))));
  }, [defaultResult]);

  return (
    <>
      <Container>
        <Editor
          editorState={editor}
          stripPastedStyles={true}
          onEditorStateChange={(editorState: EditorState) => { handleEditorStateChange(editorState) }}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          handlePastedText={() => false}
          localization={{
            locale: 'pt',
          }}
          /*
          handlePastedText={(text, html, _, onChange) => {
            console.log('text', text);
            console.log('html', html);
            const editorState = EditorState.createWithContent(
              convertFromRaw(mdToDraftjs(text.replace(/ +(?= )/g,''))),
            );

            setEditor(editorState);

            return true;
          }}
          */
          placeholder={placeholder ? placeholder : ''}
          toolbar={{
            options: ['inline', 'list', 'emoji', 'image'],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic', 'underline'],
            },
            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['unordered', 'ordered'],
            },
            link: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: false,
              defaultTargetOption: '_blank',
              options: [],
              linkCallback: () => {
                return { title: 'outro', target: 'outro', targetOption: '_self' };
              },
            },
            emoji: {
              className: 'add',
              component: undefined,
              popupClassName: undefined,
              emojis: [
                '💜', '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇', '🙂', '🙃', '😉', '😌', '😍', '🥰', '😘',
                '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐', '🤓', '😎', '🤩', '🥳', '😏',
                '😱', '🤗', '🤭', '😬', '😲', '🤠', '👋', '🤚', '🖐', '✋', '🖖', '👌', '✌️', '🤞', '🤟', '🤘', '🤙',
                '👈', '👉', '👆', '👇', '☝️', '👍', '👎', '👊', '🤛', '🤜', '👏', '🙌', '🤝', '🙏',
                '✍️', '💅', '💪', '🧠', '👀', '🏆', '🥇', '🥈', '🥉', '🏅', '🖊', '🖋', '✒️', '🖌', '🖍', '📝', '✏️',
              ],
            },
            image: {
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              urlEnabled: false,
              uploadEnabled: true,
              alignmentEnabled: false,
              uploadCallback: uploadImageCallBack,
              previewImage: true,
              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
              alt: { present: false, mandatory: false },
              defaultSize: {
                width: '100%',
              },
            },
          }}
        />
      </Container>
    </>
  );
}

export default WysiwygInnerModal;
