import styled from 'styled-components';

interface IBadgeExpProps {
  background?: string;
  color?: string;
  style?: React.CSSProperties;
}

export const Container = styled.section<IBadgeExpProps>`

> span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.background || props.theme.specific.asideModule};
    border: 1px solid ${props => props.theme.header.border};
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 11px;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 5%);
    flex-flow: wrap;

    > img {
      width: 23px;
      margin-right: 8px;
    }
  }
`;
