import React, { useCallback, useEffect, useRef, useState } from 'react';

import GradientProgress from "@delowar/react-circle-progressbar";

import { useTheme } from '../../hooks/theme';

import { FiChevronRight, FiInfo } from 'react-icons/fi';

import { Container, ProgressInner } from './styles';
import CountUpAdapter from '../CountUpAdapter';
import { Link } from 'react-router-dom';
import TooltipAdapter from '../TooltipAdapter';
import { format, formatDistance, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Countdown, { CountdownRenderProps } from 'react-countdown';

interface ICardSimulatesProgressProps {
  id: string;
  totalQuestions: number;
  totalCorrects: number;
  link?: string;
  title?: string;
  created_at: string;
  expires_at: string;
  className?: string;
  isAvailable: boolean;
  isFinished: boolean;
  style?: React.CSSProperties;
  height?: string;
}

const CardSimulatesProgress: React.FC<ICardSimulatesProgressProps> = ({ id, totalQuestions, totalCorrects, link, title, created_at, expires_at, className, isAvailable, isFinished, style, height }) => {
  const { theme } = useTheme();

  const countDownRef = useRef<any>(null);

  const [startTime, setStartTime] = useState<number>(9999999999999);

  const [respondedQuestions, setRespondendQuestions] = useState<number>(0);

  const [finishCountDown, setFinishCountDown] = useState<boolean>(false);

  const renderer = useCallback(
    ({ hours, minutes, seconds, completed }: CountdownRenderProps) => {
      if (completed) {
        // Render a completed state
        return '';
      }

      // Render a countdown
      return (
        <>
          {hours > 0 && <span>{`0${hours}`.slice(-2)}</span>}
          {hours > 0 && <span>:</span>}
          <span> {`0${minutes}`.slice(-2)}</span>
          <span>:</span>
          <span>{`0${seconds}`.slice(-2)}</span>
        </>
      );
    }, []);

  useEffect(() => {
    if (!expires_at) return;

    const dateInMilliseconds = new Date(expires_at).getTime();
    setStartTime(dateInMilliseconds);
  }, [expires_at]);

  useEffect(() => {
    /* check if simulates in localstorage */
    const simulate = localStorage.getItem(`@simulated-${id}`);

    if (simulate) {
      const parsedSimulate: [] = JSON.parse(simulate);

      if (parsedSimulate.length > 0) {
        setRespondendQuestions(parsedSimulate.length);
      }
    }
  }, [id]);

  return (
    <>
      {isFinished ? (
        <Container className={className} style={style}>
          <div>
            <div>
              <h2>{title ? <>{title} <span>({created_at && format(parseISO(created_at), "dd'/'MM'/'yyyy", { locale: ptBR })})</span></> : 'Último simulado'}</h2>
              <p>Realizado há {created_at && formatDistance(parseISO(created_at), new Date(), { locale: ptBR })}</p>
            </div>
            <div>
              <TooltipAdapter
                alt={title ? `Você acertou ${totalCorrects} de ${totalQuestions} questões 😊` : 'Faça simulados periodicamente e <br /> some pontos no ranking 😊'}
                place="top"
                cursor="help"
                textColor="#dadbe7"
              >
                <FiInfo strokeWidth={2.5} size={20} />
              </TooltipAdapter>
            </div>
          </div>
          <main>
            <div>
              <aside>
                <h5>Total de questões</h5>
                <h3>{totalQuestions}</h3>
              </aside>
              <aside>
                <h5>Acertos</h5>
                <h3>{totalCorrects}</h3>
              </aside>
            </div>
            <div>
              <GradientProgress
                className="progress-graph"
                percent={totalCorrects * 100 / totalQuestions}
                viewport={false}
                size={200}
                isGradient
                transition={3000}
                gradient={{
                  angle: 130,
                  startColor: theme === 'dark' ? "#79ffb8" : '#01b574',
                  stopColor: theme === 'dark' ? "#79ffb828" : '#17c0a71f',
                }}
                emptyColor={theme === 'dark' ? "#ffffff1f" : '#17c0a71f'}
              >
                <ProgressInner>
                  <h2>
                    <CountUpAdapter value={totalCorrects * 100 / totalQuestions} />
                  </h2>
                  <p>assertividade</p>
                </ProgressInner>
              </GradientProgress>
            </div>
          </main>
          <footer>
            <Link to={link || `/simulado/${id}/visualizar`}>{link ? 'Ver todos' : 'Visualizar simulado'} <FiChevronRight /></Link>
          </footer>
        </Container>
      ) : (
        <Container className={className} style={{ background: '#9080fa', height: height || '357px', color: "#fff" }}>
          <div>
            <div>
              <h2 style={{ fontSize: '1.8rem', color: '#fff' }}>Simulado em andamento</h2>
              <p style={{ color: '#fff' }}><span>Você ainda tem </span>
                {!finishCountDown ? (
                  <Countdown
                    ref={countDownRef}
                    controlled={false}
                    date={startTime}
                    renderer={renderer}
                    onComplete={() => setFinishCountDown(true)}
                  />)
                : (
                  <span>00:00</span>
                )}
                <span> para terminar o simulado.</span>
              </p>

            </div>
          </div>
          <main>
            <div style={{ flexDirection: 'row', width: '100%' }}>
              <aside>
                <h5 style={{ color: '#fff' }}>Total de questões</h5>
                <h3>{totalQuestions}</h3>
              </aside>
              <aside>
                <h5 style={{ color: '#fff' }}>Respondidas</h5>
                <h3>{respondedQuestions}</h3>
              </aside>
            </div>
          </main>
          {<footer>
            <Link to={`/simulado/${id}`} style={{ color: '#fff' }}>Continuar simulado<FiChevronRight /></Link>
          </footer>}
        </Container>
      )}
    </>
  );
}

export default CardSimulatesProgress;