import styled from 'styled-components';

export const Container = styled.div`

`;

export const MainContent = styled.section`
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
  padding: 0 1rem;
  grid-template-columns: minmax(265px, 1.15fr) 5fr;

  /* NOTEBOOK */
  @media (max-width: 1120px) {
    grid-template-columns: 1fr;
    margin: 2rem 0;
  }

  > aside {
    
    > div {
      position: sticky;
      top: -1rem;
      
      background: ${props => props.theme.cards.primary};
      border: 1px solid ${props => props.theme.cards.border};
      box-shadow: ${props => props.theme.shadows.primary};
      border-radius: ${props => props.theme.radius.primary};
    }
  }

  > main {

    > section {
      display: flex;
      padding: 2rem;
  
      background: ${props => props.theme.cards.primary};
      border: 1px solid ${props => props.theme.cards.border};
      box-shadow: ${props => props.theme.shadows.primary};
      border-radius: ${props => props.theme.radius.primary};
    }
  }
`;

export const FormProfile = styled.section`
  width: 100%;

  > header {

    > h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      margin-bottom: 2rem;

      > svg {
        margin-right: 0.5rem;
      }
    }
  }

`;

export const Line1 = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1rem;

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const LineButton = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
`;