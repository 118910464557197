import styled from 'styled-components';

interface ICardModuleProps {
  background: string;
  checked?: boolean;
}

export const Container = styled.div<ICardModuleProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  background: ${props => props.theme.cards.primary};
  backdrop-filter: ${props => props.theme.cards.backdropFilter};
  border-radius: ${props => props.theme.radius.primary};
  width: 100%;
  height: 100%;
  color: #ffffff;
  background: url(${props => props.background}) !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  transition: all .25s ease-in-out;
  min-height: 320px;
  text-decoration: none;
  filter: ${props => !props.checked ? 'saturate(0) opacity(0.9)' : ''};

  &:hover {
    cursor: pointer;
    filter: none;
    
    &:before {
      opacity: 1;
      background-image: linear-gradient(180deg, #000, transparent);
    }

    a svg {
      margin-left: 0.25rem;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(180deg, #011212, transparent);
    transition: all 0.3s ease-in-out 0s;
    opacity: 0.8;
  }

  > header {

    > div {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h2 {
        color: #ffffff;
        font-size: 2rem;

        /* TABLET MINI */
        @media (max-width: 950px) {
          font-size: 1.75rem;
        }
      }

      > aside {
        background: #0a2823;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        border: 3px solid #ffffff52;
        transition: all .2s ease-in-out;
        
        input[type="radio"] {
          border: 3px solid #ffffff52;
          background-color: transparent;
          border: .0625em solid rgba(255,255,255,.5);
          border-radius: 50%;
          box-shadow: inset 0 0 0 0 white;
          cursor: pointer;
          font: inherit;
          height: 100%;
          outline: none;
          width: 100%;
          -moz-appearance: none;
          -webkit-appearance: none;
          
          &:checked {
            background-color: white;
            box-shadow: inset 0 0 0 .1875em #47d1da;
            -webkit-transition: background .15s, box-shadow .1s; 
            transition: background .15s, box-shadow .1s; 
            
            &:before {
              display: flex;
              justify-content: center;
              align-items: center;
              content: '✔';
            }
          }
        }


        > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        > svg {
          padding-top: 2px;
        }
      }
    }
  
    > p { 
      color: ${props => props.theme.colors.gray};
      font-size: 14px;
      line-height: 1.5;
      text-align: justify;
    }
  }

  > footer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      display: inline-flex;
      gap: 1rem;
      
      > span {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 16px;
      }
    }

    > p {
      display: flex;
      align-items: center;
      flex-direction: row;
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;

      > svg {
        font-size: 18px;
      }
    }
  }
`;
