import React from 'react';

import { Container } from './styles';

import CountUpAdapter from '../CountUpAdapter';

import { IconProps } from 'phosphor-react';

interface ICardMainFeaturesProps {
  icon: React.ComponentType<IconProps>;
  title: string;
  value: number;
  iconBackground?: string;
}

const CardMainFeatures: React.FC<ICardMainFeaturesProps> = ({ icon: Icon, title, value, iconBackground }) => {
  return (
    <Container iconBackground={iconBackground}>
      <aside>
        <h6>{title}</h6>
        <h4><CountUpAdapter value={value} /></h4>
      </aside>
      <main>
        <Icon weight="duotone" color="#ffffff" size={26} />
      </main>
    </Container>
  );
}

export default CardMainFeatures;