import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  PropsWithChildren
} from 'react';
import { toast } from 'react-hot-toast';
import { io, Socket } from 'socket.io-client';
import ModalAchiviement from '../components/ModalAchiviement';
import { IAchievementsProps } from '../interfaces/Achievements';
import { IUserExperienceProps } from '../interfaces/User';
import { loadConfettiAllPage } from '../utils/useConfetti';
import { useAuth } from './auth';

interface RegisterProps {
  event: string;
  listener(props: any): any;
}

interface SocketContextState {
  register(props: RegisterProps): void;
}

const SocketContext = createContext<SocketContextState>(
  {} as SocketContextState,
);

const SocketProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, updateUser } = useAuth();

  const [socket, setSocket] = useState<Socket>();
  
  const [achiviements, setAchiviements] = useState<IAchievementsProps[]>([]);

  useEffect(() => {
    if (user.id) {
      setSocket(
        io(process.env.REACT_APP_API_URL || 'http://localhost:3333', {
          query: { user_id: user.id },
        }),
      );
    }
  }, [user.id]);

  const register = useCallback(
    ({ event, listener }: RegisterProps): void => {
      if (!socket) return;

      socket.on(event, listener);
    },
    [socket],
  );

  useEffect(() => {
    register({
      event: 'achievement',
      listener: (achievement: IAchievementsProps) => {
        if (achievement) {

          setAchiviements(oldAchiviements => [...oldAchiviements, achievement]);

          updateUser(oldUser => {
            const exp = oldUser.current_exp + achievement.exp;

            return {
              ...oldUser,
              exp,
              current_exp: Math.trunc(exp % 100),
              level: Math.trunc(exp / 100),
            };
          });
        }
      },
    });

    register({
      event: 'experience',
      listener: (experience: IUserExperienceProps) => {
        if (experience) {
          updateUser(oldUser => {
            if (oldUser.level < experience.currentLevel) {
              toast.success(`Você subiu de nível!`, {
                position: 'bottom-right',
                duration: 6000,
                className: 'toast-success',
              });

              loadConfettiAllPage();
            }
            else {
             toast.success(`Você ganhou +${experience.expReward} de experiência!`, {
                icon: '🎉',
                duration: 6000,
                position: 'bottom-right',
              });
            }

            return {
              ...oldUser,
              exp: experience.exp,
              current_exp: experience.currentExp,
              level: experience.currentLevel,
              evolution: experience.evolution,
            }
          });
        }
      },
    });
  }, [register, updateUser]);

  return (
    <SocketContext.Provider value={{ register }}>
      {achiviements.map(achiviement => {
        return (<ModalAchiviement key={achiviement.id} achiviement={achiviement} />)
      })}
      {children}
    </SocketContext.Provider>
  );
};

function useSocket(): SocketContextState {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be user within an SocketProvider');
  }

  return context;
}

export { SocketProvider, useSocket };
