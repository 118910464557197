import styled, {css} from 'styled-components';

interface IContainerProps {
  disabled: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  gap: 0.5rem;
  cursor: ${props => props.disabled ? 'not-allowed' : 'default'};	;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: fit-content;
    border: none;
    font-size: 12px;
    background: transparent;
    transition: all .3s ease-in-out;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};	
    gap: 0.5rem;
    color: ${props => props.theme.colors.muted};

    &.disabled {
      cursor: text !important;
    }

    ${
      props => !props.disabled && css`
        &:first-child {

        &:hover {
          color: #9080fa;
        }}

        &:last-child {

        &:hover {
          color: #ff0000;
        }
        }

        &.checkedTrue {
        color: #9080fa;
        }

        &.checkedFalse {
        color: #ff0000;
        }
      `
    }
  }
`;
