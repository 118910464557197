import styled from 'styled-components';
import IAsideProps from './interface';

export const MENU_WIDTH_OPEN = '280px';
export const MENU_WIDTH_CLOSED = '110px';

export const Container = styled.aside<IAsideProps>`
  position: relative;
  grid-area: 1 / 1 / 3 / 1;
  width: ${props => (props.collapsed ? MENU_WIDTH_CLOSED : MENU_WIDTH_OPEN)};
  height: auto;
  background: ${props => props.theme.cards.primary};
  backdrop-filter: blur(35px) saturate(200%);
  border-right: 1px solid ${props => props.theme.cards.border};
  transition: width 0.3s ease-in-out;
  user-select: none;
  z-index: 9999;
  padding-bottom: 5rem;

  ::-webkit-scrollbar {
    display: none;
  }

  /* NOTEBOOK */
  @media (max-width: 1440px) {
  }

  /* TABLET */
  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-height: 843px) {
    display: none;
  }

  &.open {
    width: ${MENU_WIDTH_CLOSED};
  }

  > main {
    padding: 1rem 1.75rem;
    position: relative;

    > ul {
      list-style: none;

      > li {
        position: relative;
        margin: 0.25rem 0;

        &.new {
          position: relative;

          &::before {
            content: 'Novo';
            position: absolute;
            z-index: 99999;
            border-radius: 12px;
            text-align: center;
            box-sizing: border-box;
            background: ${props => props.theme.colors.purple};
            color: #fff;
            height: 12px;
            width: 30px;
            font-size: 9px;
            right: -0.5rem;
            bottom: 15px;
            color: #fff;
            font-weight: 600;
          }
        }

        > a {
          position: relative;
          display: flex;
          text-decoration: none;
          color: ${props => props.theme.colors.white};
          width: 100%;
          display: flex;
          border-radius: ${props => props.theme.radius.primary};
          justify-content: ${props =>
            props.collapsed ? 'center' : 'flex-start'};
          padding: 0.25rem;
          transition: all 0.2s ease-in-out;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: ${props => props.theme.menu.buttonShadowHover};
            transition: 0.5s;
            transform: scale(0.5);
            z-index: -1;
            opacity: 0;
            display: ${props => (props.collapsed ? 'block' : 'none')};
          }

          &:hover:before {
            transform: scale(1.1);
            box-shadow: 0 0 15px #03bb85;
            filter: blur(50px);
            opacity: 1;
          }

          &.active:before {
            transform: scale(1.1);
            box-shadow: 0 0 15px #03bb85;
            filter: blur(30px);
            opacity: 1;
          }

          /* AQUI */
          &:hover,
          &.active {
            background: ${props =>
              props.collapsed ? 'none' : props => props.theme.cards.border};
          }

          &:after {
            content: attr(data-tooltip);
            position: absolute;
            z-index: 99999999999999999;
            border-radius: 1rem;
            text-align: center;
            box-sizing: border-box;
            width: auto;
            box-shadow: ${props => props.theme.shadows.primary};
            background: ${props => props.theme.colors.white};
            color: ${props => props.theme.colors.dark};
            background: #6841b9;
            color: #fff;
            font-weight: 700;
            white-space: nowrap;
            line-height: 1;
            margin-top: 14px;
            font-size: 12px;
            margin-left: 5.1rem;
            left: 0;
            padding: 0.15rem 0.55rem;
            opacity: 0;
            visibility: hidden;
            transition: 0.4s;
            display: ${props => (props.collapsed ? 'flex' : 'none')};
          }

          &:hover::after {
            opacity: 1;
            visibility: visible;
            transform: translateX(-1.5rem);
          }
          /* AQUI */

          > div {
            position: relative;
            background: ${props => props.theme.menu.buttonBackground};

            > svg {
              font-size: 20px;
              color: ${props => props.theme.menu.buttonColor};
            }
          }

          > span {
            display: inline-block;
            white-space: nowrap;
            opacity: ${props => (props.collapsed ? 0 : 1)};
            visibility: ${props => (props.collapsed ? 'hidden' : 'visible')};
            width: ${props => (props.collapsed ? 0 : 'auto')};
            overflow: ${props => (props.collapsed ? 'hidden' : 'visible')};
            display: ${props => (props.collapsed ? 'none' : 'block')};
            margin-left: 0rem;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 14px;

            transition: width 0.2s ease-in-out;
            transition-delay: 1250ms;
            animation: in 0.8s;
          }
        }
      }
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.info};
  border-radius: ${props => props.theme.radius.primary};
  padding: 0.75rem;
`;

export const ButtonCollapse = styled.button`
  position: absolute;
  right: -12px;
  border: none;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999999;
  transition: all 0.25s ease-in-out 0.35s;
  background: ${props => props.theme.colors.purple};
  backdrop-filter: blur(35px) saturate(115%);
  border: 1px solid ${props => props.theme.colors.purple};
  border-radius: 50%;
  color: #fff;

  &.collapsed {
    transform: rotate(-180deg);
  }
`;
