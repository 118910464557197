import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';

import api from '../../services/api';
import { API_S3 } from '../../services/api_s3';

import { formatDistance, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { BackgroundImage } from "react-image-and-background-image-fade";

import Avatar from '../Avatar';

import { Container } from './styles';
import Breadcrumbs from '../Breadcrumbs';
import { FiBook } from 'react-icons/fi';
import { IMaterialProps } from '../../interfaces/Material';


const CardMaterialFeatrured: React.FC = () => {
  const theme = useTheme();

  const [materialFeactured, setMaterialFeactured] = useState<IMaterialProps>({} as IMaterialProps);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/materials/last`);
        setMaterialFeactured(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Container>
      <header>
        <aside>
          <Avatar
            radius="square"
            size="md"
            url={API_S3 + '/samuel/profile-pic.jpg'}
          />
          <main>
            <h5>Samuel Cunha</h5>
            {materialFeactured?.created_at && (<p>há {formatDistance(parseISO(materialFeactured?.created_at), new Date(), { locale: ptBR })}</p>)}
          </main>
        </aside>
        <main>
          <Link to="/materiais">Ver todos</Link>
        </main>
      </header>

      <section>
        <main>
          <a href={materialFeactured.material_url} target="_blank" rel="noreferrer">
            <BackgroundImage
              element="figure"
              src={materialFeactured.thumbnail_url ? materialFeactured.thumbnail_url : (theme === 'light' ? `${API_S3}/default/image-placeholder-light.svg` : `${API_S3}/default/image-placeholder-dark.svg`)}
              //src={materialFeactured?.thumbnail_url || theme === 'light' ? `${API_S3}/default/image-placeholder-light.svg` : `${API_S3}/default/image-placeholder-dark.svg`}
              height="250px"
              lazyLoad
            />
          </a>
          <section>
            <header>
              <Breadcrumbs
                icon={FiBook}
                separator="/"
                route={[
                  { title: 'Materiais didáticos', link: '/materiais' },
                  { title: materialFeactured?.category?.name , link: `/materiais?filter=${materialFeactured?.category?.id}` },
                ]}
              />
            </header>
            <a href={materialFeactured.material_url} target="_blank" rel="noreferrer">{materialFeactured.name}</a>
            <p>{materialFeactured.description}</p>
          </section>
        </main>
      </section>
    </Container>
  );
}

export default CardMaterialFeatrured;