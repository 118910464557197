import React from 'react';
import { API_S3 } from '../../services/api_s3';

import { Container } from './styles';

interface IBadgeExpProps {
  exp: number;
  text?: string;
  background?: string;
  color?: string;
  style?: React.CSSProperties;
}

const BadgeExp: React.FC<IBadgeExpProps> = ({ exp, text, background, color, style }) => {
  return (
    <Container style={style} background={background}>
      <span color={color}>
        <img src={`${API_S3}/default/2656376.png`} alt="" />
        <strong style={{marginRight: '0.25rem'}}>+{exp} exp</strong> {text}
      </span>
    </Container>
  );
}

export default BadgeExp;