export const fileExtensionType = (fileExtension: string) => {
  switch (fileExtension) {
    case 'pdf':
      return 'pdf';
    case 'doc':
    case 'docx':
      return 'doc';
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return 'img';
    default:
      return 'pdf';
  }
}