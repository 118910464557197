import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1.25rem;
  background: #9080fa;
  border: 3px solid hsla(0,0%,100%,.16);
  padding: 10px 16px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  z-index: 9999999;
  transition: all 0.2s ease-in-out;
  animation: pulseAnimationCss 2s infinite;
  
  &:hover {
    background: #2196fd;
  }

  @keyframes pulseAnimationCss {
    0% {
      -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}50;
      box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}50;
    }
    70% {
      -moz-box-shadow: 0 0 0 6px ${props => props.theme.colors.muted}10;
      box-shadow: 0 0 0 6px ${props => props.theme.colors.muted}10;
    }
    100% {
      -moz-box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}10;
      box-shadow: 0 0 0 0 ${props => props.theme.colors.muted}10;
    }
  }
  
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.35rem;
  }
`;

export const PopUpSupport = styled.div`
  background: #ffffff;
  position: fixed;
  z-index: 9999999;
  bottom: 1rem;
  left: 1.25rem;
  min-width: 300px;
  max-width: 320px;
  height: auto;
  border-radius: 6px;
  padding: 1rem 1.5rem;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #3e3f5e;
    padding: 0 0 0 0.25rem;

    > h1 {
      font-size: 18px;
    }
  }

  > main {
    margin-top: 1rem;
    background: #e2f0ff;
    color: #368beb;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-weight: 600;
    display: flex;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    > p {
      font-size: 13px;
      line-height: 1.4;
    }
  }

  > section {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 0.25rem;

    > h2 {
      font-size: 14px;
      color: #3e3f5e;
    }

    > ol {
      margin-left: 1rem;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      > li {
        color: #3e3f5e;

        > a {
          color: #3e3f5e;
          text-decoration: none;
          transition: all 0.2s ease-in-out;
          color: #368beb;

          &:hover {
            color: #537ae6;
          }
        }
      }
    }
  }

  > footer {
    position: relative;
    width: 100%;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;

    > p {
      font-size: 11px;
      color: ${props => props.theme.colors.gray};
      text-align: center;
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: #368beb;
      border-radius: 6px;
      padding: 0.75rem;
      text-decoration: none;
      color: #fff;
      border: none;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: #537ae6;
      }
    }
  }

`;

export const CloseBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  top: -10px;
  right: -10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #3e3f5e50;

  &:hover {
    color: #3e3f5e99;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 1;
  backdrop-filter: blur(7px) saturate(110%) !important;
  transition: all 0.2s ease-in-out 0s !important;
  background: rgba(0,0,0,0.19) !important;
`;