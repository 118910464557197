import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';
import { useTheme } from '../../../hooks/theme';

import { Link, useParams } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import { Book, DotsSixVertical, Eye, Pencil, ThumbsDown, ThumbsUp } from 'phosphor-react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DropdownButton from '../../../components/DropdownButton';
import LoaderBIO from '../../../components/Loader';
import Modal from '../../../components/Modal';
import DropZone from '../../../components/Form/DropZone';

import { FiHome, FiSettings } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { BtnEdit, CardTableContainer, Container, DndItem, DrpableContext, FakeMaterial, HeaderSection, HeaderStatistics, LessonInfoContainer, ListDndContainer, ModalContainer, Poster, RatingContainer } from './styles';

import { IClusterProps } from '../../../interfaces/Cluster';
import { ILessonProps } from '../../../interfaces/Lesson';
import Button from '../../../components/Button';
import toast from 'react-hot-toast';
import CardMaterial from '../../../components/CardMaterial';
import { loadConfettiAllPage } from '../../../utils/useConfetti';
import TooltipAdapter from '../../../components/TooltipAdapter';
import { handleVideoThumbinalDependingProvider } from '../../../utils/videoThumbinalDependingProvider';

const Modulos: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { theme } = useTheme();

  // Modal 
  const [modalAddMaterialIsOpen, setModalAddMaterialIsOpen] = useState(false);

  // Loaders
  const [loading, setLoading] = useState(false);

  // Request 
  const [filterText, setFilterText] = useState('');

  // Data
  const [clusterData, setClusterData] = useState<IClusterProps>({} as IClusterProps);
  const [filesData, setFilesData] = useState<File[]>([]);


  const columns: TableColumn<ILessonProps>[] = [
    {
      name: 'Nome',
      selector: row => row.title,
      width: '30%',
      cell: (row: ILessonProps) => (
        <>
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <Poster
              src={handleVideoThumbinalDependingProvider(row.video_provider, row.video) || row.thumbnail_url}
              alt={row.title} />
            <div>
              <Link to={`/aula/editar/${clusterData.slug}/${row.id}`}>{row.order} - {row.title} ({row.duration_formatted})</Link>
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Provedor',
      cell: (row: ILessonProps) => (
        <p style={{ padding: '1rem 0' }}>{row.video_provider}</p>
      ),
    },
    {
      name: 'Descrição',
      width: '20%',
      cell: (row: ILessonProps) => (
        <p style={{ padding: '1rem 0' }}>{row.description}</p>
      ),
    },
    {
      name: 'Visualizações',
      cell: (row: ILessonProps) => (
        <RatingContainer>
          <p><Eye weight="bold" /> {row.views}</p>
        </RatingContainer>
      ),
    },
    {
      name: 'Avaliações',
      cell: (row: ILessonProps) => (
        <RatingContainer>
          <p>
            <ThumbsUp weight="bold" /> {row.upvotes}
            <ThumbsDown weight="bold" /> {row.downvotes}
          </p>
        </RatingContainer>
      ),
    },
    {
      name: 'Tem exercício?',
      cell: (row: ILessonProps) => (
        <RatingContainer>
          <p style={row.has_exercises ? { background: '#03bb85', padding: '4px 6px', borderRadius: '4px', color: '#fff' } : { background: 'red', padding: '4px 6px', borderRadius: '4px', color: '#fff' }}>
            {row.has_exercises ? 'Sim' : 'Não'}
          </p>
        </RatingContainer>
      ),
    },
    {
      name: 'Tem Material?',
      cell: (row: ILessonProps) => (
        <RatingContainer>
          <p style={row.attachments.length > 0 ? { background: '#03bb85', padding: '4px 6px', borderRadius: '4px', color: '#fff' } : { background: 'red', padding: '4px 6px', borderRadius: '4px', color: '#fff' }}>
            {row.attachments.length > 0 ? 'Sim' : 'Não'}
          </p>
        </RatingContainer>
      ),
    },
    {
      name: (<FiSettings size={16} strokeWidth={2.5} />),
      sortable: false,
      button: true,
      right: true,
      cell: (row: ILessonProps, index: number) => (
        <DropdownButton key={index}>
          <ul>
            <li>
              <Link to={`/aula/editar/${clusterData.slug}/${row.id}`}>
                <MdKeyboardArrowRight size={18} />
                Editar
              </Link>
            </li>
          </ul>
        </DropdownButton>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(`/clusters/${slug}`, {
          params: {
            search: filterText,
          }
        });

        // Data
        setClusterData(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [filterText, slug]);

  const handleUpdateCluster = useCallback(async () => {
    try {
      setLoading(true);

      if (filesData.length === 0) {
        toast.error('Selecione um arquivo para enviar!');
        return;
      }

      const formData = new FormData();

      formData.append('title', clusterData.title);
      formData.append('description', clusterData.description);
      formData.append('material', filesData[0]);
      formData.append('order', String(clusterData.order));

      const response = await api.put(`/clusters/${clusterData.id}`, formData);

      if (response.status === 200) {
        setModalAddMaterialIsOpen(false);
        console.log(response.data);
        setClusterData({
          ...clusterData,
          material: response.data.material,
          material_url: response.data.material_url,
        });
        toast.success('Módulo atualizado com sucesso!', {
          position: 'bottom-right',
          duration: 4000,
          className: 'toast-samuquinha',
        });

        loadConfettiAllPage();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalAddMaterialIsOpen(false);
    }
    finally {
      setLoading(false);
      setModalAddMaterialIsOpen(false);
    }
  }, [clusterData, filesData]);

  const onDragEnd = useCallback(async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    console.log('result: ', result)

    const items = Array.from(clusterData.lessons);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newLessons = items.map((lesson, index) => {
      return {
        ...lesson,
        order: index + 1,
      }
    });

    setClusterData({
      ...clusterData,
      lessons: newLessons,
    });

    const lessonsIds = newLessons.map((lesson) => lesson.id);

    console.log('lessonsIds: ', lessonsIds);

    try {
      const response = await api.put(`/clusters/${clusterData.id}/lessons/reorder`, {
        lesson_ids: lessonsIds,
      });

      if (response.status === 204) {
        toast.success('Aulas reordenadas com sucesso!', {
          position: 'bottom-right',
          duration: 4000,
          className: 'toast-samuquinha',
        });
      }

    } catch (error) {
      console.log(error);
      toast.error('Ops! Algo deu errado ao tentar reordenar as aulas.', {
        position: 'bottom-right',
        duration: 4000,
        className: 'toast-samuquinha',
      });
    }
  }, [clusterData]);

  return (
    <>
      <LoaderBIO isVisible={loading} />

      <Container>
        <HeaderSection>
          <div>
            <Breadcrumbs
              icon={FiHome}
              separator="/"
              route={[
                //{ title: 'Módulos', link: '/modulos' },
                { title: 'Módulos', link: clusterData.type === 'exam' ? '/vestibulares-especificos' : '/modulos' },
                { title: `${clusterData.title}`, link: `/aulas/${clusterData.slug}` },
              ]}
            />
          </div>
          <aside>
            <Link to={`/cadastrar-aula/${slug}`}>
              Cadastrar nova aula
            </Link>
          </aside>
        </HeaderSection>

        {/* <CardTableContainer>
          <DataTableAdapter
            title={clusterData.title}
            subTitle={''}
            columns={columns}
            data={clusterData.lessons}
            paginationServer={false}
            pagination={true}
          //setFilterText={setFilterText}
          />
        </CardTableContainer> */}

        <HeaderStatistics>
          <div>
            <h1>{clusterData.title} ({clusterData?.lessons?.length})</h1>
            <p>
              {clusterData.description}
            </p>
          </div>
        </HeaderStatistics>

        <ListDndContainer>
          <DragDropContext onDragEnd={result => onDragEnd(result)}>
            <DrpableContext>
              <Droppable droppableId={'1'} key={'1'}>
                {(provided, snapshot) => {

                  return (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDraggingOver
                          ? '#adafca20'
                          : "transparent",
                        width: '100%',
                        minWidth: 140,
                        borderRadius: 6,
                      }}
                    >
                      {clusterData?.lessons?.length > 0 && clusterData?.lessons.map((lesson, index) => {
                        return (
                          <Draggable key={lesson.id} draggableId={lesson.id} index={index}>
                            {(provided, snapshot) => {

                              return (
                                <ListDndContainer>
                                  <DndItem
                                    id={lesson.id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      backgroundColor: snapshot.isDragging
                                        ? `$#ffffff`
                                        : theme === 'light' ? '#ffffff' : 'transparent',
                                      ...provided.draggableProps.style
                                    }}>

                                    <div>
                                      <DotsSixVertical weight="duotone" />
                                    </div>
                                    <div>
                                      <Link to={`/aula/editar/${clusterData.slug}/${lesson.id}`}>
                                        <Poster src={handleVideoThumbinalDependingProvider(lesson.video_provider, lesson.video) || lesson.thumbnail_url} alt={lesson.title} />
                                      </Link>
                                    </div>
                                    <div>
                                      <Link to={`/aula/editar/${clusterData.slug}/${lesson.id}`}>{lesson.order} - {lesson.title} ({lesson.duration_formatted})</Link>
                                      <LessonInfoContainer>
                                        <span><Eye weight="bold" /> {lesson.views}</span>
                                        <span>
                                          <ThumbsUp weight="bold" /> {lesson.upvotes}
                                          <ThumbsDown weight="bold" /> {lesson.downvotes}
                                        </span>
                                        <span>
                                          <TooltipAdapter alt={`Contém exercício?`} place="top">
                                            <Pencil weight="bold" /> {lesson.has_exercises ? 'Sim' : 'Não'}
                                          </TooltipAdapter>
                                        </span>
                                        <span>
                                          <TooltipAdapter alt={`Contém material?`} place="top">
                                            <Book weight="bold" /> {lesson.attachments.length > 0 ? 'Sim' : 'Não'}
                                          </TooltipAdapter>
                                        </span>
                                      </LessonInfoContainer>
                                    </div>
                                    <div>
                                      <p className="description">{clusterData.description}</p>
                                    </div>
                                    <div className="center">
                                      <TooltipAdapter alt={`Editar video no ${lesson.video_provider}`} place="top">
                                        <a
                                          href={lesson.video_provider === 'vimeo' ?
                                            `https://vimeo.com/manage/videos/${lesson.video}`
                                            :
                                            `https://www.youtube.com/watch?v=${lesson.video}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <span className="provider">
                                            {lesson.video_provider}
                                          </span>
                                        </a>
                                      </TooltipAdapter>
                                    </div>
                                    <div className="actions">
                                      <DropdownButton key={index}>
                                        <ul>
                                          <li>
                                            <Link to={`/aula/editar/${clusterData.slug}/${lesson.id}`}>
                                              <MdKeyboardArrowRight size={18} />
                                              Editar
                                            </Link>
                                          </li>
                                        </ul>
                                      </DropdownButton>
                                    </div>
                                  </DndItem>
                                </ListDndContainer>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DrpableContext>
          </DragDropContext>
        </ListDndContainer>

        <HeaderStatistics style={{ marginTop: '2rem' }}>
          <div>
            <h1>Apostila do módulo</h1>
          </div>
        </HeaderStatistics>

        <div>
          {clusterData?.material?.length > 0 ? (
            <>
              <CardMaterial
                href={clusterData.material_url}
                title={`Material ${clusterData.title}`}
                type={'pdf'}
              />
              <BtnEdit onClick={() => setModalAddMaterialIsOpen((oldValue) => !oldValue)}>
                <TooltipAdapter alt={`Editar material do módulo`} place="top" cursor='pointer'>
                  <Pencil weight="bold" size={32} /> Editar material do módulo
                </TooltipAdapter>
              </BtnEdit>
            </>
          ) : (
            <div style={{ position: 'relative', width: 'fit-content' }}>
              <FakeMaterial onClick={() => setModalAddMaterialIsOpen((oldValue) => !oldValue)}>
                <CardMaterial
                  href={''}
                  title={`Adicionar material ao módulo ${clusterData.title}`}
                  type={'pdf'}
                />
              </FakeMaterial>
            </div>
          )}
        </div>
      </Container>

      {/* START MODAL EDIT TOPIC */}
      <Modal
        isOpen={modalAddMaterialIsOpen}
        setIsOpen={() => setModalAddMaterialIsOpen((oldValue) => !oldValue)}
        closeModal={() => setModalAddMaterialIsOpen(false)}
        size="lg"
        title="Editar material do módulo"
      >
        <ModalContainer>
          <div>
            <DropZone
              setFilesData={setFilesData}
              maxSize={1024 * 1024 * 5} // 5 mb
              isMulti={false}
              icons={true}
              accept={{
                'image/png': ['.png', '.jpg', '.jpeg', '.webp'],
                'application/pdf': ['.pdf'],
                'application/msword': ['.doc', '.docx'],
              }}
            />
          </div>
          <div>
            <Button type="button" color="primary" onClick={handleUpdateCluster}>Editar material</Button>
          </div>
        </ModalContainer>
      </Modal>
      {/* END MODAL EDIT TOPIC */}
    </>
  );
}

export default Modulos;