import React from 'react';
import { useTheme } from '../../hooks/theme';

import { API_S3 } from '../../services/api_s3';

import { Link } from 'react-router-dom';
import { BackgroundImage } from "react-image-and-background-image-fade";

import { Container } from './styles';

import { IMaterialCategoryProps } from '../../interfaces/MaterialCategory';
import { Book } from 'phosphor-react';

interface ICardMaterialTumbnailProps {
  name: string;
  description: string;
  thumbnail_url: string;
  category: IMaterialCategoryProps;
  material_url: string;
  setCategory(category_id: string): void;
}

const CardMaterialTumbnail: React.FC<ICardMaterialTumbnailProps> = ({ name, description, thumbnail_url, material_url, category, setCategory }) => {
  const { theme } = useTheme();

  return (
    <Container>
      <section>
        <main>
          <a href={material_url} target="_blank" rel="noreferrer">
            <BackgroundImage
              element="figure"
              src={thumbnail_url ? thumbnail_url : (theme === 'light' ? `${API_S3}/default/image-placeholder-light.svg` : `${API_S3}/default/image-placeholder-dark.svg`)}
              //src={theme === 'light' ? `${API_S3}/default/image-placeholder-light.svg` : `${API_S3}/default/image-placeholder-dark.svg`}
              height="250px"
              lazyLoad
            />
          </a>
          <section>
            <header>
              <p><Book weight="bold" size={16} /></p>
              <span>/</span>
              <p onClick={() => setCategory("")}>Materiais didáticos</p>
              <span>/</span>
              <p onClick={() => setCategory(category.id)}>{category.name}</p>
            </header>
            <a href={material_url} target="_blank" rel="noreferrer">{name}</a>
            <p>{description}</p>
          </section>
        </main>
      </section>
    </Container>
  );
}

export default CardMaterialTumbnail;