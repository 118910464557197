import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import api from '../../../services/api';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { FiHome } from 'react-icons/fi';
import { ArrowsVertical, Book, Plus, Trash, YoutubeLogo } from 'phosphor-react';
import toast from 'react-hot-toast';
import axios from 'axios';

import Breadcrumbs from '../../../components/Breadcrumbs';
import LoaderBIO from '../../../components/Loader';
import Input from '../../../components/Form/Input';
import InputMask from '../../../components/Form/InputMask';
import SimpleMultiSelect from '../../../components/Form/SimpleMultiSelect';
import MessageErrorValidator from '../../../components/MessageErrorValidator';
import WysiwygEditor from '../../../components/WysiwygEditor';

import { Container, DetailsExerciseToggle, ExerciseContainer, FakeDropZone, HeaderSection, Line1, Line2, Line3, Line4, Line5, Line6, Line7, LineButton, LineInfoQuestions, SectionContainer1, Separator } from './styles';
import { IClusterProps } from '../../../interfaces/Cluster';
import { IGenericOptions } from '../../../interfaces/IGenericOptions';
import Button from '../../../components/Button';
import Textarea from '../../../components/Form/Textarea';
import DropZone from '../../../components/Form/DropZone';
import CardMaterial from '../../../components/CardMaterial';
import ViewedLessonButton from '../../user/AulasModulo/ViewedLessonButton';

import { loadConfettiAllPage } from '../../../utils/useConfetti';
import { fileExtensionType } from '../../../utils/fileExtensionType';

import { OptionsEntity } from '../../../interfaces/exercise';
import { ILessonProps } from '../../../interfaces/Lesson';
import { IAttachmentsProps } from '../../../interfaces/Attachments';
import Toggle from '../../../components/Toggle';
import { handleVideoThumbinalDependingProvider } from '../../../utils/videoThumbinalDependingProvider';
import { IProviders } from '../../../interfaces/Providers';

const AulaEdit: React.FC = () => {
  const { clusterSlug, id } = useParams<{ clusterSlug: string, id: string }>();
  const history = useHistory();

  // Validation
  const schema = Joi.object({
    cluster_slug: Joi.string().required().messages({ '*': 'Informe um módulo válido.' }),
    title: Joi.string().min(10).max(100).required().messages({
      '*': 'Informe um título válido.',
      'string.min': `O campo título deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo título deve ter no máximo {#limit} caracteres`,
    }),
    duration: Joi.string().min(8).max(8).required().messages({ '*': 'Informe uma duração válida.' }),
    video_provider: Joi.string().required().messages({ '*': 'Informe um provedor de vídeos válido.' }),
    video: Joi.string().min(7).required().messages({ '*': 'Informe um código válido.' }),
    description: Joi.string().allow('').messages({
      '*': 'Informe uma descrição válida.',
      'string.min': `O campo descrição deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo descrição deve ter no máximo {#limit} caracteres`,
    }),
    exercises: Joi.array().items(Joi.object({
      id: Joi.string().allow(null),
      lesson_id: Joi.string().allow(null),
      exam: Joi.string().required().messages({ '*': 'Informe uma Instituição válida.' }),
      year: Joi.string().required().messages({ '*': 'Informe um ano válido.' }),
      difficulty: Joi.string().required().messages({ '*': 'Informe a dificuldade da questão.' }),
      content: Joi.string().max(3000).required().messages({
        '*': 'Informe um enunciado válido.',
        'string.min': `O campo enunciado deve ter no mínimo {#limit} caracteres`,
        'string.max': `O campo enunciado deve ter no máximo {#limit} caracteres`,
      }),
      options: Joi.array().items(Joi.object({
        id: Joi.string().allow(null),
        exercise_id: Joi.string().allow(null),
        content: Joi.string().max(3000).required().messages({
          '*': 'Informe uma opção válida.',
          'string.min': `O campo opção deve ter no mínimo {#limit} caracteres`,
          'string.max': `O campo opção deve ter no máximo {#limit} caracteres`,
        }),
      })),
      correctOption: Joi.string().required().messages({ '*': 'Informe uma resposta correta.' }),
      resolution: Joi.string().max(3000).allow('').messages({
        '*': 'Informe uma resolução válida.',
        'string.min': `O campo resolução deve ter no mínimo {#limit} caracteres`,
        'string.max': `O campo resolução deve ter no máximo {#limit} caracteres`,
      }),
    })).messages({
      '*': 'Informe uma descrição válida.',
      'string.min': `O campo descrição deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo descrição deve ter no máximo {#limit} caracteres`,
    }),
  });

  const { register, handleSubmit, setValue, control, getValues, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));

      let newData = data;

      if (!hasExercises) {
        newData = {
          ...data,
          exercises: [],
        };
      }

      return joiResolver(schema)(newData, context, options);
    },
  });
  const { fields, append, prepend, remove, swap, move, insert, replace, update } = useFieldArray({
    control,
    name: "exercises",
  });

  // States
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [hasExercises, setHasExercises] = useState(false);
  const [openDetails, setOpenDetails] = useState(true);
  const [currentProvider ,setCurrentProvider] = useState<IProviders>('panda');

  // Data
  const [currentLesson, setCurrentLesson] = useState<ILessonProps>({} as ILessonProps);
  const [clustersData, setClustersData] = useState<IGenericOptions[]>([]);
  const [filesData, setFilesData] = useState<File[]>([]);

  // Loaders
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/clusters/${clusterSlug}/lessons/${id}`);

        if (!clusterSlug || !id) return; // Guard clause

        console.log('Complete lesson::: ', response.data);
        setCurrentLesson(response.data);
        setFilesData(response.data.files || []);

        const { cluster_slug, title, duration, video_provider, video, description, exercises } = response.data;

        setCurrentVideoUrl(video);

        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration - hours * 3600) / 60);
        const seconds = duration - hours * 3600 - minutes * 60;

        reset({
          cluster_slug,
          title,
          video_provider,
          video,
          description,
          duration: `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
          exercises: exercises.map((exercise: any) => ({
            id: exercise.id,
            lesson_id: exercise.lesson_id,
            exam: exercise.exam,
            year: exercise.year,
            difficulty: exercise.difficulty,
            content: exercise.content,
            correctOption: String(exercise.options.findIndex((option: any) => option.is_correct === true)),
            resolution: exercise.resolution,
            options: exercise.options.map((option: any) => ({
              id: option.id,
              exercise_id: option.exercise_id,
              content: option.content,
            })),
          }))
        });

        setHasExercises(exercises.length > 0);
      } catch (error) {
        console.log('error: ', error);
      }
    })();
  }, [clusterSlug, id, setValue, append, reset]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/clusters`, {
          params: {
            pageIndex: 0,
            pageSize: 99,
            search: '',
          }
        });

        const clusters = response.data?.records;

        // create a new array of options and values
        const options: IGenericOptions[] = clusters.map((cluster: IClusterProps) => {
          return {
            value: cluster.slug,
            label: cluster.title,
          }
        });

        setClustersData(options);
      }
      catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleRemoveExercise = useCallback((index: number) => {
    if (window.confirm('Deseja realmente remover esta questão?')) {
      remove(index);
    }
  }, [remove]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoadingSubmit(true);

      const [hours, minutes, seconds] = data.duration.split(':');
      const durationInNumber = Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);

      const formData = new FormData();
      formData.append('title', data.title);
      formData.append('duration', String(durationInNumber));
      formData.append('video_provider', data.video_provider);
      formData.append('video', data.video);
      formData.append('audio', '');
      formData.append('description', data.description || '');

      let index = 0;

      if(currentLesson?.attachments?.length > 0) {
        currentLesson.attachments.forEach((attachment)=> {
          formData.append(`attachments[${index}][id]`, attachment.id);
          formData.append(`attachments[${index}][lesson_id]`, attachment.lesson_id);
          formData.append(`attachments[${index}][name]`, attachment.name);
          formData.append(`attachments[${index}][type]`, attachment.type);
          
          index++;
        });
      }

      if (filesData.length > 0) {
        filesData.forEach((file) => {
          formData.append(`attachments[${index}][name]`, String(file.name));
          formData.append(`attachments[${index}][file]`, file);
  
          const fileExtension = file.name.split('.').pop();
          if (!fileExtension) return;
          formData.append(`attachments[${index}][type]`, String(fileExtensionType(fileExtension)));

          index++;
        });
      }

      if (hasExercises) {
        data.exercises.forEach((exercise: any, index: number) => {
          if (exercise.id) {
            formData.append(`exercises[${index}][id]`, exercise.id);
            formData.append(`exercises[${index}][lesson_id]`, exercise.lesson_id);
          }

          formData.append(`exercises[${index}][content]`, exercise.content);
          formData.append(`exercises[${index}][resolution]`, exercise.resolution);
          formData.append(`exercises[${index}][year]`, exercise.year);
          formData.append(`exercises[${index}][difficulty]`, exercise.difficulty);
          formData.append(`exercises[${index}][exam]`, String(exercise.exam).toUpperCase());

          exercise.options.forEach((option: OptionsEntity, indexOption: number) => {
            if (option.id) {
              formData.append(`exercises[${index}][options][${indexOption}][id]`, option.id);
              formData.append(`exercises[${index}][options][${indexOption}][exercise_id]`, option.exercise_id);
            }

            formData.append(`exercises[${index}][options][${indexOption}][content]`, option.content);

            const isCorrect = Number(exercise.correctOption) === indexOption;
            formData.append(`exercises[${index}][options][${indexOption}][is_correct]`, String(isCorrect));
          });
        });
      }

      const response = await api.put(`/clusters/${data.cluster_slug}/lessons/${id}`, formData);
      console.log('response', response.data);

      if (response.status === 200) {
        toast.success('Aula editada com sucesso!', {
          position: 'bottom-right',
          duration: 5000,
          className: 'toast-samuquinha',
        });

        loadConfettiAllPage();

        history.push(`/aulas/${data.cluster_slug}`);
      }

    } catch (error: any) {
      console.log(error);

      if (error.response.data.error === 'lesson-already-exists') {
        toast.error('Ops! Esta aula já foi cadastrada na plataforma! ', {
          position: 'bottom-right',
          duration: 5000,
          className: 'toast-samuquinha',
        });

        return;
      }

      toast.error('Erro ao editar a aula! Por favor, entre em contato com o suporte técnico.', {
        position: 'bottom-right',
        duration: 5000,
        className: 'toast-samuquinha',
      });
    }
    finally {
      setLoadingSubmit(false);
    }
  });

  return (
    <>
      <LoaderBIO isVisible={loading} />

      <Container>
        <HeaderSection>
          <Breadcrumbs
            icon={FiHome}
            separator="/"
            route={[
              { title: 'Módulos', link: '/modulos' },
              { title: `${currentLesson?.cluster?.title}`, link: `/aulas/${clusterSlug}` },
              { title: `${currentLesson.title}`, link: `/aula/editar/${clusterSlug}/${id}` },
            ]}
          />
          <aside>
            {/* button? */}
          </aside>
        </HeaderSection>

        <SectionContainer1>
          <form onSubmit={onSubmit}>
            <Separator>
              <YoutubeLogo size={20} weight="bold" />
              <h2>Informe os dados da aula abaixo:</h2>
            </Separator>
            <Line1>
              <div>
                <SimpleMultiSelect
                  register={register}
                  name="cluster_slug"
                  label="Módulo"
                  placeHolder="Selecione..."
                  options={clustersData}
                  setResult={(values) => { setValue('cluster_slug', values) }}
                  isMulti={false}
                  defaultValue={clusterSlug}
                  required
                />
                <MessageErrorValidator>{errors?.cluster_slug?.message as string}</MessageErrorValidator>
              </div>
              <div>
                <Input
                  register={register}
                  name="title"
                  type="text"
                  label="Título da aula"
                  autoComplete="off"
                />
                <MessageErrorValidator>{errors?.title?.message as string}</MessageErrorValidator>
              </div>
              <div>
                <InputMask
                  register={register}
                  name="duration"
                  type="text"
                  label="duração da aula"
                  pattern="##:##:##"
                  autoComplete="off"
                  setResult={(values) => { setValue('duration', values) }}
                />
                <MessageErrorValidator>{errors?.duration?.message as string}</MessageErrorValidator>
              </div>
            </Line1>
            <Line2>
              <div>
                <Controller
                  name="video_provider"
                  control={control}
                  render={({ field }) => {

                    return (
                      <SimpleMultiSelect
                        register={register}
                        name={field.name}
                        label="Provedor do vídeo"
                        placeHolder="Selecione..."
                        options={[
                          { value: 'panda', label: 'Panda' },
                          { value: 'vimeo', label: 'Vimeo' },
                        ]}
                        setResult={(values) => { 
                          setValue('video_provider', values);
                          setCurrentProvider(values);
                         }}
                        defaultValue={field.value}
                        isMulti={false}
                        required
                      />
                    )
                  }}
                />
                <MessageErrorValidator>{errors?.video_provider?.message as string}</MessageErrorValidator>
              </div>
              <div>
                <Input
                  register={register}
                  name="video"
                  type="text"
                  label="Código do vídeo"
                  autoComplete="off"
                  maxLength={100}
                  placeholder="Ex: 123456789"
                  onChange={(e) => setCurrentVideoUrl(e.target.value)}
                />
                <MessageErrorValidator>{errors?.video?.message as string}</MessageErrorValidator>
              </div>
            </Line2>
            {currentVideoUrl.length > 6 && (
              <Line3>
                <div>
                  <img src={handleVideoThumbinalDependingProvider(currentProvider, currentVideoUrl)} alt="" />
                </div>
                <div>
                  <Textarea
                    register={register}
                    name="description"
                    label="Descrição da aula"
                    autoComplete="off"
                    type="text"
                    rows={10}
                  />
                  <MessageErrorValidator>{errors?.description?.message as string}</MessageErrorValidator>
                </div>
              </Line3>
            )}
            <Separator>
              <Book size={20} weight="bold" />
              <h2>Adicione novos materiais a aula no campo abaixo:</h2>
            </Separator>
            <Line4>
              <div>
                <DropZone
                  setFilesData={setFilesData}
                  maxSize={1024 * 1024 * 3} // 3 mb
                  isMulti={true}
                  icons={true}
                  accept={{
                    'image/png': ['.png', '.jpg', '.jpeg', '.webp'],
                    'application/pdf': ['.pdf'],
                    'application/msword': ['.doc', '.docx'],
                  }}
                />
              </div>
              <Separator style={{marginTop: '1rem'}}>
                <Book size={20} weight="bold" />
                <h2>Materiais da aula:</h2>
              </Separator>
              <div>
                {currentLesson?.attachments?.length > 0 ? (
                  <FakeDropZone>
                    {currentLesson?.attachments?.map((file: IAttachmentsProps, index) => (
                      <CardMaterial
                        key={index} 
                        href={file.file_url} 
                        title={file.name} 
                        type={file.type} 
                        onDelete={() => {

                          if (window.confirm('Deseja realmente excluir este material?')) {
                            setCurrentLesson({
                              ...currentLesson,
                              attachments: currentLesson.attachments.filter((attachment) => attachment.id !== file.id)
                            });
                          }
                        }} 
                      /> 
                    ))}
                  </FakeDropZone>
                ) : (
                  <p>Nenhum material encontrado para esta aula.</p>
                )}
              </div>
            </Line4>
            <Separator style={{ justifyContent: 'space-between' }}>
              <ViewedLessonButton
                type="button"
                id="has-exercises"
                label="Esta aula possui exercícios?"
                isViewed={hasExercises}
                onClick={() => setHasExercises(!hasExercises)}
              />
              {hasExercises && (
                <Toggle
                  onChange={() => setOpenDetails((oldValue) => !oldValue)}
                  checked={!openDetails}
                  labelLeft="Expandir todos"
                  labelRight="Recuar todos"
                  onColor="#cde5e1"
                  offColor="#07302b"
                />
              )}
            </Separator>
            {hasExercises && (
              <>
                {fields.map((item, index) => (
                  <DetailsExerciseToggle key={item.id} open={openDetails}>
                    <summary>
                      <aside>
                        <ArrowsVertical size={22} weight="duotone" />
                        Exercício {index + 1}
                      </aside>
                      <main>
                        {fields.length > 1 && <button type="button" onClick={() => handleRemoveExercise(index)}>
                          <Trash size={20} weight="bold" />
                          Remover Questão {index + 1}
                        </button>}
                      </main>
                    </summary>

                    <ExerciseContainer>
                      <LineInfoQuestions>
                        <div>
                          <Input
                            register={register}
                            name={`exercises[${index}][exam]`}
                            type="text"
                            label="Instituição"
                            autoComplete="off"
                          />
                          <MessageErrorValidator>{(errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.exam?.message as string}</MessageErrorValidator>
                        </div>
                        <div>
                          <Input
                            register={register}
                            name={`exercises[${index}][year]`}
                            type="text"
                            label="Ano da questão"
                            autoComplete="off"
                            minLength={4}
                            maxLength={4}
                          />
                          <MessageErrorValidator>{(errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.year?.message as string}</MessageErrorValidator>
                        </div>
                        <div>
                          <Controller
                            name={`exercises[${index}][difficulty]`}
                            control={control}
                            render={({ field }) => {

                              return (
                                <SimpleMultiSelect
                                  register={register}
                                  name={field.name}
                                  label="Dificuldade"
                                  placeHolder="Selecione..."
                                  options={[
                                    { value: 'easy', label: 'Fácil' },
                                    { value: 'medium', label: 'Médio' },
                                    { value: 'hard', label: 'Difícil' },
                                  ]}
                                  setResult={(values) => { setValue(`exercises[${index}][difficulty]`, values) }}
                                  defaultValue={field.value}
                                  isMulti={false}
                                  required
                                />
                              )
                            }}
                          />
                          <MessageErrorValidator>{(errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.difficulty?.message as string}</MessageErrorValidator>
                        </div>
                      </LineInfoQuestions>
                      <Line5>
                        <div>
                          <Controller
                            name={`exercises[${index}][content]`}
                            control={control}
                            render={({ field }) => (
                              <WysiwygEditor
                                setResult={(values) => { setValue(`exercises[${index}][content]`, values) }}
                                minEditorHeight={200}
                                uploadRoute={`/exercises/upload`}
                                defaultValue={field.value}
                                name={field.name}
                              />
                            )}
                          />
                          <MessageErrorValidator>{(errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.content?.message as string}</MessageErrorValidator>
                        </div>
                      </Line5>
                      <Line6>
                        <div>
                          <h4>(Questão {index + 1}) - Alternativa A</h4>
                          <Controller
                            name={`exercises[${index}][options][0][content]`}
                            control={control}
                            render={({ field }) => (
                              <WysiwygEditor
                                setResult={(values) => { setValue(`exercises[${index}][options][0][content]`, values) }}
                                minEditorHeight={200}
                                uploadRoute={`/exercises/upload`}
                                defaultValue={field.value}
                                name={field.name}
                              />
                            )}
                          />
                          <MessageErrorValidator>{((errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.options?.length > 0) && (errors?.exercises as any)[index]?.options[0]?.content?.message as string}</MessageErrorValidator>
                        </div>
                        <div>
                          <h4>(Questão {index + 1}) - Alternativa B</h4>
                          <Controller
                            name={`exercises[${index}][options][1][content]`}
                            control={control}
                            render={({ field }) => (
                              <WysiwygEditor
                                setResult={(values) => { setValue(`exercises[${index}][options][1][content]`, values) }}
                                minEditorHeight={200}
                                uploadRoute={`/exercises/upload`}
                                defaultValue={field.value}
                                name={field.name}
                              />
                            )}
                          />
                          <MessageErrorValidator>{((errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.options?.length > 0) && (errors?.exercises as any)[index]?.options[1]?.content?.message as string}</MessageErrorValidator>
                        </div>
                        <div>
                          <h4>(Questão {index + 1}) - Alternativa C</h4>
                          <Controller
                            name={`exercises[${index}][options][2][content]`}
                            control={control}
                            render={({ field }) => (
                              <WysiwygEditor
                                setResult={(values) => { setValue(`exercises[${index}][options][2][content]`, values) }}
                                minEditorHeight={200}
                                uploadRoute={`/exercises/upload`}
                                defaultValue={field.value}
                                name={field.name}
                              />
                            )}
                          />
                          <MessageErrorValidator>{((errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.options?.length > 0) && (errors?.exercises as any)[index]?.options[2]?.content?.message as string}</MessageErrorValidator>
                        </div>
                        <div>
                          <h4>(Questão {index + 1}) - Alternativa D</h4>
                          <Controller
                            name={`exercises[${index}][options][3][content]`}
                            control={control}
                            render={({ field }) => (
                              <WysiwygEditor
                                setResult={(values) => { setValue(`exercises[${index}][options][3][content]`, values) }}
                                minEditorHeight={200}
                                uploadRoute={`/exercises/upload`}
                                defaultValue={field.value}
                                name={field.name}
                              />
                            )}
                          />
                          <MessageErrorValidator>{((errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.options?.length > 0) && (errors?.exercises as any)[index]?.options[3]?.content?.message as string}</MessageErrorValidator>
                        </div>
                        <div>
                          <h4>(Questão {index + 1}) - Alternativa E</h4>
                          <Controller
                            name={`exercises[${index}][options][4][content]`}
                            control={control}
                            render={({ field }) => (
                              <WysiwygEditor
                                setResult={(values) => { setValue(`exercises[${index}][options][4][content]`, values) }}
                                minEditorHeight={200}
                                uploadRoute={`/exercises/upload`}
                                defaultValue={field.value}
                                name={field.name}
                              />
                            )}
                          />
                          <MessageErrorValidator>{((errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.options?.length > 0) && (errors?.exercises as any)[index]?.options[4]?.content?.message as string}</MessageErrorValidator>
                        </div>
                      </Line6>
                      <Separator>
                        <section>
                          <main>
                            Qual é a alternativa correta?

                            <Controller
                              name={`exercises[${index}][correctOption]`}
                              control={control}
                              render={({ field }) => {

                                return (
                                  <SimpleMultiSelect
                                    register={register}
                                    name={field.name}
                                    label="Alternativa correta"
                                    placeHolder="Selecione..."
                                    options={[
                                      { value: '0', label: 'A' },
                                      { value: '1', label: 'B' },
                                      { value: '2', label: 'C' },
                                      { value: '3', label: 'D' },
                                      { value: '4', label: 'E' },
                                    ]}
                                    setResult={(values) => { setValue(`exercises[${index}][correctOption]`, values) }}
                                    defaultValue={field.value}
                                    isMulti={false}
                                    required
                                  />
                                )
                              }}
                            />

                            {/* <SimpleMultiSelect
                              register={register}
                              name={`exercises[${index}][correctOption]`}
                              label="Alternativa correta"
                              placeHolder="Selecione..."
                              options={[
                                { value: '0', label: 'A' },
                                { value: '1', label: 'B' },
                                { value: '2', label: 'C' },
                                { value: '3', label: 'D' },
                                { value: '4', label: 'E' },
                              ]}
                              setResult={(values) => { setValue(`exercises[${index}][correctOption]`, values) }}
                              isMulti={false}
                              defaultValue={'3'}
                              required
                            /> */}
                            <MessageErrorValidator>{(errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.correctOption?.message as string}</MessageErrorValidator>
                          </main>
                        </section>
                      </Separator>
                      <Line7>
                        <div>
                          <h4>Resolução da questão (opcional)</h4>
                          <Controller
                            name={`exercises[${index}][resolution]`}
                            control={control}
                            render={({ field }) => (
                              <WysiwygEditor
                                setResult={(values) => { setValue(`exercises[${index}][resolution]`, values) }}
                                minEditorHeight={200}
                                uploadRoute={`/exercises/upload`}
                                defaultValue={field.value}
                                name={field.name}
                              />
                            )}
                          />
                          <MessageErrorValidator>{(errors?.exercises as any)?.length > 0 && (errors?.exercises as any)[index]?.resolution?.message as string}</MessageErrorValidator>
                        </div>
                      </Line7>
                    </ExerciseContainer>
                  </DetailsExerciseToggle>
                ))}
              </>
            )}
            {hasExercises && (
              <LineButton>
                <section onClick={() => append({})}>
                  <Plus size={22} weight="duotone" />
                  Adicionar novo exercício ({fields.length + 1})
                </section>
              </LineButton>
            )}
            <LineButton>
              <Button type="submit" loading={loadingSubmit} color="primary">
                Editar Aula
              </Button>
            </LineButton>
          </form>
        </SectionContainer1>
      </Container>
    </>
  );
}

export default AulaEdit;