import React, { HTMLInputTypeAttribute } from 'react';

import { DebounceInput } from 'react-debounce-input';

//import { Container } from './styles';

interface IInputDebounceAdapterProps {
  onChange(event: any): void;
  minLength: number;
  debounceTimeout: number;
  placeholder?: string;
  type?: HTMLInputTypeAttribute
}


const InputDebounceAdapter: React.FC<IInputDebounceAdapterProps> = ({ type, onChange, minLength, debounceTimeout, placeholder }) => {
  return (
    <DebounceInput
      type={type}
      minLength={minLength}
      debounceTimeout={debounceTimeout}
      onChange={onChange} 
      placeholder={placeholder}
    />
  );
}

export default InputDebounceAdapter;