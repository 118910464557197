import React, { useEffect, useState } from 'react';

import api from '../../../services/api';


import { Link } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DataTableAdapter from '../../../components/DataTableAdapter';
import LoaderBIO from '../../../components/Loader';

import { FiHome, FiSettings } from 'react-icons/fi';

import { CardTableContainer, Container, HeaderSection, Infos, Poster } from './styles';

import { IStudyPlanProps } from '../../user/PlanoDeEstudosAula';
import { Clock, YoutubeLogo } from 'phosphor-react';
import DropdownButton from '../../../components/DropdownButton';
import { MdKeyboardArrowRight } from 'react-icons/md';

const PlanoDeEstudos: React.FC = () => {

  // Loaders
  const [loading, setLoading] = useState(false);

  // Data
  const [studyPlansData, setStudyPlansData] = useState<IStudyPlanProps[]>([]);

  const columns: TableColumn<IStudyPlanProps>[] = [
    {
      name: 'Nome do Plano',
      selector: row => row.title,
      cell: (row: IStudyPlanProps) => (
        <>
          <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <Poster src={row.thumbnail_url} alt={row.title} />
            <div>
              {row.title}
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Informações',
      cell: (row: IStudyPlanProps) => (
        <Infos>
          <span>
            <YoutubeLogo size={20} weight="duotone" />
            {row.countLessons} aulas
          </span>
          <span>
            <Clock size={20} weight="duotone" />
            {row.totalDuration}
          </span>
        </Infos>
      ),
    },
    {
      name: 'Descrição',
      cell: (row: IStudyPlanProps) => (
        <>
          <p style={{ padding: '1rem 0' }}>{row.description}</p>
        </>
      ),
    },
    /* {
      name: (<FiSettings size={16} strokeWidth={2.5} />),
      sortable: false,
      button: true,
      right: true,
      cell: (row: IStudyPlanProps, index: number) => (
        <DropdownButton key={index}>
          <ul>
            <li>
              <Link to={`/plano-de-estudos/${row.id}/editar`}>
                <MdKeyboardArrowRight size={18} />
                Editar
              </Link>
            </li>
          </ul>
        </DropdownButton>
      ),
    }, */
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get(`/study-plans`);

        setStudyPlansData(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <LoaderBIO isVisible={loading} />
      
      <Container>
        <HeaderSection>
          <Breadcrumbs
            icon={FiHome}
            separator="/"
            route={[{ title: 'Plano de estudos', link: '/plano-de-estudos' }]}
          />
          <aside>
            <Link to="/plano-de-estudos/novo" type="button" color="primary">Novo plano de estudos</Link>
          </aside>
        </HeaderSection>

        <CardTableContainer>
          <DataTableAdapter
            title="Planos de Estudos"
            subTitle={'Gerenciamento de planos de estudos'}
            columns={columns}
            data={studyPlansData}
            paginationServer={false}
          />
        </CardTableContainer>
      </Container>
    </>
  );
}

export default PlanoDeEstudos;