import styled from 'styled-components';

interface IMainHeaderProps {
  cover_url: string;
}

export const MainHeader = styled.section<IMainHeaderProps>`
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 1rem;
  background: ${props => props.theme.cards.primary};
  box-shadow: ${props => props.theme.shadows.primary};

  > section {
    position: relative;
    width: 104%;
    background: url(${props => props.cover_url}) no-repeat center;
    background-position: center center;
    background-size: cover;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 150px;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }

    > header {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      max-width: 1536px;
      height: 360px;
      margin: 0 auto;
  
      > main {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: space-between;
        padding: 0 1rem;

        /* TABLET */
        @media (max-width: 1300px) {
          padding: 0 1rem;
        }
  
        > section:nth-child(1) {
          width: 100%;
          padding: 1rem 2rem;

          @media (max-width: 425px) {
            display: flex;
            justify-content: center;
          }
        }
  
        > section:nth-child(2) {
          width: 100%;
          padding: 1rem 2rem;
          display: flex;
          justify-content: flex-end;

          @media (max-width: 600px) {
            display: none;
          }
        }
      }
  
      > footer {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 1rem;
        padding: 0 3rem;
        margin-bottom: 1rem;
        z-index: 9;

        /* TABLET */
        @media (max-width: 1300px) {
          padding: 0 3rem;
        }

        @media (max-width: 425px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          > div {
            gap: 1rem;
            margin-bottom: 1.5rem;
          }

          h2, p {
            display: flex;
            justify-content: center;
            text-align: center;
          }
        }
    }
  }
}
`;

export const FloatValidation = styled.div`
  padding: 7px 12px;
  backdrop-filter: blur(5px);
  border-radius: 6px;
  color: #fff;
  text-align: center;
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 700;
  border: 2px solid #ffffff45;
  width: fit-content;
`;

export const ChangeCover = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 12px;
  backdrop-filter: blur(5px);
  border-radius: 6px;
  color: #fff;
  text-align: center;
  font-size: .7rem;
  text-transform: uppercase;
  font-weight: 700;
  border: 2px dashed #ffffff45;
  width: fit-content;
  cursor: pointer;

  > svg {
    margin-right: 6px;
    font-size: 14px;
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
  cursor: pointer;

  @media (max-width: 425px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p, h2 {
      position: relative;
      width: 100%;
      text-align: center;
    }
  }
`;

export const Bio = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: -5px;

  > h2 {
    margin-bottom: 0.5rem;
  }

  > h2, p {
    width: 100%;
    color: #ffffff;
    text-align: left;
  }

  > p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
  }
`;