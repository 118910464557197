import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  border-radius: ${props => props.theme.radius.primary};
  padding: 1rem;

  #pagination-first-page, #pagination-previous-page, #pagination-next-page, #pagination-last-page {
    
    > svg {
      color: ${props => props.theme.colors.white} !important;
      fill: ${props => props.theme.colors.white} !important;
    }

    &:disabled {

      > svg {
        color: ${props => props.theme.colors.white}50 !important;
        fill: ${props => props.theme.colors.white}50 !important;
      }
    }
  }

  a {
    color: ${props => props.theme.colors.white};
    font-weight: 600;
    text-decoration: none;
  }

  > div {
    ::-webkit-scrollbar {
      width: 22px;
      height: 22px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0px;
      border: 8px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      background-color: #29c191;
    }
  }

  header {
    padding: 0 0.5rem;
    background: transparent !important;
  }

  .rdt_TableRow {
    color: ${props => props.theme.colors.white};
    background: transparent;
  }

  .rdt_Table {
    background: transparent !important;
    
    > div {
      background: transparent !important;

      > div {
        color: ${props => props.theme.colors.white};
      }
    }
  }
  
  .rdt_TableHeadRow {
    padding-right: 5px;
  }

  .rdt_TableHeadRow {
    background: ${props => props.theme.colors.white}15;
    backdrop-filter: blur(10px);
    color: ${props => props.theme.colors.white};
    border-radius: 8px 8px 0 0;
  }

  .rdt_Pagination {
    background: transparent;
    color: ${props => props.theme.colors.white};
  }

  .rdt_TableCol_Sortable {

    > div, span {
      font-weight: bold;
      text-transform: uppercase !important;
    }
  }
`;

export const SubHeaderComponentMemo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  
  > aside {
    
    h2 {
      color: ${props => props.theme.colors.white};
    }
  }
  
  > main {
    display: flex;
    align-items: center;
    gap: 1rem;
    
    > input {
      padding: 0.75rem 2.5rem 0.75rem 1rem;
      background: ${props => props.theme.cards.primary};
      border: 1px solid ${props => props.theme.cards.border};
      box-shadow: ${props => props.theme.shadows.primary};
      border-radius: ${props => props.theme.radius.secondary};
      color: ${props => props.theme.colors.white};
      width: 220px;
      transition: all .3s ease-in-out;
      
      &:focus {
        width: 300px;
      }
    }
  }
  `;

  export const ExportButton = styled.div`
    border: none;
    cursor: pointer;
    color: ${props => props.theme.colors.white};
    padding: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid transparent;
    background: none;
  `;