import styled from 'styled-components';

interface IBadgePros {
  role:
    | 'moderation'
    | 'author'
    | 'student'
}

const badgeColorVariants = {
  moderation: '#2cc292',
  author: '#9080fa',
  student: '#ffc107',
}

export const Container = styled.div<IBadgePros>`
    height: 20px;
    margin-top: 12px;
    padding: 0 8px;
    border-radius: 200px;
    background: ${props => [badgeColorVariants[props.role]]};
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
`;
