import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';

import { useAuth } from '../../../hooks/auth';
import { useForm } from 'react-hook-form';
import { trim } from 'lodash';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import Galaxy from '../Galaxy';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import MessageErrorValidator from '../../../components/MessageErrorValidator';

import { FiLock, FiUser } from 'react-icons/fi';

import { Container, ModalForgetContainer, WorldRotate } from './styles';

import { API_S3 } from '../../../services/api_s3';

import logoWhiteVertical from '../../../assets/logo/logo-vertical-white.svg';
import api from '../../../services/api';
import ModalMaintenance from '../../../components/ModalMaintenance';

const SignInOld: React.FC = () => {
  const { login } = useAuth();

  const developmentUri = (() => {
    const uri = window.location.href;

      if (uri.includes('localhost') || uri.includes('vercel')) {
        return true;
      } else {
        return false;
      }
  })();

  // Validation login
  const { handleSubmit, setValue, formState: { errors }, reset, register } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log(
        'validation result',
        await joiResolver(schema)(data, context, options),
      );
  
      return joiResolver(schema)(data, context, options);
    },
  });

  // Validation send email forget password
  const { handleSubmit: handleSubmit2, setValue: setValue2, formState: { errors: errors2 }, reset: reset2, register: register2 } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log(
        'validation result',
        await joiResolver(schema2)(data, context, options),
      );
      return joiResolver(schema2)(data, context, options);
    },
  });

  // Schema forget password
  const schema = Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }).required().messages({ '*': 'Informe um e-mail válido.', 'string.email': 'Informe um e-mail válido.' }),
    password: Joi.string().required().messages({
      '*': 'Informe uma senha válida.',
      'string.min': `O campo senha deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo senha deve ter no máximo {#limit} caracteres`,
    }),
  });

  // Schema forget password
  const schema2 = Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }).required().messages({ '*': 'Informe um e-mail válido.', 'string.email': 'Informe um e-mail válido.' }),
  });

  //Loader
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  // Modals
  const [openModal, setOpenModal] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setButtonLoading(true);
      //console.log(data);

      const emailTrined = trim(data.email.replace(/\s/g, '')).trim().toLocaleLowerCase();

      await login({
        email: emailTrined,
        password: data.password
      });
    }
    catch (error) {
      console.log(error);
      toast.error('Desculpe, as informações de login fornecidas não correspondem a nenhum usuário cadastrado. Verifique se o endereço de e-mail e a senha estão corretos e tente novamente. 😉',
        {
          position: 'bottom-right',
          duration: 10000,
          className: 'toast-samuquinha',
        }
      );
    }
    finally {
      setButtonLoading(false);
    }
  });

  const onSubmitForgetPassword = handleSubmit2(async data => {
    try {
      console.log(data);
      setLoading(true);
      const emailTrined = trim(data.email.replace(/\s/g, '')).trim().toLocaleLowerCase();

      const response = await api.post('/passwords/forgot', {
        email: emailTrined,
      });

      console.log(response.data);

      toast.success('O e-mail de redefinição de senha foi enviado com sucesso para o endereço de e-mail especificado. Verifique a sua caixa de entrada para mais detalhes. 😉', {
        position: 'bottom-right',
        duration: 7000,
        className: 'toast-samuquinha',
      });

      setOpenModal(false);
    } catch (error: any) {
      console.log(error);

      if (error.response.data.error === 'user-not-found') {
        toast.error('Desculpe, não encontramos nenhum usuário com o nome ou informações especificadas. Por favor, verifique se as informações estão corretas e tente novamente. 😉', {
          position: 'bottom-right',
          duration: 10000,
          className: 'toast-samuquinha',
        });

        return;
      }

      toast.error('Ops! Algo deu errado. Verifique o email informado e tente novamente.', {
        position: 'bottom-center',
        duration: 10000,
      });

      reset2();

      setLoading(false);
    } finally {
      setLoading(false);
    }
  });

  return (
    <>
      <Container style={{ backgroundImage: `url('${API_S3}/default/bg-login.jpg')` }}>
        {/* {!developmentUri && <ModalMaintenance />} */}
        <WorldRotate style={{ backgroundImage: `url('${API_S3}/default/mundo-biolandia.png')` }} />
        <Galaxy />
        <main>
          <section>
            <main className="main-form">
              <header>
                <img src={logoWhiteVertical} alt="" />
              </header>
              <div>
                <p>Preencha suas credenciais abaixo para acessar a plataforma.</p>
              </div>
              <form onSubmit={onSubmit}>
                <div>
                  <aside>
                    <FiUser size={25} />
                  </aside>
                  <main>
                    <label>E-mail:</label>
                    <input {...register("email")} type="text" />
                  </main>
                </div>
                <div>
                  <aside>
                    <FiLock size={25} />
                  </aside>
                  <main>
                    <label>Senha:</label>
                    <input {...register("password")} type="password" />
                  </main>
                </div>
                {errors?.email?.message && <MessageErrorValidator>❌ {errors?.email?.message as string}</MessageErrorValidator>}
                <p>Não está conseguindo acessar? <span onClick={() => setOpenModal(!openModal)}>Redefina a sua senha.</span></p>
                <footer>
                  <Button type="submit" color="primary" loading={buttonLoading} disabled={buttonLoading}>Entrar</Button>
                  <p>© {new Date().getFullYear()} - Desenvolvido por: Px Tecnologia</p>
                </footer>
              </form>
            </main>
          </section>
        </main>
      </Container>

      {/* MODALS */}
      <Modal
        isOpen={openModal}
        setIsOpen={() => setOpenModal(!openModal)}
        closeModal={() => setOpenModal(false)}
        size="md"
        background="#ffffff"
        border="3px solid #3e3f5e11"
        titleColor="#3e3f5e"
        height="auto"
      >
        <ModalForgetContainer>
          <h1>Esqueceu sua senha? 😐</h1>
          <p>
            Por favor, informe o endereço de e-mail que você forneceu durante a compra para que possamos lhe enviar instruções de como trocar a sua senha. 
          </p>
          <main>
            <form onSubmit={onSubmitForgetPassword}>
              <label>Informe o seu e-mail</label>
              <input type="text" {...register2("email")} autoComplete="off" />
              {errors2?.email?.message && <MessageErrorValidator>{errors2?.email?.message as string}</MessageErrorValidator>}

              <Button type="submit" color="primary" disabled={loading}>Recuperar senha</Button>
              <p>Se você não receber um e-mail de redefinição de senha em alguns minutos, verifique sua caixa de spam ou entre em contato com o suporte. 😉</p>
            </form>
          </main>
        </ModalForgetContainer>
      </Modal>
    </>
  );
}

export default SignInOld;