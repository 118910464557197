import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  /* TABLET */
  @media (max-width: 768px) { }

  /* MOBILE */
  @media (max-width: 480px) { }
`;

export const ContentGrid1 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  padding: 28px 32px;
  border-radius: 12px;
  grid-template-columns: repeat(1, 1fr);
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};

  /* TABLET */
  @media (max-width: 768px) {
    //grid-template-columns: repeat(1, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    //grid-template-columns: repeat(1, 1fr);
  }

  > div {

    > form {
      display: flex;
      position: relative;

      > div:first-child {
        z-index: 9;
      }
    }
  }
`;

export const ContentGrid2 = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0;
  min-height: 200px;
  grid-template-columns: repeat(3, 1fr);

  /* TABLET */
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* MOBILE */
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }

  > a {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 42px 32px;
    border-radius: 12px;
    text-decoration: none;
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    color: ${props => props.theme.colors.white};
    transition: all .2s ease-in-out;

    &:hover {
      transform: translateY(-.4rem);
    }

    > svg {
      margin-bottom: 1.4rem;
      color: ${props => props.theme.colors.purple};
    }

    > h1 {
      line-height: 1;
      font-size: 20px;
      margin-bottom: 1rem;
      color: ${props => props.theme.colors.textColor};
    }

    > p {
      color: ${props => props.theme.colors.muted};
      font-size: 12px;
      font-weight: 500;
      line-height: 1.4285714286em;
    }
  }

`;

export const SearchButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.primary};
  color: #fff;
  padding: .9rem 1.5em;
  border-radius: 0 12px 12px 0;
  margin-left: -1rem;
  z-index: 9;
  text-decoration: none;

  > svg {
    width: 24px;
    height: 24px;
  }
`;
