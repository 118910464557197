import React, { useEffect, useLayoutEffect, useState } from 'react';

import api from '../../../services/api';

import { useTheme } from '../../../hooks/theme';

import Breadcrumbs from '../../../components/Breadcrumbs';

import LoaderBIO from '../../../components/Loader';

import { FiHome } from 'react-icons/fi';

import { CardTableContainer, Container, HeaderSection } from './styles';


const Aula: React.FC = () => {
  const { theme } = useTheme();

  // Loaders
  const [loading, setLoading] = useState(false);

  return (
    <>
      <LoaderBIO isVisible={loading} />
      
      <Container>
        <HeaderSection>
          <Breadcrumbs
            icon={FiHome}
            separator="/"
            route={[
              { title: 'Módulos', link: '/modulos' },
            ]}
          />
          <aside>
            {/* button? */}
          </aside>
        </HeaderSection>

        <CardTableContainer>
         A visualização da aula ainda não está disponível. 😢
        </CardTableContainer>
      </Container>
    </>
  );
}

export default Aula;