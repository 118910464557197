import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTheme } from '../../hooks/theme';
import { useAuth } from '../../hooks/auth';

import CountUpAdapter from '../CountUpAdapter';
import TooltipAdapter from '../TooltipAdapter';

import ArcProgress from 'react-arc-progress';
import { FiChevronsRight, FiInfo } from 'react-icons/fi';
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react';
import { EffectCards } from 'swiper';
import 'swiper/swiper-bundle.css';

import {
  CloseBtn,
  Container,
  InnerSlider,
  Modal,
  ModalContainer,
  ModalInner,
  PersonIcon,
  PesonContainer,
} from './styles';
import LoaderSpinner from '../LoaderSpinner';
import { CircleWavyCheck, Info, X } from 'phosphor-react';
import { API_S3 } from '../../services/api_s3';

interface IEvolutionProps {
  exp_max: number;
  exp_min: number;
  icon: string;
  icon_url: string;
  key: string;
  name: string;
  next_evolution?: IEvolutionProps;
}

const CardLevelProgress: React.FC = () => {
  const { theme } = useTheme();
  const { user } = useAuth();
  const personRef = useRef<HTMLDivElement>(null);
  const [swiperRef, setSwiperRef] = useState<SwiperRef | null>(null);

  const [curentEvolution, setCurentEvolution] = useState<IEvolutionProps>(
    user?.evolution,
  );

  // Modals
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setCurentEvolution(user.evolution);
  }, [user.evolution]);

  const handleShowNextLevel = useCallback(() => {
    if (user.evolution?.next_evolution) {
      setCurentEvolution(user.evolution?.next_evolution);
      personRef.current?.style.setProperty('filter', 'brightness(0%)');
    }
  }, [user.evolution]);

  const handleShowBackLevel = useCallback(() => {
    setCurentEvolution(user.evolution);
    personRef.current?.style.setProperty('filter', 'brightness(100%)');
  }, [user.evolution]);

  const SlideTo = useCallback(
    (index: number) => {
      swiperRef?.swiper?.slideTo(index, 1000, false);
    },
    [swiperRef],
  );

  const handleOpenModalEvolutions = useCallback(() => {
    setOpenModal(true);
    SlideTo(5);
  }, [SlideTo]);

  const slides = [
    {
      title: 'Ovo',
      subtitle: 'Start with your email',
      image: API_S3 + '/evolutions/0.png',
      range: 'Do level 0 ao 4',
      min: 0,
    },
    {
      title: 'Larva 1',
      subtitle: "Let's make things secure",
      image: API_S3 + '/evolutions/1.png',
      range: 'Do level 5 ao 10',
      min: 5,
    },
    {
      title: 'Larva 2',
      subtitle: 'Smash the button below',
      image: API_S3 + '/evolutions/2.png',
      range: 'Do level 11 ao 16',
      min: 11,
    },
    {
      title: 'Larva 3',
      subtitle: 'Smash the button below',
      image: API_S3 + '/evolutions/3.png',
      range: 'Do level 17 ao 22',
      min: 17,
    },
    {
      title: 'Larva 4',
      subtitle: 'Smash the button below',
      image: API_S3 + '/evolutions/4.png',
      range: 'Do level 23 ao 29',
      min: 23,
    },
    {
      title: 'Larva 5',
      subtitle: 'Smash the button below',
      image: API_S3 + '/evolutions/5.png',
      range: 'Do level 30 ao 37',
      min: 30,
    },
    {
      title: 'Larva 6',
      subtitle: 'Smash the button below',
      image: API_S3 + '/evolutions/6.png',
      range: 'Do level 38 ao 45',
      min: 38,
    },
    {
      title: 'Larva 7',
      subtitle: 'Smash the button below',
      image: API_S3 + '/evolutions/7.png',
      range: 'Do level 46 ao 53',
      min: 46,
    },
    {
      title: 'Pupa',
      subtitle: 'Smash the button below',
      image: API_S3 + '/evolutions/8.png',
      range: 'Do level 54 ao 62',
      min: 54,
    },
    {
      title: 'Besouro',
      subtitle: 'Smash the button below',
      image: API_S3 + '/evolutions/9.png',
      range: 'Do level 63 ao ∞',
      min: 63,
    },
  ];

  return (
    <>
      <Container>
        {user.evolution ? (
          <>
            <header>
              <div>
                {user.evolution.next_evolution ? (
                  <h2>
                    Faltam +{user.evolution.exp_max - user.current_exp} EXP
                  </h2>
                ) : (
                  <h2>Parabéns, {user.first_name}! 😍</h2>
                )}
                {user.evolution.next_evolution ? (
                  <p>
                    Para você alcançar o nível{' '}
                    <strong>{user.evolution.next_evolution.name}</strong>
                  </p>
                ) : (
                  <p>Você alcançou o nível máximo!</p>
                )}
              </div>
              <TooltipAdapter
                alt="Conheça todos os personagens 👻"
                place="top"
                cursor="pointer"
                onClick={handleOpenModalEvolutions}
              >
                <FiInfo strokeWidth={2.5} size={20} />
              </TooltipAdapter>
            </header>
            <main>
              <div>
                <ArcProgress
                  progress={
                    user.evolution?.next_evolution ? user.current_exp / 100 : 1
                  } //0 to 1
                  fillColor={
                    theme === 'dark'
                      ? { gradient: ['#79ffb8', '#79ffb850'] }
                      : { gradient: ['#01b574', '#17c0a71f'] }
                  }
                  emptyColor={theme === 'dark' ? '#ffffff1f' : '#17c0a71f'}
                  style={{ position: 'relative' }}
                  thickness={18}
                  speed={-70} // -100 to 100, zero is default
                  size={270}
                />

                <footer>
                  <main>
                    <div className="value">0%</div>
                    <div>
                      <h1 className="value-percent">
                        {user.evolution?.next_evolution ? (
                          <CountUpAdapter value={user?.current_exp || 0} />
                        ) : (
                          <CountUpAdapter value={100} />
                        )}
                      </h1>
                    </div>
                    <div className="value">100%</div>
                  </main>
                  <p>
                    {user.evolution?.name}
                    {user.evolution?.next_evolution && (
                      <>
                        <FiChevronsRight strokeWidth={2.5} />
                        {user.evolution?.next_evolution?.name}
                      </>
                    )}
                  </p>
                </footer>
                <PesonContainer>
                  <TooltipAdapter
                    alt={
                      user.evolution?.next_evolution
                        ? `🚫 Próxima evolução: ${user.evolution?.next_evolution.name}`
                        : `${user.evolution?.name}`
                    }
                    place="top"
                    cursor="help"
                  >
                    <PersonIcon
                      ref={personRef}
                      style={{
                        backgroundImage: `url(${curentEvolution?.icon_url})`,
                      }}
                      onMouseOver={handleShowNextLevel}
                      onMouseOut={handleShowBackLevel}
                    />
                  </TooltipAdapter>
                </PesonContainer>
              </div>
            </main>
          </>
        ) : (
          <LoaderSpinner backgroundTransparent />
        )}
      </Container>

      {/* START MODAL */}
      <Modal
        onRequestClose={() => setOpenModal(!openModal)}
        isOpen={openModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        closeTimeoutMS={200}
        overlayClassName="react-modal-overlay"
      >
        <ModalContainer>
          <ModalInner>
            <CloseBtn onClick={() => setOpenModal(!openModal)}>
              <X weight="bold" />
            </CloseBtn>

            <Swiper
              effect={'cards'}
              grabCursor={true}
              modules={[EffectCards]}
              className="mySwiper"
            >
              {slides.map((slide, index) => (
                <SwiperSlide>
                  <InnerSlider>
                    {user?.level >= slide.min ? (
                      <header>
                        <TooltipAdapter
                          alt="Personagem desbloqueado!"
                          place="top"
                          cursor="help"
                        >
                          <CircleWavyCheck
                            size={40}
                            color="#ffffff"
                            weight="duotone"
                          />
                        </TooltipAdapter>
                      </header>
                    ) : (
                      <header>
                        <TooltipAdapter
                          alt="Você ainda não desbloqueou <br /> este personagem! 🤐"
                          place="top"
                          cursor="help"
                        >
                          <Info size={40} color="#ffffff" weight="duotone" />
                        </TooltipAdapter>
                      </header>
                    )}
                    <img
                      key={index}
                      src={slide.image}
                      alt=""
                      className={user?.level >= slide.min ? '' : 'silhete'}
                    />
                    <div>
                      <span>
                        <h4>{slide.title}</h4>
                        <p>{slide.range}</p>
                      </span>
                    </div>
                  </InnerSlider>
                </SwiperSlide>
              ))}
            </Swiper>
          </ModalInner>
        </ModalContainer>
      </Modal>
      {/* END MODAL */}
    </>
  );
};

export default CardLevelProgress;
