import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  overflow: hidden;
  
  > header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    background: ${props => props.theme.cards.tertiary};
    border-bottom: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};

    > aside {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      > p {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 0.5rem;
        font-size: 12px;
        color: ${props => props.theme.colors.muted};

        @media (max-width: 600px) {
          font-size: 10px;
        }
      }
    }

    > main {
      display: flex;
      gap: 0.75rem;

      @media (max-width: 600px) {
        font-size: 10px;
      }
      
      > button {
        background: none;
        border: none;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
        font-size: 11px;
        color: ${props => props.theme.colors.muted};

        &:last-child {
          color: red;
        }

        &:first-child {
          color: ${props => props.theme.colors.muted};
        }
      }
    }
  }
  
  > main {
    position: relative;
    display: grid;
    gap: 4rem;
    grid-template-columns: 150px auto;
    width: 100%;
    padding: 2rem;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
    
    > aside {
      // foto do usuário
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      > h3 {
        margin-top: 0.75rem;
        margin-bottom: 0.35rem;
        font-size: 14px;
        font-weight: 600;
      }
      
      > p {
        font-size: 12px;
        font-weight: 400;
        width: 100%;
        text-align: center;
        color: ${props => props.theme.colors.muted};
      }
    }
    
    > main {
      
      > h2 {
        margin-bottom: 1rem;
      }
      
      > p {
        font-size: 14px;
        line-height: 1.71429em;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      img {
        max-width: 300px;
      }
    }
  }
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > main {

    > h2 {
      font-size: 22px;
      margin-bottom: 1.5rem;
    }

    > p {
      font-size: 14px;
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: flex-start;
      line-height: 1.5;

      @media (max-width: 600px) {
        margin-bottom: 2rem;
      }

      img {
        max-width: 60%;
        margin: 1rem 0;
        border-radius: 6px;
      }
    }
  }
  
  > footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-top: 1px solid ${props => props.theme.cards.border};
    padding: 1rem 0 0 0;

    > p {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;
      font-size: 12px;
      color: ${props => props.theme.colors.muted};
    }
  }
`;

export const ModalContainer = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Line1 = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Line2 = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  `;

export const Line3 = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;

  > div, button {
    width: 100%;
  }
`;