import React, { useCallback, useContext, useState } from 'react';

import api from '../../services/api';

import { useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/auth';
import { AnswerContext } from '../../pages/user/ComunidadeFeed';

import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ReactMarkdown from 'react-markdown';
import { CalendarBlank, ChatCenteredDots } from 'phosphor-react';
import toast from 'react-hot-toast';

import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

import AvatarProgress from '../AvatarProgress';
import BadgeUserRole from '../BadgeUserRole';
import ModalConfirm from '../ModalConfirmation';
import Modal from '../Modal';
import MessageErrorValidator from '../MessageErrorValidator';
import WysiwygInnerModal from '../WysiwygInnerModal';
import AwesomeButtonAdapter from '../AwesomeButtonAdapter';
import ToggleLikeAndDeslike, { IVoteType } from '../ToggleLikeAndDeslike';

import { Container, Line2, Line3, MainContent, ModalContainer } from './styles';

import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';

import badgeVerified from '../../assets/verified.svg';

import { loadConfettiAllPage } from '../../utils/useConfetti';
import { Role } from '../../interfaces/Role';
import { IEvolutionProps } from '../../interfaces/Evolutions';
import { AxiosError } from 'axios';

interface ICardCommunityAnswerProps {
  id: string;
  topic_id: string;
  content: string;
  is_verified: boolean;
  created_at: string;
  upvotes: number;
  downvotes: number;
  user_vote?: IVoteType;
  author: {
    id: string;
    current_exp: number;
    show_name: string;
    avatar_url: string;
    level: number;
    role: Role;
    role_name: string;
    evolution: IEvolutionProps;
    university?: {
      id: string;
      name: string;
      acronym: string;
      created_at: string;
      updated_at: string;
    };
    career?: {
      id: string;
      name: string;
      created_at: string;
      updated_at: string;
    };
  };
  className: string;
}

const CardCommunityAnswer: React.FC<ICardCommunityAnswerProps> = ({
  className,
  id,
  topic_id,
  content,
  is_verified,
  created_at,
  author,
  upvotes,
  downvotes,
  user_vote,
}) => {
  const { user, WithAuthorization } = useAuth();

  // Validation
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log(
        'validation result',
        await joiResolver(schema)(data, context, options),
      );
      return joiResolver(schema)(data, context, options);
    },
  });

  // Joi Validation Schema
  const schema = Joi.object({
    description: Joi.string().min(5).max(1500).required().messages({
      '*': 'Informe uma descrição válida.',
      'string.min': `O campo descrição deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo descrição deve ter no máximo {#limit} caracteres`,
    }),
  });

  // Modals
  const [modalEditTopicIsOpen, setModalEditTopicIsOpen] = useState(false);
  const [confirmRemoveTopicModal, setConfirmRemoveTopicModal] = useState(false);
  const [confirmVerifiedModal, setConfirmVerifiedModal] = useState(false);

  // Context
  const { answersData, setAnswersData } = useContext(AnswerContext);

  const handleRemoveTopic = useCallback(
    async (topic_id: string) => {
      try {
        const response = await api.delete(
          `/forums/topics/${topic_id}/answers/${id}`,
        );
        console.log(response.data);

        if (response.status === 200) {
          const newAnswersData = answersData.filter(answer => answer.id !== id);
          setAnswersData([...newAnswersData]);

          toast.error('Resposta excluída com sucesso!', {
            position: 'bottom-right',
            duration: 6000,
            className: 'toast-samuquinha',
          });

          setConfirmRemoveTopicModal(oldValue => !oldValue);
        }
      } catch (error) {
        console.log(error);
        toast.error('Ops! Algo deu errado. 🤷‍♂️', {
          position: 'bottom-center',
          duration: 6000,
        });
        setConfirmRemoveTopicModal(oldValue => !oldValue);
      }
    },
    [id, answersData, setAnswersData],
  );

  const onSubmit = handleSubmit(async data => {
    try {
      console.log(data);

      const response = await api.put(
        `/forums/topics/${topic_id}/answers/${id}`,
        {
          content: capitalizeFirstLetter(data.description),
        },
      );

      console.log(response.data);

      if (response.status === 200) {
        toast.success('Tópico editado com sucesso!', {
          position: 'bottom-right',
          duration: 6000,
          className: 'toast-samuquinha',
        });

        setAnswersData(
          answersData.map(answer => {
            if (answer.id === id) {
              return {
                ...answer,
                content: capitalizeFirstLetter(data.description),
              };
            }
            return answer;
          }),
        );

        setModalEditTopicIsOpen(oldValue => !oldValue);
        reset();
      }
    } catch (error) {
      console.log(error);
      toast.error('Ops! Algo deu errado.', {
        position: 'bottom-center',
        duration: 6000,
      });
    }
  });

  const scrollToBottom = useCallback(() => {
    const mainScroll = document.querySelector('#main-scroll');

    if (mainScroll) {
      mainScroll.scrollBy({
        left: 0,
        top: mainScroll.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  const handleVerifyAnswer = useCallback(
    async (answer_id: string) => {
      console.log(answer_id);
      try {
        const response = await api.patch(
          `/forums/topics/${topic_id}/answers/${answer_id}/verify`,
        );
        console.log(response.data);

        if (response.status === 200) {
          setAnswersData(
            answersData.map(answer => {
              if (answer.id === answer_id) {
                return {
                  ...answer,
                  is_verified: response.data.is_verified,
                  vote_balance: response.data.vote_balance,
                  upvotes: response.data.upvotes,
                  downvotes: response.data.downvotes,
                };
              }
              return answer;
            }),
          );

          toast.success('Resposta verificada com sucesso!', {
            position: 'bottom-right',
            duration: 6000,
            className: 'toast-samuquinha',
          });

          setConfirmVerifiedModal(oldValue => !oldValue);

          loadConfettiAllPage();
        }
      } catch (error: any) {
        console.log(error);

        if (
          error.response.data.error === 'topic-already-have-verified-answer'
        ) {
          toast.error('Este tópico já possui uma resposta verificada. 🤷‍♂️', {
            position: 'bottom-center',
            duration: 6000,
          });
        } else {
          toast.error('Ops! Algo deu errado.', {
            position: 'bottom-center',
            duration: 6000,
          });
        }

        setConfirmVerifiedModal(oldValue => !oldValue);
      }
    },
    [topic_id, answersData, setAnswersData],
  );

  const handleUserVote = useCallback(
    async (answer_id: string, voteType: IVoteType) => {
      try {
        const response = await api.post(
          `/forums/topics/${topic_id}/answers/${answer_id}/vote`,
          {
            type: voteType,
          },
        );

        const { upvotes, downvotes, user_vote } = response.data;

        setAnswersData(
          answersData.map(answer => {
            if (answer.id === id) {
              return {
                ...answer,
                upvotes: upvotes,
                downvotes: downvotes,
                user_vote: user_vote,
              };
            }
            return answer;
          }),
        );
      } catch (error) {
        console.log(error);
      }
    },
    [id, topic_id, answersData, setAnswersData],
  );

  return (
    <>
      <Container className={className}>
        <header>
          <aside>
            <p>
              <CalendarBlank size={20} weight="duotone" />{' '}
              {created_at &&
                format(
                  parseISO(created_at),
                  "EEEE, dd 'de' MMMM 'às' HH'h'mm",
                  { locale: ptBR },
                  )}
            </p>
          </aside>
          <main>
            <button onClick={scrollToBottom}>Responder</button>
            {!is_verified && (
              <WithAuthorization roles={['admin']}>
                <button
                  className="verificada"
                  onClick={() => setConfirmVerifiedModal(oldValue => !oldValue)}
                >
                  Marcar como verificada
                </button>
              </WithAuthorization>
            )}
            {author.id === user.id && !is_verified && (
              <button
                className="editar"
                onClick={() => setModalEditTopicIsOpen(oldValue => !oldValue)}
              >
                Editar
              </button>
            )}
            {author.id === user.id ? (
              <button
                className="excluir"
                onClick={() =>
                  setConfirmRemoveTopicModal(oldValue => !oldValue)
                }
              >
                Excluir
              </button>
            ) : (
              <WithAuthorization roles={['admin']}>
                <button
                  className="excluir"
                  onClick={() =>
                    setConfirmRemoveTopicModal(oldValue => !oldValue)
                  }
                >
                  Excluir
                </button>
              </WithAuthorization>
            )}
          </main>
        </header>
        <main>
          <aside>
            <AvatarProgress
              percent={author.current_exp}
              size={130}
              badgeValue={author?.level}
              url={author?.avatar_url}
              currentEvolutionUrl={author?.evolution?.icon_url}
            />
            <h3>{author?.show_name}</h3>
            {author?.career?.name && <p>{author?.career?.name}</p>}
            <BadgeUserRole role={author?.role} />
          </aside>
          <MainContent>
            <main>
              {is_verified && (
                <h2>
                  Resposta verificada{' '}
                  <img src={badgeVerified} alt="Resposta verificada" />
                </h2>
              )}
              <ReactMarkdown disallowedElements={['a', 'link', 'anchor']}>
                {content?.replace(/\\s/g, '\n')}
              </ReactMarkdown>
            </main>
            <footer>
              <ToggleLikeAndDeslike
                upvotes={upvotes}
                downvotes={downvotes}
                userVote={user_vote ?? null}
                onVote={voteType => handleUserVote(id, voteType)}
                disabled={author.id === user.id}
              />
            </footer>
          </MainContent>
        </main>
      </Container>

      {/* START MODAL CONFIRM */}
      <ModalConfirm
        title="Excluir Resposta?"
        confirmText="Confirmar"
        cancelText="Cancelar"
        text="Tem certeza de que deseja excluir sua resposta?"
        onConfirm={() => handleRemoveTopic(id)}
        isOpen={confirmRemoveTopicModal}
        isLoading={false}
        setIsOpen={() => setConfirmRemoveTopicModal(oldValue => !oldValue)}
        closeModal={() => setConfirmRemoveTopicModal(false)}
      ></ModalConfirm>
      {/* END MODAL CONFIRM */}

      {/* START MODAL CONFIRM ASWER VERIFIED */}
      <ModalConfirm
        title="Marcar como verificada?"
        confirmText="Confirmar"
        cancelText="Cancelar"
        text="Tem certeza de que marcar esta resposta como verificada?"
        onConfirm={() => handleVerifyAnswer(id)}
        isOpen={confirmVerifiedModal}
        isLoading={false}
        setIsOpen={() => setConfirmVerifiedModal(oldValue => !oldValue)}
        closeModal={() => setConfirmVerifiedModal(false)}
      ></ModalConfirm>
      {/* END MODAL CONFIRM */}

      {/* START MODAL EDIT TOPIC */}
      <Modal
        isOpen={modalEditTopicIsOpen}
        setIsOpen={() => setModalEditTopicIsOpen(oldValue => !oldValue)}
        closeModal={() => setModalEditTopicIsOpen(false)}
        size="lg"
        title="Editar tópico"
      >
        <ModalContainer>
          <form onSubmit={onSubmit}>
            <Line2>
              <WysiwygInnerModal
                maxLength={1500}
                placeholder="Formule melhor sua pergunta aqui..."
                setResult={values => {
                  setValue('description', values);
                }}
                defaultResult={content}
              />
              {errors.description && (
                <MessageErrorValidator>
                  {errors.description.message as string}
                </MessageErrorValidator>
              )}
            </Line2>
            <Line3>
              <AwesomeButtonAdapter type="primary" ripple={true}>
                Editar tópico{' '}
                <ChatCenteredDots size={32} color="#aa14cb" weight="duotone" />
              </AwesomeButtonAdapter>
            </Line3>
          </form>
        </ModalContainer>
      </Modal>
      {/* END MODAL EDIT TOPIC */}
    </>
  );
};

export default CardCommunityAnswer;
