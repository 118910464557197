import React, { ButtonHTMLAttributes } from 'react';

import { IconBaseProps } from 'react-icons';

import { Container, LoadingSpinner } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  color:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'info'
    | 'success'
    | 'error'
    | 'outline'
    | 'purple'
    | 'none';
  block?: boolean;
  loading?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  iconBefore?: boolean;
  iconStrokeWidth?: number;
  fontSize?: string;
  cursor?: 'default' | 'pointer' | 'not-allowed' | 'help';
};

const Button: React.FC<ButtonProps> = ({
  children,
  block = false,
  loading,
  color,
  cursor = 'pointer',
  icon: Icon,
  iconBefore,
  fontSize,
  iconStrokeWidth = 3.5,
  ...rest
}) => {
  return (
    <Container 
      type="button"
      color={color}
      disabled={loading}
      block={block}
      cursor={cursor}
      fontSize={fontSize}
      {...rest}
    >
      {loading ? 
        <LoadingSpinner><div><span /></div></LoadingSpinner> 
      : 
      <>
        {iconBefore && Icon && <Icon strokeWidth={iconStrokeWidth} />}
        {children}
        {!iconBefore && Icon && <Icon strokeWidth={iconStrokeWidth} />}
      </>
      }
    </Container>
  );
};

export default Button;