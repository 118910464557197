import React, { useCallback, useEffect, useRef, useState } from 'react';

import Countdown, { CountdownRenderProps } from 'react-countdown';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import ModalConfirm from '../ModalConfirmation';
import NotFoundRegister from '../NotFoundRegister';

import { Container, ModalContainer, TimeContainer, Timer } from './styles';

interface IFloatTimeCountDownProps {
  pratice_id: string;
  expires_at: string;
}

const FloatTimeCountDown: React.FC<IFloatTimeCountDownProps> = ({ expires_at, pratice_id }) => {
  const history = useHistory();
  const [startTime, setStartTime] = useState<number>(9999999999999);
  const [durationInSeconds, setDurationInSeconds] = useState<number>(0);

  // Modals
  const [finishConfirmModal, setFinishConfirmModal] = useState<boolean>(false);

  const countDownRef = useRef<any>(null);

  const renderer = useCallback(
    ({ hours, minutes, seconds, completed }: CountdownRenderProps) => {
      if (completed) {
        // Render a completed state
        return '';
      }

      // Render a countdown
      return (
        <Timer>
          {hours > 0 && <span>{`0${hours}`.slice(-2)}</span>}
          {hours > 0 && <span>:</span>}
          <span> {`0${minutes}`.slice(-2)}</span>
          <span>:</span>
          <span>{`0${seconds}`.slice(-2)}</span>
        </Timer>
      );
    }, []);

  useEffect(() => {
    const dateInMilliseconds = new Date(expires_at).getTime();
    setStartTime(dateInMilliseconds);
  }, [expires_at]);

  useEffect(() => {
    const timeInSeconds = (startTime - Date.now()) / 1000;
    setDurationInSeconds(timeInSeconds);
  }, [startTime]);


  const handleFinishForTimer = useCallback(() => {
    countDownRef.current?.pause();
    setFinishConfirmModal(true);
  }, []);

  const handleFinishConfirm = useCallback(async () => {
    try {
      const response = await api.post(`/practices/${pratice_id}/answers`, {
        answers: JSON.parse(localStorage.getItem(`@simulated-${pratice_id}`) as string) ?? [],
      });

      console.log(response.data);

    } catch (error) {
      console.log(error);
    }

    setFinishConfirmModal(false);
    history.push('/simulados');
  }, [history, pratice_id]);

  return (
    <>
      <Container>
        <TimeContainer>
          <p>Tempo restante</p>
          <Countdown
            ref={countDownRef}
            controlled={false}
            date={startTime}
            renderer={renderer}
            onComplete={handleFinishForTimer}
          />
        </TimeContainer>

        <CountdownCircleTimer
          isPlaying
          duration={durationInSeconds}
          //@ts-ignore
          colors={['#ffffff']}
          trailColor="#ffffff30"
          size={104}
          strokeWidth={7}
          strokeLinecap="round"
        >
        </CountdownCircleTimer>
      </Container>

      {/* START MODAL CONFIRM TIME FINISH */}
      <ModalConfirm
        confirmText="Entendi"
        confirmButtonColor="#2cc292"
        cancelText="Cancelar"
        onConfirm={handleFinishConfirm}
        showCancelButton={false}
        isOpen={finishConfirmModal}
        isLoading={false}
        setIsOpen={() => setFinishConfirmModal(!finishConfirmModal)}
        closeModal={() => setFinishConfirmModal(false)}
        allowOutsideClick={false}
        allowEscapeKey={false}
      >
        <ModalContainer>
          <NotFoundRegister description="Tempo esgotado!" />
          <p>Poxa, infelizmente o tempo para realizar o simulado acabou.</p>
        </ModalContainer>
      </ModalConfirm>
      {/* END MODAL CONFIRM TIME FINISH */}
    </>
  );
}

export default FloatTimeCountDown;