import { addYears } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';

import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Container } from './styles';

interface InputProps {
  name: string;
  label: string;
  register: any;
  ref?: HTMLInputElement,
  setResult(values: any): any;
}

const InputDatePicker: React.FC<InputProps> = ({ name, label, setResult }) => {

  const [finalValue, setfinalValue] = useState<any>('');

  
  useEffect(() => {
    setResult(finalValue);
  }, [finalValue, setResult]);

  return (
    <Container setResult={finalValue}>
      <ReactDatePicker
        selected={finalValue}
        onChange={(date: Date) => setfinalValue(date)}
        minDate={new Date()}
        maxDate={addYears(new Date(), 1)}
        showTimeSelect
        timeIntervals={30}
        dateFormat="dd/MM/yyyy HH:mm"
        locale={ptBR}
      />
      <label htmlFor={name}>{label}</label>
    </Container>
  );
};

export default InputDatePicker;