import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '../../../hooks/theme';

import { TableColumn } from 'react-data-table-component';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DataTableAdapter from '../../../components/DataTableAdapter';
import DropdownButton from '../../../components/DropdownButton';
import Button from '../../../components/Button';

import { FiHome, FiSettings } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { CardTableContainer, Container, HeaderSection, Poster } from './styles';

import api from '../../../services/api';

import { IMaterialProps } from '../../../interfaces/Material';
import LoaderSpinner from '../../../components/LoaderSpinner';

import { API_S3 } from '../../../services/api_s3';
import { IGenericOptions } from '../../../interfaces/IGenericOptions';

import CreateMaterial from './CreateOrEdit';
import toast from 'react-hot-toast';

const Materiais: React.FC = () => {
  const { theme } = useTheme();

  // Request 
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [categoryId, setCategoryId] = useState('');

  // Data
  const [materialsData, setMaterialsData] = useState<IMaterialProps[]>([]);
  const [categoriesOptions, setCategoriesOptions] = useState<IGenericOptions[]>([]);

  // States
  const [showCreateOrEditMaterial, setShowCreateOrEditMaterial] = useState(false);
  const [materialToEdit, setMaterialToEdit] = useState<IMaterialProps | undefined>({} as IMaterialProps);

  // Loaders
  const [loadingMaterials, setLoadingMaterials] = useState(false);

  const columns: TableColumn<IMaterialProps>[] = [
    {
      name: 'Nome',
      selector: row => row.name,
      width: '30%',
      cell: (row: IMaterialProps) => (
        <>
          <Poster src={row.thumbnail_url ? row.thumbnail_url : theme === 'light' ? `${API_S3}/default/image-placeholder-light.svg` : `${API_S3}/default/image-placeholder-dark.svg`} />
          <a href={row.material_url} target="_blank" rel="noreferrer">{row.name}</a>
        </>
      ),
    },
    {
      name: 'Descrição',
      selector: row => row.description,
      wrap: true,
      cell: (row: IMaterialProps) => (
        <span style={{ padding: '1rem 0', }}>{row.description}</span>
      ),
    },
    {
      name: 'Categoria',
      selector: row => row.category.name,
      width: '15%',
      cell: (row: IMaterialProps) => (
        <span>{row.category.name}</span>
      ),
    },
    {
      name: 'Data Cadastro',
      selector: row => row.created_at,
      cell: (row: IMaterialProps) => (
        new Date(row.created_at).toLocaleString('pt-BR')
      ),
    },
    {
      name: (<FiSettings size={16} strokeWidth={2.5} />),
      sortable: false,
      button: true,
      right: true,
      cell: (row: IMaterialProps, index: number) => (
        <DropdownButton key={index}>
          <ul>
            <li>
              <Button color="primary" type="button" onClick={() => handleEditMaterial(row)}>
                <MdKeyboardArrowRight size={18} />
                Editar material
              </Button>
            </li>
            <li>
              <Button color="primary" type="button" onClick={() => handleDeleteMaterial(row.id)}>
                <MdKeyboardArrowRight size={18} />
                Excluir material
              </Button>
            </li>
          </ul>
        </DropdownButton>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoadingMaterials(true);

        const [response1, response2] = await Promise.all([
          api.get(`/materials`, {
            params: {
              pageIndex: page,
              pageSize: perPage,
              search: filterText,
              category_id: categoryId,
            }
          }),
          api.get(`/materials/categories/options`),
        ]);

        setMaterialsData(response1.data.records);
        setTotalRows(response1.data.totalRecords);
        console.log(response1.data);

        setCategoriesOptions(response2.data);

      } catch (error) {
        console.log(error);
        setLoadingMaterials(false);
      }
      finally {
        setLoadingMaterials(false);
      }
    })();
  }, [page, perPage, filterText, categoryId]);

  const handleDeleteMaterial = useCallback(async (id: string) => {
    try {

      const confirm = window.confirm('Deseja excluir este material?');

      if (confirm) {
        await api.delete(`/materials/${id}`);

        toast.error('Material excluído com sucesso!', {
          duration: 5000,
          position: 'bottom-right',
          className: 'toast-samuqinha',
        });
        setMaterialsData(materialsData.filter(material => material.id !== id));
      }
    } catch (error) {
      console.log(error);
      toast.error('Ops! Algo deu errado tentar excluir material!', {
        duration: 5000,
        position: 'bottom-right',
        className: 'toast-samuqinha',
      });
    }
  }, [materialsData]);

  const handleEditMaterial = useCallback((material: IMaterialProps) => {
    setShowCreateOrEditMaterial(true);
    setMaterialToEdit(material);
  }, []);

  return (
    <>
      {!showCreateOrEditMaterial ? (
        <Container>

          {loadingMaterials && (<LoaderSpinner />)}

          <HeaderSection>
            <Breadcrumbs
              icon={FiHome}
              separator="/"
              route={[{ title: 'Materiais didáticos', link: '/materiais' }]}
            />
            <aside>
              <section>
                <span>Filtre por categoria: </span>
                {categoriesOptions.length > 0 && (
                  <select
                    name="category_id"
                    id="category_id"
                    value={categoryId}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value="">Selecione...</option>
                    {categoriesOptions.map((option, index) => (
                      <option key={index} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                )}
              </section>
              <Button
                onClick={() => {setShowCreateOrEditMaterial(true); setMaterialToEdit(undefined);}}
                color="primary"
                iconBefore
              >
                Cadastrar novo material
              </Button>
            </aside>
          </HeaderSection>

          <CardTableContainer>
            <DataTableAdapter
              title="Lista de Materiais didáticos"
              subTitle={''}
              columns={columns}
              data={materialsData}
              paginationServer={true}
              perPage={perPage}
              totalRows={totalRows}
              setFilterText={setFilterText}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          </CardTableContainer>
        </Container>
      )
        : (
          <CreateMaterial setShowCreateOrEditMaterial={setShowCreateOrEditMaterial} setMaterialsData={setMaterialsData} materialToEdit={materialToEdit} />
        )}
    </>
  );
}

export default Materiais;