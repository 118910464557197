import React, { useCallback, useEffect, useState } from 'react';

import api from '../../../services/api';

import Breadcrumbs from '../../../components/Breadcrumbs';
import InputDebounceAdapter from '../../../components/InputDebounceAdapter';

import {
  Container,
  HeaderSection,
  SectionContainer1,
  SearchContainer,
  PaginationContainer,
} from './styles';

import Pagination from '../../../components/Pagination';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import { Books, House, MagnifyingGlass } from 'phosphor-react';
import LoaderSpinner from '../../../components/LoaderSpinner';
import NotFoundRegister from '../../../components/NotFoundRegister';
import CardBookThumbnail from '../../../components/CardBookThumbnail';

export interface IBooksProps {
  id: string;
  title: string;
  author_name: string;
  number_of_pages: number;
  publishing_year: string;
  summary: string;
  vote_average: number;
  vote_count: number;
  cover: string;
  cover_url: string;
  file: string;
  material_url: string;
  created_at: string;
  updated_at: string;
  user_vote?: string;
  genres: string[];
}

export interface IDataBooksProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: IBooksProps[];
}

const SasaBook: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [books, setBooks] = useState<IDataBooksProps>({} as IDataBooksProps);

  // pagination
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get('/books', {
          params: {
            pageIndex: currentPage - 1,
            pageSize: 18,
            search: search,
          },
        });

        console.log(response.data);
        setBooks(response.data);

        //pagination
        setShowingTo(response.data.showingTo);
        setShowingFrom(response.data.showingFrom);
        setMaxPages(response.data.maxPages);
        setTotalRecords(response.data.totalRecords);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentPage, search]);

  const handleFilterModule = useCallback((value: string) => {
    setSearch(value);
    setCurrentPage(1);
  }, []);

  return (
    <Container>
      <HeaderSectionContainer padding="1rem 2rem">
        <h1>
          <Books weight="duotone" /> SasaBooks
        </h1>
        <p>
          Sugestões de livros relacionados ao mundo da biologia e vestibulares.
        </p>
      </HeaderSectionContainer>

      <HeaderSection>
        <Breadcrumbs
          icon={House}
          separator="/"
          route={[{ title: 'SasaBooks', link: '/Sasabooks' }]}
        />
        <SearchContainer>
          <InputDebounceAdapter
            type="text"
            debounceTimeout={300}
            minLength={3}
            onChange={event => handleFilterModule(event.target.value)}
            placeholder="Busque por livros..."
          />
          <MagnifyingGlass weight="duotone" />
        </SearchContainer>
      </HeaderSection>

      <SectionContainer1
        style={loading ? { height: '250px', overflow: 'hidden' } : {}}
      >
        {loading && <LoaderSpinner blur />}

        {books.records?.map(book => (
          <CardBookThumbnail
            key={book.id}
            id={book.id}
            name={book.title}
            cover_url={book.cover_url}
            duration={Number(book.publishing_year)}
            rating={book.vote_average}
            year={book.publishing_year}
            vote_average={book.vote_average}
            height="350px"
          />
        ))}
      </SectionContainer1>

      {books.records?.length === 0 && (
        <NotFoundRegister
          emoji="😢"
          description={
            search.length > 0
              ? `Nenhum registro encontrado para a busca "${search}".`
              : `Nenhum registro encontrado.`
          }
        />
      )}

      {!loading && (
        <PaginationContainer>
          <p>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} livros`}</p>
          {totalRecords !== 0 && (
            <Pagination
              count={maxPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </PaginationContainer>
      )}
    </Container>
  );
};

export default SasaBook;
