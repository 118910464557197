import React, { useCallback, useEffect, useState } from 'react';

import { useTheme } from '../../../hooks/theme';

import api from '../../../services/api';

//@ts-ignore
import ProgressBar from 'react-animated-progress-bar';

import { GraduationCap, House, MagnifyingGlass } from 'phosphor-react';

import LoaderBIO from '../../../components/Loader';
import Breadcrumbs from '../../../components/Breadcrumbs';
import InputDebounceAdapter from '../../../components/InputDebounceAdapter';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import NotFoundRegister from '../../../components/NotFoundRegister';

import {
  Container,
  HeaderSection,
  SectionContainer1,
  SearchContainer,
  CardExtraCourse,
} from './styles';

import { IClusterProps } from '../../../interfaces/Cluster';

export interface IPaginationProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: IClusterProps[];
}

const ModulosCursosExtras: React.FC = () => {
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);

  const scrollRef = document.querySelector('#main-scroll');

  const [clustersData, setClustersData] = useState<IPaginationProps>(
    {} as IPaginationProps,
  );
  const [search, setSearch] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/clusters`, {
          params: {
            pageIndex: 0,
            pageSize: 99,
            search: search,
            type: 'extra',
          },
        });

        console.log(response.data);
        setClustersData(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [search]);

  const handleFilterModule = useCallback((value: string) => {
    console.log(value);
    setSearch(value);
  }, []);

  return (
    <Container>
      {loading && <LoaderBIO isVisible />}
      <HeaderSectionContainer padding="1rem 2rem">
        <h1>
          <GraduationCap weight="duotone" /> Cursos extras
        </h1>
        <p>Aulas focadas em Cursos extras.</p>
      </HeaderSectionContainer>

      <HeaderSection>
        <Breadcrumbs
          icon={House}
          separator="/"
          route={[{ title: 'Cursos extras', link: '/cursos-extras' }]}
        />
        <SearchContainer>
          <InputDebounceAdapter
            type="text"
            debounceTimeout={300}
            minLength={3}
            onChange={event => handleFilterModule(event.target.value)}
            placeholder="Busque por vestibular..."
          />
          <MagnifyingGlass weight="duotone" />
        </SearchContainer>
      </HeaderSection>

      <SectionContainer1>
        {clustersData.records?.map(cluster => (
          <>
            <CardExtraCourse key={cluster.id} to={`aulas/${cluster.slug}/!`}>
              <div>
                <img
                  src={cluster.thumbnail_url}
                  alt={cluster.title}
                  key={cluster.id}
                  className={
                    theme === 'dark' ? 'svg-light-mode' : 'svg-dark-mode'
                  }
                />
                {/*<img src='https://samuel-cunha-api.s3.sa-east-1.amazonaws.com/clusters/revisao-biolandia.png' alt={cluster.title} key={cluster.id} className={theme === 'dark' ? 'svg-light-mode' : 'svg-dark-mode'} />*/}
              </div>
              <footer>
                <div>
                  <h5>{cluster.title}</h5>
                  <p>{cluster.description}</p>
                </div>
              </footer>
            </CardExtraCourse>
          </>
        ))}
      </SectionContainer1>

      {clustersData.records?.length === 0 && <NotFoundRegister />}
    </Container>
  );
};

export default ModulosCursosExtras;
