import React from 'react';

import { Container, ToggleLabel, ToggleSelector } from './styles';

export interface IToggleProps {
  labelLeft: string;
  labelRight: string;
  onColor: string;
  offColor: string;
  checked: boolean;
  onChange(): void;
}

const Toggle: React.FC<IToggleProps> = ({
  labelLeft, labelRight, checked, onChange, onColor, offColor,
 }) => {
  return (
    <Container>
      <ToggleLabel>{labelLeft}</ToggleLabel>
      <ToggleSelector
        checked={checked}
        uncheckedIcon={false}
        checkedIcon={false}
        onChange={onChange}
        onColor={onColor}
        offColor={offColor}
        boxShadow="none"
        activeBoxShadow="none"
      />
      <ToggleLabel>{labelRight}</ToggleLabel>
    </Container>
  )
}

export default Toggle;
