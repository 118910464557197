import React from 'react';

import { Container } from './styles';

interface LoaderSpinnerProps {
  isFixed?: boolean;
  borderRadius?: string;
  zIndex?: number;
  backgroundTransparent?: boolean;
  blur?: boolean;
  background?: string;
  spinnerColor?: string;
  message?: string;
  style?: React.CSSProperties;
}

const LoaderSpinner: React.FC<LoaderSpinnerProps> = ({ style, isFixed = false, borderRadius, zIndex, backgroundTransparent, background, spinnerColor, blur, message }) => {
  return (
    <Container style={style} isFixed={isFixed} borderRadius={borderRadius} zIndex={zIndex} backgroundTransparent={backgroundTransparent} background={background} blur={blur} spinnerColor={spinnerColor}>
      <main>
        <div>
          <span />
        </div>
        {message && <p>{message}</p>}
      </main>
    </Container>
  );
};

export default LoaderSpinner;
