import React, { useEffect, useState } from 'react';

interface IPandaPlayerProviderProps {
  video: string;
  style?: React.CSSProperties;
  onEnded?: (ref: any | null) => void;
}

const PandaPlayerProvider: React.FC<IPandaPlayerProviderProps> = ({
  video,
  style,
  onEnded,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://player.pandavideo.com.br/api.v2.js';
    script.async = true;

    document.body.appendChild(script);

    console.log('script', script);

    return () => {
      document.body.removeChild(script);
    };

  }, []);

  useEffect(() => {
    if (isLoaded) return;

    // @ts-ignore
    window.pandascripttag = window.pandascripttag || [];
    // @ts-ignore
    window.pandascripttag.push(function () {
      // @ts-ignore
      const player = new PandaPlayer(`panda-${video}`, {
        onReady: () => {
          console.log('PLAYER LOADED')
          setIsLoaded(() => true);

          player.onEvent((event: any) => {
            //console.log('EVENT', event);

            if (event.message === 'panda_ended') {
              console.log('ENDED');
              onEnded && onEnded(player);
            }
          });	
        },
      });
    });
  }, [video, onEnded, isLoaded]);

  return (
    <div
      style={{ minHeight: 'auto', position: 'relative', paddingTop: '56.25%', zIndex: 9, }}>
      <iframe
        title="video"
        id={`panda-${video}`}
        src={`https://player-vz-cb1dc143-4cf.tv.pandavideo.com.br/embed/?v=${video}`}
        style={style ? style : { border: 'none', position: 'absolute', top: 0, left: 0, }}
        allowFullScreen={true}
        width="100%"
        height="100%"
      />

    </div>
  );
}

export default PandaPlayerProvider;