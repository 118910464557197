import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import api from '../../../services/api';

import { Container, ContentGrid1, ContentGrid2, SearchButton, NotFound } from './styles';
import InputAnimateDropdown from '../../../components/InputAnimateDropdown';
import LoaderBIO from '../../../components/Loader';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import { House, MagnifyingGlass, Question } from 'phosphor-react';
import Breadcrumbs from '../../../components/Breadcrumbs';

interface ISuportArticlesProps {
  id: string;
  section_id: string;
  title: string;
  body: string;
  slug: string;
  is_promoted: boolean;
  vote_count: number;
  vote_sum: number;
  created_at: string;
  updated_at: string;
  abstract: string;
  reading_time: string;
}

interface ICategoriesProps {
  id: string;
  name: string;
  description: string;
  slug: string;
  icon: string;
  position: number;
  created_at: string;
  updated_at: string;
}

const SuporteTecnicoTopicos: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const [loading, setLoading] = useState(true);

  const [suporteArticles, setSuportArticles] = useState<ISuportArticlesProps[]>([]);
  const [suporteCategories, setSuportCategories] = useState<ICategoriesProps>({} as ICategoriesProps);

  // List ARTICLES
  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const [sectionResponse, articlesResponse] = await Promise.all([
          api.get(`/sections/${slug}`),
          api.get(`/sections/${slug}/articles`),
        ]);

        console.log('LIST CATEGORY >>>>>>>>>>>>>>>>>>>');
        console.log(sectionResponse.data);
        setSuportCategories(sectionResponse.data);

        console.log('LIST SUPORT ARTICLES >>>>>>>>>>>>>>>>>>>');
        console.log(articlesResponse.data);
        setSuportArticles(articlesResponse.data);

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [slug]);

  return(
    <Container>
        <HeaderSectionContainer padding="1rem 2rem">
        <h1><Question weight="duotone" /> Central de ajuda</h1>
        <p>Preparamos uma dezena de artigos para solucionar qualquer probleminha da plataforma!</p>
      </HeaderSectionContainer>

      <Breadcrumbs
          icon={House}
          separator="/"
          padding="1rem 0"
          route={[
            { title: 'Central de ajuda', link: '/suporte' },
            { title: suporteCategories.name, link: `/suporte/artigos/${slug}` }
          ]}
        />

        <ContentGrid1>
          <div>
            <form onSubmit={e => e.preventDefault()}>
              <InputAnimateDropdown
                label="Qual é a sua dúvida?"
                type="text"
                name="duvida"
                margin="0"
                required
              />
              <SearchButton>
                <MagnifyingGlass weight="duotone" />
              </SearchButton>
            </form>
          </div>
        </ContentGrid1>
        
        <ContentGrid2>
          {suporteArticles.length ?
            suporteArticles.map((article, index) => (
              <Link to={`/suporte/artigos/${slug}/${article.slug}`} key={index}>
                <h2>{index + 1}.</h2>
                <h1>{article.title}</h1>
                <p>{article.abstract}</p>
                <Link to={`/suporte/artigos/${slug}/${article.slug}`} > <MagnifyingGlass weight="duotone" /> Visualizar artigo</Link>
              </Link>
            ))
            :
            !loading && <NotFound>Poxa, não encontramos nenhum artigo para a categoria selecionada. 😢</NotFound>
          }

          <LoaderBIO isVisible={loading} />
        </ContentGrid2>
    </Container>
  );
}

export default SuporteTecnicoTopicos;
