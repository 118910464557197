import React from 'react';

import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { parseISO } from 'date-fns/esm';

import { FiPlay } from 'react-icons/fi';

import { Container } from './styles';

interface VideoTumbnailProps {
  videoTumbnailUrl?: string;
  videoTitle: string;
  dateView?: string;
  percentView?: number;
  heightPropriety?: string;
  minHeight?: string;
  borderRadius?: string;
  showOnlyThumb?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const VideoTumbnail: React.FC<VideoTumbnailProps> = ({ videoTumbnailUrl, style, videoTitle, dateView, percentView, heightPropriety, borderRadius, minHeight, showOnlyThumb = false, onClick }) => {

  return (
    <Container
      style={style}
      onClick={onClick}
      videoTumbnailUrl={videoTumbnailUrl}
      heightPropriety={heightPropriety}
      borderRadius={borderRadius}
      minHeight={minHeight}
      showOnlyThumb={showOnlyThumb}>
      <header>
        {percentView ? (<span>Continue assistindo de onde parou</span>) : ''}
      </header>
      <div className="btn">
        <FiPlay strokeWidth={2} size={50} />
      </div>
      <footer>
        <p>{videoTitle}</p>
        {dateView && <p>Você visualizou este vídeo há {formatDistance(parseISO(dateView), new Date(), { locale: ptBR })}</p>}
      </footer>
    </Container>
  );
}

export default VideoTumbnail;
