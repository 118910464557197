import styled from 'styled-components';

import banner from '../../assets/banner.png';

interface IHeaderSectionContainerProps {
  padding?: string;
  margin?: string;
}

export const Container = styled.div<IHeaderSectionContainerProps>`
  background: url(${banner}), linear-gradient(90deg, #8f7ff9 40%, #a68fff 50%);
  background-position: center right;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
  padding: ${props => props.padding || '2rem 2rem 1rem 1rem'};
  margin: ${props => props.margin || '1rem 0'};
  height: 160px;
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};
  justify-content: center;
  align-items: flex-start;

  > h1 {
    font-size: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: .5rem;

    @media (max-width: 425px) {
      font-size: 30px;
    }

    > svg {
      font-size: 40px;
      min-height: 40px;
      min-width: 40px;
    }
  }

  > p {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    color: #79ffb8;
  }

  > main {
    display: flex;
    flex-direction: row;
    align-items: center;

    > img {
      width: 200px;
      margin-top: -50px;
      margin-right: 1rem;
    }

    > aside {

      > h1 {
        font-size: 40px;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
  
      > p {
        font-size: 12px;
        font-weight: 300;
        color: #79ffb8;
        width: 50%;
      }
    }
  }
  
  > aside {
    width: 100%;
    
    > h1 {
      font-size: 40px;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  
    > p {
      font-size: 12px;
      font-weight: 300;
      color: #79ffb8;
      width: 50%;
    }
  }
`;
