import React from 'react';
import { Role } from '../../interfaces/Role';

import { Container } from './styles';

interface IBadgeUserRolePros {
  role: Role | 'author';
}

const BadgeUserRole: React.FC<IBadgeUserRolePros> = ({ role }) => {
  const roles = {
    student: 'student',
    admin: 'moderation',
    moderator: 'moderation',
    author: 'author',
    newbie: 'student',
  };

  const titles = {
    student: 'Aluno',
    admin: 'Professor',
    moderator: 'Moderador',
    author: 'Autor',
    newbie: 'Aluno',
  };

  return <Container role={roles[role] as any}>{titles[role] as any}</Container>;
};

export default BadgeUserRole;
