import styled from 'styled-components';

export const Container = styled.div`

  > button {
    border: none;
    cursor: pointer;
    color: ${props => props.theme.colors.white};
    padding: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${props => props.theme.radius.secondary};
    border: 1px solid transparent;
    background: none;

    &:hover, &.open {
      background: #ffffff20;
      border: 1px solid ${props => props.theme.specific.menuButtonTriggerBorder};
      transition: all .2s ease-in-out;
    }

    > svg {
      fill: #ffffff20;
      stroke: ${props => props.theme.colors.secondary};
    }
  } 
`;
