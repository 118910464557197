import React, { useCallback, useMemo } from 'react';

import DataTable, { PaginationOptions, TableColumn, TableRow, TableProps } from 'react-data-table-component';
import { FiDownload } from 'react-icons/fi';

import InputDebounceAdapter from '../InputDebounceAdapter';
import LoaderSpinner from '../LoaderSpinner';
import TooltipAdapter from '../TooltipAdapter';

import { Container, SubHeaderComponentMemo, ExportButton } from './styles';

interface IDataTableProps extends TableProps<[]> {
  title?: string;
  subTitle?: string;
  columns: TableColumn<any>[];
  data: any[];
  perPage?: number;
  totalRows?: number;
  paginationServer: boolean;
  setPage?(currentPage: number): void;
  setPerPage?(currentRowsPerPage: number): void;
  setFilterText?(text: string): void;
  setSelectedRows?(values: any): void;
}

const DataTableAdapter: React.FC<IDataTableProps> = ({ title, subTitle, columns, data, setSelectedRows, setFilterText, paginationServer, setPage, perPage, setPerPage, totalRows, ...rest }) => {

  const paginationComponentOptions: PaginationOptions = {
    rowsPerPageText: 'Registros por página:',
    rangeSeparatorText: 'de',
    selectAllRowsItem: false,
    selectAllRowsItemText: 'Todos',
  };

  const handleChange = ({ selectedRows }: TableRow) => {
    console.log('Selected Rows: ', selectedRows);
    setSelectedRows && setSelectedRows(selectedRows);
  };

  const convertArrayOfObjectsToCSV = useCallback((array: any) => {
    let result: any;

    const columnDelimiter = ';';
    const lineDelimiter = '\n';
    const keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item: any) => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }, [data]);

  const downloadCSV = useCallback((array: any) => {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();

  }, [convertArrayOfObjectsToCSV]);

  const FilterComponent = useMemo(() => {
    return (
      <SubHeaderComponentMemo>
        <aside>
          <h2>{title}</h2>
          <p>{subTitle}</p>
        </aside>

        {setFilterText && (
          <main>
            <TooltipAdapter
              alt="Exportar CSV"
              place="top"
              cursor="pointer"
            >
              <ExportButton onClick={() => downloadCSV(data)}><FiDownload strokeWidth={3} /></ExportButton>
            </TooltipAdapter>

            <InputDebounceAdapter
              type="text"
              debounceTimeout={500}
              minLength={3}
              onChange={(event) => setFilterText && setFilterText(event.target.value)}
              placeholder="Pesquisar..."
            />
          </main>
        )}
      </SubHeaderComponentMemo>
    )
  }, [title, subTitle, data, downloadCSV, setFilterText]);

  return (
    <Container>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
        paginationPerPage={perPage}
        onSelectedRowsChange={handleChange}
        fixedHeader
        fixedHeaderScrollHeight="550px"
        progressComponent={<LoaderSpinner backgroundTransparent={false} blur={true} style={{ zIndex: 99, position: 'relative', minHeight: '300px' }} />}
        subHeader
        subHeaderComponent={FilterComponent}
        paginationServer={paginationServer}
        onChangePage={(currentPage: number) => setPage && setPage(currentPage - 1)}
        onChangeRowsPerPage={(currentRowsPerPage: number) => setPerPage && setPerPage(currentRowsPerPage)}
        paginationTotalRows={totalRows}
        noDataComponent={<div style={{ padding: '2rem' }}>Nenhum registro encontrado. 😢</div>}
        {...rest}
      />
    </Container>
  );
}

export default DataTableAdapter;