import styled from 'styled-components';

interface VideoTumbnailProps {
  videoTumbnailUrl?: string;
  heightPropriety?: string;
  borderRadius?: string;
  minHeight?: string;
  showOnlyThumb?: boolean;
}

export const Container = styled.div<VideoTumbnailProps>`
  position: relative;
  background: url(${props => props.videoTumbnailUrl}) center center / cover no-repeat;
  border-radius: ${props => props.borderRadius ? props.borderRadius : '12px'};
  overflow: hidden;
  min-height: ${props => props.minHeight ? props.minHeight : '400px'};
  height: ${props => props.heightPropriety ? props.heightPropriety : '100%'};
  //min-width: 550px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: ${props => props.theme.colors.shadowBox};
  transition: all .5s ease-in-out;
  padding: 1.5rem 1.5rem;
  cursor: pointer;

  /* TABLET */
  @media (max-width: 768px) {
    min-height: ${props => props.minHeight ? '393px' : '247px'};
    //min-height: 393px !important;
  }
  /* MOBILE */
  @media (max-width: 480px) {
    min-height: ${props => props.minHeight ? '247px' : '247px'};
    //min-height: 247px !important;
  }
  
  svg {
     margin-left: 8px;
    }
    
    &:hover .btn {
      background: ${props => props.theme.colors.primary};
      border: 4px solid #ffffff;
      color: #ffffff;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, transparent, #021614);
    opacity: .4;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '12px'};
    transition: all .3s ease-in-out;
  }
  
  &:hover::before {
    opacity: 1;
    border-radius: ${props => props.borderRadius ? props.borderRadius : '12px'};
  }
  
  > header {
    display: ${props => !props.showOnlyThumb ? 'flex' : 'none'};
    width: 100%;
    
    @media (max-width: 480px) {
      top: 1rem;
    }
    
    > span {
      background: #046b5f45;
      backdrop-filter: blur(5px) saturate(150%);
      border: 1px solid ${props => props.theme.cards.border};
      color: #fff;
      padding: 0.25rem 0.75rem;
      font-size: 0.75rem;
      font-weight: 700;
      border-radius: 5px;
      text-transform: uppercase;
      
      /* MOBILE */
      @media (max-width: 480px) {
        font-size: .7rem;
      }
    }
  }

  > div {
    width: 100px;
    height: 100px;
    border: 4px solid #ffffff77;
    color: #ffffff77;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: all .3s ease-in-out;
    
    &:hover {
      background: #296d62;
      border: 4px solid #ffffff;
      color: #ffffff;
    }

    /* TABLET */
    @media (max-width: 768px) {
      transform: scale(.8);
    }
    /* MOBILE */
    @media (max-width: 480px) {
      transform: scale(.4);
    }
  }

  
  > footer {
    display: flex;
    width: 100%;
    justify-content: left;
    text-align: left;
    flex-direction: column;
    margin-bottom: 0.5rem;
    line-height: 1;
    display: ${props => !props.showOnlyThumb ? 'block' : 'none'};
    z-index: 0;
    
    @media (max-width: 480px) {
      bottom: 20px;
    }
    
    > p:nth-child(1) {
      font-size: 1.8rem;
      font-weight: 700;
      margin-left: 0.5rem;

      color: #fff;
      opacity: 1;
      visibility: visible;
      transition: transform .3s ease-in-out,visibility .3s ease-in-out,opacity .3s ease-in-out;
      
      /* TABLET */
      @media (max-width: 768px) {
        font-size: 1.3rem;
      }
      /* MOBILE */
      @media (max-width: 480px) {
        font-size: 1rem;
      }
    }

    > p:nth-child(2) {
      font-size: 0.75rem;
      margin-top: .4rem;
      font-weight: 600;
      color: #ededed;
      margin-left: 0.5rem;
      
      /* TABLET */
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
      /* MOBILE */
      @media (max-width: 480px) {
        font-size: 0.6rem;
        font-weight: 600;
      }
    }

    
    > div {
      width: 100%;
      bottom: -40px;
      left: 0;
      margin-top: 15px;
      justify-content: center;

      /* MOBILE */
      @media (max-width: 480px) {
        margin-top: .5rem;
      }
    }
  }
`;
