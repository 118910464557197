import React, { useEffect, useState } from 'react';

import api from '../../../../services/api';

import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useForm } from 'react-hook-form';
import { ArrowLeft, Book, Image, Trash } from 'phosphor-react';
import toast from 'react-hot-toast';

import LoaderBIO from '../../../../components/Loader';
import Input from '../../../../components/Form/Input';
import SimpleMultiSelect from '../../../../components/Form/SimpleMultiSelect';
import MessageErrorValidator from '../../../../components/MessageErrorValidator';

import { BtnRemove, Container, HeaderSection, Line1, Line2, Line4, LineButton, Poster, SectionContainer1, Separator } from './styles';
import { IGenericOptions } from '../../../../interfaces/IGenericOptions';
import Button from '../../../../components/Button';
import Textarea from '../../../../components/Form/Textarea';
import DropZone from '../../../../components/Form/DropZone';

import { loadConfettiAllPage } from '../../../../utils/useConfetti';
import { IMaterialProps } from '../../../../interfaces/Material';
import { FakeDropZone } from '../../AulaEdit/styles';

interface IProps {
  setShowCreateOrEditMaterial(value: boolean): void;
  setMaterialsData(values: any): void;
  materialToEdit?: IMaterialProps;
}

const CreateOrEdit: React.FC<IProps> = ({ setShowCreateOrEditMaterial, setMaterialsData, materialToEdit }) => {

  // Validation
  const schema = Joi.object({
    name: Joi.string().min(10).max(100).required().messages({
      '*': 'Informe um título válido.',
      'string.min': `O campo título deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo título deve ter no máximo {#limit} caracteres`,
    }),
    description: Joi.string().min(20).max(350).messages({
      '*': 'Informe uma descrição válida.',
      'string.min': `O campo descrição deve ter no mínimo {#limit} caracteres`,
      'string.max': `O campo descrição deve ter no máximo {#limit} caracteres`,
    }),
    category_name: Joi.string().required().messages({ '*': 'Informe uma categoria válida.' }),
  });

  const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log('validation result', await joiResolver(schema)(data, context, options));

      delete data.thumbnail;

      return joiResolver(schema)(data, context, options);
    },
  });

  // States
  const [materialFileData, setMaterialFileData] = useState<File[]>([]);
  const [thumbnalFileData, setThumbnalFileData] = useState<File[]>([]);

  // Data
  const [categoriesData, setCategoriesData] = useState<IGenericOptions[]>([]);

  // Loaders
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/materials/categories/options`);

        const categories = response.data;

        // create a new array of options and values
        const options: IGenericOptions[] = categories.map((option: { value: string, label: string }) => {
          return {
            value: option.label,
            label: option.label,
          }
        });

        setCategoriesData(options);
      }
      catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (materialToEdit && materialToEdit.id) {
      reset({
        name: materialToEdit.name,
        description: materialToEdit.description,
        category_name: materialToEdit.category.name,
      });
    }
  }, [materialToEdit, reset]);


  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoadingSubmit(true);

      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('category_name', data.category_name);

      if (materialFileData.length === 0 && !materialToEdit) {
        toast.error('É necessário inserir um arquivo para o material.', {
          position: 'bottom-right',
          duration: 5000,
          className: 'toast-samuquinha',
        });

        return;
      } 

      formData.delete('file');

      if (materialFileData.length > 0) {
        formData.append('file', materialFileData[0]);
      }

      formData.delete('thumbnail');

      if (thumbnalFileData.length > 0) {
        formData.append('thumbnail', (thumbnalFileData[0]));
      }

      if (materialToEdit && materialToEdit.id) {
        const response = await api.put(`/materials/${materialToEdit.id}`, formData);
        console.log('response', response.data);

        if (response.status === 200) {
          toast.success('Material editado com sucesso!', {
            position: 'bottom-right',
            duration: 5000,
            className: 'toast-samuquinha',
          });
  
          setShowCreateOrEditMaterial(false);
          setMaterialsData((oldValue: any) => [response.data, ...oldValue]);
  
          loadConfettiAllPage();
        }

        return;
      }

      const response = await api.post(`/materials`, formData);
      console.log('response', response.data);

      if (response.status === 200) {
        toast.success('Material cadastrado com sucesso!', {
          position: 'bottom-right',
          duration: 5000,
          className: 'toast-samuquinha',
        });

        setShowCreateOrEditMaterial(false);
        setMaterialsData((oldValue: any) => [response.data, ...oldValue]);

        loadConfettiAllPage();
      }

    } catch (error: any) {
      console.log(error);
    }
    finally {
      setLoadingSubmit(false);
    }
  });

  return (
    <>
      <LoaderBIO isVisible={loading} />

      <Container>
        <HeaderSection>
          <div onClick={() => { setShowCreateOrEditMaterial(false); }}>
            <ArrowLeft weight="duotone" /> Voltar para a listagem de materiais
          </div>
        </HeaderSection>

        <SectionContainer1>
          <form onSubmit={onSubmit}>
            <Separator>
              <Book size={20} weight="duotone" />
              <h2>Informe os dados do Material abaixo:</h2>
            </Separator>
            <Line1>
              <div>
                <Controller
                  name="category_name"
                  control={control}
                  render={({ field }) => {

                    return (
                      <SimpleMultiSelect
                        register={register}
                        name={field.name}
                        label="Provedor do vídeo"
                        placeHolder="Selecione..."
                        options={categoriesData}
                        setResult={(values) => { setValue('category_name', values) }}
                        defaultValue={field.value}
                        isMulti={false}
                        required
                      />
                    )
                  }}
                />
                <MessageErrorValidator>{errors?.category_name?.message as string}</MessageErrorValidator>
              </div>
              <div>
                <Input
                  register={register}
                  name="name"
                  type="text"
                  label="Nome do material"
                  autoComplete="off"
                />
                <MessageErrorValidator>{errors?.name?.message as string}</MessageErrorValidator>
              </div>
            </Line1>
            <Line2>
              <div>
                <Textarea register={register} name="description" type="text" label="Descrição do material" autoComplete="off" />
                <MessageErrorValidator>{errors?.description?.message as string}</MessageErrorValidator>
              </div>
            </Line2>
            {!materialToEdit && <>
              <Separator>
                <Book size={20} weight="bold" />
                <h2>Insira o arquivo do material (pdf ou doc):</h2>
              </Separator>
              <Line4>
                <div>
                  <DropZone
                    setFilesData={setMaterialFileData}
                    maxSize={1024 * 1024 * 5} // 5 mb
                    isMulti={false}
                    icons={false}
                    accept={{
                      'application/pdf': ['.pdf'],
                      'application/msword': ['.doc', '.docx'],
                    }}
                  />
                </div>
              </Line4>
            </>}

            {((materialToEdit !== undefined && !materialToEdit.thumbnail_url) || (materialToEdit === undefined))  && (
              <>
                <Separator>
                  <Image size={20} weight="bold" />
                  <h2>Insira a imagem de capa do material</h2>
                </Separator>
                <Line4>
                  <div>
                    <DropZone
                      setFilesData={setThumbnalFileData}
                      maxSize={1024 * 1024 / 2} // 500 kb
                      isMulti={false}
                      icons={false}
                      placeHolder="Insira a imagem de capa do material (Tamanho recomendado 225x150px)"
                      accept={{
                        'image/png': ['.png', '.jpg', '.jpeg', '.webp'],
                      }}
                    />
                  </div>
                </Line4>
              </>
            )}
            {materialToEdit && materialToEdit.thumbnail_url && (
              <>
                <Separator style={{ marginTop: '1rem' }}>
                  <Book size={20} weight="bold" />
                  <h2>Imagem destaque do material:</h2>
                </Separator>
                <div>
                  {materialToEdit.thumbnail_url ? (
                    <FakeDropZone>
                      <Poster>
                        <BtnRemove onClick={() => {
                          if (window.confirm('Deseja realmente remover a imagem de capa do material?')) {
                            setThumbnalFileData([]);
                            setValue('thumbnail', '');

                            materialToEdit.thumbnail = '';
                            materialToEdit.thumbnail_url = '';
                          }
                        }}>
                          <Trash size={20} weight="duotone" />
                        </BtnRemove>
                        <img src={materialToEdit.thumbnail_url} alt="" />
                      </Poster>
                    </FakeDropZone>
                  ) : (
                    <p>Nenhum material encontrado para esta aula.</p>
                  )}
                </div>
              </>
            )}
            <LineButton>
              <Button type="submit" loading={loadingSubmit} color="primary">
                {!!materialToEdit ? 'Atualizar Material' : 'Cadastrar Material'}
              </Button>
            </LineButton>
          </form>
        </SectionContainer1>
      </Container>
    </>
  );
}

export default CreateOrEdit;