import { IThemeProps } from "./IThemeProps";

const dark: IThemeProps = {
  colors: {
    primary: '#03bb85',
    secondary: '#79ffb8',
    tertiary: '#79ffb8',
    success: '',
    danger: '#d94545',
    warning: '',
    info: '#52da59',
    light: '',
    dark: '#3d3d3d',
    white: '#ffffff',
    muted: '#dadbe7',
    gray: '#dadbe7',
    whiteGray: '#ffffffa8',
    purple: '#aa14cb',
  },
  gradients: {
    primary: '#ffffff1f', // não mexer
    secondary: 'linear-gradient(-180deg, #22c7a5 0%, #01b590 50%)',
    tertiary: 'linear-gradient(-180deg, #052421 0%, #01b590 50%)',
    rankingGradient: 'radial-gradient(circle,#ffffff4a  0%, #ffffff00 100%)',
  },
  radius: {
    primary: '12px',
    secondary: '6px',
  },
  header: {
    header: '#052824',
    border: '#1fb3971f',
    inputSearch: '#1c6c6426',
    inputSearchBorder: '#c3b8b817',
  },
  menu: {
    buttonBackground: 'transparent',
    buttonColor: '#fff',
    buttonShadow: 'none',
    buttonShadowHover: '#03bb85',
  },
  cards: {
    primary: '#1c6c6426',
    secondary: '#182e12',
    tertiary: '#f4fffd0f',
    backgroundSpinner: '#00897580',
    backdropFilter: 'blur(35px) saturate(115%)',
    border: '#c3b8b817',
    modal: '#06242270',
  },
  modal: {
    background: '#00000080',
    border: '#17b585',
    closeButton: '#dadbe7',
  },
  background: {
    primary: 'rgb(28 108 100 / 15%)',
    secondary: '#182e12',
    tertiary: '#009576', 
  },
  shadows: {
    primary: 'rgb(0 0 0 / 18%) 3px 5px 30px 0px',
  },
  input: {
    background: '#0a322e',
    border: '#17b585',
    borderActive: '#79ffb8',
    labelColor: '#79ffb8',
  },
  specific: {
    menuTitles: '#79ffb8',
    menuBorder: 'none',
    menuButton: '#ffffff',
    menuDropDownAvatarButtonOnHover: '#ffffff0f',
    menuButtonTriggerBorder: 'transparent',
    cardGradient3D: 'linear-gradient(180deg, #008570 0%,transparent 125%)',
    cardGradientShadow: '0px 0px 70px #11111140',
    inputSearchHeaderBg: '#183a36',
    loaderBg: 'radial-gradient(#17b585 0,transparent 100%)',
    clockDown: '#183a36',
    asideModule: '#183a36',
    footerModule: '#042523',
    cardDashedBorder: '#ffffff4a',
    cardCommunity: '#0e3333',
    svgFilterRecolor: 'invert(94%) sepia(60%) saturate(681%) hue-rotate(69deg) brightness(100%) contrast(92%)',
    avatarBorderColor: '#0e3333',
    verifyBorderAnimationColor: '#ffffff20',
    wysiwygButtons: '#79ffb8',
  }
}

export default dark;
