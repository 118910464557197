import React, { useCallback, useEffect, useState } from 'react';

import api from '../../services/api';

import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Bug, CaretCircleLeft, CaretCircleRight, ChartLineUp } from 'phosphor-react';

import LoaderSpinner from '../LoaderSpinner';
import ModalConfirm from '../ModalConfirmation';
import Button from '../Button';

import { CardQuestion, ExerciseContainer, FooterButtons, InputGroup, ModalConfirmationContainer } from './styles';
import ReportQuestion from '../ReportQuestion';
import BadgeExp from '../BadgeExp';
import exp from '../../utils/expValues';

export interface IPraticeProps {
  id: string;
  user_id: string;
  total_hits: number;
  total_questions: number;
  is_finished: boolean;
  expires_at: string;
  created_at: string;
  updated_at: string;
  answers: (IAnswersProps)[];
  questions: (IQuestionsProps)[];
  is_available: boolean;
}

interface IAnswersProps {
  id: string;
  practice_id: string;
  question_id: string;
  selected_option: string;
  is_correct: boolean;
  created_at: string;
  updated_at: string;
}

export interface IQuestionsProps {
  id: string;
  cluster_id: string;
  content: string;
  total_hits: number;
  total_misses: number;
  year: string;
  difficulty: string;
  exam: string;
  created_at: string;
  updated_at: string;
  options: (OptionsEntity)[];
}

export interface OptionsEntity {
  id: string;
  question_id: string;
  content: string;
  created_at: string;
  updated_at: string;
}

interface IExerciseModuleProps {
  pratice_id: string;
}

const SimulatedModule: React.FC<IExerciseModuleProps> = ({ pratice_id }) => {
  const history = useHistory();

  // Loading
  const [loading, setLoading] = useState(true);

  // Data
  const [exercisesData, setExercisesData] = useState<IQuestionsProps[]>([]);

  // States
  const [currentExercise, setCurrentExercise] = useState(0);
  const [checkedAnswer, setCheckedAnswer] = useState('');
  const [readOnly, setReadOnly] = useState(false);

  // Modals
  const [modalConfirmSubmitSimulated, setModalConfirmSubmitSimulated] = useState(false);

  const handleChangeAnswer = useCallback((answer: string) => {
    if (!readOnly) {
      setCheckedAnswer(answer);
    }
  }, [readOnly]);


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/practices/${pratice_id}`);

        console.log(response.data);

        if (response.status === 200) {
          setExercisesData(response.data.questions);
        }

      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [pratice_id]);

  const handleNextQuestion = useCallback(() => {
    if (currentExercise < exercisesData.length - 1) {
      setCurrentExercise(currentExercise + 1);
      setCheckedAnswer('');
    }
    else {
      toast.error('Não há mais exercícios nesta aula. 🤷‍♂️', {
        duration: 6000,
        position: 'bottom-center'
      });
    }
  }, [currentExercise, exercisesData.length]);

  const handleBackQuestion = useCallback(() => {
    if (currentExercise > 0) {
      setCurrentExercise(currentExercise - 1);
      setCheckedAnswer('');
    }
    else {
      toast.error('Não há mais exercícios nesta aula. 🤷‍♂️', {
        duration: 6000,
        position: 'bottom-center'
      });
    }
  }, [currentExercise]);

  const handleSendPraticeResult = useCallback(async () => {
    try {
      const response = await api.post(`/practices/${pratice_id}/answers`, {
        answers: JSON.parse(localStorage.getItem(`@simulated-${pratice_id}`) || ''),
      });

      console.log(response.data);

      if (response.status === 200) {
        toast.success('Respostas enviadas com sucesso! 🎉', {
          duration: 6000,
          position: 'bottom-center'
        });

        setModalConfirmSubmitSimulated(false);
        localStorage.removeItem(`@simulated-${pratice_id}`);
        history.push(`/simulados`);
      }

    } catch (error: any) {
      console.log(error);

      if (error.response.data.error === 'practice-already-finished') {
        toast.error('Este simulado já foi finalizado.', { duration: 6000, position: 'bottom-center' });
        setModalConfirmSubmitSimulated(false);
        return;
      }

      if (error.response.data.error === 'invalid-answers') {
        toast.error('Você ainda não respondeu todas as questões do simulado.', { duration: 6000, position: 'bottom-center' });
        setModalConfirmSubmitSimulated(false);
        return;
      }

      toast.error('Ocorreu um erro ao enviar as respostas. Tente novamente mais tarde.', { duration: 6000, position: 'bottom-center' });

      setModalConfirmSubmitSimulated(false);
    }
  }, [pratice_id, history]);

  const updateAnswersStorage = useCallback(async (question_id: string, selected_option: string) => {

    if (localStorage.getItem(`@simulated-${pratice_id}`)) {
      const data = JSON.parse(localStorage.getItem(`@simulated-${pratice_id}`) as string);

      const question = data.find((question: IAnswersProps) => question.question_id === question_id);

      if (question) {
        question.selected_option = selected_option;
      }
      else {
        data.push({
          question_id: question_id,
          selected_option: selected_option,
        });
      }

      localStorage.setItem(`@simulated-${pratice_id}`, JSON.stringify(data));
    }
    else {
      localStorage.setItem(`@simulated-${pratice_id}`, JSON.stringify([{
        question_id: question_id,
        selected_option: selected_option,
      }]));
    }

  }, [pratice_id]);

  useEffect(() => {
    const exercise = exercisesData[currentExercise];

    if (!exercise) return;

    if (localStorage.getItem(`@simulated-${pratice_id}`)) {
      const data = JSON.parse(localStorage.getItem(`@simulated-${pratice_id}`) as string);

      const question = data.find((question: IAnswersProps) => question.question_id === exercise.id);

      if (question) {
        setCheckedAnswer(question.selected_option);
      }
      else {
        setCheckedAnswer('');
      }
    }
    else {
      setCheckedAnswer('');
    }
  }, [exercisesData, currentExercise, pratice_id]);

  useEffect(() => {
    if (checkedAnswer) {
      updateAnswersStorage(exercisesData[currentExercise].id, checkedAnswer);
    }
  }, [checkedAnswer, currentExercise, exercisesData, updateAnswersStorage]);

  const checkAllQuestionsAnswered = useCallback(() => {
    if (localStorage.getItem(`@simulated-${pratice_id}`)) {
      const data = JSON.parse(localStorage.getItem(`@simulated-${pratice_id}`) as string);

      if (data.length === exercisesData.length) {
        setModalConfirmSubmitSimulated(true)
        return true;
      }
      else {
        toast.error('Você ainda não respondeu todas as questões do simulado.', { duration: 6000, position: 'bottom-center' });

        /* find first index question is not answerd */
        const questionNotAnswered = exercisesData.findIndex((exercise) => {
          const question = data.find((question: IAnswersProps) => question.question_id === exercise.id);

          if (!question) {
            return true;
          }

          return false;
        });

        setCurrentExercise(questionNotAnswered);
      }
    }

  }, [exercisesData, pratice_id]);

  return (
    <>
      <ExerciseContainer>
        {loading && (<LoaderSpinner />)}
        <BadgeExp
          exp={exp.exerciseCorrect}
          text="para cada questão correta"
          style={{
            margin: '-50px 0px 20px 0px',
            display: 'flex',
            justifyContent: 'flex-end',
            boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.75)',
          }}
        />
        {JSON.parse(localStorage?.getItem(`@simulated-${pratice_id}`) as string)?.length > 0 && (
          <p>
            Você respondeu
            {` ${JSON.parse(localStorage?.getItem(`@simulated-${pratice_id}`) as string)?.length} ` ?? 0}
            de {exercisesData.length} questões
          </p>
        )}
        <header>
          {exercisesData?.map((exercise, index) => {

            const question = JSON.parse(localStorage.getItem(`@simulated-${pratice_id}`) as string);

            return (
              <CardQuestion
                current={exercise.id === exercisesData[currentExercise].id}
                onClick={() => { setCheckedAnswer(''); setCurrentExercise(index); }}
                className={question?.find((question: IAnswersProps) => question.question_id === exercise.id) ? 'answered' : ''}
              >
                <p>{index + 1}</p>
              </CardQuestion>
            )
          }
          )}
        </header>
        <main>
          <div>
            <h5>{exercisesData[currentExercise]?.exam} ({exercisesData[currentExercise]?.year})</h5>
            {(exercisesData[currentExercise]?.content) && (<ReactMarkdown rehypePlugins={[rehypeRaw]}>{exercisesData[currentExercise]?.content?.replace(/\\s/g, '\n')}</ReactMarkdown>)}
          </div>
        </main>
        <footer style={readOnly ? { cursor: 'not-allowed' } : { cursor: 'default' }}>
          {exercisesData[currentExercise]?.options.map((option, index) => (
            option?.content && (
              <InputGroup
                readOnly={readOnly}
                value={option?.id}
                correctAnswer={null}
                checkedAnswer={checkedAnswer}
              >
                <input
                  id={`radio-${index}`}
                  name={`radio-${index}`}
                  type="radio"
                  checked={checkedAnswer === option?.id}
                  onChange={() => { handleChangeAnswer(option?.id) }}
                />
                <label htmlFor={`radio-${index}`}>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{option?.content?.replace(/\\s/g, '\n')}</ReactMarkdown>
                </label>
              </InputGroup>
            )))}
        </footer>
        <ReportQuestion requestRoute={`/questions/${exercisesData[currentExercise]?.id}/report`} questionId={exercisesData[currentExercise]?.id} />
      </ExerciseContainer>

      {!loading && (
        <FooterButtons>
          {(currentExercise > 0) && (
            <Button
              color="tertiary"
              onClick={() => handleBackQuestion()}
            >
              <CaretCircleLeft size={28} weight="fill" />
              Questão anterior
            </Button>
          )}

          {(currentExercise < exercisesData.length - 1) && (
            <Button
              color="purple"
              onClick={() => handleNextQuestion()}
            >
              Próxima questão
              <CaretCircleRight size={28} weight="fill" />
            </Button>
          )}

          {((currentExercise === exercisesData.length - 1) && (
            <Button
              color="purple"
              onClick={() => checkAllQuestionsAnswered()}
            >
              <ChartLineUp size={20} weight="duotone" />
              Finalizar simulado
            </Button>
          ))}
        </FooterButtons>
      )}

      {/* START MODAL CONFIRM SUBMIT SIMULATED */}
      <ModalConfirm
        confirmText="Finalizar simulado"
        cancelText="Voltar"
        confirmButtonColor="#03bb85"
        onConfirm={() => handleSendPraticeResult()}
        isOpen={modalConfirmSubmitSimulated}
        isLoading={false}
        setIsOpen={() => setModalConfirmSubmitSimulated((oldValue) => !oldValue)}
        closeModal={() => setModalConfirmSubmitSimulated(false)}
      >
        <ModalConfirmationContainer>
          <h3>Deseja confirmar o envio de suas respostas?</h3>
          <p>Esta ação será irreversível, ok? 😊</p>
        </ModalConfirmationContainer>
      </ModalConfirm>
      {/* END MODAL CONFIRM SUBMIT SIMULATED */}
    </>
  );
}

export default SimulatedModule;