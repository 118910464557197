import styled from 'styled-components';

export const Container = styled.div`

`;

export const HeaderSection = styled.section`
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
`;

export const SectionContainer1 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3,1fr);

  /* MONITOR */
  @media (max-width: 1600px) {
    grid-template-columns: repeat(2,1fr);
  }

  /* NOTEBOOK */
  @media (max-width: 1125px) {
    grid-template-columns: repeat(1,1fr);
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  /* MOBILE */
  @media (max-width: 550px) {
    display: none;
  }

  > svg {
    margin-right: 1rem;
    position: absolute;
    color: ${props => props.theme.colors.muted}80;
  }

  > input {
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
    color: ${props => props.theme.colors.white};
    width: 300px;
    transition: all .3s ease-in-out;

    &:focus {
      width: 450px;
    }
  }
`;