
import styled from 'styled-components';

export const Grid = styled.section`
  position: relative;
  display: grid; 
  grid-template-columns: auto 1fr; 
  grid-template-rows: auto 1fr; 
  gap: 0px 0px; 
  width: 100%; 
  height: 100vh; 
  color: ${props => props.theme.colors.white};
  background-size: cover !important;
  transition: all .25s ease-in-out;
  overflow: hidden;

  /* GLOBAL STYLES WHITH THEME */
  
  @keyframes pulseAnimation2 {
    0% {
      box-shadow: 0 0 0 0 ${props => props.theme.specific.verifyBorderAnimationColor};
    }
    70% {
        box-shadow: 0 0 0 15px rgba(255,255,255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255,255,255, 0);
    }
  }
`;
