import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { TableColumn } from 'react-data-table-component';

import Breadcrumbs from '../../../components/Breadcrumbs';
import DataTableAdapter from '../../../components/DataTableAdapter';
import DropdownButton from '../../../components/DropdownButton';

import { FiHome, FiSettings } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';

import { BadgeDifficulty, CardTableContainer, Container, HeaderSection, QuestionContentainer } from './styles';

import api from '../../../services/api';

import IDataTable from '../../../interfaces/DataTable';
import LoaderBIO from '../../../components/Loader';

import { IQuestionsProps } from '../../../components/SimulatedModule';
import SimpleMultiSelect from '../../../components/Form/SimpleMultiSelect';

interface IFilterProps {
  exams: (SelectEntity)[];
  years: (SelectEntity)[];
  difficulties: (SelectEntity)[];
  clusters: (SelectEntity)[];
}
interface SelectEntity {
  value: string;
  label: string;
}

const Simulados: React.FC = () => {

  // Loaders
  const [loading, setLoading] = useState(false);

  // Request 
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [filterExam, setFilterExam] = useState('');

  const [exams, setExams] = useState<SelectEntity[]>([]);

  const [questionsData, setQuestionsData] = useState<IDataTable<IQuestionsProps>[]>([]);

  const difficultyVariations = {
    easy: 'Fácil',
    medium: 'Médio',
    hard: 'Difícil',
  }

  const columns: TableColumn<IQuestionsProps>[] = [
    {
      name: 'Ano',
      width: '80px',
      cell: (row: IQuestionsProps) => (
        <span>{row.year}</span>
      ),
    },
    {
      name: 'Banca',
      width: '120px',
      cell: (row: IQuestionsProps) => (
        <span>{row.exam}</span>
      ),
    },
    {
      name: 'Questão',
      wrap: true,
      minWidth: '400px',
      cell: (row: IQuestionsProps) => (
        <QuestionContentainer>
          <Link to={`/simulados/questao/${row.id}`}>{row.content}</Link>
        </QuestionContentainer>
      ),
    },
    {
      name: 'Dificuldade',
      center: true,
      cell: (row: IQuestionsProps) => (
        <BadgeDifficulty difficulty={row.difficulty}>
          {row.difficulty && difficultyVariations[row.difficulty as 'easy' | 'medium' | 'hard']}
        </BadgeDifficulty>
      ),
    },
    {
      name: '% de acertos',
      center: true,
      cell: (row: IQuestionsProps) => (
        <span>{row.total_hits + row.total_misses / row.total_misses || 0}%</span>
      ),
    },
    {
      name: (<FiSettings size={16} strokeWidth={2.5} />),
      sortable: false,
      button: true,
      right: true,
      cell: (row: IQuestionsProps, index: number) => (
        <DropdownButton key={index}>
          <ul>
            <li>
              <Link to={`/simulados/editar/${row.id}`}>
                <MdKeyboardArrowRight size={18} />
                Editar
              </Link>
            </li>
          </ul>
        </DropdownButton>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const responseFilters = await api.get<IFilterProps>(`/practices/filters`);
        setExams(responseFilters.data.exams);

        const response = await api.get(`/questions`, {
          params: {
            pageIndex: page,
            pageSize: perPage,
            search: filterText,
            exam: filterExam,
          }
        });

        setQuestionsData(response.data.records);

        setTotalRows(response.data.totalRecords);
        console.log(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [page, perPage, filterText, filterExam]);

  return (
    <>
      <LoaderBIO isVisible={loading} />

      <Container>
        <HeaderSection>
          <Breadcrumbs
            icon={FiHome}
            separator="/"
            route={[{ title: 'Questões dos simulados', link: '/simulados' }]}
          />
          <aside>
            <Link to="/simulados/questoes">Cadastrar questão</Link>
          </aside>
        </HeaderSection>

        <CardTableContainer>
          <header>
            <SimpleMultiSelect
              register={() => { }}
              name="exams"
              label="Filtre por instituição"
              placeHolder="Selecione..."
              options={exams}
              setResult={(values) => { setFilterExam(values) }}
              isMulti={false}
              selectAll={false}
              selectAllLabel="Todas as instituições"
              clearnable
              required
            />
          </header>
          <DataTableAdapter
            title="Questões dos simulados"
            subTitle={''}
            columns={columns}
            data={questionsData}
            paginationServer={true}
            perPage={perPage}
            totalRows={totalRows}
            setFilterText={setFilterText}
            setPage={setPage}
            setPerPage={setPerPage}
          />
        </CardTableContainer>
      </Container>
    </>
  );
}

export default Simulados;