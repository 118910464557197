import React from 'react';

import { IProviders } from '../../interfaces/Providers';
import ReactPlayerProvider from './providers/ReactPlayerProvider';
import PandaPlayerProvider from './providers/PandaPlayerProvider';

interface IPlayerAdapterProps {
  provider: IProviders;
  video_url: string;
  video: string;
  style?: React.CSSProperties;
  onReady?: (ref: any | null) => void;
  onPlay?: (ref: any | null) => void;
  onPause?: (ref: any | null) => void;
  onEnded?: () => void;
  onProgress?: (
    played: number,
    playedSeconds: number,
    loaded: number,
    loadedSeconds: number,
  ) => void;
}

const PlayerAdapter: React.FC<IPlayerAdapterProps> = ({ 
  provider,
  video_url,
  video,
  style,
  onReady,
  onPlay,
  onPause,
  onEnded,
  onProgress,
 }) => {

  return (
    <>
      {provider === 'panda' && (
        <PandaPlayerProvider 
          video={video}
          onEnded={onEnded}
        />
      )}

      {provider === 'vimeo' && (
      <ReactPlayerProvider 
        video_url={video_url}
        onReady={onReady}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnded}
        onProgress={onProgress}
      />
      )}
    </>
  );
}

export default PlayerAdapter;