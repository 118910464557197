import React from 'react';

import { Link } from 'react-router-dom';
import { IconProps } from 'phosphor-react';

import { Container } from './styles';



interface IBreadcrumbsProps {
  icon: React.ComponentType<IconProps>;
  separator?: string;
  padding?: string;
  route: {
    title: string;
    link: string;
  }[];
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ icon: Icon, separator, route, padding }) => {
  return (
    <Container style={ padding ? {padding} : {}}>
      <Link to="/">
        <Icon weight="bold" size={16} />
      </Link>
      <span>{separator || '/'}</span>
      {route.map((item, index) => (
        <React.Fragment key={index}>
          <Link to={`${item.link}`}>{item.title}</Link>
          {index < (route.length - 1) && <span>{separator || '/'}</span>}
        </React.Fragment>
      ))}
    </Container>
  );
}

export default Breadcrumbs;