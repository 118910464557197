import React, { useEffect, useMemo, useState } from 'react';

import { useDashboard } from '../../hooks/dashboard';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import api from '../../services/api';

import { Chat, Info, X } from 'phosphor-react';

import LoaderSpinner from '../LoaderSpinner';

import { CloseBtn, Container, Overlay, PopUpSupport } from './styles';

export interface IArticleProps {
  id: string;
  section_id: string;
  title: string;
  body: string;
  slug: string;
  is_promoted: boolean;
  abstract: string;
  vote_count: number;
  vote_sum: number;
  views: number;
  created_at: string;
  updated_at: string;
  section: {
    id: string;
    name: string;
    description: string;
    slug: string;
    icon: string;
    position: number;
    created_at: string;
    updated_at: string;
  };
}

const SupportFloatingBadge: React.FC = () => {
  const location = useLocation();
  const localtionSplited = location.pathname.split('/')[1];
  const [hide, setHide] = useState(false);
  const { user } = useAuth();
  const { dashboard } = useDashboard();

  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [relevantsArticles, setRelevantsArticles] = useState<IArticleProps[]>([]);

  const notShow = useMemo(() => [
    'simulados',
    'simulado',
    'suporte',
  ], []);

  useEffect(() => {
    const find = notShow.find((item) => item === localtionSplited);
    find ? setHide(true) : setHide(false);
  }, [localtionSplited, notShow]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (popupIsOpen) {
          const response = await api.get('/sections/articles/relevants');
          setRelevantsArticles(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [popupIsOpen]);

  return (
    <>
      {dashboard === 'student' && <>
        {!popupIsOpen &&
          (<Container style={hide ? { display: 'none' } : {}} onClick={() => setPopupIsOpen((oldValue) => !oldValue)}>
            <span>
              <Chat size={20} weight="duotone" />
              Suporte
            </span>
          </Container>
          )}
        {popupIsOpen && (
          <>
            <PopUpSupport>
              {loading && <LoaderSpinner background="#ffffff" message="Carregando..." style={{ left: 0, top: 0, margin: 0 }} />}

              <header>
                <h1>E aí, {user.show_name}! 😉</h1>
                <CloseBtn onClick={() => setPopupIsOpen(false)}><X weight="bold" /></CloseBtn>
              </header>
              <main>
                <Info size={70} weight="duotone" />
                <p>
                  Tem alguma dúvida? Relaxa! A gente lista as perguntas mais comuns pra te ajudar!
                </p>
              </main>
              <section>
                <h2>Principais sugestões</h2>
                <ol>
                  {relevantsArticles.map((article) => (
                    <li key={article.id}>
                      <Link to={`/suporte/artigos/${article.section.slug}/${article.slug}`} onClick={() => setPopupIsOpen(false)}>{article.title}</Link>
                    </li>
                  ))}
                </ol>
              </section>
              <footer>
                <p>Não encontrou o que estava procurando?</p>
                <Link to="/suporte" onClick={() => setPopupIsOpen(false)}>Central de ajuda</Link>
              </footer>
            </PopUpSupport>
            <Overlay onClick={() => setPopupIsOpen(false)} />
          </>
        )}
      </>
      }
    </>
  );
}

export default SupportFloatingBadge;