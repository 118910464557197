import { X } from 'phosphor-react';
import React, { SelectHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiX } from 'react-icons/fi';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { useDetectOutsideClick } from '../../../utils/useDetectOutsideClick';
import TooltipAdapter from '../../TooltipAdapter';

import { Container, FakeInput, FlatList, MenuNav, MenuDropDownContainer, CloseButton } from './styles';

interface ISimpleMultiSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  showLabel?: boolean;
  label: string;
  placeHolder?: string;
  register: any;
  name: string;
  isMulti: boolean;
  selectAll?: boolean;
  selectAllLabel?: string;
  defaultValue?: string;
  clearnable?: boolean;
  options: {
    value: any;
    label: any;
  }[],
  setResult(values: any): any;
}

const SimpleMultiSelect: React.FC<ISimpleMultiSelectProps> = ({ options, name, clearnable, label, selectAll, selectAllLabel, placeHolder = 'Selecione...', register, defaultValue, required, setResult, isMulti, ...rest }) => {

  // Menu user dropdown 
  const menuNavRef = useRef<HTMLDivElement>(null);
  const [menuNavIsOpen, setMenuNavIsOpen] = useDetectOutsideClick(menuNavRef, false);
  const openMenuContextAvatar = () => setMenuNavIsOpen(!menuNavIsOpen);

  const [values, setValues] = useState<string[]>([]);
  const [selecteds, setSelecteds] = useState<any>('');

  useEffect(() => {
    //.log('=========== VALUES =================')
    //console.log(values);
    //console.log('selecteds', selecteds);
    if (selecteds) {
      setResult(!isMulti ? JSON.stringify(values).replaceAll('[', '').replaceAll(']', '').replaceAll('"', '') : values);
    }
  }, [selecteds, values, setResult, isMulti]);

  useEffect(() => {
    const findOption = options.find((option) => option.value === defaultValue);

    if (findOption) {
      setValues([findOption.value]);
      setSelecteds([findOption.label]);
    }

  }, [defaultValue, options]);

  useEffect(() => {
    const nv = document.querySelector('.nvv > div');
    nv?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [menuNavIsOpen]);

  useEffect(() => {
    if (selectAll) {
      setValues(options.map((option) => option.value));
      setSelecteds(options.map((option) => option.label));
    }
  }, [selectAll, options]);

  const handleUnselectAll = useCallback(() => {
    setValues([]);
    setSelecteds([]);
  }, []);

  return (
    <Container ref={menuNavRef}>

      <MenuDropDownContainer>
        <MenuNav className={`nvv ${menuNavIsOpen ? "open" : "closed"}`}>
          <FlatList>
            <ul>
              {options.map((option, index) => (
                <li key={index} onClick={() => (!isMulti && values.length === 0) && setMenuNavIsOpen(false)}>
                  <label className={(values.find((value) => value !== option.value && !isMulti) ? 'disabledd' : 'normal')}>
                    <input
                      id={name}
                      type="checkbox"
                      value={option.value}
                      //@ts-ignore
                      disabled={(values.find((value) => value !== option.value && !isMulti) ? true : false)}
                      //@ts-ignore
                      checked={values.find((item) => item === option.value)}
                      onChange={() => {
                        const find = values.find((value) => value === option.value);

                        if (find) {
                          //console.log('controu igual')
                          let filteredArray = values.filter(item => item !== option.value);
                          setValues(filteredArray);

                          let filteredArrayLabels = selecteds.filter((item: any) => item !== option.label);
                          setSelecteds(filteredArrayLabels);
                        } else {
                          //console.log('n contem no arr igual')
                          setValues([...values, option.value]);
                          setSelecteds([...selecteds, option.label]);
                        }
                      }}
                    />
                    {option.label}
                  </label>
                </li>
              ))}
            </ul>
            <select setResult={values} {...register(name, { required })} multiple {...rest} style={{ display: 'none' }}>
              {options.map((option, index) => (
                //@ts-ignore
                <option key={index} value={option.value} selected={values.find((item) => item === option.value)}>{option.label}</option>
              ))}
            </select>
            <footer>
              <CloseButton type="button" onClick={() => setMenuNavIsOpen(!menuNavIsOpen)}>Fechar</CloseButton>
            </footer>
          </FlatList>
        </MenuNav>
      </MenuDropDownContainer>

      <FakeInput onClick={openMenuContextAvatar}>
        <label className="label-multi-select">{label}</label>
        <div className={`${menuNavIsOpen ? "openn" : "closedd"}`}>
          {selecteds.length !== options.length ? <main>
            {(values.length === selecteds.length) && (
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {(selecteds && selecteds.length !== 0) ?
                  JSON.stringify(selecteds.length > 2 ? selecteds.slice(0, 2) + `<span className="itens-count">+ ${selecteds.length - 2} ${selecteds.length - 2 > 1 ? 'items' : 'item'}</span>` : selecteds).replaceAll('[', '').replaceAll(/[\\"]/g, '').replaceAll(',', ', ').replaceAll(']', '')
                  :
                  placeHolder
                }
              </ReactMarkdown>
            )}
          </main>
            :
            <span className="itens-count" style={{ margin: 0 }}>{selectAllLabel || 'Todos'}</span>
          }
          <footer>
            {(clearnable && selecteds.length > 0) && (
              <TooltipAdapter alt="Remover selecionados" place="top">
                <button onClick={handleUnselectAll}>
                  <FiX strokeWidth={3} />
                </button>
              </TooltipAdapter>
            )}
            <aside>
              <FiChevronDown strokeWidth={3} />
            </aside>
          </footer>
        </div>
      </FakeInput>
    </Container>
  );
}

export default SimpleMultiSelect;