import styled, { css } from 'styled-components';

interface IContainerProps {
  isViewed: boolean;
  hasLabel: boolean;
}

export const Container = styled.button<IContainerProps>`
  @keyframes check {
    50% {
      transform: scale(1.2);
    }
  }

  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 12px;
  color: ${props => props.theme.colors.muted};
  cursor: pointer;

  ${({ hasLabel }) => hasLabel && css`
    padding: 0 16px;
    border: 3px solid ${props => props.theme.colors.muted}50;
    border-radius: 30px;
    height: 45px;
    background: ${props => props.theme.cards.background}54;
  `}

  span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);

    &:first-child {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      transform: scale(1);
      vertical-align: middle;
      border: 2px solid ${props => props.theme.colors.muted}80;
      transition: all 0.2s ease;

      svg {
        position: absolute;
        z-index: 1;
        top: 6px;
        left: 4px;
        fill: none;
        stroke: white;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: ${props => props.theme.colors.purple};
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 50%;
        transition-delay: 0.2s;
      }

      ${(props) => props.isViewed && css`
      border-color: #6c23c0;
      background: #6c23c0;
      animation: check 0.6s ease-in-out;

      svg {
        stroke-dashoffset: 0;
      }

      &::before {
        transform: scale(2.2);
        opacity: 0;
        transition: all 0.6s ease;
      }
    `}
    }
  }
`;