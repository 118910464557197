import React, {  useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import toast from 'react-hot-toast';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';

import Button from '../../../components/Button';
import MessageErrorValidator from '../../../components/MessageErrorValidator';

import { FiLock, FiUser } from 'react-icons/fi';

import { Container } from './styles';

import { API_S3 } from '../../../services/api_s3';

import api from '../../../services/api';

import logoWhiteVertical from '../../../assets/logo/logo-vertical-white.svg';
import { loadConfettiAllPage } from '../../../utils/useConfetti';

const RedefinirSenha: React.FC = () => {
  const history = useHistory();

  // Validation login
  const { handleSubmit, setValue, formState: { errors }, reset, register } = useForm({
    resolver: async (data, context, options) => {
      console.log('formData', data);
      console.log(
        'validation result',
        await joiResolver(schema)(data, context, options),
      );
  
      return joiResolver(schema)(data, context, options);
    },
  });

  const schema = Joi.object({
    password: Joi.string().min(6).required().messages({ '*': 'Informe um senha válida.', 'string.min': `O campo deve ter no mínimo {#limit} caracteres`, }),
    password_confirmation: Joi.any().valid(Joi.ref('password')).required().messages({ 'any.only': 'As senhas não coincidem' }),
  });

  // Token
  const [token, setToken] = useState(() => {
    const urlParams = new URLSearchParams(window?.location?.search);
    return urlParams.get('token');
  });

  //Loader
  const [buttonLoading, setButtonLoading] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!token) {
        if (!token) {
          history.push('/');
          return;
        }
      }

      setButtonLoading(true);
      console.log('data', data);
      console.log('token>>>>>>>>', token);

     const response = await api.post(`/passwords/reset`, {
        token: token,
        password: data.password,
        password_confirmation: data.password_confirmation,
      }); 

      console.log('response', response.data);

      toast.success('Senha alterada com sucesso!', {
        icon: '👍',
        duration: 5000,
        className: 'toast-samuquinha',
        position: 'bottom-right',
      });

      loadConfettiAllPage();
      history.push('/');
    }
    catch (error: any) {
      console.log(error);

      if (error.response.data.error === 'token-not-found') {
        toast.error('Token não encontrado', {
          duration: 5000,
          className: 'toast-samuquinha',
          position: 'bottom-right',
        });

        history.push('/');

        return;
      }

      if (error.response.data.error === 'token-expired') {
        toast.error('Token expirado. Por favor, solicite a redefinição de senha novamente.', {
          duration: 5000,
          className: 'toast-samuquinha',
          position: 'bottom-right',
        });
        
        return;
      }
    }
    finally {
      setButtonLoading(false);
    }
  });

  return (
    <>
      <Container style={{ backgroundImage: `url('${API_S3}/default/bg-login.jpg')` }}>
        <main>
          <section>
            <main className="main-form">
            <header>
                <img src={logoWhiteVertical} alt="" />
              </header>
              <div>
                <p>Redefina a sua senha</p>
              </div>
              <form onSubmit={onSubmit}>
                <div>
                  <aside>
                    <FiUser size={25} />
                  </aside>
                  <main>
                    <label>Nova senha:</label>
                    <input {...register("password")} type="password" />
                    {errors?.password?.message && <MessageErrorValidator>❌ {errors?.password?.message as string}</MessageErrorValidator>}
                  </main>
                </div>
                <div>
                  <aside>
                    <FiLock size={25} />
                  </aside>
                  <main>
                    <label>Repita a nova senha:</label>
                    <input {...register("password_confirmation")} type="password" />
                  </main>
                </div>
                {errors?.password_confirmation?.message && <MessageErrorValidator>❌ {errors?.password_confirmation?.message as string}</MessageErrorValidator>}
                <p>Não está conseguindo alterar sua senha? <a href="mailto:suporte@biolandia.com.br" target="_blank" rel="noreferrer">Mande-nos um e-mail.</a></p>
                <footer>
                  <Button type="submit" color="primary" loading={buttonLoading} disabled={buttonLoading}>Alterar senha</Button>
                  <p>© {new Date().getFullYear()} - Desenvolvido por: Px Tecnologia</p>
                </footer>
              </form>
            </main>
          </section>
        </main>
      </Container>
    </>
  );
}

export default RedefinirSenha;