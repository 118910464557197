import React from 'react';

import CountUp from "react-countup";

interface ICountUpAdapterProps {
  value: number;
  durationInSeconds?: number;
}

const CountUpAdapter: React.FC<ICountUpAdapterProps> = ({ value, durationInSeconds }) => {

  return (
    <CountUp end={value} duration={durationInSeconds || 3} enableScrollSpy />
  );
}

export default CountUpAdapter;