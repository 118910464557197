import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import { FiPlay } from 'react-icons/fi';

import { Container, MovieRating } from './styles';

import { IMoviesProps } from '../../interfaces/Movie';
import { Star } from 'phosphor-react';
import HetaryGroup from './HetaryGroup';

interface IMovieOfTheWeekProps {
  id: string;
  movie_id: string;
  created_at: string;
  updated_at: string;
  movie: IMoviesProps;
}

const CardMovieHighlight: React.FC = () => {

  const [currentMovie, setCurrentMovie] = useState<IMovieOfTheWeekProps>({} as IMovieOfTheWeekProps);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/movies/suggestions/last`);
        setCurrentMovie(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
      <Container to={`/sasaflix/${currentMovie?.movie?.id}`} cover={currentMovie?.movie?.cover}>
        <MovieRating>
          <aside>
            <Star size={20} weight="fill" />
            <p>{Number(String(currentMovie?.movie?.vote_average)).toFixed(1)}</p>
          </aside>
          <HetaryGroup age_group={currentMovie?.movie?.age_group} />
        </MovieRating>
        <main>
          <div>
            <FiPlay strokeWidth={2.5} size={24}  />
          </div>
          <footer>
            <h3>{currentMovie?.movie?.name}</h3>
          </footer>
        </main>
      </Container>
  );
}

export default CardMovieHighlight;
