import React, { useCallback, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { useDashboard } from '../../../hooks/dashboard';

import { Container, Icon, ButtonCollapse } from './styles';

import { FiChevronRight } from "react-icons/fi";

import menuUserLinks from '../../../routes/menu/MenuUserLinks';
import menuAdminLinks from '../../../routes/menu/menuAdminLinks';

import Logo from './Logo';

const Aside: React.FC = () => {
  const { dashboard } = useDashboard();
  const location = useLocation();
  const localtionSplited = location.pathname;

  const [collapse, setCollapese] = useState(() => {
    return localStorage.getItem("@menuPreference") === "open" ? false : true
  });

  const handleToggleMenu = useCallback(() => {
    setCollapese(oldValue => !oldValue);
    localStorage.setItem("@menuPreference", !collapse ? "collapsed" : "open");
  }, [collapse]);

  return (
    <Container collapsed={collapse}>
      <Logo menuCollapsed={collapse} />
      <ButtonCollapse onClick={handleToggleMenu} className={!collapse ? 'collapsed' : ''}>
        <FiChevronRight strokeWidth={3.5} />
      </ButtonCollapse>
      <main>
        <ul>
          {dashboard === 'student' ? (
            menuUserLinks.map((menuItem, index) => (
              <li key={index} className={`${menuItem.badge ? 'new' : ''}`}>
                <Link
                  to={menuItem.location} className={localtionSplited === menuItem.location || (localtionSplited === '' && index === 0) ? 'active' : ''}
                  data-tooltip={menuItem.title}
                >
                  <Icon>
                    <menuItem.icon size={25} weight="duotone" />
                  </Icon>
                  {!collapse && <span>{menuItem.title}</span>}
                </Link>
              </li>
            )))
            :
            (menuAdminLinks.map((menuItem, index) => (
              <li key={index}>
                <Link
                  to={menuItem.location} className={localtionSplited === menuItem.location || (localtionSplited === '' && index === 0) ? 'active' : ''}
                  data-tooltip={menuItem.title}
                >
                  <Icon>
                    <menuItem.icon size={25} weight="duotone" />
                  </Icon>
                  {!collapse && <span>{menuItem.title}</span>}
                </Link>
              </li>
            )))}
        </ul>
      </main>
    </Container>
  );
}

export default Aside;