import React, { useEffect, useLayoutEffect, useState } from 'react';

import Accordion from '../../../components/Accordion';

import { Center } from '../../../layout/components/Content/styles';
import { House } from 'phosphor-react';

import MainHeaderProfile from '../../../components/MainHeaderProfile';
import Breadcrumbs from '../../../components/Breadcrumbs';

import { CardNotification, Container, ContentGrid3, MainContent, PaginationContainer, SectionNotifications } from './styles';
import { IIconProps, INotificationsProps } from '../../../interfaces/Notifications';

import { maxWidthContent } from '../../../utils/maxWidthContent';
import api from '../../../services/api';
import { formatDistance, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import AvatarProgress from '../../../components/AvatarProgress';
import Pagination from '../../../components/Pagination';
import LoaderSpinner from '../../../components/LoaderSpinner';

const Notificacoes: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [notificationsForumData, setNotificationsForumData] = useState<INotificationsProps[]>([]);
  const [notificationsGeneralData, setNotificationsGeneralData] = useState<INotificationsProps[]>([]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [showingFrom, setShowingFrom] = useState(0);
  const [showingTo, setShowingTo] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useLayoutEffect(() => {
    const mainContentSection: any = document.querySelector('#main-scroll');
    const mainContentCenter: any = document.querySelector('#content-center');
    const mainFooter: any = document.querySelector('#main-footer-container');

    if (mainContentSection && mainContentCenter && mainFooter) {
      mainContentSection.style.padding = '0px';
      mainContentCenter.style.maxWidth = '100%';
      mainFooter.style.display = 'none';
    }

    return () => {
      if (mainContentSection && mainContentCenter) {
        mainContentSection.style.padding = '2.35rem 1rem 2rem 2rem';
        mainContentCenter.style.maxWidth = maxWidthContent;
        mainFooter.style.display = 'block';
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const [responseNotificationsForum, responseNotificationsGeneral] = await Promise.all([
          api.get(`/notifications`, {
            params: { pageIndex: currentPage - 1, pageSize: 4, type: 'forum' },
          }),
          api.get(`/notifications`, {
            params: { pageIndex: currentPage - 1, pageSize: 4, type: 'general' },
          }),
        ]);

        setNotificationsForumData(responseNotificationsForum.data.records);
        console.log(responseNotificationsForum.data);

        setNotificationsGeneralData(responseNotificationsGeneral.data.records);
        console.log(responseNotificationsGeneral.data);

        //pagination
        setShowingTo(responseNotificationsForum.data.showingTo);
        setShowingFrom(responseNotificationsForum.data.showingFrom);
        setMaxPages(responseNotificationsForum.data.maxPages);
        setTotalRecords(responseNotificationsForum.data.totalRecords);
        console.log('responseNotificationsForum.data.totalRecords', responseNotificationsForum.data.totalRecords);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      finally {
        setLoading(false);
      }
    })();
  }, [currentPage]);

  return (
    <>
      <Container>
        <MainHeaderProfile />

        <Center>
          <MainContent>
            <aside>
              <Accordion />
            </aside>
            <main>
              <Breadcrumbs
                icon={House}
                separator="/"
                route={[
                  { title: 'Notificações', link: '/notificacoes' },
                  { title: 'Minhas notificações', link: '/notificacoes' },
                ]}
                padding="0 0 2rem 0"
              />

              <SectionNotifications style={ loading ? { overflow: 'hidden', height: '250px' } : {}}>
                {loading && <LoaderSpinner backgroundTransparent={false} blur={true} />}
                <aside>
                  {notificationsForumData.map((notification, index) => (
                    <CardNotification key={index} to={notification?.link || '/notificacoes'}>
                      <AvatarProgress
                        badgeValue={
                          (notification.icon as IIconProps).level || 0
                        }
                        percent={
                          (notification.icon as IIconProps).exp % 100 || 0
                        }
                        size={64}
                        url={(notification.icon as IIconProps).avatar}
                      />
                      <p>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {notification?.content?.replace(/\\s/g, '\n')}
                        </ReactMarkdown>
                        <span>{formatDistance(parseISO(notification.created_at), new Date(), { locale: ptBR })} atrás</span>
                      </p>
                    </CardNotification>
                  ))}
                </aside>
                <main>
                  {notificationsGeneralData.map((notification, index) => (
                    <CardNotification key={index} to={notification?.link || '/notificacoes'}>
                      <AvatarProgress
                        badgeValue={
                          (notification.icon as IIconProps).level || 0
                        }
                        percent={
                          (notification.icon as IIconProps).exp % 100 || 0
                        }
                        size={64}
                        url={(notification.icon as IIconProps).avatar}
                      />
                      <p>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {notification?.content?.replace(/\\s/g, '\n')}
                        </ReactMarkdown>
                        <span>{formatDistance(parseISO(notification.created_at), new Date(), { locale: ptBR })} atrás</span>
                      </p>
                    </CardNotification>
                  ))}
                </main>
              </SectionNotifications>
              <ContentGrid3>
                <footer>
                  {!loading && (
                    <PaginationContainer>
                      <p>{`Exibindo de ${showingFrom} a ${showingTo} de ${totalRecords} notificações`}</p>
                      {totalRecords !== 0 && (
                        <Pagination count={maxPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                      )}
                    </PaginationContainer>
                  )}
                </footer>
              </ContentGrid3>
            </main>
          </MainContent>

        </Center>
      </Container>
    </>
  );
}

export default Notificacoes;