import React, { useCallback, useState } from 'react';

import { X } from 'phosphor-react';

import { CloseBtn, Container, ModalAlertContainer, ModalHome, Poster } from './styles';

interface IModalSignatueExpiresProps {
  setModalSignatueExpiresIsOpen: (value: boolean) => void;
}

const ModalSignatueExpires: React.FC<IModalSignatueExpiresProps> = ({ setModalSignatueExpiresIsOpen }) => {
  const [openModal, setOpenModal] = useState(true);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setModalSignatueExpiresIsOpen(false);
  }, [setOpenModal, setModalSignatueExpiresIsOpen]);

  return (
    <Container>
      <ModalHome
        onRequestClose={handleCloseModal}
        isOpen={openModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        closeTimeoutMS={300}
        overlayClassName="react-modal-overlay"
      >
        <ModalAlertContainer>
          <Poster>
            <CloseBtn onClick={handleCloseModal}><X weight="bold" /></CloseBtn>

            <a href="https://professorsamuelcunha.com.br" target="_blank" rel="noreferrer">
              <img src="https://samuel-cunha-api.s3.sa-east-1.amazonaws.com/default/estender-plano.jpg" alt="" />
            </a>
          </Poster>
        </ModalAlertContainer>
      </ModalHome>
    </Container>
  );
}

export default ModalSignatueExpires;