import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';

import avatar from '../../assets/modal-avatar.png';
interface ModalProps {
  size: 'sm' | 'md' | 'lg' | 'full';
  background?: string;
  border?: string;
  show3dAvatar?: boolean;
  height?: string;
}

const sizeVariations = {
  sm: css`
    max-width: 350px;
    width: calc(100% - 32px);
  `,
  md: css`
    max-width: 500px;
    width: calc(100% - 32px);
  `,
  lg: css`
    max-width: 700px;
    width: calc(100% - 32px);
  `,
  full: css`
    width: calc(100% - 32px);
  `,
};


export const Container = styled(ReactModal)<ModalProps>`
  ${props => sizeVariations[props.size]};
  position: absolute;
  margin: 0 16px;
  top: 50%;
  left: calc(50% - 16px);
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: ${props => props.theme.modal.background};
  background: ${props => props.background && props.background};
  border-radius: ${props => props.theme.radius.primary};
  color: ${props => props.theme.colors.white};
  overflow: hidden;
  
  &:before {
    display: ${props => props.show3dAvatar ? 'block' : 'none'};
    content: "";
    left: 0px;
    position: absolute;
    width: 100%;
    height: 302px;
    background: url(${avatar}) center center no-repeat;
    top: -260px;
    margin: 0px auto;
    z-index: -1;
  }
  
  /* MOBILE */
  @media (max-width: 550px) {
    max-width: 100%;
    width: 100% !important;
    height: 100%;
    border-radius: 0;
    border: none;
    
    > main {
      max-height: 100% !important;
    }
  }
  
  > main {
    position: relative;
    overflow-y: auto;
    max-height: ${props => props.height ? props.height : '300px'};
    margin-right: 0;
    padding: 1.5rem; //discount scrollbar right

    ::-webkit-scrollbar {
      width: 8px;
      width: 22px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 8px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: ${props => props.theme.colors.muted}50;
    }
  }
`;

interface IModalFixedHeader {
  border?: string;
}

export const ModalFixedHeader = styled.section<IModalFixedHeader>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: ${props => props.border || '1.5px solid' + props.theme.modal.border};

  > aside {

    > h2 {
      font-size: 24px;
    }

    > p {
      font-size: 12px;
    }
  }

  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background: #ffffff0f;
    border: 1px solid ${props => props.theme.modal.closeButton};
    box-shadow: ${props => props.theme.shadows.primary};
    margin-left: 20px;
    height: 30px;
    z-index: 1 !important;
    
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      z-index: -1 !important;

      > svg {
        color: ${props => props.theme.modal.closeButton};
      }
    }
  }
`;