import React, { useEffect } from 'react';

import { Container } from './styles';

interface IViewedLessonButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isViewed: boolean;
  label?: string;
}

const ViewedLessonButton: React.FC<IViewedLessonButtonProps> = ({ isViewed, label, ...rest }) => {
  const [viewed, setViewed] = React.useState(isViewed);

  useEffect(() => {
    setViewed(isViewed);
  }, [isViewed]);

  return (
    <Container isViewed={viewed} hasLabel={!!label} {...rest}>
      <span>
        <svg width="12px" height="9px" viewBox="0 0 12 9">
          <polyline points="1 5 4 8 11 1"></polyline>
        </svg>
      </span>

      {label}
    </Container>
  );
}

export default ViewedLessonButton;
