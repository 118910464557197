import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  text-align: center;
  padding: 1rem;
  margin: 1rem 0;
  font-weight: 600;
  color: ${props => props.theme.colors.white};

  > h1 {
    font-size: 50px;
    margin-bottom: 1rem;
    user-select: none;
  }
`;
