import React from 'react';

import { FiChevronRight } from 'react-icons/fi';

import { Container } from './styles';
import { Check, Timer, VideoCamera } from 'phosphor-react';

interface ICardModuleProps {
  id: string;
  background: string;
  title: string;
  description: string;
  inputName: string;
  link: string;
  icon?: string;
  checked?: boolean;
  className?: string;
  countLessons?: number;
  totalDuration?: string;
  onClick(): void;
}

const CardModuleCheckbox: React.FC<ICardModuleProps> = ({ background, title, description, countLessons, totalDuration, checked, onClick }) => {

  return (
    <>
      <Container background={background} onClick={onClick} checked={checked}>
        <header>
          <div>
            <h2>{title}</h2>
            <aside>
              {checked && <Check size={20} weight="bold" />}
            </aside>
          </div>
          <p>{description}</p>
        </header>
        <footer>
          <div>
            <span>
              <VideoCamera weight="duotone" /> {countLessons} aulas
            </span>
            <span>
              <Timer weight="duotone" /> {totalDuration}
            </span>
          </div>
          <p>Acessar plano de estudos <FiChevronRight /></p>
        </footer>
      </Container>
    </>
  );
}

export default CardModuleCheckbox;