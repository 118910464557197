import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';

import api from '../../../services/api';

import { useTheme } from '../../../hooks/theme';
import { useHistory, useParams } from 'react-router-dom';

import Slider from "react-slick";
import ReactStars from 'react-stars';
import toast from 'react-hot-toast';

import Modal from '../../../components/Modal';
import TooltipAdapter from '../../../components/TooltipAdapter';
import LoaderBIO from '../../../components/Loader';

import { CardContainer, ChannelsContainer, Container, Description, FakeBg, MainContent, MainHeader, ModalContainer, PLayerLogo, RatingContainer, SampleNextArrow, SamplePrevArrow, SliderContainer, TrailerButtonContainer, ViewMoreSpan } from './styles';

import { maxWidthContent } from '../../../utils/maxWidthContent';
import CardBookThumbnail from '../../../components/CardBookThumbnail';
import { IBooksProps } from '../SasaBook';
import { FilePdf } from 'phosphor-react';

const Livro: React.FC = () => {
  const { id: bookId } = useParams<{ id: string }>();
  const { theme } = useTheme();
  const history = useHistory();

  // Modals
  const [modalMovieTrailer, setModalMovieTrailer] = useState(false);

  // Loaders
  const [loadingMovie, setLoadingMovie] = useState(false);

  // Datas
  const [bookData, setBookData] = useState<IBooksProps>({} as IBooksProps);
  const [bookSuggestionsData, setNookSuggestionsData] = useState<IBooksProps[]>([]);

  const [showAllSummaryText, setShowAllSummaryText] = useState(false);

  useLayoutEffect(() => {
    const mainContentSection: any = document.querySelector('#main-scroll');
    const mainContentCenter: any = document.querySelector('#content-center');
    const mainFooter: any = document.querySelector('#main-footer-container');

    if (mainContentSection && mainContentCenter && mainFooter) {
      mainContentSection.style.padding = '0px';
      mainContentCenter.style.maxWidth = '100%';
      mainFooter.style.display = 'none';
    }

    return () => {
      if (mainContentSection && mainContentCenter) {
        mainContentSection.style.padding = '2.35rem 1rem 2rem 2rem';
        mainContentCenter.style.maxWidth = maxWidthContent;
        mainFooter.style.display = 'block';
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!bookId) return;
        setLoadingMovie(true);

        const response = await api.get(`/books/${bookId}`);

        setBookData(response.data);
      } catch (error: any) {
        console.log(error);
        setLoadingMovie(false);

        if (error.response.status === 400) {
          toast.error('Desculpe, este Livro não foi encontrado! 😥', {
            duration: 5000,
            position: 'bottom-right',
            className: 'toast-samuquinha',
          });

          history.push('/sasabooks');
        }
      }
      finally {
        setLoadingMovie(false);
      }
    })();
  }, [bookId, history]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/books/random`, {
          params: { quantity: 18 }
        });

        setNookSuggestionsData(response.data);
      } catch (error) {
        console.log(error);
        toast.error('Erro ao carregar Livros sugeridos');
      }
    })();
  }, []);

  const handleOpenRatingModal = useCallback(async (newRating: number) => {

    if (bookData.user_vote) {
      toast.error('Ops! Você já opinou sobre esse Livro ', {
        duration: 3000,
        position: 'bottom-right',
        className: 'toast-samuquinha',
      });

      return;
    }

    const response = await api.post(`/books/${bookId}/vote`, {
      stars: newRating,
    });

    console.log(Number(response.data));

    setBookData((oldbookData) => ({
      ...oldbookData,
      user_vote: String(newRating),
      vote_average: Number(response.data),
      vote_count: Number(oldbookData.vote_count + 1),
    }));
  }, [bookId, bookData.user_vote]);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow>{/* <FiArrowLeft size={22} /> */}</SamplePrevArrow>,
    nextArrow: <SampleNextArrow>{/* <FiArrowRight size={22} /> */}</SampleNextArrow>,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <LoaderBIO isVisible={loadingMovie || !bookData.id} />
      <FakeBg style={theme === 'dark' ? { backgroundColor: '#009576' } : { backgroundColor: '#f7fafc' }} />
      <Container>
        <MainHeader cover_url={bookData.cover_url}>
          <section>
            <header>
              <main>
                <section>
                  <aside onClick={() => setModalMovieTrailer(!modalMovieTrailer)}>
                    <CardBookThumbnail
                      key={bookData.id}
                      id={bookData.id}
                      name={bookData.title}
                      cover_url={bookData.cover_url}
                      duration={Number(bookData.publishing_year)}
                      rating={bookData.vote_average}
                      year={bookData.publishing_year}
                      vote_average={bookData.vote_average}
                      height="400px"
                      isHighlight
                    />
                  </aside>
                  <main>
                    <h1>{bookData.title} ({bookData.publishing_year})</h1>
                    <h4>Autor: {bookData.author_name}</h4>
                    <h4 className="duration">Páginas: {bookData.number_of_pages}</h4>
                    <RatingContainer>
                      <TooltipAdapter alt={
                        !bookData.user_vote ? 'Avalie este Livro' :
                          `${Number(bookData?.vote_count)} voto${Number(bookData.vote_count) === 1 ? '' : 's'}`
                      }
                        place="top"
                        cursor={!bookData.user_vote ? 'pointer' : 'default'}
                      >
                        <ReactStars
                          count={5}
                          edit={bookData.user_vote ? false : true}
                          size={32}
                          color1={theme === 'dark' ? '#fff' : '#adafca'}
                          color2="#e8f262"
                          half={true}
                          value={Number(bookData?.user_vote)}
                          onChange={(newRating: number) => handleOpenRatingModal(newRating)}
                        />
                      </TooltipAdapter>
                      <p>{Number(bookData.vote_count)} voto{Number(bookData.vote_count) === 1 ? '' : 's'}</p>
                    </RatingContainer>
                    <Description className='description'>
                      <p>{showAllSummaryText ? bookData.summary : `${String(bookData.summary).slice(0, 700)}... `}
                        {!showAllSummaryText && String(bookData.summary).length > 700 ?
                          <ViewMoreSpan onClick={() => { setShowAllSummaryText(!showAllSummaryText) }}>Ver mais</ViewMoreSpan> :
                          <ViewMoreSpan onClick={() => { setShowAllSummaryText(!showAllSummaryText) }}>Ver menos</ViewMoreSpan>
                        }
                      </p>

                    </Description>
                    <ChannelsContainer>
                      {bookData.material_url && (
                        <main>
                          <a href={bookData.material_url} title="Lista de exercícios sobre o livro" target="_blank" rel="noreferrer">
                            <FilePdf weight="duotone" size={20} /> Lista de exercícios sobre o livro
                          </a>
                        </main>
                      )}
                    </ChannelsContainer>
                  </main>
                </section>
                <MainContent>
                  <SliderContainer>
                    {bookSuggestionsData && <Slider {...settings}>
                      {bookSuggestionsData.map((suggest) => (
                        <CardContainer key={suggest.id}>
                          <CardBookThumbnail
                            key={suggest.id}
                            id={suggest.id}
                            name={suggest.title}
                            cover_url={suggest.cover_url}
                            duration={Number(suggest.publishing_year)}
                            rating={suggest.vote_average}
                            year={suggest.publishing_year}
                            vote_average={suggest.vote_average}
                            height="350px"
                          />
                        </CardContainer>
                      ))}
                    </Slider>}
                  </SliderContainer>
                </MainContent>
              </main>
            </header>
          </section>
        </MainHeader>
      </Container>
    </>
  );
}

export default Livro;